export const SUBMIT_ITEM = "SUBMIT_ITEM";
export const SUBMIT_ITEM_SUCCESS = "SUBMIT_ITEM_SUCCESS";
export const LOAD_ITEM = "LOAD_ITEM";
export const LOAD_ITEM_SUCCESS = "LOAD_ITEM_SUCCESS";
export const LOAD_LIVE_ITEM = "LOAD_LIVE_ITEM";
export const LOAD_LIVE_ITEM_SUCCESS = "LOAD_LIVE_ITEM_SUCCESS";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const UPDATE_ITEM_SUCCESS = "UPDATE_ITEM_SUCCESS";
export const RETURN_ITEM = "RETURN_ITEM";
export const RETURN_ITEM_SUCCESS = "RETURN_ITEM_SUCCESS";
export const LOAD_PRODUCT_DETAILS = "LOAD_PRODUCT_DETAILS";
export const LOAD_PRODUCT_DETAILS_SUCCESS = "LOAD_PRODUCT_DETAILS_SUCCESS";
export const LOAD_PRODUCT_DETAILS_FOR_MODEL = "LOAD_PRODUCT_DETAILS_FOR_MODEL";
export const LOAD_PRODUCT_DETAILS_FOR_MODEL_SUCCESS =
  "LOAD_PRODUCT_DETAILS_FOR_MODEL_SUCCESS";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const LOAD_CATEGORIES = "LOAD_CATEGORIES";
export const LOAD_CATEGORIES_SUCCESS = "LOAD_CATEGORIES_SUCCESS";
export const ADD_BRAND = "ADD_BRAND";
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS";
export const ADD_MODEL = "ADD_MODEL";
export const ADD_MODEL_SUCCESS = "ADD_MODEL_SUCCESS";
export const LOAD_MODELS = "LOAD_MODELS";
export const LOAD_MODELS_SUCCESS = "LOAD_MODELS_SUCCESS";
export const LOAD_EXPIRED_ITEMS = "LOAD_EXPIRED_ITEMS";
export const LOAD_EXPIRED_ITEMS_SUCCESS = "LOAD_EXPIRED_ITEMS_SUCCESS";
export const LOAD_SELLERWISE_ITEMS = "LOAD_SELLERWISE_ITEMS";
export const LOAD_SELLERWISE_ITEMS_SUCCESS = "LOAD_SELLERWISE_ITEMS_SUCCESS";
export const ITEM_API_ERROR = "ITEM_API_ERROR";
