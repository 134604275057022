import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import showNotification from "../../helpers/show_notification";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import TagsInput from "react-tagsinput";

import { postSubmitForm } from "../../helpers/forms_helper";
// actions
import exportFromJSON from "export-from-json";
const AllProductCategories = (props) => {
  const { SearchBar } = Search;
  useEffect(() => {
    loadAllSections();
  }, []);

  const [allSections, setAllSections] = useState([]);
  const loadAllSections = async () => {
    let url = process.env.REACT_APP_BASEURL + "productsections/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllSections(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const handleValidUpdate = async (e, v) => {
    try {
      const object = {
        id: selectedSection._id,
        section: v.name,
        category: categories,
      };

      let url = process.env.REACT_APP_BASEURL + "productsections/update";
      let response = await postSubmitForm(url, object);
      if (response.status === 1) {
        loadAllSections();
        setCategories([]);
        setEditModal(!editModal);
        showNotification(response.message, "Success");
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };

  const [selectedSection, setSelecetedSection] = useState();
  const [categories, setCategories] = useState();
  const [editModal, setEditModal] = useState();
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Section Name"),
      //formatter: employeeFormatter,
      dataField: "section",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "16%" };
      },
    },
    {
      text: props.t("Categories"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            {row.category.map((category) => {
              return (
                <span class="font-size-12 mr-2 badge-soft-success badge badge-success badge-pill">
                  {category}
                </span>
              );
            })}
          </>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "35%" };
      },
    },
    {
      text: props.t("Action"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <span className="text-info">
            <i
              className="bx bxs-edit font-size-15"
              title="Click to Edit"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEditModal(!editModal);
                setSelecetedSection(row);
                setCategories(row.category);
              }}
            ></i>
          </span>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];
  const handleDownload = async () => {
    const fileName = "allproductCategories";
    const exportType = "xls";

    let data_to_export = allSections;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          category: v.category,
          section: v.section,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Product Section")}
            breadcrumbItem={props.t("Update Product Section")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Product Sections")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t(
                      "View/Update all your existing product sections here"
                    )}
                  </CardSubtitle>

                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"  "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>

                  <div id="printDiv">
                    <ToolkitProvider
                      bootstrap4
                      keyField="_id"
                      data={allSections && allSections}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}>
                              {" "}
                              <CardTitle>Product Category</CardTitle>
                            </Col>
                          </Row>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>

                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update Product Section")}
                    </ModalHeader>
                    <ModalBody>
                      {selectedSection && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm onValidSubmit={handleValidUpdate}>
                                    <Row>
                                      <Col lg={6}>
                                        <AvField
                                          name="name"
                                          value={selectedSection.section}
                                          label={props.t("Section")}
                                          placeholder={props.t(
                                            "Enter Section Name"
                                          )}
                                          type="text"
                                          validate={{
                                            required: {
                                              value: true,
                                              errorMessage: props.t(
                                                "Name cannot be empty"
                                              ),
                                            },
                                          }}
                                        />
                                      </Col>{" "}
                                      <Col lg={6}>
                                        <Label>
                                          {props.t("Category Name")}
                                        </Label>{" "}
                                        ({props.t("press ENTER/TAB to add")})
                                        <TagsInput
                                          value={categories}
                                          onChange={(tags) => {
                                            setCategories(tags);
                                          }}
                                          onlyUnique={true}
                                          inputProps={{
                                            className: "react-tagsinput-input",
                                            placeholder: props.t("Add..."),
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                            >
                                              {props.t("Update")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(
  connect(null, {})(withNamespaces()(AllProductCategories))
);
