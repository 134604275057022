import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { postSubmitForm } from "../../../helpers/forms_helper";
import moment from "moment";

const BranchPORequests = (props) => {
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [allBranches, setAllBranches] = useState([]);
  const { SearchBar } = Search;
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    setDateFrom(formatDate(firstDay));
    setDateTo(formatDate(currentDay));
    loadAllBranches();
  }, []);
  // useEffect(() => {
  //   loadAllPORequests();
  // }, []);
  useEffect(() => {
    if (dateFrom && dateTo) {
      loadAllPORequests();
    }
  }, [dateFrom, dateTo, selectedBranch, selectedStatus]);

  const [allPORequests, setAllPORequests] = useState([]);
  const loadAllPORequests = async () => {
    preloader(true);
    let url = process.env.REACT_APP_BASEURL + "branchporequests/getall";
    const response = await postSubmitForm(url, {
      date_from: dateFrom,
      date_to: dateTo,
    });
    if (response && response.status === 1) {
      preloader(false);
      setAllPORequests(response.data);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [filteredOrders, setFilteredOrders] = useState([]);
  useEffect(() => {
    if (selectedStatus == "All" && selectedBranch == "All") {
      console.log("1");
      setFilteredOrders(allPORequests);
    } else if (selectedStatus == "All" && selectedBranch !== "All") {
      let arr = allPORequests.filter(
        (row) =>
          row.branch_details && row.branch_details.name === selectedBranch
      );

      setFilteredOrders(arr);
    } else if (selectedStatus !== "All" && selectedBranch == "All") {
      let arr = allPORequests.filter((row) => row.status === selectedStatus);

      setFilteredOrders(arr);
    } else if (selectedStatus !== "All" && selectedBranch !== "All") {
      let arr = allPORequests.filter(
        (row) =>
          row.status === selectedStatus &&
          row.branch_details &&
          row.branch_details.name === selectedBranch
      );

      setFilteredOrders(arr);
    }
  }, [allPORequests, selectedBranch, selectedStatus]);

  const columns = [
    {
      dataField: "_id",
      hidden: true,
      style: { cursor: "pointer" },
    },
    {
      text: props.t("#"),
      dataField: "_id",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      style: { cursor: "pointer" },
    },
    {
      text: props.t("Request ID"),
      dataField: "request_id",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
      style: { cursor: "pointer" },
    },
    {
      text: props.t("Branch Name"),
      dataField: "branch_details.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "30%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "CreateDate",
      text: props.t("Date"),
      formatter: (cell, row) =>
        moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
      style: { cursor: "pointer" },
    },
    {
      text: props.t("Status"),
      dataField: "status",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
    },
  ];
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      window
        .open(
          `/branch_po_generation?id=${row._id}&branch_id=${row.branch_details._id}&branch_name=${row.branch_details.name}`,
          "_blank"
        )
        .focus();
    },
  };
  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.status === "Approved") {
      style.backgroundColor = "#fffbb8";
    }
    return style;
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            title={props.t("Procurement")}
            alternateTitle={props.t("Branch PO Requests")}
            breadcrumbItem1={props.t("Branch PO Requests")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="branch"
                          label={props.t("Branch")}
                          type="select"
                          value={selectedBranch}
                          onChange={(e) => {
                            setSelectedBranch(e.target.value);
                          }}
                        >
                          <option value="All">{props.t("All")}</option>
                          {allBranches &&
                            allBranches.map((branch) => {
                              return (
                                <option value={branch.name}>
                                  {branch.name}
                                </option>
                              );
                            })}
                        </AvField>
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="branch"
                          label={props.t("Status")}
                          type="select"
                          value={selectedStatus}
                          onChange={(e) => {
                            setSelectedStatus(e.target.value);
                          }}
                        >
                          <option value="All">{props.t("All")}</option>
                          <option value="Closed">{props.t("Closed")}</option>
                          <option value="Pending">{props.t("Pending")}</option>
                          <option value="Approved">
                            {props.t("Approved")}
                          </option>
                        </AvField>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Branch PO Requests")} </CardTitle>

                  <ToolkitProvider
                    bootstrap4
                    keyField="_id"
                    data={filteredOrders && filteredOrders}
                    columns={columns}
                    noDataIndication={props.t("No data to display.")}
                    search
                  >
                    {(props) => (
                      <div>
                        <Row>
                          <Col lg={4}>
                            <SearchBar
                              {...props.searchProps}
                              style={{ width: "300px" }}
                            />
                          </Col>
                          <Col lg={4}></Col>
                          <Col lg={4} className="text-right">
                            {/* <button
                              className="btn-sm btn btn-info"
                              onClick={() => {
                                setAddInventoryModal(!addInventoryModal);
                              }}
                            >
                              Add Item
                            </button> */}
                          </Col>
                        </Row>
                        <BootstrapTable
                          rowEvents={rowEvents}
                          striped
                          hover
                          condensed
                          rowStyle={rowStyle}
                          {...props.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(BranchPORequests))
);
