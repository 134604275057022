import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";

import { postSubmitForm } from "../../helpers/forms_helper";
import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

import swal from "sweetalert2";
const AllOrders = (props) => {
  useEffect(() => {
    loadAllOrders({ status: "All" });
  }, []);
  const [allOrders, setAllOrders] = useState();
  const loadAllOrders = async (criteria) => {
    try {
      let url = process.env.REACT_APP_BASEURL + "orders/getbystatus";
      const response = await postSubmitForm(url, criteria);

      if (response && response.status === 1) {
        setAllOrders(response.data);
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error, "Error");
    }
  };
  function showNotification(message, type) {
    var title = type;

    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const [selectedItem, setselectedItem] = useState();
  const [isModal, setModal] = useState();
  const onRowSelect = async (row, isSelected, e) => {
    setselectedItem(row);
    setModal(!isModal);
  };
  const selectRowProp = {
    mode: "radio",
    clickToSelect: true,
    onSelect: onRowSelect,
    columnWidth: "20px",
  };
  const pagination_options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: props.enquiries && props.enquiries.length,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 10, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 2, // the pagination bar size.
    prePage: "Prev", // Previous page button text
    nextPage: "Next", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    paginationShowsTotal: true, // Accept bool or function
    paginationPosition: "bottom", // default is bottom, top and both is all available
    hideSizePerPage: true, //> You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    // withFirstAndLast: false > Hide the going to First and Last page button
  };
  function handleOrderStatusChange(e) {
    loadAllOrders({ status: e.target.value });
  }
  const role = localStorage.getItem("role");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Orders")}
            breadcrumbItem={props.t("All Orders")}
          />
          <Row>
            <Col className="col-lg-6 col-sm-12">
              <Card>
                <CardBody>
                  <Col lg={12}>
                    <AvForm>
                      <AvField
                        name="order_status"
                        label={props.t("Search on Order Status")}
                        type="select"
                        onChange={handleOrderStatusChange}
                      >
                        <option value="All">{props.t("All")}</option>

                        <option value="Booked">{props.t("Booked")}</option>
                        <option value="Sold">{props.t("Sold")}</option>
                        <option value="Delivered">
                          {props.t("Delivered")}
                        </option>
                        <option value="Closed">{props.t("Closed")}</option>
                      </AvField>
                    </AvForm>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Orders")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all existing orders here")}
                  </CardSubtitle>

                  <BootstrapTable
                    data={allOrders && allOrders}
                    selectRow={selectRowProp}
                    pagination={true}
                    options={pagination_options}
                    striped
                    search
                    ignoreSinglePage
                    responsive
                    hover
                  >
                    <TableHeaderColumn
                      dataField="_id"
                      isKey={true}
                      hidden={true}
                      width="70"
                      tdStyle={{ whiteSpace: "normal" }}
                    ></TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="selling_price"
                      width="70"
                      tdStyle={{ whiteSpace: "normal" }}
                    >
                      {props.t("Selling Price")}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="discount"
                      width="70"
                      tdStyle={{ whiteSpace: "normal" }}
                    >
                      {props.t("Discount")}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="final_price"
                      width="70"
                      tdStyle={{ whiteSpace: "normal" }}
                    >
                      {props.t("Final Price")}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="booking_amount"
                      width="70"
                      tdStyle={{ whiteSpace: "normal" }}
                    >
                      {props.t("Booking Amount")}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="payment_method"
                      width="70"
                      tdStyle={{ whiteSpace: "normal" }}
                    >
                      {props.t("Payment Method")}
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="status"
                      width="70"
                      tdStyle={{ whiteSpace: "normal" }}
                    >
                      {props.t("Status")}
                    </TableHeaderColumn>
                  </BootstrapTable>

                  <Modal
                    size="lg"
                    isOpen={isModal}
                    toggle={() => setModal(!isModal)}
                  >
                    <ModalHeader toggle={() => setModal(!isModal)}>
                      {props.t("Plate Number")}:{" "}
                      {selectedItem &&
                        selectedItem.product_details.plate_number}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <FormGroup className="mb-0 text-center">
                            <label for="update_call_type" class="">
                              {props.t("Order Status")}:{" "}
                              {selectedItem && selectedItem.status}
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <hr />
                      <AvForm>
                        <Row>
                          <Col className="col-2">
                            <p>{props.t("Selling Price")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem && selectedItem.selling_price}
                              </strong>
                            </p>
                          </Col>
                          <Col className="col-2">
                            <p>{props.t("Discount")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem && selectedItem.discount}
                              </strong>
                            </p>
                          </Col>
                          <Col className="col-2">
                            <p>{props.t("Final Price")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem && selectedItem.final_price}
                              </strong>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-2">
                            <p>{props.t("Booking Amount")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem && selectedItem.booking_amount}
                              </strong>
                            </p>
                          </Col>
                          <Col className="col-2">
                            <p>{props.t("Discount Remarks")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem && selectedItem.discount_remarks}
                              </strong>
                            </p>
                          </Col>
                          <Col className="col-2">
                            <p>{props.t("Remarks")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem && selectedItem.remarks}
                              </strong>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-2">
                            <p>{props.t("Buyer Name")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.buyer_details.name}
                              </strong>
                            </p>
                          </Col>
                          <Col className="col-2">
                            <p>{props.t("Buyer Qatar ID")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.buyer_details.qatar_id}
                              </strong>
                            </p>
                          </Col>
                          <Col className="col-2">
                            <p>{props.t("Buyer Mobile")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.buyer_details.mobile}
                              </strong>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-2">
                            <p>{props.t("Sales Executive Name")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.sales_executive.name}
                              </strong>
                            </p>
                          </Col>
                          <Col className="col-2">
                            <p>{props.t("Sales Executive Mobile")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.sales_executive.mobile}
                              </strong>
                            </p>
                          </Col>
                          <Col className="col-2">
                            <p>{props.t("Sales Executive Username")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.sales_executive.username}
                              </strong>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-2">
                            <p>{props.t("Insurance")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem && selectedItem.insurance.status
                                  ? "Yes"
                                  : "No"}
                              </strong>
                            </p>
                          </Col>
                          {selectedItem && selectedItem.insurance.status ? (
                            <>
                              <Col className="col-2">
                                <p>{props.t("Insurance Amount")}:</p>
                              </Col>
                              <Col className="col-2">
                                <p>
                                  <strong className="text-primary">
                                    {selectedItem &&
                                      selectedItem.insurance.amount}
                                  </strong>
                                </p>
                              </Col>
                            </>
                          ) : null}
                          <Col className="col-2">
                            <p>{props.t("Registration")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                selectedItem.registration.status
                                  ? "Yes"
                                  : "No"}
                              </strong>
                            </p>
                          </Col>
                          {selectedItem && selectedItem.registration.status ? (
                            <>
                              <Col className="col-2">
                                <p>{props.t("Registration Amount")}:</p>
                              </Col>
                              <Col className="col-2">
                                <p>
                                  <strong className="text-primary">
                                    {selectedItem &&
                                      selectedItem.registration.amount}
                                  </strong>
                                </p>
                              </Col>
                            </>
                          ) : null}
                          <Col className="col-2">
                            <p>{props.t("Payment Method")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem && selectedItem.payment_method}
                              </strong>
                            </p>
                          </Col>
                        </Row>
                      </AvForm>

                      <hr />
                      <Row>
                        <Col lg={12}>
                          <FormGroup className="mb-0 text-center">
                            <label for="update_call_type" class="">
                              {props.t("Car Details")}
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>

                      <hr />
                      <AvForm>
                        <Row>
                          <Col className="col-2">
                            <p>{props.t("Plate Number")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.product_details.plate_number}
                              </strong>
                            </p>
                          </Col>
                          <Col className="col-2">
                            <p>{props.t("Seller Name")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.product_details.seller_details
                                    .name}
                              </strong>
                            </p>
                          </Col>
                          <Col className="col-2">
                            <p>{props.t("Seller Mobile")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.product_details.seller_details
                                    .mobile}
                              </strong>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-2">
                            <p>{props.t("Make")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.product_details.car_details.make}
                              </strong>
                            </p>
                          </Col>
                          <Col className="col-2">
                            <p>{props.t("Model")}:</p>{" "}
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.product_details.car_details
                                    .model}
                              </strong>
                            </p>
                          </Col>
                          <Col className="col-2">
                            <p>{props.t("Variant")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.product_details.car_details
                                    .variant}
                              </strong>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-2">
                            <p>{props.t("Color")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.product_details.color}
                              </strong>
                            </p>
                          </Col>
                          <Col className="col-2">
                            <p>{props.t("Mileage")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.product_details.mileage}
                              </strong>
                            </p>
                          </Col>
                          <Col className="col-2">
                            <p>{props.t("Year")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.product_details.year}
                              </strong>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-2">
                            <p>{props.t("Warranty Status")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                selectedItem.product_details.warranty_status
                                  ? "Yes"
                                  : "No"}
                              </strong>
                            </p>
                          </Col>
                          <Col className="col-2">
                            <p>{props.t("Warranty Period")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.product_details.warranty_period}
                              </strong>
                            </p>
                          </Col>
                          <Col className="col-2">
                            <p>{props.t("Remarks")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.product_details.remarks}
                              </strong>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-2">
                            <p> {props.t("Description")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.product_details.description}
                              </strong>
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="col-2">
                            <p>{props.t("Car Type")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.product_details.type}
                              </strong>
                            </p>
                          </Col>

                          <Col className="col-2">
                            <p>{props.t("Asking Price (QR)")}:</p>
                          </Col>
                          <Col className="col-2">
                            <p>
                              <strong className="text-primary">
                                {selectedItem &&
                                  selectedItem.product_details.asking_price}
                              </strong>
                            </p>
                          </Col>
                          {selectedItem &&
                          selectedItem.product_details.type === "Owned" ? (
                            <>
                              <Col className="col-2">
                                <p> {props.t("Cost Price")}:</p>
                              </Col>
                              <Col className="col-2">
                                <p>
                                  <strong className="text-primary">
                                    {selectedItem &&
                                      selectedItem.product_details.cost_price}
                                  </strong>
                                </p>
                              </Col>
                            </>
                          ) : null}
                          {selectedItem &&
                          selectedItem.product_details.type === "Owned" ? (
                            <>
                              <Col className="col-2">
                                <p>{props.t("Expense Amount")}:</p>
                              </Col>
                              <Col className="col-2">
                                <p>
                                  {" "}
                                  <strong className="text-primary">
                                    {selectedItem &&
                                      selectedItem.product_details
                                        .expense_amount}
                                  </strong>
                                </p>
                              </Col>
                              <Col className="col-2">
                                <p>{props.t("Expense Remark")}:</p>
                              </Col>
                              <Col className="col-2">
                                <p>
                                  {" "}
                                  <strong className="text-primary">
                                    {selectedItem &&
                                      selectedItem.product_details
                                        .expense_remarks}
                                  </strong>
                                </p>
                              </Col>
                            </>
                          ) : null}
                          {selectedItem &&
                          selectedItem.product_details.type === "Registered" ? (
                            <>
                              <Col className="col-2">
                                <p>{props.t("Profit Type")}:</p>
                              </Col>
                              <Col className="col-2">
                                <p>
                                  {" "}
                                  <strong className="text-primary">
                                    {selectedItem &&
                                      selectedItem.product_details.profit_type}
                                  </strong>
                                </p>
                              </Col>
                              <Col className="col-2">
                                <p>
                                  {selectedItem.product_details.profit_type ===
                                  "Fixed"
                                    ? props.t("Profit Amount (QR)")
                                    : props.t("Profit Percent")}
                                  :
                                </p>
                              </Col>
                              <Col className="col-2">
                                <p>
                                  {" "}
                                  <strong className="text-primary">
                                    {selectedItem &&
                                      selectedItem.product_details.profit_value}
                                  </strong>
                                </p>
                              </Col>
                            </>
                          ) : null}
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <hr />
                          </Col>
                          <Col lg={4}>
                            <p>{props.t("Image 1")}:</p>
                            <img
                              src={
                                selectedItem &&
                                selectedItem.product_details.image1
                              }
                              alt="Image 1"
                              width="150"
                            />
                          </Col>
                          <Col lg={4}>
                            <p>{props.t("Image 2")}:</p>
                            <img
                              src={
                                selectedItem &&
                                selectedItem.product_details.image2
                              }
                              alt="Image 2"
                              width="150"
                            />
                          </Col>
                          <Col lg={4}>
                            <p>{props.t("Image 3")}:</p>
                            <img
                              src={
                                selectedItem &&
                                selectedItem.product_details.image3
                              }
                              alt="Image 3"
                              width="150"
                            />
                          </Col>
                          <Col lg={4}>
                            <p>{props.t("Image 4")}:</p>
                            <img
                              src={
                                selectedItem &&
                                selectedItem.product_details.image4
                              }
                              alt="Image 4"
                              width="150"
                            />
                          </Col>
                          <Col lg={4}>
                            <p>{props.t("Image 5")}:</p>
                            <img
                              src={
                                selectedItem &&
                                selectedItem.product_details.image5
                              }
                              alt="Image 5"
                              width="150"
                            />
                          </Col>
                        </Row>
                      </AvForm>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const {} = state.Sellers;
  return {};
};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(AllOrders))
);
