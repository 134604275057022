import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
  Input,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";

import {
  postSubmitForm,
  postSubmitForm_withformdata,
} from "../../../helpers/forms_helper";
import moment from "moment";

const BranchPORequests = (props) => {
  const [POItems, setPOItems] = useState([]);
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const { SearchBar } = Search;
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    setDateFrom(formatDate(firstDay));
    setDateTo(formatDate(currentDay));
  }, []);

  // useEffect(() => {
  //   loadAllPORequests();
  // }, []);

  useEffect(() => {
    if (dateFrom && dateTo) {
      loadAllPORequests();
    }
  }, [dateFrom, dateTo]);

  const [allPORequests, setAllPORequests] = useState([]);
  const loadAllPORequests = async () => {
    preloader(true);
    let url = process.env.REACT_APP_BASEURL + "branchporequests/getall";
    const response = await postSubmitForm(url, {
      date_from: dateFrom,
      date_to: dateTo,
    });
    if (response && response.status === 1) {
      preloader(false);
      setAllPORequests(response.data);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const [itemsModal, setItemsModal] = useState();
  const [selectedPO, setSelectedPO] = useState();
  function editFormatter(cell, row) {
    if (row.purchaseorders.length > 0) {
      return (
        <>
          <span className=" mr-2">
            <i
              className="mdi mdi-text-box-search font-size-15"
              title="View Details"
              style={{ cursor: "pointer" }}
              onClick={async () => {
                let url =
                  process.env.REACT_APP_BASEURL + "purchaseorders/get_by_id";
                const response = await postSubmitForm(url, {
                  id: row.purchaseorders[0]._id,
                });

                if (response && response.status === 1) {
                  setSelectedPO(response.data);
                  let arr = JSON.parse(JSON.stringify(row.items));
                  arr.forEach((obj) => (obj.expiry_date = "2025-12-31"));
                  setPOItems(arr);
                  setInvoiceNumber();
                  setInvoice();
                } else {
                  showNotification(response.message, "Error");
                }

                setItemsModal(!itemsModal);
              }}
            ></i>
          </span>
          <span className="mr-2 text-info">
            <a
              href={"/purchase_order?po_id=" + row.purchaseorders[0]._id}
              target="_blank"
              className="font-size-11"
            >
              <i
                className="mdi mdi-printer font-size-15"
                title="Print PO"
                style={{ cursor: "pointer" }}
              ></i>
            </a>
          </span>
        </>
      );
    }
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
      style: { cursor: "pointer" },
    },
    {
      text: props.t("#"),
      dataField: "_id",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("Request ID"),
      dataField: "request_id",
      sort: true,
    },
    // {
    //   text: props.t("Branch Name"),
    //   dataField: "branch_details.name",
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "30%" };
    //   },
    // },
    {
      dataField: "CreateDate",
      text: props.t("Date"),
      formatter: (cell, row) =>
        moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
      sort: true,
    },
    {
      text: props.t("Status"),
      dataField: "status",
      sort: true,
    },
    {
      text: props.t("PO Status"),
      dataField: "purchaseorders[0].status",
      sort: true,
    },
    {
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];

  const columns_items = [
    {
      dataField: "item_id",
      hidden: true,
    },
    {
      dataField: "name",
      text: props.t("Name"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      editable: false,
    },
    {
      dataField: "sub_category",
      text: props.t("Sub-Category"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },

    {
      dataField: "unit",
      text: props.t("Unit"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "price",
      text: props.t("Price"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },

    {
      dataField: "quantity.in_unit",
      text: props.t("Quantity"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "expiry_date",
      text: "Expiry",
      editable: true,
      formatter: (cell, row) => {
        let dateObj = cell;
        if (typeof cell !== "object") {
          dateObj = new Date(cell);
        }
        return row.expiry_date;
      },
      editor: {
        type: Type.DATE,
      },
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
  ];

  const columns_delivered_items = [
    {
      dataField: "item_id",
      hidden: true,
    },
    {
      dataField: "name",
      text: props.t("Name"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      editable: false,
    },
    {
      dataField: "sub_category",
      text: props.t("Sub-Category"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "price",
      text: props.t("Price"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "unit",
      text: props.t("Unit"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "receive.in_unit",
      text: props.t("Received"),
      editable: false,

      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "receiving_date",
      text: props.t("Date"),
      editable: false,
      formatter: (cell, row) => {
        return moment(row.receiving_date).format("DD/MM/YYYY HH:mm");
      },

      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "invoice_url",
      text: props.t("Invoice"),
      editable: false,

      formatter: (cell, row) => {
        if (row.invoice_url) {
          return (
            <a href={row.invoice_url}>
              <i
                className="mdi mdi-download font-size-15"
                title="Download Invoice"
              ></i>
            </a>
          );
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
  ];
  const handleReceiveItems = async () => {
    let formData = new FormData();
    // if (!invoiceNumber) {
    //   return showNotification("Please provide invoice number.", "Error");
    // }

    formData.set("id", selectedPO._id);
    formData.set("delivery_details", JSON.stringify(POItems));
    formData.append("invoice_number", invoiceNumber);
    formData.append("invoice", invoice);

    console.log(...formData);
    let url = process.env.REACT_APP_BASEURL + "purchaseorders/receive";
    const response = await postSubmitForm_withformdata(url, formData);

    if (response && response.status === 1) {
      loadAllPORequests();
      setItemsModal(!itemsModal);
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [invoiceNumber, setInvoiceNumber] = useState();
  const [invoice, setInvoice] = useState();

  const handleDownload = async () => {
    const fileName = "all_po_requests";
    const exportType = "xls";

    let data_to_export = allPORequests;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          RequestID: v.request_id,
          Date: moment(v.createdAt).format("DD/MM/YYYY HH:mm"),
          Status: v.status,
          POStatus:
            v.purchaseorders.length > 0 ? v.purchaseorders[0].status : "",
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            title={props.t("Procurement")}
            alternateTitle={props.t("All PO Requests")}
            breadcrumbItem1={props.t("All PO Requests")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"   "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <div id="printDiv">
                    <CardTitle className="text-center">
                      {props.t("All PO Requests")}
                    </CardTitle>
                    <ToolkitProvider
                      bootstrap4
                      keyField="_id"
                      data={allPORequests && allPORequests}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}></Col>
                            <Col lg={4} className="text-right">
                              {/* <button
                              className="btn-sm btn btn-info"
                              onClick={() => {
                                setAddInventoryModal(!addInventoryModal);
                              }}
                            >
                              Add Item
                            </button> */}
                            </Col>
                          </Row>
                          <BootstrapTable
                            // rowEvents={rowEvents}
                            striped
                            hover
                            condensed
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                  <Modal
                    size="lg"
                    style={{
                      width: "940px",
                      maxWidth: "950px",
                    }}
                    isOpen={itemsModal}
                    toggle={() => setItemsModal(!itemsModal)}
                  >
                    <ModalHeader toggle={() => setItemsModal(!itemsModal)}>
                      Purchase Order ID: {selectedPO && selectedPO.po_id}
                    </ModalHeader>
                    <ModalBody>
                      {selectedPO && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col lg={12}>
                                      <Label>Ordered Items</Label>
                                      <ToolkitProvider
                                        keyField="_id"
                                        data={POItems && POItems}
                                        columns={columns_items}
                                      >
                                        {(props) => (
                                          <div>
                                            <BootstrapTable
                                              {...props.baseProps}
                                              cellEdit={cellEditFactory({
                                                mode: "click",
                                                blurToSave: true,
                                              })}
                                              noDataIndication="No data to display."
                                              striped
                                              hover
                                              condensed
                                            />
                                          </div>
                                        )}
                                      </ToolkitProvider>
                                    </Col>
                                    {selectedPO.deliver_at_branch == true &&
                                      selectedPO.status !== "Delivered" &&
                                      selectedPO.status !== "Closed" && (
                                        <>
                                          <Col lg={4}>
                                            <Label>Invoice Number</Label>
                                            <Input
                                              type="text"
                                              id="invoiceNumber"
                                              onChange={(e) => {
                                                setInvoiceNumber(
                                                  e.target.value
                                                );
                                              }}
                                              required="true"
                                            />
                                          </Col>
                                          <Col lg={8}>
                                            <Label>
                                              Upload Invoice Documents :{" "}
                                            </Label>
                                            <Input
                                              type="file"
                                              id="invoice"
                                              className="form-control-file"
                                              onChange={(e) => {
                                                setInvoice(e.target.files[0]);
                                              }}
                                            />
                                          </Col>
                                          <Col lg={12} className="text-center">
                                            <Label>&nbsp;</Label>
                                            <br />
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="btn btn-default waves-effect waves-light"
                                              onClick={handleReceiveItems}
                                            >
                                              {props.t("Receive Items")}
                                            </Button>{" "}
                                            <hr />
                                          </Col>
                                        </>
                                      )}
                                  </Row>
                                  <Row>
                                    <Col lg={12}>
                                      <Label>Delivered Items</Label>
                                      <ToolkitProvider
                                        keyField="_id"
                                        data={
                                          selectedPO &&
                                          selectedPO.delivery_details
                                        }
                                        columns={columns_delivered_items}
                                      >
                                        {(props) => (
                                          <div>
                                            <BootstrapTable
                                              {...props.baseProps}
                                              cellEdit={cellEditFactory({
                                                mode: "click",
                                                blurToSave: true,
                                              })}
                                              noDataIndication="No data to display."
                                              striped
                                              hover
                                              condensed
                                            />
                                          </div>
                                        )}
                                      </ToolkitProvider>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(BranchPORequests))
);
