import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
  Table,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import Line from "../../../AllCharts/echart/linechart";
import Pie from "../../../AllCharts/echart/piechart";

const animatedComponents = makeAnimated();

const ItemCategorywiseOrderReport = (props) => {
  const { SearchBar } = Search;
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [allBranches, setAllBranches] = useState([]);
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    setDateFrom(formatDate(firstDay));
    setDateTo(formatDate(currentDay));
    loadAllBranches();
  }, []);

  useEffect(() => {
    if (dateFrom && dateTo) {
      loadOrders();
    }
  }, [dateFrom, dateTo, selectedBranch]);

  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [allOrders, setAllOrders] = useState([]);
  const loadOrders = async () => {
    console.log(selectedBranch, dateFrom, dateTo);
    let url =
      process.env.REACT_APP_BASEURL +
      "itemorders/item_categorywise_order_report";
    const response = await postSubmitForm(url, {
      branch_id: selectedBranch,
      date_from: dateFrom,
      date_to: dateTo,
    });
    if (response && response.status === 1) {
      console.log(response.data.length);
      setAllOrders(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [orderStatus, setOrderStatus] = useState("All");
  const [filteredOrders, setFilteredOrders] = useState([]);
  useEffect(() => {
    if (orderStatus === "All") {
      console.log("1");
      setFilteredOrders(allOrders);
    } else {
      console.log("2");
      let arr = allOrders.filter((order) => order.status === orderStatus);
      setFilteredOrders(arr);
    }
  }, [allOrders, orderStatus]);

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns_external = [
    {
      dataField: "_id.item_id",
      hidden: true,
    },
    {
      dataField: "_id.item_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },

    {
      dataField: "category",
      text: props.t("Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      dataField: "sub_category",
      text: props.t("Sub Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },

    {
      dataField: "total",
      text: props.t("Total Order"),

      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.total.in_unit +
                " " +
                row.unit +
                " " +
                row.total.in_sub_unit +
                " " +
                row.sub_unit
              : row.total.in_unit + " " + row.unit}
          </>
        );
      },

      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];

  const handleDownload = () => {
    const fileName = "ItemCategorywiseOrderReport";
    const exportType = "xls";
    console.log(filteredOrders);
    if (filteredOrders) {
      var data = JSON.parse(JSON.stringify(filteredOrders));
      data.forEach(function (v) {
        v.total = v.sub_unit
          ? v.total.in_unit +
            " " +
            v.unit +
            " " +
            v.total.in_sub_unit +
            " " +
            v.sub_unit
          : v.total.in_unit + " " + v.unit;

        delete v._id;
        delete v.unit;
        delete v.sub_unit;
        delete v.formula;
        delete v.quantity_in_unit;
        delete v.quantity_in_sub_unit;
      });
      console.log(data);
      exportFromJSON({ data, fileName, exportType });
    }
  };
  const handlePrint = () => {
    let printContents = document.getElementsByClassName(
      "react-bootstrap-table"
    )[0].innerHTML;
    let originalContents = document.body.innerHTML;
    //console.log(document.getElementById("printDiv"));
    document.body.innerHTML = printContents;

    window.print();
    window.location.reload();
    //document.body.innerHTML = originalContents;
    //window.print();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Item-wise Order Report")}
            title={props.t("Reports")}
            breadcrumbItem1={props.t("Orders")}
            breadcrumbItem2={props.t("Item-wise Order Report")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={4}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={4}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={4}>
                        <AvField
                          name="branch"
                          label={props.t("Branch")}
                          type="select"
                          value={selectedBranch}
                          onChange={(e) => {
                            setSelectedBranch(e.target.value);
                          }}
                        >
                          <option value="All">{props.t("All")}</option>
                          {allBranches &&
                            allBranches.map((branch) => {
                              return (
                                <option value={branch._id}>
                                  {branch.name}
                                </option>
                              );
                            })}
                        </AvField>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>{" "}
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handlePrint}
                      >
                        <i className="mdi mdi-printer"></i> {props.t("Print")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <div className="row" id="printDiv">
                    <div className="col-md-12">
                      <ToolkitProvider
                        keyField="item_details._id"
                        data={filteredOrders && filteredOrders}
                        columns={columns_external}
                        noDataIndication={props.t("No data to display.")}
                        bootstrap4
                        search
                      >
                        {(props) => (
                          <div>
                            <Row>
                              <Col lg={4}>
                                <SearchBar
                                  {...props.searchProps}
                                  style={{ width: "300px" }}
                                />
                              </Col>
                              <Col lg={4}></Col>
                              <Col lg={4} className="text-right">
                                {/* <select
                                  name="status"
                                  className="form-control"
                                  value={orderStatus}
                                  onChange={(e) => {
                                    setOrderStatus(e.target.value);
                                  }}
                                >
                                  <option value="All">All</option>
                                  <option value="Placed">Placed</option>
                                  <option value="Approved">Approved</option>
                                  <option value="Dispatched">Dispatched</option>
                                  <option value="Partially Dispatched">
                                    Partially Dispatched
                                  </option>
                                  <option value="Closed">Closed</option>
                                </select> */}
                              </Col>
                            </Row>
                            <BootstrapTable
                              striped
                              hover
                              condensed
                              {...props.baseProps}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(ItemCategorywiseOrderReport))
);
