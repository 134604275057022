import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withNamespaces } from "react-i18next";

import { connect } from "react-redux";

const Navbar = (props) => {
  // const [dashboard, setdashboard] = useState(false);
  const [procurement, setProcurement] = useState(false);
  const [procurement_CentreRequisitions, setProcurement_CentreRequisitions] =
    useState(false);

  const [centreInventory, setCentreInventory] = useState(false);
  const [centralStore_ItemInventory, setCentralStore_ItemInventory] =
    useState(false);
  const [centralStore_BranchInventory, setCentralStore_BranchInventory] =
    useState(false);
  const [centreInventory_ItemOrder, setCentreInventory_ItemOrder] =
    useState(false);
  // const [
  //   centreInventory_FinishedProducts,
  //   setCentreInventory_FinishedProducts,
  // ] = useState(false);

  const [centralKitchen, setCentralKitchen] = useState(false);
  const [centralKitchen_FPOrder, setCentralKitchen_FPOrder] = useState(false);
  const [centralKitchen_FPInventory, setCentralKitchen_FPInventory] =
    useState(false);
  const [centralKitchen_BranchInventory, setCentralKitchen_BranchInventory] =
    useState(false);

  const [supplier, setSupplier] = useState(false);

  const [masterData, setMasterData] = useState(false);
  const [branches, setBranches] = useState(false);
  const [master_Item, setMaster_Item] = useState(false);
  const [master_Item_Items, setMaster_Item_Items] = useState(false);
  const [master_Item_ItemCategories, setMaster_Item_ItemCategories] =
    useState(false);
  const [master_Products, setMaster_Products] = useState(false);
  const [master_Products_Products, setMaster_Products_Products] =
    useState(false);
  const [
    master_Products_ProductCategories,
    setMaster_Products_ProductCategories,
  ] = useState(false);
  const [master_Supplier, setMaster_Supplier] = useState(false);

  const [users, setUsers] = useState(false);

  const [branchOrders, setBranchOrders] = useState(false);
  const [branchOrders_FinishedProducts, setBranchOrders_FinishedProducts] =
    useState(false);
  const [branchOrders_Items, setBranchOrders_Items] = useState(false);
  const [branchInventory, setBranchInventory] = useState(false);

  const [branchTransferInventory, setBranchTransferInventory] = useState(false);
  const [
    branchTransfers_FinishedProducts,
    setBranchTransfers_FinishedProducts,
  ] = useState(false);
  const [branchTransfers_Items, setBranchTransfers_Items] = useState(false);

  const [branchClosing, setBranchClosing] = useState(false);

  const [centralKitchenStore, setCentralKitchenStore] = useState(false);

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });
  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  const role = localStorage.getItem("role");
  const is_store = localStorage.getItem("is_store");
  const transfer_privilege = localStorage.getItem("transfer_privilege");
  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/dashboard">
                    <i className="bx bx-home-circle mr-2"></i>
                    {props.t("Dashboard")}
                  </Link>
                </li>
                {role === "Branch Supervisor" && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setProcurement(!procurement);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-receipt mr-2"></i>
                      {props.t("Procure.")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: procurement,
                      })}
                    >
                      <Link
                        to="/branch_requisition_request"
                        className="dropdown-item"
                      >
                        {props.t("New PO Request")}
                      </Link>
                      <Link to="/branch_po_requests" className="dropdown-item">
                        {props.t("All PO Requests")}
                      </Link>
                      <Link to="/branch_all_po" className="dropdown-item">
                        {props.t("All Purchase Orders")}
                      </Link>
                    </div>
                  </li>
                )}
                {(role === "admin" ||
                  role === "Operations Manager" ||
                  role === "Central Store Manager") && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setProcurement(!procurement);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-receipt mr-2"></i>
                      {props.t("Procurement")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: procurement,
                      })}
                    >
                      {/* <Link to="/add_requisition" className="dropdown-item">
                        {props.t("Add Requisition")}
                      </Link> */}
                      <div className="dropdown">
                        <Link
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setProcurement_CentreRequisitions(
                              !procurement_CentreRequisitions
                            );
                          }}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t("Generate PO")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: procurement_CentreRequisitions,
                          })}
                        >
                          <Link
                            to="/branchwise_requisition"
                            className="dropdown-item"
                          >
                            {props.t("Branch-wise")}
                          </Link>
                          <Link
                            to="/supplierwise_requisition"
                            className="dropdown-item"
                          >
                            {props.t("Supplier-wise")}
                          </Link>
                        </div>
                      </div>
                      <Link to="/branch_po_requests" className="dropdown-item">
                        {props.t("Branch PO Requests")}
                      </Link>
                      <Link to="/all_po" className="dropdown-item">
                        {props.t("All Purchase Orders")}
                      </Link>
                    </div>
                  </li>
                )}
                {(role == "Central Store Assistant" ||
                  role == "Storekeeper") && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setProcurement(!procurement);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-receipt mr-2"></i>
                      {props.t("Procurement")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: procurement,
                      })}
                    >
                      {/* <Link to="/add_requisition" className="dropdown-item">
                        {props.t("Add Requisition")}
                      </Link> */}

                      <Link to="/all_po" className="dropdown-item">
                        {props.t("All Purchase Orders")}
                      </Link>
                    </div>
                  </li>
                )}

                {role === "Owner" && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/all_po_owner"
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-receipt mr-2"></i>
                      {props.t("Purchase Order Approvals")}
                    </Link>
                  </li>
                )}

                {(role === "admin" ||
                  role == "Operations Manager" ||
                  role === "Central Store Manager") && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setCentreInventory(!centreInventory);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="mdi mdi-cart-plus mr-2"></i>
                      {props.t("Central Store")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: centreInventory,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setCentreInventory_ItemOrder(
                              !centreInventory_ItemOrder
                            );
                          }}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t("Item Order")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: centreInventory_ItemOrder,
                          })}
                        >
                          <Link
                            to="/items/new_orders"
                            className="dropdown-item"
                          >
                            {props.t("New Orders")}
                          </Link>
                          <Link
                            to="/items/orders_in_process"
                            className="dropdown-item"
                          >
                            {props.t("Orders in Process")}
                          </Link>
                          <Link
                            to="/items/edit_orders"
                            className="dropdown-item"
                          >
                            {props.t("Edit Orders")}
                          </Link>
                          <Link
                            to="/items/closed_orders"
                            className="dropdown-item"
                          >
                            {props.t("Closed Orders")}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setCentralStore_ItemInventory(
                              !centralStore_ItemInventory
                            );
                          }}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t("Item Inventory")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: centralStore_ItemInventory,
                          })}
                        >
                          <Link
                            to="/items/update_inventory"
                            className="dropdown-item"
                          >
                            {props.t("Add Inventory")}
                          </Link>
                          <Link
                            to="/items/view_inventory"
                            className="dropdown-item"
                          >
                            {props.t("View Inventory")}
                          </Link>
                          <Link
                            to="/items/used_inventory"
                            className="dropdown-item"
                          >
                            {props.t("Used Inventory")}
                          </Link>
                          {/* <Link to="/#" className="dropdown-item">
                            {props.t("View Items Branch-wise")}
                          </Link>
                          <Link to="/#" className="dropdown-item">
                            {props.t("Expired Items")}
                          </Link>
                          <Link to="/#" className="dropdown-item">
                            {props.t("View Items Below Threshold")}
                          </Link> */}
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setCentralStore_BranchInventory(
                              !centralStore_BranchInventory
                            );
                          }}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t("Branch Inventory")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: centralStore_BranchInventory,
                          })}
                        >
                          <Link
                            to="/items/view_branchwise_inventory"
                            className="dropdown-item"
                          >
                            {props.t("View Inventory")}
                          </Link>
                          <Link
                            to="/items/transfer_inventory"
                            className="dropdown-item"
                          >
                            {props.t("Transfer Inventory")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                )}
                {role == "Central Store Assistant" && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setCentreInventory(!centreInventory);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="mdi mdi-cart-plus mr-2"></i>
                      {props.t("Central Store")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: centreInventory,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setCentreInventory_ItemOrder(
                              !centreInventory_ItemOrder
                            );
                          }}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t("Item Order")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: centreInventory_ItemOrder,
                          })}
                        >
                          <Link
                            to="/items/new_orders"
                            className="dropdown-item"
                          >
                            {props.t("New Orders")}
                          </Link>
                          <Link
                            to="/items/orders_in_process"
                            className="dropdown-item"
                          >
                            {props.t("Orders in Process")}
                          </Link>
                          <Link
                            to="/items/edit_order"
                            className="dropdown-item"
                          >
                            {props.t("Edit Orders")}
                          </Link>
                          <Link
                            to="/items/closed_orders"
                            className="dropdown-item"
                          >
                            {props.t("Closed Orders")}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setCentralStore_ItemInventory(
                              !centralStore_ItemInventory
                            );
                          }}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t("Item Inventory")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: centralStore_ItemInventory,
                          })}
                        >
                          <Link
                            to="/items/update_inventory"
                            className="dropdown-item"
                          >
                            {props.t("Add Inventory")}
                          </Link>
                          <Link
                            to="/items/view_inventory"
                            className="dropdown-item"
                          >
                            {props.t("View Inventory")}
                          </Link>

                          {/* <Link to="/#" className="dropdown-item">
                            {props.t("View Items Branch-wise")}
                          </Link>
                          <Link to="/#" className="dropdown-item">
                            {props.t("Expired Items")}
                          </Link>
                          <Link to="/#" className="dropdown-item">
                            {props.t("View Items Below Threshold")}
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </li>
                )}
                {role == "Storekeeper" && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setCentreInventory(!centreInventory);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="mdi mdi-cart-plus mr-2"></i>
                      {props.t("Central Store")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: centreInventory,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setCentralStore_ItemInventory(
                              !centralStore_ItemInventory
                            );
                          }}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t("Item Inventory")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: centralStore_ItemInventory,
                          })}
                        >
                          <Link
                            to="/items/update_inventory"
                            className="dropdown-item"
                          >
                            {props.t("Add Inventory")}
                          </Link>
                          <Link
                            to="/items/view_inventory"
                            className="dropdown-item"
                          >
                            {props.t("View Inventory")}
                          </Link>

                          {/* <Link to="/#" className="dropdown-item">
                            {props.t("View Items Branch-wise")}
                          </Link>
                          <Link to="/#" className="dropdown-item">
                            {props.t("Expired Items")}
                          </Link>
                          <Link to="/#" className="dropdown-item">
                            {props.t("View Items Below Threshold")}
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </li>
                )}

                {(role === "admin" ||
                  role == "Operations Manager" ||
                  role === "Central Kitchen Manager") && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setCentralKitchen(!centralKitchen);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-store mr-2"></i>
                      {props.t("Central Kitchen")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: centralKitchen,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setCentralKitchen_FPOrder(!centralKitchen_FPOrder);
                          }}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t("FP Order")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: centralKitchen_FPOrder,
                          })}
                        >
                          <Link
                            to="/finished_products/new_orders"
                            className="dropdown-item"
                          >
                            {props.t("New Orders")}
                          </Link>
                          <Link
                            to="/finished_products/orders_in_process"
                            className="dropdown-item"
                          >
                            {props.t("Orders in Process")}
                          </Link>
                          <Link
                            to="/finished_products/closed_orders"
                            className="dropdown-item"
                          >
                            {props.t("Closed Orders")}
                          </Link>
                          <Link
                            to="/finished_products/all_orders_new"
                            className="dropdown-item"
                          >
                            {props.t("Orders List")}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setCentralKitchen_FPInventory(
                              !centralKitchen_FPInventory
                            );
                          }}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t("FP Inventory")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: centralKitchen_FPInventory,
                          })}
                        >
                          <Link
                            to="/finished_products/update_inventory"
                            className="dropdown-item"
                          >
                            {props.t("Add Inventory")}
                          </Link>
                          <Link
                            to="/finished_products/view_inventory"
                            className="dropdown-item"
                          >
                            {props.t("View Inventory")}
                          </Link>
                          {/* <Link to="/#" className="dropdown-item">
                            {props.t("View FP Branch-wise")}
                          </Link>
                          <Link to="/#" className="dropdown-item">
                            {props.t("Expired Products")}
                          </Link>
                          <Link to="/#" className="dropdown-item">
                            {props.t("View Products Below Threshold")}
                          </Link> */}
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setCentralKitchen_BranchInventory(
                              !centralKitchen_BranchInventory
                            );
                          }}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t("Branch Inventory")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: centralKitchen_BranchInventory,
                          })}
                        >
                          <Link
                            to="/finished_products/view_branchwise_inventory"
                            className="dropdown-item"
                          >
                            {props.t("View Inventory")}
                          </Link>
                          <Link
                            to="/finished_products/transfer_inventory"
                            className="dropdown-item"
                          >
                            {props.t("Transfer Inventory")}
                          </Link>
                        </div>
                      </div>

                      {/* <Link
                        to="/items/used_inventory"
                        className="dropdown-item"
                      >
                        {props.t("Used Item Inventory")}
                      </Link> */}
                      <Link
                        to="/items/view_branch_inventory"
                        className="dropdown-item"
                      >
                        {props.t("Items")}
                      </Link>
                      <Link
                        to="/items/view_branch_inventory_kitchen"
                        className="dropdown-item"
                      >
                        {props.t("Used Items")}
                      </Link>
                      <Link
                        to="/items/expired_inventory"
                        className="dropdown-item"
                      >
                        {props.t("Expired Items")}
                      </Link>
                    </div>
                  </li>
                )}
                {role === "Central Kitchen Manager" && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setCentralKitchenStore(!centralKitchenStore);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="mdi mdi-cart mr-2"></i>
                      {props.t("Central Kitchen Store")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: centralKitchenStore,
                      })}
                    >
                      <Link to="/items/add_order" className="dropdown-item">
                        {props.t("Add New Order")}
                      </Link>
                      <Link to="/items/all_orders" className="dropdown-item">
                        {props.t("View All Orders")}
                      </Link>
                      <Link to="/branch_all_po" className="dropdown-item">
                        {props.t("All Purchase Orders")}
                      </Link>
                    </div>
                  </li>
                )}
                {(role === "admin" ||
                  role == "Central Store Assistant" ||
                  role == "Operations Manager" ||
                  role === "Central Store Manager" ||
                  role === "Central Kitchen Manager") && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setMasterData(!masterData);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-book mr-2"></i>
                      {props.t("Master Data")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: masterData,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setBranches(!branches);
                          }}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t("Branches")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: branches,
                          })}
                        >
                          {(role === "admin" ||
                            role == "Operations Manager") && (
                            <>
                              <Link
                                to="/add_branches"
                                className="dropdown-item"
                              >
                                {props.t("Add Branch")}
                              </Link>
                              <Link
                                to="/update_branch"
                                className="dropdown-item"
                              >
                                {props.t("View/Update Branches")}
                              </Link>
                            </>
                          )}
                          {(role === "admin" ||
                            role == "Central Store Assistant" ||
                            role == "Operations Manager" ||
                            role === "Central Store Manager") && (
                            <Link to="/branch_items" className="dropdown-item">
                              {props.t("Branch Items")}
                            </Link>
                          )}
                          {(role === "admin" ||
                            role == "Operations Manager" ||
                            role === "Central Kitchen Manager") && (
                            <Link
                              to="/branch_products"
                              className="dropdown-item"
                            >
                              {props.t("Branch Products")}
                            </Link>
                          )}
                        </div>
                      </div>

                      {(role === "admin" ||
                        role == "Operations Manager" ||
                        role === "Central Store Manager") && (
                        <div className="dropdown">
                          <Link
                            to="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              setMaster_Item(!master_Item);
                            }}
                            className="dropdown-item dropdown-toggle arrow-none"
                          >
                            {props.t("Item")} <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: master_Item,
                            })}
                          >
                            <div className="dropdown">
                              <Link
                                className="dropdown-item dropdown-toggle arrow-none"
                                to="/#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setMaster_Item_Items(!master_Item_Items);
                                }}
                              >
                                <span key="t-email-templates">
                                  {props.t("Items")}
                                </span>
                                <div className="arrow-down"></div>
                              </Link>
                              <div
                                className={classname("dropdown-menu", {
                                  show: master_Item_Items,
                                })}
                              >
                                <Link to="/add_item" className="dropdown-item">
                                  {props.t("Add Item")}
                                </Link>
                                <Link to="/all_items" className="dropdown-item">
                                  {props.t("View/Update Item")}
                                </Link>
                              </div>
                            </div>
                            <div className="dropdown">
                              <Link
                                className="dropdown-item dropdown-toggle arrow-none"
                                to="/#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setMaster_Item_ItemCategories(
                                    !master_Item_ItemCategories
                                  );
                                }}
                              >
                                <span key="t-email-templates">
                                  {props.t("Item Categories")}
                                </span>
                                <div className="arrow-down"></div>
                              </Link>
                              <div
                                className={classname("dropdown-menu", {
                                  show: master_Item_ItemCategories,
                                })}
                              >
                                <Link
                                  to="/item_categories"
                                  className="dropdown-item"
                                >
                                  {props.t("Add New Item Category")}
                                </Link>
                                <Link
                                  to="/categories-update"
                                  className="dropdown-item"
                                >
                                  {props.t("View/Update Item Categories")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {(role === "admin" ||
                        role == "Operations Manager" ||
                        role === "Central Kitchen Manager") && (
                        <div className="dropdown">
                          <Link
                            to="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              setMaster_Products(!master_Products);
                            }}
                            className="dropdown-item dropdown-toggle arrow-none"
                          >
                            {props.t("Finished Products")}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: master_Products,
                            })}
                          >
                            <div className="dropdown">
                              <Link
                                className="dropdown-item dropdown-toggle arrow-none"
                                to="/#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setMaster_Products_Products(
                                    !master_Products_Products
                                  );
                                }}
                              >
                                <span key="t-email-templates">
                                  {props.t("Products")}
                                </span>
                                <div className="arrow-down"></div>
                              </Link>
                              <div
                                className={classname("dropdown-menu", {
                                  show: master_Products_Products,
                                })}
                              >
                                <Link
                                  to="/add_product"
                                  className="dropdown-item"
                                >
                                  {props.t("Add Product")}
                                </Link>
                                <Link
                                  to="/all_products"
                                  className="dropdown-item"
                                >
                                  {props.t("View/Update Products")}
                                </Link>
                              </div>
                            </div>
                            <div className="dropdown">
                              <Link
                                className="dropdown-item dropdown-toggle arrow-none"
                                to="/#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setMaster_Products_ProductCategories(
                                    !master_Products_ProductCategories
                                  );
                                }}
                              >
                                <span key="t-email-templates">
                                  {props.t("Product Sections")}
                                </span>
                                <div className="arrow-down"></div>
                              </Link>
                              <div
                                className={classname("dropdown-menu", {
                                  show: master_Products_ProductCategories,
                                })}
                              >
                                <Link
                                  to="/add_product_category"
                                  className="dropdown-item"
                                >
                                  {props.t("Add Product Section")}
                                </Link>
                                <Link
                                  to="/product_categories"
                                  className="dropdown-item"
                                >
                                  {props.t("View/Update Product Sections")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {(role === "admin" ||
                        role == "Operations Manager" ||
                        role === "Central Store Manager") && (
                        <div className="dropdown">
                          <Link
                            to="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              setMaster_Supplier(!master_Supplier);
                            }}
                            className="dropdown-item dropdown-toggle arrow-none"
                          >
                            {props.t("Supplier")}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: master_Supplier,
                            })}
                          >
                            <Link to="/add_supplier" className="dropdown-item">
                              {props.t("Add Supplier")}
                            </Link>
                            <Link
                              to="/supplier-Update"
                              className="dropdown-item"
                            >
                              {props.t("View/Update Suppliers")}
                            </Link>
                          </div>
                        </div>
                      )}
                      {role == "Central Store Assistant" && (
                        <div className="dropdown">
                          <Link
                            to="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              setMaster_Supplier(!master_Supplier);
                            }}
                            className="dropdown-item dropdown-toggle arrow-none"
                          >
                            {props.t("Supplier")}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: master_Supplier,
                            })}
                          >
                            <Link
                              to="/supplier-Update"
                              className="dropdown-item"
                            >
                              {props.t("View/Update Suppliers")}
                            </Link>
                          </div>
                        </div>
                      )}

                      {(role === "admin" ||
                        role == "Central Store Assistant" ||
                        role == "Operations Manager") && (
                        <>
                          <div className="dropdown">
                            <Link
                              to="/#"
                              onClick={(e) => {
                                e.preventDefault();
                                setUsers(!users);
                              }}
                              className="dropdown-item dropdown-toggle arrow-none"
                            >
                              {props.t("Users")}
                              <div className="arrow-down"></div>
                            </Link>
                            <div
                              className={classname("dropdown-menu", {
                                show: users,
                              })}
                            >
                              <Link
                                to="/users-adduser"
                                className="dropdown-item"
                              >
                                {props.t("Add User")}
                              </Link>
                              <Link
                                to="/users-updateuser"
                                className="dropdown-item"
                              >
                                {props.t("View/Update Users")}
                              </Link>
                            </div>
                          </div>
                          {(role === "admin" ||
                            role == "Operations Manager") && (
                            <Link to="/app_settings" className="dropdown-item">
                              {props.t("Settings")}
                            </Link>
                          )}
                        </>
                      )}
                    </div>
                  </li>
                )}

                {role === "Branch Supervisor" && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setBranchOrders(!branchOrders);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="mdi mdi-cart mr-2"></i>
                      {props.t("Orders")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: branchOrders,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setBranchOrders_FinishedProducts(
                              !branchOrders_FinishedProducts
                            );
                          }}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t("Finished Products")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: branchOrders_FinishedProducts,
                          })}
                        >
                          <Link
                            to="/finished_products/add_order"
                            className="dropdown-item"
                          >
                            {props.t("Add New Order")}
                          </Link>
                          <Link
                            to="/finished_products/all_orders"
                            className="dropdown-item"
                          >
                            {props.t("View All Orders")}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setBranchOrders_Items(!branchOrders_Items);
                          }}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t("Items")} <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: branchOrders_Items,
                          })}
                        >
                          <Link to="/items/add_order" className="dropdown-item">
                            {props.t("Add New Order")}
                          </Link>
                          <Link
                            to="/items/all_orders"
                            className="dropdown-item"
                          >
                            {props.t("View All Orders")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                )}
                {role === "Branch Supervisor" && is_store === "false" && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setBranchInventory(!branchInventory);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="mdi mdi-cart-plus mr-2"></i>
                      {props.t("Kitchen Inv.")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: branchInventory,
                      })}
                    >
                      <Link
                        to="/finished_products/view_branch_inventory"
                        className="dropdown-item"
                      >
                        {props.t("Finished Products")}
                      </Link>
                      <Link
                        to="/finished_products/view_branch_inventory_kitchen"
                        className="dropdown-item"
                      >
                        {props.t("Used Finished Products")}
                      </Link>
                      <Link
                        to="/finished_products/expired_inventory"
                        className="dropdown-item"
                      >
                        {props.t("Expired Products")}
                      </Link>
                      <Link
                        to="/items/view_branch_inventory"
                        className="dropdown-item"
                      >
                        {props.t("Items")}
                      </Link>
                      <Link
                        to="/items/view_branch_inventory_kitchen"
                        className="dropdown-item"
                      >
                        {props.t("Used Items")}
                      </Link>
                      <Link
                        to="/items/expired_inventory"
                        className="dropdown-item"
                      >
                        {props.t("Expired Items")}
                      </Link>
                    </div>
                  </li>
                )}
                {role === "Branch Supervisor" && is_store === "true" && (
                  <>
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          setBranchInventory(!branchInventory);
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="mdi mdi-cart-plus mr-2"></i>
                        {props.t("Kitch. Inv.")}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: branchInventory,
                        })}
                      >
                        <Link
                          to="/finished_products/view_branch_inventory"
                          className="dropdown-item"
                        >
                          {props.t("Finished Products")}
                        </Link>
                        <Link
                          to="/finished_products/view_branch_inventory_kitchen"
                          className="dropdown-item"
                        >
                          {props.t("Used Finished Products")}
                        </Link>
                        <Link
                          to="/finished_products/expired_inventory"
                          className="dropdown-item"
                        >
                          {props.t("Expired Products")}
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          setBranchInventory(!branchInventory);
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="mdi mdi-cart-plus mr-2"></i>
                        {props.t("Store Inv.")}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: branchInventory,
                        })}
                      >
                        <Link
                          to="/items/view_branch_inventory_store"
                          className="dropdown-item"
                        >
                          {props.t("Items")}
                        </Link>
                        <Link
                          to="/items/view_branch_inventory_kitchen"
                          className="dropdown-item"
                        >
                          {props.t("Used Items")}
                        </Link>
                        <Link
                          to="/items/expired_inventory"
                          className="dropdown-item"
                        >
                          {props.t("Expired Items")}
                        </Link>
                      </div>
                    </li>
                  </>
                )}
                {role === "Branch Supervisor" && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setBranchTransferInventory(!branchTransferInventory);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="mdi mdi-cart-plus mr-2"></i>
                      {props.t("Branch Inv. Transfer ")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: branchTransferInventory,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setBranchTransfers_FinishedProducts(
                              !branchTransfers_FinishedProducts
                            );
                          }}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t("Finished Products")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: branchTransfers_FinishedProducts,
                          })}
                        >
                          {transfer_privilege === "true" && (
                            <Link
                              to="/finished_products/transfer_inventory_by_branch"
                              className="dropdown-item"
                            >
                              {props.t("New Transfer")}
                            </Link>
                          )}
                          <Link
                            to="/finished_products/transfer_inventory"
                            className="dropdown-item"
                          >
                            {props.t("All Transfers")}
                          </Link>
                          {transfer_privilege === "true" && (
                            <Link
                              to="/finished_products/view_all_branch_inventory"
                              className="dropdown-item"
                            >
                              {props.t("Branch Inventory")}
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setBranchTransfers_Items(!branchTransfers_Items);
                          }}
                          className="dropdown-item dropdown-toggle arrow-none"
                        >
                          {props.t("Items")} <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: branchTransfers_Items,
                          })}
                        >
                          {transfer_privilege === "true" && (
                            <Link
                              to="/items/transfer_inventory_by_branch"
                              className="dropdown-item"
                            >
                              {props.t("New Transfer")}
                            </Link>
                          )}
                          <Link
                            to="/items/transfer_inventory"
                            className="dropdown-item"
                          >
                            {props.t("All Transfers")}
                          </Link>
                          {transfer_privilege === "true" && (
                            <Link
                              to="/items/view_all_branch_inventory"
                              className="dropdown-item"
                            >
                              {props.t("Branch Inventory")}
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                )}
                {role === "Branch Supervisor" && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setBranchClosing(!branchClosing);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="mdi mdi-cart-plus mr-2"></i>
                      {props.t("Closing")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: branchClosing,
                      })}
                    >
                      <Link
                        to="/closing/finished_products"
                        className="dropdown-item"
                      >
                        {props.t("Finished Products")}
                      </Link>
                      <Link to="/closing/items" className="dropdown-item">
                        {props.t("Items")}
                      </Link>
                    </div>
                  </li>
                )}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(Navbar))
);
