import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  Label,
  CardSubtitle,
  Container,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

import showNotification from "../../helpers/show_notification";

import { postSubmitForm } from "../../helpers/forms_helper";

const AddProductCategory = (props) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    loadAllSections();
  }, []);

  const [allSections, setAllSections] = useState([]);
  const loadAllSections = async () => {
    let url = process.env.REACT_APP_BASEURL + "productsections/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllSections(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const refContainer = useRef(null);

  const handleValidSubmit = async (e, v) => {
    try {
      const object = {
        section: v.name,
        category: categories,
      };
      let url = process.env.REACT_APP_BASEURL + "productsections/insert";
      let response = await postSubmitForm(url, object);
      if (response.status === 1) {
        loadAllSections();
        setCategories([]);
        showNotification(response.message, "Success");
        refContainer.current.reset();
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Section Name"),
      //formatter: employeeFormatter,
      dataField: "section",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "16%" };
      },
    },
    {
      text: props.t("Categories"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            {row.category.map((category) => {
              return (
                <span class="font-size-12 mr-2 badge-soft-success badge badge-success badge-pill">
                  {category}
                </span>
              );
            })}
          </>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "35%" };
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Product Sections")}
            breadcrumbItem={props.t("Add Product Section")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm onValidSubmit={handleValidSubmit} ref={refContainer}>
                    <CardTitle>{props.t("Add Product Section")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Enter the following details to add a new product section"
                      )}
                    </CardSubtitle>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="name"
                          label={props.t("Section")}
                          placeholder={props.t("Enter Section Name")}
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: props.t("Name cannot be empty"),
                            },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <Label>{props.t("Category Name")}</Label> (
                        {props.t("press ENTER/TAB to add")})
                        <TagsInput
                          value={categories}
                          onChange={(tags) => {
                            setCategories(tags);
                          }}
                          onlyUnique={true}
                          inputProps={{
                            className: "react-tagsinput-input",
                            placeholder: props.t("Add..."),
                          }}
                        />
                      </Col>

                      <Col lg={12}>
                        <hr />
                        <FormGroup className="mb-0 text-center">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                            >
                              {props.t("Submit")}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Existing Product Sections")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing product sections here")}
                  </CardSubtitle>
                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allSections && allSections}
                    columns={columns}
                    noDataIndication={props.t("No data to display.")}
                    striped
                    hover
                    condensed
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(
  connect(null, {})(withNamespaces()(AddProductCategory))
);
