import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import showNotification from "../../helpers/show_notification";

import { postSubmitForm } from "../../helpers/forms_helper";

import TagsInput from "react-tagsinput";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();
const AddSupplier = (props) => {
  useEffect(() => {
    loadAllCategories();
    loadAllSuppliers();
    loadAllCities();
  }, []);
  const refContainer = useRef(null);
  const refNewCategoryForm = useRef(null);
  const refSelectCategory = useRef(null);

  const [allSuppliers, setAllSuppliers] = useState([]);
  const loadAllSuppliers = async () => {
    let url = process.env.REACT_APP_BASEURL + "suppliers/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllSuppliers(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const loadAllCategories = async () => {
    let url = process.env.REACT_APP_BASEURL + "itemcategories/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      response.data.map((category) => {
        category.label = category.category;
        category.value = category._id;
      });
      setAllCategories(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [allCities, setAllCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState();
  const loadAllCities = async () => {
    let url = process.env.REACT_APP_BASEURL + "cities/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllCities(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [newCategoryModal, setNewCategoryModal] = useState();
  const [newSubCategories, setNewSubCategories] = useState([]);
  const handleAddNewCategory = async (e, v) => {
    try {
      const object = {
        category: v.name,
        sub_categories: newSubCategories,
      };
      let url = process.env.REACT_APP_BASEURL + "itemcategories/insert";
      let response = await postSubmitForm(url, object);
      if (response.status === 1) {
        loadAllCategories();

        let selected_categories = JSON.parse(
          JSON.stringify(selectedCategories)
        );
        response.data.label = response.data.category;
        response.data.value = response.data._id;
        selected_categories.push(response.data);
        setSelectedCategories(selected_categories);

        setNewSubCategories([]);
        setNewCategoryModal(!newCategoryModal);
        refNewCategoryForm.current.reset();
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };

  const [newCityModal, setNewCityModal] = useState();
  const handleAddNewCity = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "cities/insert";
    const response = await postSubmitForm(url, v);
    if (response && response.status === 1) {
      loadAllCities();
      setSelectedCity(v.name);
      setNewCityModal(!newCityModal);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const handleValidSubmit = async (e, v) => {
    try {
      let selected_categories = [];

      selectedCategories.map((category) => {
        selected_categories.push(category.category);
      });
      if (selected_categories.length > 0) {
        const object = {
          name: v.name,
          contact_person: v.contact_person,
          category: selected_categories,
          mobile: v.mobile,
          phone: v.phone,
          address: v.address,
          city: v.city,
          email: v.email,
          remarks: v.remarks,
        };

        let url = process.env.REACT_APP_BASEURL + "suppliers/insert";
        let response = await postSubmitForm(url, object);
        if (response.status === 1) {
          setSelectedCategories();
          loadAllSuppliers();
          showNotification(response.message, "Success");
          refSelectCategory.current.select.clearValue();
          refContainer.current.reset();
        } else {
          showNotification(response.message, "Error");
        }
      } else {
        showNotification(props.t("Select at least one category."), "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };

  const columns = [
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "contact_person",
      text: props.t("Contact Person"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "mobile",
      text: props.t("Mobile"),
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
    },
    {
      dataField: "phone",
      text: props.t("Phone"),
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
    },
    {
      dataField: "address",
      text: props.t("Address"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "city",
      text: props.t("City"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "category",
      text: props.t("Categories"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      formatter: (cell, row) => {
        return (
          <>
            {row.category.map((category) => {
              return (
                <span class="font-size-12 mr-2 badge-soft-info badge badge-info badge-pill">
                  {category}
                </span>
              );
            })}
          </>
        );
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Suppliers")}
            breadcrumbItem={props.t("Add Supplier")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm onValidSubmit={handleValidSubmit} ref={refContainer}>
                    <CardTitle>{props.t("Add Supplier")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Enter the following details to add a new supplier"
                      )}
                    </CardSubtitle>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="name"
                          label={props.t("Name")}
                          placeholder={props.t("Enter Name")}
                          type="text"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Name cannot be empty")}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="contact_person"
                          label={props.t("Contact Person")}
                          placeholder={props.t("Enter Contact Person Name")}
                          type="text"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t(
                            "Contact person name cannot be empty"
                          )}
                        />
                      </Col>
                      <Col lg={12}>
                        <Row>
                          <Col lg={6}>
                            <Row>
                              <Col lg={9}>
                                <Label>{props.t("Categories")}</Label>
                                <Select
                                  ref={refSelectCategory}
                                  value={selectedCategories}
                                  isMulti={true}
                                  onChange={(selected) => {
                                    setSelectedCategories(selected);
                                  }}
                                  options={allCategories}
                                  classNamePrefix="select2-selection"
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  required
                                />
                              </Col>
                              <Col lg={3}>
                                {" "}
                                <Label>&nbsp;</Label>
                                <br />
                                <Link
                                  class="btn btn-success waves-effect waves-light btn-sm"
                                  onClick={() => {
                                    setNewCategoryModal(!newCategoryModal);
                                  }}
                                >
                                  <i class="mdi mdi-file-plus"></i>{" "}
                                  {props.t("Add New")}
                                </Link>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6}>
                            <AvField
                              name="mobile"
                              label={props.t("Mobile")}
                              placeholder={props.t("Enter Mobile")}
                              type="text"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: props.t(
                                    "Mobile cannot be empty."
                                  ),
                                },
                                pattern: {
                                  value: "^[0-9]+$",
                                  errorMessage: props.t(
                                    "Only numbers are allowed."
                                  ),
                                },
                                maxLength: {
                                  value: 8,
                                  errorMessage: props.t("Enter valid mobile."),
                                },
                                minLength: {
                                  value: 8,
                                  errorMessage: props.t("Enter valid mobile."),
                                },
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col lg={6}>
                        <AvField
                          name="phone"
                          label={props.t("Phone")}
                          type="text"
                          errorMessage={props.t("Phone cannot be empty")}
                          placeholder={props.t("Enter Phone")}
                          validate={{
                            pattern: {
                              value: "^[0-9]+$",
                              errorMessage: props.t(
                                "Only numbers are allowed."
                              ),
                            },
                            // maxLength: {
                            //   value: 8,
                            //   errorMessage: props.t("Enter valid mobile."),
                            // },
                            // minLength: {
                            //   value: 8,
                            //   errorMessage: props.t("Enter valid mobile."),
                            // },
                          }}
                        />
                      </Col>

                      <Col lg={6}>
                        <AvField
                          name="email"
                          label={props.t("Email")}
                          type="email"
                          placeholder={props.t("Enter Email")}
                        />
                      </Col>

                      <Col lg={6}>
                        <AvField
                          name="address"
                          label={props.t("Address")}
                          placeholder={props.t("Enter Address")}
                          type="text"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Address cannot be empty")}
                        />
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col lg={9}>
                            <AvField
                              name="city"
                              label={props.t("Select City")}
                              value={selectedCity}
                              type="select"
                              validate={{ required: { value: true } }}
                              errorMessage={props.t("City cannot be empty")}
                            >
                              <option value="">
                                {props.t("-- Select City --")}
                              </option>
                              {allCities &&
                                allCities.map((e) => (
                                  <option key={e._id} value={e.name}>
                                    {e.name}
                                  </option>
                                ))}
                            </AvField>
                          </Col>
                          <Col lg={3}>
                            {" "}
                            <Label>&nbsp;</Label>
                            <br />
                            <Link
                              class="btn btn-success waves-effect waves-light btn-sm"
                              onClick={() => {
                                setNewCityModal(!newCityModal);
                              }}
                            >
                              <i class="mdi mdi-file-plus"></i>{" "}
                              {props.t("Add New")}
                            </Link>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="remarks"
                          label={props.t("Remark")}
                          type="textarea"
                          errorMessage={props.t("Please Enter Remark")}
                          placeholder={props.t("Enter Remark")}
                        />
                      </Col>
                      <Col lg={12}>
                        <hr />
                        <FormGroup className="mb-0 text-center">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                            >
                              {props.t("Submit")}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </AvForm>
                  <Modal
                    size="md"
                    isOpen={newCategoryModal}
                    toggle={() => setNewCategoryModal(!newCategoryModal)}
                  >
                    <ModalHeader
                      toggle={() => setNewCategoryModal(!newCategoryModal)}
                    >
                      {props.t("Add new category")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewCategory(e, v);
                                }}
                                ref={refNewCategoryForm}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Category")}
                                      placeholder={props.t(
                                        "Enter Category Name"
                                      )}
                                      type="text"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: props.t(
                                            "Name cannot be empty"
                                          ),
                                        },
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <Label>
                                      {props.t("Sub-Category Name")}
                                    </Label>{" "}
                                    ({props.t("press ENTER/TAB to add")})
                                    <TagsInput
                                      value={newSubCategories}
                                      onChange={(tags) => {
                                        setNewSubCategories(tags);
                                      }}
                                      onlyUnique={true}
                                      inputProps={{
                                        className: "react-tagsinput-input",
                                        placeholder: props.t("Add..."),
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="md"
                    isOpen={newCityModal}
                    toggle={() => setNewCityModal(!newCityModal)}
                  >
                    <ModalHeader toggle={() => setNewCityModal(!newCityModal)}>
                      {props.t("Add new city")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewCity(e, v);
                                }}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Name of City") + " *"}
                                      placeholder={props.t("Enter City Name")}
                                      type="text"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: props.t(
                                            "Name cannot be empty."
                                          ),
                                        },
                                      }}
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Existing Suppliers")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing suppliers here")}
                  </CardSubtitle>
                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allSuppliers && allSuppliers}
                    columns={columns}
                    noDataIndication={props.t("No data to display.")}
                    striped
                    hover
                    condensed
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withNamespaces()(AddSupplier)));
