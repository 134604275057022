import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap";
import moment from "moment";

import { withNamespaces } from "react-i18next";

//Import Image
import logo from "../assets/images/logo-light.png";
const PurchaseOrder = (props) => {
  console.log(props.purchase_order);
  // function printInvoice() {
  //   window.print();
  // }
  function printInvoice() {
    const containerElement = document.getElementsByClassName("page-content")[0];
    containerElement.scrollIntoView({ behavior: "smooth", block: "start" });
    setTimeout(() => {
      window.print();
    }, 500);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="invoice-title">
                    <h3 className="float-right ">
                      <span className="font-size-18">
                        {props.t("PURCHASE ORDER ")}
                      </span>
                      <br />
                      <span className="font-size-15">
                        PO #:{" "}
                        {props.purchase_order && props.purchase_order.po_id}
                      </span>
                      <br />
                      <span className="font-size-15">
                        Date:{" "}
                        {props.purchase_order && moment(props.purchase_order.createdAt).format("DD/MM/YYYY")}
                      </span>
                    </h3>

                    <div className="mb-6">
                      <img src={logo} alt="logo" height="35" />
                    </div>
                  </div>

                  <Row>
                    <div className="table-responsive">
                      <Table className="table-nowrap">
                        <thead>
                          <tr>
                            <th colSpan="2">{props.t("VENDOR INFORMATION")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border-0" style={{ width: "50%" }}>
                              VENDOR NAME <br />
                              <strong>
                                {props.purchase_order &&
                                  props.purchase_order.supplier.name}
                              </strong>
                            </td>
                            <td className="border-0" style={{ width: "50%" }}>
                              SALES PERSON <br />
                              <strong>
                                {props.purchase_order &&
                                  props.purchase_order.supplier.contact_person}
                              </strong>{" "}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" className="border-0">
                              ADDRESS <br />
                              <strong>
                                {props.purchase_order &&
                                  props.purchase_order.supplier.address}
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0">
                              CONTACT NO. <br />
                              <strong>
                                {props.purchase_order &&
                                  props.purchase_order.supplier.mobile}
                              </strong>
                            </td>
                            <td className="border-0">
                              EMAIL <br />
                              <strong>
                                {props.purchase_order &&
                                  props.purchase_order.supplier.email}{" "}
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <Table
                        hidden={
                          props.purchase_order &&
                          props.purchase_order.deliver_at_branch
                        }
                        className="table-nowrap"
                      >
                        <thead>
                          <tr>
                            <th colSpan="2">{props.t("DELIVERY ADDRESS")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                              <td className="border-0" style={{ width: "50%" }}>
                              CUSTOMER NAME <br />
                              <strong>KARAK MQANES</strong>
                            </td>
                            <td className="border-0" style={{ width: "50%" }}>
                              PURCHASE MANAGER <br />
                              <strong>MR MOIN MUNAWER</strong>{" "}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" className="border-0">
                              ADDRESS <br />
                              <strong>
                                GWC Bu Fesseela Warehousing Park,
                                VA 8-5 (A) OPP TO EMDADCO FOOD STUFF
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0">
                              CONTACT NO. <br />
                              <strong>77234663</strong>
                            </td>
                            <td className="border-0">
                              EMAIL <br />
                              <strong>purchase@karakmqanes.com</strong>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <Table
                        hidden={
                          props.purchase_order &&
                          !props.purchase_order.deliver_at_branch
                        }
                        className="table-nowrap"
                      >
                        <thead>
                          <tr>
                            <th colSpan="2">{props.t("DELIVERY ADDRESS")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border-0" style={{ width: "50%" }}>
                              CUSTOMER NAME <br />
                              <strong>
                                {props.purchase_order &&
                                  props.purchase_order.deliver_at_branch &&
                                  props.purchase_order.branch_details.name}
                              </strong>
                            </td>
                            <td className="border-0" style={{ width: "50%" }}>
                              <br />
                              <strong> </strong>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" className="border-0">
                              ADDRESS <br />
                              <strong>
                                {props.purchase_order &&
                                  props.purchase_order.deliver_at_branch &&
                                  props.purchase_order.branch_details.address}
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0">
                              CONTACT NO. <br />
                              <strong>
                                {props.purchase_order &&
                                  props.purchase_order.deliver_at_branch &&
                                  props.purchase_order.branch_details.mobile}
                              </strong>
                            </td>
                            <td className="border-0">
                              EMAIL <br />
                              <strong>
                                {props.purchase_order &&
                                  props.purchase_order.deliver_at_branch &&
                                  props.purchase_order.branch_details
                                    .email}{" "}
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                  {props.purchase_order &&
                    props.purchase_order.items &&
                    props.purchase_order.items.length > 0 ? (
                    <>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          {props.t("Items")}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-nowrap">
                          <thead>
                            <tr>
                              <th>{props.t("Name")}</th>
                              <th>{props.t("Category")}</th>
                              <th className="text-right">
                                {props.t("Quantity")}
                              </th>
                              <th className="text-right">{props.t("Price")}</th>
                              <th className="text-right">
                                {props.t("Total Price")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.purchase_order &&
                              props.purchase_order.items.map((item, key) => (
                                <tr>
                                  <td>{item.name}</td>
                                  <td>{item.category}</td>
                                  <td className="text-right">
                                    {`${item.quantity.in_unit} ${item.unit}`}
                                  </td>
                                  <td className="text-right">{item.price}</td>
                                  <td className="text-right">
                                    {item.total_price}
                                  </td>
                                </tr>
                              ))}
                            <tr>
                              <td colSpan="4" className="border-0 text-right">
                                <strong>{props.t("Total")}</strong>
                              </td>
                              <td className="border-0 text-right">
                                {props.purchase_order &&
                                  props.purchase_order.items.reduce(
                                    (accumulator, currentValue) =>
                                      +accumulator + +currentValue.total_price,
                                    0
                                  )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </>
                  ) : null}

                  <hr />
                  <Table className="table-nowrap">
                    <tbody>
                      <tr>
                        <td className="border-0 ">
                          <strong>{props.t("Additional Note")}</strong>
                        </td>
                        <td className="border-0 text-right">
                          <strong>{props.t("AUTHORIZE BY")}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0">
                          {props.t(
                            "Payment shall be 30 days upon delivery of the above items."
                          )}
                        </td>
                        <td className="border-0 text-right"></td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="d-print-none">
                    <div className="float-right">
                      <Link
                        to="#"
                        onClick={() => {
                          printInvoice();
                        }}
                        className="btn btn-success waves-effect waves-light mr-2"
                      >
                        <i className="fa fa-print"></i> {props.t("Print")}
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withNamespaces()(PurchaseOrder);
