import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";

import { postSubmitForm } from "../../../helpers/forms_helper";
import moment from "moment";

const BranchWiseRequisition = (props) => {
  const [cartItems, setCartItems] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const { SearchBar } = Search;
  useEffect(() => {
    loadAllBranches();
    loadItems();
  }, []);

  const [selectedItems, setSelectedItems] = useState([]);
  const [allBranches, setAllBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [deliverAtBranch, setDeliverAtBranch] = useState(false);
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [searchkey, setSearchKey] = useState("");
  const [allItems, setAllItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const loadItems = async () => {
    preloader(true);
    let url = process.env.REACT_APP_BASEURL + "items/get_branchwise_for_po";
    const response = await postSubmitForm(url, { branch_id: selectedBranch });
    if (response && response.status === 1) {
      preloader(false);
      let arr = response.data.map((category) => {
        //console.log(items);
        let out_category = category;
        out_category.items = Array.from(category.items, (x) => {
          let y = x;
          y.quantity = 0;
          //y.price = 0;
          y.total_price = 0;
          y.is_selected = false;
          return y;
        });
        return out_category;
      });
      setAllItems(arr);
      setFilteredItems(arr);
      setDisableSubmit(false);
      // let expandedRows = [];
      // response.data.map((item) => {
      //   expandedRows.push(item._id);
      // });
      // setExpanded(expandedRows);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };
  useEffect(() => {
    filterItems(allItems);
  }, [searchkey]);
  // useEffect(() => {
  //   filterItems(allItems);
  // }, [allItems]);
  const filterItems = async (arr) => {
    //console.log(searchkey);
    arr = JSON.parse(JSON.stringify(arr));
    let arr_filtered;
    if (searchkey.length > 0) {
      arr_filtered = arr.map((category) => {
        let out_category = category;

        let cat_items = category.items.filter((item) => {
          return item.name.includes(searchkey) == true;
        });

        out_category.items = cat_items;
        return out_category;
      });
    } else {
      arr_filtered = arr;
    }

    setFilteredItems(arr_filtered);
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns_items = [
    {
      dataField: "item_id",
      hidden: true,
    },
    {
      dataField: "is_selected",
      text: "",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <input
            type="checkbox"
            id={"c" + rowIndex}
            checked={row.is_selected}
            onClick={(e) => {
              e.preventDefault();

              handleOnSelectRow(row, !row.is_selected, extraData);
            }}
          />
        );
      },
      formatExtraData: { allItems, searchkey },
    },
    {
      dataField: "item_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      editable: false,
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "45%" };
      },
      editable: false,
    },
    {
      text: props.t("Cat."),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: false,
    },
    {
      text: props.t("Sub-Cat."),
      dataField: "sub_category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: false,
    },
    {
      text: props.t("Brand"),
      dataField: "brand",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: false,
    },
  ];
  const columns_cartitems = [
    {
      dataField: "item_id",
      hidden: true,
    },
    {
      dataField: "item_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      sort: false,
      footer: false,
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      footer: false,
    },
    {
      text: props.t("Unit"),
      dataField: "unit",
      sort: false,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      footer: false,
    },
    {
      text: props.t("Formula"),
      dataField: "formula",
      sort: false,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      footer: false,
    },
    {
      text: props.t("Qty."),
      dataField: "quantity",
      sort: false,
      footer: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          };
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          };
        }
        return true;
      },
      editable: (content, row, rowIndex, columnIndex) => {
        return row.is_selected;
      },
      attrs: (cell, row, rowIndex, colIndex) => {
        const title_text = row.is_selected
          ? { title: "Click to Edit" }
          : { title: "Select to Edit" };
        return title_text;
      },
      style: (cell, row, rowIndex, colIndex) => {
        const cur_style = row.is_selected
          ? { cursor: "pointer" }
          : { cursor: "not-allowed" };
        return cur_style;
      },
    },
    {
      text: props.t("Price"),
      dataField: "price",
      sort: false,
      footer: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: (content, row, rowIndex, columnIndex) => {
        return row.is_selected;
      },
      attrs: (cell, row, rowIndex, colIndex) => {
        const title_text = row.is_selected
          ? { title: "Click to Edit" }
          : { title: "Select to Edit" };
        return title_text;
      },
      style: (cell, row, rowIndex, colIndex) => {
        const cur_style = row.is_selected
          ? { cursor: "pointer" }
          : { cursor: "not-allowed" };
        return cur_style;
      },
    },
    {
      text: props.t("Total"),
      dataField: "total_price",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: false,
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
  ];

  const handleSupplierSelection = (event) => {
    let arrSelected = JSON.parse(JSON.stringify(selectedItems));
    let selectedCategory = event.target.getAttribute("category");
    let selectedSupplier = event.target.value;
    let existingIndex = arrSelected.findIndex(
      (item) => item.category === selectedCategory
    );
    if (existingIndex >= 0) {
      if (selectedSupplier === "") {
        arrSelected.splice(existingIndex, 1);
      } else {
        arrSelected[existingIndex].supplier = selectedSupplier;
      }
    } else {
      arrSelected.push({
        category: selectedCategory,
        supplier: selectedSupplier,
      });
    }
    setSelectedItems(arrSelected);
  };

  const updateCart = (arr) => {
    arr = JSON.parse(JSON.stringify(arr));
    let arr_selected = [];
    arr.map((cat) => {
      cat.items.map((item) => {
        if (item.is_selected == true) {
          arr_selected.push(item);
        }
      });
    });
    setCartItems(arr_selected);
  };

  const handleOnSelectRow = (row, isSelect, extraData) => {
    let arr = JSON.parse(JSON.stringify(extraData.allItems));

    arr.map((category) => {
      category.items.map((item) => {
        if (item.item_id == row.item_id) {
          item.is_selected = isSelect;
        }
      });
    });

    setAllItems(arr);
    filterItems(arr);

    //console.log(arr_filtered);
    updateCart(arr);
    return true;
  };
  const handleOnSelectAllRows = (is_selected, rows) => {
    let arr = JSON.parse(JSON.stringify(allItems));
    let selectedCatIndex = arr.findIndex((cat) => {
      return cat._id === rows[0].category;
    });

    arr[selectedCatIndex].items.map((item) => {
      item.is_selected = is_selected;
    });
    updateCart(arr);
    setAllItems(arr);
  };
  // const selectRow = {
  //   mode: "checkbox",
  //   clickToSelect: false,
  //   onSelect: handleOnSelectRow,
  //   onSelectAll: handleOnSelectAllRows,
  //   formatExtraData: { allItems, searchkey },
  // };
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} - {to} of {size} records.
    </span>
  );

  const pagination_options = {
    paginationSize: 50,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: allItems && allItems.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };

  const handleSubmitRequisition = async () => {
    let validFlag = true;
    let arrSelected = JSON.parse(JSON.stringify(selectedItems));

    let data = arrSelected.map((selected_cat) => {
      if (selected_cat.supplier) {
        const itemIndex = allItems.findIndex(
          (item) => item._id === selected_cat.category
        );
        let selectedCategory_items = allItems[itemIndex].items.filter(
          (item) => {
            if (
              item.is_selected &&
              (+item.quantity === 0 || +item.price === 0)
            ) {
              validFlag = false;
              showNotification(
                "Please enter price and quantity for all selected items.",
                "Error"
              );
            }
            return item.is_selected && item.quantity > 0 && +item.price > 0;
          }
        );
        if (selectedCategory_items.length > 0 && validFlag) {
          selected_cat.items = [...selectedCategory_items];
          return selected_cat;
        } else if (validFlag) {
          validFlag = false;
          showNotification(
            "Please select at least one item for every selected supplier.",
            "Error"
          );
        }
      } else {
        validFlag = false;
        showNotification(
          "Please select supplier for all selected items.",
          "Error"
        );
      }
    });
    if (validFlag) {
      try {
        if (data.length > 0) {
          let url = process.env.REACT_APP_BASEURL + "purchaseorders/insert";
          let data_to_send = { items: data };
          if (selectedBranch !== "") {
            data_to_send.branch_id = selectedBranch;
            
          }
          data_to_send.deliver_at_branch = deliverAtBranch;
          console.log(data_to_send);
          let response = await postSubmitForm(url, data_to_send);
          if (response.status === 1) {
            setDisableSubmit(true);
            showNotification(response.message, "Success");
            for (let i = 0; i < response.data.length; i++) {
              window.open(
                `/purchase_order?po_id=${response.data[i]}`,
                "_blank"
              );
            }
            window.location.reload();
          } else {
            showNotification(response.message, "Error");
          }
        } else {
          showNotification(
            "Please select at least one item and supplier.",
            "Error"
          );
        }
      } catch (error) {
        showNotification(error.message, "Error");
      }
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Branch-wise Purchase Order")}
            title={props.t("Procurement")}
            breadcrumbItem1={props.t("Branch-wise Purchase Order")}
          />
          <Row>
            <Col lg={7}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={12}>
                        <AvField
                          name="search"
                          label={props.t("Search")}
                          type="text"
                          onChange={(e) => {
                            e.preventDefault();
                            setSearchKey(e.target.value);
                          }}
                        ></AvField>
                      </Col>
                    </Row>
                    {/* <Button type="submit">Submit</Button> */}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            <Col lg={5}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={12}>
                        <AvField
                          name="branch"
                          label={props.t("Select Branch")}
                          type="select"
                          value={selectedBranch}
                          errorMessage={props.t("Branch cannot be empty")}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setSelectedBranch(e.target.value);
                            if(e.target.value==""){
                              setDeliverAtBranch(false);
                            }
                          }}
                        >
                          <option value="">{props.t("None")}</option>
                          {allBranches &&
                            allBranches.map((e) => (
                              <option key={e._id} value={e._id} name={e.name}>
                                {e.name}
                              </option>
                            ))}
                        </AvField>
                      </Col>
                      {selectedBranch && (
                        <Col lg={12}>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              checked={deliverAtBranch}
                              onChange={(e) => {
                                setDeliverAtBranch(!deliverAtBranch);
                              }}
                              className="custom-control-input"
                              id="customCheck1"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              Deliver at branch.
                            </label>
                          </div>
                        </Col>
                      )}
                    </Row>
                    {/* <Button type="submit">Submit</Button> */}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <Card>
                <CardBody>
                  {filteredItems.map((item) => {
                    if (item.items.length > 0) {
                      return (
                        <>
                          <Row>
                            <Col lg={6}>
                              <Label>Category: {item._id}</Label>
                            </Col>
                            <Col lg={6}>
                              <AvForm>
                                <AvField
                                  name="supplier"
                                  type="select"
                                  category={item._id}
                                  onChange={handleSupplierSelection}
                                >
                                  <option value="">
                                    {props.t("--Select Supplier--")}
                                  </option>
                                  {item.suppliers &&
                                    item.suppliers.map((e) => (
                                      <option
                                        key={e._id}
                                        value={e._id}
                                        name={e.name}
                                      >
                                        {e.name}
                                      </option>
                                    ))}
                                </AvField>
                              </AvForm>
                            </Col>
                          </Row>
                          <ToolkitProvider
                            bootstrap4
                            keyField="item_id"
                            data={item.items}
                            columns={columns_items}
                            noDataIndication={props.t("No data to display.")}
                            search
                          >
                            {(props) => (
                              <div>
                                <BootstrapTable
                                  striped
                                  hover
                                  condensed
                                  //selectRow={selectRow}

                                  {...props.baseProps}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </>
                      );
                    }
                  })}
                </CardBody>
              </Card>
            </Col>
            <Col lg={5}>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col lg={9}>
                          <CardTitle>Cart Items</CardTitle>
                        </Col>
                        <Col lg={3}>
                          <Link
                            to="#"
                            onClick={() => {
                              window.location.reload();
                            }}
                            className="btn btn-sm btn-success mr-2"
                          >
                            Clear All
                          </Link>
                        </Col>
                        <Col lg={12}>
                          {cartItems && (
                            <ToolkitProvider
                              bootstrap4
                              keyField="item_id"
                              data={cartItems}
                              columns={columns_cartitems}
                              noDataIndication={props.t("No data to display.")}
                              search
                            >
                              {(props) => (
                                <div>
                                  <BootstrapTable
                                    striped
                                    hover
                                    condensed
                                    cellEdit={cellEditFactory({
                                      mode: "click",
                                      blurToSave: true,
                                      afterSaveCell: (
                                        oldValue,
                                        newValue,
                                        row,
                                        column
                                      ) => {
                                        row.total_price = Number(
                                          (row.price * row.quantity).toFixed(2)
                                        );

                                        let arr = JSON.parse(
                                          JSON.stringify(allItems)
                                        );
                                        arr.map((category) => {
                                          category.items.map((item) => {
                                            if (item.item_id == row.item_id) {
                                              item.quantity = row.quantity;
                                              item.price = row.price;
                                              item.total_price = Number(
                                                (
                                                  row.price * row.quantity
                                                ).toFixed(2)
                                              );
                                            }
                                          });
                                        });

                                        //console.log(arr);
                                        setAllItems(arr);

                                        //updateCart(arr);
                                        console.log(row.price, row.quantity);
                                      },
                                    })}
                                    {...props.baseProps}
                                  />
                                </div>
                              )}
                            </ToolkitProvider>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12}>
                      <FormGroup className="mb-0 text-center">
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="mr-1"
                            disabled={disableSubmit}
                            onClick={handleSubmitRequisition}
                          >
                            {props.t("Submit")}
                          </Button>{" "}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(BranchWiseRequisition))
);
