import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import { AvForm, AvField } from "availity-reactstrap-validation";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
  Table,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import Line from "../../../AllCharts/echart/linechart";

const animatedComponents = makeAnimated();

const ItemCostTrendReport = (props) => {
  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const { SearchBar } = Search;
  const [monthYear, setMonthYear] = useState();

  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedItem, setSelectedItem] = useState("-Select-");
  const [allItems, setAllItems] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    //setDateFrom(formatDate(firstDay));
    setDateFrom(formatDate(currentDay));
    setDateTo(formatDate(currentDay));
   
    loadAllItems();
  }, []);

  useEffect(() => {
    if (dateFrom > dateTo) {
      loadOrders();
    }
  }, [dateFrom, dateTo, selectedItem]);

  const [allOrders, setAllOrders] = useState([]);
  const loadOrders = async () => {
    preloader(true);
    let url = process.env.REACT_APP_BASEURL + "reports/item_cost_trend_report";
    const response = await postSubmitForm(url, {
      item_id: selectedItem,
      date_from: dateFrom,
      date_to: dateTo,
    });
    if (response && response.status === 1) {
      preloader(false);
      //console.log(response.data.length);
      setItemsLineChartData(response.data.purchaseorders_chart);
      setAllOrders(response.data.purchaseorders_data);
      console.log(response.data.purchaseorders_data);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };
  const [orderStatus, setOrderStatus] = useState("All");
  const [filteredOrders, setFilteredOrders] = useState([]);
  // useEffect(() => {
  //   if (orderStatus === "All") {
  //     setFilteredOrders([...allOrders]);
  //   } else {
  //     let arr = allOrders.filter((order) => order.status === orderStatus);
  //     setFilteredOrders(arr);
  //   }
  // }, [allOrders, orderStatus]);

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const loadAllItems = async () => {
    let url = process.env.REACT_APP_BASEURL + "items/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllItems(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [purchaseModal, setPurchaseModal] = useState(false);
  const [directPurchaseModal, setDirectPurchaseModal] = useState(false);
  const [productModal, setProductModal] = useState(false);
  const [itemModal, setItemModal] = useState(false);

  const columns = [
    {
      dataField: "id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("Purchase Order"),
      dataField: "po_id",
      formatter: (cell, row, rowIndex) => {
        return (
          <a
            href={"/purchase_order?po_id=" + row._id}
            target="_blank"
            style={{ color: "blue" }}
          >
            {row.po_id}
          </a>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Branch"),
      dataField: "branch_details.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      filter: selectFilter({
        options: () => {
          const unique = [
            ...new Map(
              [
                ...new Set(
                  allOrders.map(({ branch_details: value }) => ({
                    value: value.name,
                    label: value.name,
                  }))
                ),
              ].map((item) => [item["value"], item])
            ).values(),
          ];

          unique.sort(dynamicSort("label"));
          return unique;
        },
      }),
    },
    {
      dataField: "supplier.name",
      text: props.t("Supplier Name"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      filter: selectFilter({
        options: () => {
          const unique = [
            ...new Map(
              [
                ...new Set(
                  allOrders.map(({ supplier: value }) => ({
                    value: value.name,
                    label: value.name,
                  }))
                ),
              ].map((item) => [item["value"], item])
            ).values(),
          ];

          unique.sort(dynamicSort("label"));
          return unique;
        },
      }),
      style: { cursor: "pointer" },
    },

    {
      text: props.t("Status"),
      dataField: "status",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      filter: selectFilter({
        options: () => {
          const unique = [
            ...new Map(
              [
                ...new Set(
                  allOrders.map(({ status: value }) => ({
                    value: value,
                    label: value,
                  }))
                ),
              ].map((item) => [item["value"], item])
            ).values(),
          ];

          unique.sort(dynamicSort("label"));
          return unique;
        },
      }),
    },

    {
      text: props.t("Date"),
      dataField: "createdAt",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      formatter: (cell, row, rowIndex) => {
        return moment(row.createdAt).format("DD/MM/YYYY");
      },
    },
    // {
    //   dataField: "items.name",
    //   text: props.t("Item"),
    //   formatter: ((cell, row) => {
    //    row.item
    //   }),
    //   sort: true,
    //   // headerStyle: (colum, colIndex) => {
    //   //   return { width: "12%" };
    //   // },

    //   style: { cursor: "pointer" },
    // },
    // {
    //   dataField: "items.price",
    //   text: props.t("Old Price"),

    //   headerStyle: (colum, colIndex) => {
    //     return { width: "9%" };
    //   },

    //   style: { cursor: "pointer" },
    // },
  ];

  const [orderDetailsModal, setOrderDetailsModal] = useState();
  const [itemsLineChartData, setItemsLineChartData] = useState();

  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      setExpanded([...expanded, row._id]);
    } else {
      setExpanded(expanded.filter((x) => x !== row._id));
    }
  };
  const [expanded, setExpanded] = useState([]);

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.item_details}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
    expanded: expanded,
    onExpand: handleOnExpand,
  };

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.status === "Completed") {
      style.backgroundColor = "#b0ff9e";
    } else if (row.status === "Booked") {
      style.backgroundColor = "#9ec3ff";
    } else if (row.status === "In Process") {
      style.backgroundColor = "#fffd9e";
    } else if (row.status === "Cancelled") {
      style.backgroundColor = "#ff9e9e";
    }

    return style;
  };

  const handleDownload = async () => {
    const fileName = "item_cost_trend_report";
    const exportType = "xls";

    let data_to_export = filteredOrders;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Product: v.name,
          Section: v.section,
          Category: v.category,
          Quantity: v.total_quantity,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }

  const columns_ordered_items = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Sub-Category"),
      dataField: "sub_category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Available"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.available.in_unit +
              " " +
              row.unit +
              " " +
              row.available.in_sub_unit +
              " " +
              row.sub_unit
              : row.available.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Order Quantity"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.quantity.in_unit +
              " " +
              row.unit +
              " " +
              row.quantity.in_sub_unit +
              " " +
              row.sub_unit
              : row.quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];

  const columns_delivered_items = [
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Sub-Category"),
      dataField: "sub_category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Delivered Qty."),
      formatter: (cell, row) => {
        return (
          <>
            {row.delivered_quantity.in_sub_unit
              ? row.delivered_quantity.in_unit +
              " " +
              row.unit +
              " " +
              row.delivered_quantity.in_sub_unit +
              " " +
              row.sub_unit
              : row.delivered_quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Rec. Qty."),
      formatter: (cell, row) => {
        return (
          <>
            {row.received_quantity.in_sub_unit
              ? row.received_quantity.in_unit +
              " " +
              row.unit +
              " " +
              row.received_quantity.in_sub_unit +
              " " +
              row.sub_unit
              : row.received_quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb
            alternateTitle={props.t("Item Cost Trend Report")}
            title={props.t("Reports")}
            breadcrumbItem={props.t("Item Cost Trend Report")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={3}>
                        <AvField
                          name="item"
                          label={props.t("Item")}
                          type="select"
                          value={selectedItem}
                          onChange={(e) => {
                            setSelectedItem(e.target.value);
                          }}
                          errorMessage={props.t("Item cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        >
                          <option value="-Select-">
                            {props.t("-Select-")}
                          </option>
                          {allItems &&
                            allItems.map((item) => {
                              return (
                                <option value={item._id}>{item.name}</option>
                              );
                            })}
                        </AvField>
                      </Col>
                      <Col lg={3}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={3}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={3}>
                        <Button
                          type="submit"
                          className="btn btn-md btn-success mt-4"
                          onClick={() => {
                            if (dateFrom && dateTo) {
                              loadOrders();
                              loadOrders();
                            }
                          }}
                        >
                          <i className="mdi mdi-checkbox-marked-circle"></i>{" "}
                          {props.t("Check")}
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"   "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <Card>
                        <CardBody>
                          <div id="line-chart" className="e-chart">
                            <Line
                              line_data={
                                itemsLineChartData && itemsLineChartData
                              }
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <div id="printDiv">
                    <CardTitle className="text-center">
                      {props.t("Item Cost Trend Report")}
                    </CardTitle>
                    <ToolkitProvider
                      keyField="id"
                      data={allOrders && allOrders}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      bootstrap4
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}></Col>
                            <Col lg={4}></Col>
                          </Row>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            rowStyle={rowStyle}
                            filter={filterFactory()}
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>

                  <Modal
                    size="lg"
                    isOpen={orderDetailsModal}
                    toggle={() => setOrderDetailsModal(!orderDetailsModal)}
                  >
                    <ModalHeader
                      toggle={() => setOrderDetailsModal(!orderDetailsModal)}
                    >
                      {props.t("Order Details")}
                    </ModalHeader>
                    <ModalBody>
                      <>
                        <Row>
                          <Col lg={12}>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col lg={12} md={12}>
                                    <Label>Ordered Items</Label>
                                  </Col>

                                  <Col lg={12}>
                                    <BootstrapTable
                                      bootstrap4
                                      keyField="name"
                                      data={
                                        selectedRow && selectedRow.item_details
                                      }
                                      columns={columns_ordered_items}
                                      noDataIndication="No data to display."
                                      striped
                                      hover
                                      condensed
                                    />
                                    <hr />
                                  </Col>

                                  {selectedRow &&
                                    selectedRow.delivery_details &&
                                    selectedRow.delivery_details.length > 0 ? (
                                    <>
                                      <Col lg={12} md={12}>
                                        <br />
                                        <Label>
                                          <b>{props.t("Delivered Items")} </b>
                                        </Label>
                                      </Col>
                                      <Col lg={12} className="mb-4">
                                        <BootstrapTable
                                          bootstrap4
                                          keyField="name"
                                          key="name"
                                          data={selectedRow.delivery_details}
                                          columns={columns_delivered_items}
                                          noDataIndication="No data to display."
                                          striped
                                          hover
                                          condensed
                                        />
                                        <hr />
                                      </Col>
                                    </>
                                  ) : null}
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => { };
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(ItemCostTrendReport))
);
