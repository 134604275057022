import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";

const ExpiredInventory = (props) => {
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  useEffect(() => {
    loadItems();
  }, []);
  const [currentDate] = useState(() => {
    var d1 = new Date();
    var d = moment(d1).add(1, "days").toDate();
    var month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  });
  const [allItems, setAllItems] = useState([]);
  const loadItems = async () => {
    preloader(true);
    let url = process.env.REACT_APP_BASEURL + "branchiteminventories/expired";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      preloader(false);
      setAllItems(response.data);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const [editItemExpiryModal, setEditItemExpiryModal] = useState();
  const [selectedItemForExpiryDate, setSelectedItemForExpiryDate] = useState();
  const columns = [
    {
      dataField: "_id",
      hidden: true,
      csvExport: false,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      csvExport: false,
    },
    {
      text: props.t("Name"),
      dataField: "item_details.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      dataField: "item_details.category",
      text: props.t("Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      dataField: "item_details.sub_category",
      text: props.t("Sub-Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },

    {
      dataField: "quantity",
      text: props.t("Quantity"),
      formatter: (cell, row) =>
        `${row.quantity.in_unit} ${row.item_details.unit} ${
          row.item_details.sub_unit
            ? `${row.quantity.in_sub_unit} ${row.item_details.sub_unit}`
            : ``
        }`,
      csvFormatter: (cell, row) =>
        `${row.quantity.in_unit} ${row.item_details.unit} ${
          row.item_details.sub_unit
            ? `${row.quantity.in_sub_unit} ${row.item_details.sub_unit}`
            : ``
        }`,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      dataField: "expiry_date",
      text: props.t("Expiry Date"),
      formatter: (cell, row) => {
        return (
          <>
            <span className="mr-4">
              {moment(row.expiry_date).format("DD-MM-YYYY ")}
            </span>
            <i
              className="bx bxs-edit font-size-14"
              title="Click to Edit"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                setEditItemExpiryModal(!editItemExpiryModal);
                setSelectedItemForExpiryDate(row);
                e.stopPropagation();
              }}
            ></i>
          </>
        );
      },
      csvFormatter: (cell, row) =>
        moment(row.expiry_date).format("DD-MM-YYYY "),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
  ];
  const [selectedItems, setSelectedItems] = useState([]);
  const handleOnSelectRow = (row, isSelect) => {
    let arrAllItems = JSON.parse(JSON.stringify(allItems));
    let arrSelectedItems = JSON.parse(JSON.stringify(selectedItems));
    let selected = arrAllItems.filter((item) => {
      return item._id === row._id;
    });
    if (isSelect) {
      arrSelectedItems.push(selected[0]._id);
    } else {
      let index = arrSelectedItems.indexOf(selected[0]._id);
      if (index != -1) {
        arrSelectedItems.splice(index, 1); // remove 1 element from index
      }
    }

    setSelectedItems(arrSelectedItems);
    return true;
  };
  const handleOnSelectAllRows = (is_selected, rows) => {
    let arrAllItems = JSON.parse(JSON.stringify(allItems));
    if (is_selected) {
      let arrSelectedItems = [];
      arrAllItems.map((item) => {
        arrSelectedItems.push(item._id);
      });
      setSelectedItems(arrSelectedItems);
    } else {
      setSelectedItems([]);
    }
  };
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: handleOnSelectRow,
    onSelectAll: handleOnSelectAllRows,
  };
  const handleDiscardAsExpired = async () => {
    const object = {
      branchiteminventory_ids: selectedItems,
    };
    let url = process.env.REACT_APP_BASEURL + "branchiteminventories/discard";
    const response = await postSubmitForm(url, object);
    if (response && response.status === 1) {
      loadItems();
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };

  const handleUpdateItemExpiry = async (e, v) => {
    const object = {
      branchiteminventory_id: selectedItemForExpiryDate._id,
      expiry_date: v.expiry_date,
    };
    let url =
      process.env.REACT_APP_BASEURL + "branchiteminventories/update_expiry";

    const response = await postSubmitForm(url, object);

    if (response && response.status === 1) {
      loadItems();
      showNotification(response.message, "Success");
      setEditItemExpiryModal(!editItemExpiryModal);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const handleDownload = async () => {
    const fileName = "expired_item_inventory";
    const exportType = "xls";

    let data_to_export = allItems;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Name: v.item_details.name,
          Category: v.item_details.category,
          Sub_Category: v.item_details.sub_category,
          Quantity: v.item_details.sub_unit
            ? v.quantity.in_unit +
              " " +
              v.item_details.unit +
              " " +
              v.quantity.in_sub_unit +
              " " +
              v.item_details.sub_unit
            : v.quantity.in_unit + " " + v.item_details.unit,
          Expiry_Date: moment(v.expiry_date).format("DD-MM-YYYY "),
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={"View/ Discard Expired Item Inventory"}
            title={props.t("Inventory")}
            breadcrumbItem1={props.t("Items")}
            breadcrumbItem2={props.t("Expired Item Inventory")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"   "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <div id="printDiv">
                    <CardTitle className="text-center">
                      {props.t("Expired Item Inventory")}
                    </CardTitle>

                    <ToolkitProvider
                      bootstrap4
                      keyField="_id"
                      data={allItems && allItems}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      search
                      exportCSV
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={8}>
                              <Row>
                                <Col lg={6}>
                                  <SearchBar
                                    {...props.searchProps}
                                    style={{ width: "300px" }}
                                  />
                                </Col>
                                <Col lg={6}></Col>
                              </Row>
                            </Col>

                            <Col lg={4} className="text-right">
                              {selectedItems && selectedItems.length > 0 && (
                                <Button
                                  className="btn btn-sm btn-danger"
                                  onClick={handleDiscardAsExpired}
                                >
                                  <i className="bx bx-trash"></i> Discard
                                  Selected
                                </Button>
                              )}
                            </Col>
                          </Row>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            {...props.baseProps}
                            selectRow={selectRow}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                  <Modal
                    size="md"
                    isOpen={editItemExpiryModal}
                    toggle={() => setEditItemExpiryModal(!editItemExpiryModal)}
                  >
                    <ModalHeader
                      toggle={() =>
                        setEditItemExpiryModal(!editItemExpiryModal)
                      }
                    >
                      {props.t("Item")}:{" "}
                      {selectedItemForExpiryDate &&
                        selectedItemForExpiryDate.item_details.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedItemForExpiryDate && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm
                                    onValidSubmit={handleUpdateItemExpiry}
                                  >
                                    <Row>
                                      <Col lg={12}>
                                        <Label>
                                          {props.t("Current Expiry Date")}:{" "}
                                          {selectedItemForExpiryDate &&
                                            moment(
                                              selectedItemForExpiryDate.expiry_date
                                            ).format("DD-MM-YYYY ")}
                                        </Label>

                                        <hr />
                                      </Col>

                                      <Col lg={12}>
                                        <Label>
                                          {props.t("Updated Expiry Date")}{" "}
                                        </Label>

                                        <AvField
                                          name="expiry_date"
                                          placeholder={props.t(
                                            "Select Expiry Date"
                                          )}
                                          type="date"
                                          min={currentDate}
                                          required
                                          errorMessage="Expiry date cannot be empty"
                                        />
                                      </Col>

                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                            >
                                              {props.t("Update")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(ExpiredInventory))
);
