import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, Media } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";

//i18n
import { withNamespaces } from "react-i18next";

import moment from "moment";

const OrderProductSections = (props) => {
  // Declare a new state variable, which we'll call "menu"

  const role = localStorage.getItem("role");
  const getLink = (section, type) => {
    let pageLink = "";

    switch (type) {
      case "new_orders":
        pageLink =
          "/finished_products/all_orders_new?status=New&section=" + section;
        break;

      case "orders_in_process":
        pageLink =
          "/finished_products/all_orders_new?status=In Process&section=" +
          section;
        break;

      case "closed_orders":
        pageLink =
          "/finished_products/all_orders_new?status=Closed&section=" + section;
        break;
    }

    return pageLink;
  };
  return (
    <>
      {" "}
      <Card>
        <CardBody>
          <CardTitle>{props.heading}</CardTitle>

          {props.sections && props.sections.length > 0 ? (
            <>
              <div
                style={{
                  height: "90px",
                  overflowX: "scroll",
                  overflowY: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {props.sections &&
                  props.sections.map((section) => {
                    return (
                      <Link
                        to={() => {
                          return getLink(section.section, props.type);
                        }}
                        style={{ display: "inline-block" }}
                      >
                        <Card
                          className={
                            props.type === "new_orders"
                              ? "mini-stats-wid bg-danger mr-4"
                              : props.type === "orders_in_process"
                              ? "mini-stats-wid bg-info mr-4"
                              : "mini-stats-wid bg-success mr-4"
                          }
                          style={{ width: "auto" }}
                        >
                          <CardBody>
                            <Media>
                              <Media body>
                                {/* <p
                                className="fw-medium"
                                style={{ color: "white" }}
                              >
                                {section.section}
                              </p> */}
                                <h5 className="mb-0" style={{ color: "white" }}>
                                  {section.section}
                                </h5>
                              </Media>
                            </Media>
                          </CardBody>
                        </Card>
                      </Link>
                      // <Link
                      //   to={() => {
                      //     return getLink(section.section, props.type);
                      //   }}
                      //   className="text-reset notification-item"
                      // >
                      //   <div className="media">
                      //     <div className="avatar-xs ">
                      //       <i class="bx bx-right-arrow-circle font-size-18"></i>
                      //     </div>
                      //     <div className="media-body">
                      //       <h5 className="mt-0 mb-1">{section.section}</h5>
                      //     </div>
                      //   </div>
                      // </Link>
                    );
                  })}
              </div>
            </>
          ) : (
            <SimpleBar style={{ height: "80px" }}>
              <Link to="" className="text-reset notification-item">
                <div className="media">
                  <div className="media-body">
                    <h6 className="mt-0 mb-1"></h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">There are no sections.</p>
                    </div>
                  </div>
                </div>
              </Link>
            </SimpleBar>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default withNamespaces()(OrderProductSections);
