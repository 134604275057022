import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import showNotification from "../../helpers/show_notification";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import exportFromJSON from "export-from-json";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import TagsInput from "react-tagsinput";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { postSubmitForm } from "../../helpers/forms_helper";
// actions

const AllProducts = (props) => {
  const { SearchBar } = Search;
  const refContainer = useRef(null);
  const refNewSectionForm = useRef(null);

  useEffect(() => {
    loadAllSections();
    loadAllUnits();
    loadAllProducts();
  }, []);

  const [allProducts, setAllProducts] = useState([]);
  const [allProductsFiltered, setAllProductsFiltered] = useState([]);
  const loadAllProducts = async () => {
    let url = process.env.REACT_APP_BASEURL + "products/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllProducts(response.data);
      setAllProductsFiltered(response.data);
      setSelectedSectionFilter("");
      setSelectedCategoryFilter("");
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [allSections, setAllSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState();
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const loadAllSections = async () => {
    let url = process.env.REACT_APP_BASEURL + "productsections/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllSections(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [allUnits, setAllUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState();
  const loadAllUnits = async () => {
    let url = process.env.REACT_APP_BASEURL + "productunits/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllUnits(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [newUnitModal, setNewUnitModal] = useState();
  const handleAddNewUnit = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "productunits/insert";
    const response = await postSubmitForm(url, v);
    if (response && response.status === 1) {
      loadAllUnits();
      setSelectedUnit(v.unit);
      setNewUnitModal(!newUnitModal);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [newSectionModal, setNewSectionModal] = useState();
  const [newCategories, setNewCategories] = useState([]);
  const handleAddNewSection = async (e, v) => {
    try {
      const object = {
        section: v.name,
        category: newCategories,
      };
      let url = process.env.REACT_APP_BASEURL + "productsections/insert";
      let response = await postSubmitForm(url, object);
      if (response.status === 1) {
        loadAllSections();
        setSelectedSection(v.name);
        setAllCategories(newCategories);
        setNewCategories([]);
        setNewSectionModal(!newSectionModal);
        refNewSectionForm.current.reset();
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };

  const [newCategoryModal, setNewCategoryModal] = useState();
  const handleAddNewCategory = async (e, v) => {
    try {
      const object = {
        section: selectedSection,
        category: v.name,
      };
      let url =
        process.env.REACT_APP_BASEURL + "productsections/insert_subcategory";
      let response = await postSubmitForm(url, object);
      if (response.status === 1) {
        loadAllSections();
        let all_cat = [...allCategories];
        all_cat.push(v.name);
        setAllCategories(all_cat);
        setSelectedCategory(v.name);
        setNewCategoryModal(!newCategoryModal);
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };

  const handleValidUpdate = async (e, v) => {
    try {
      v.id = selectedProduct._id;
      let url = process.env.REACT_APP_BASEURL + "products/update";
      let response = await postSubmitForm(url, v);
      if (response.status === 1) {
        setEditProductModal(!editProductModal);
        showNotification(response.message, "Success");
        refContainer.current.reset();
        loadAllProducts();
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };
  const [editProductModal, setEditProductModal] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "section",
      text: props.t("Section"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "category",
      text: props.t("Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "threshold",
      text: props.t("Centre Kitchen Threshold"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Unit"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "cost_price",
      text: props.t("Cost Price"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "selling_price",
      text: props.t("Selling Price"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "expiry_days",
      text: props.t("Expires in"),
      sort: true,
      formatter: (cell, row) => {
        return row.expiry_days + (row.expiry_days > 1 ? " Days" : " Day");
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Action"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      formatter: (cell, row) => {
        return (
          <span className="text-info">
            <i
              className="bx bxs-edit font-size-15"
              title="Click to Edit"
              style={{ cursor: "pointer" }}
              onClick={() => {
                var sections = [...allSections]; //JSON.parse(JSON.stringify(allCategories));

                console.log(sections);
                let arr = sections.filter(
                  (section) => section.section === row.section
                );
                console.log(row.section);
                console.log(arr);
                console.log(arr.length);
                if (arr.length > 0) {
                  setAllCategories(arr[0].category);
                }
                setSelectedSection(row.section);
                setSelectedCategory(row.category);
                setSelectedUnit(row.unit);
                setEditProductModal(!editProductModal);
                setSelectedProduct(row);
              }}
            ></i>
          </span>
        );
      },
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} - {to} of {size} records.
    </span>
  );
  const pagination_options = {
    paginationSize: 50,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: allProducts && allProducts.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };

  const [selectedSectionFilter, setSelectedSectionFilter] = useState();
  const [allCategoriesFilter, setAllCategoriesFilter] = useState([]);
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState();
  useEffect(() => {
    if (selectedSectionFilter) {
      let arr = JSON.parse(JSON.stringify(allProducts));
      if (selectedCategoryFilter) {
        let new_arr = arr.filter(
          (prod) =>
            prod.section === selectedSectionFilter &&
            prod.category === selectedCategoryFilter
        );
        setAllProductsFiltered(new_arr);
      } else {
        let new_arr = arr.filter(
          (prod) => prod.section === selectedSectionFilter
        );
        setAllProductsFiltered(new_arr);
      }
    } else {
      setAllProductsFiltered(allProducts);
    }
  }, [selectedCategoryFilter, selectedSectionFilter]);

  const handleDownload = async () => {
    const fileName = "all_products";
    const exportType = "xls";

    let data_to_export = allProductsFiltered;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          name: v.name,
          section: v.section,
          category: v.category,
          unit: v.unit,
          expiry_days: v.expiry_days,
          remarks: v.remarks,
          threshold: v.threshold,
          available: v.available,
          cost_price: v.cost_price,
          selling_price: v.selling_price,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Products")}
            breadcrumbItem={props.t("All Products")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>

                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <CardTitle>{props.t("All Products")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View/Update all existing products here")}
                  </CardSubtitle>
                  <ToolkitProvider
                    keyField="_id"
                    data={allProductsFiltered && allProductsFiltered}
                    columns={columns}
                    search
                  >
                    {(propst) => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>

                            <Col lg={8} className="text-right">
                              <Row>
                                <Col lg={6}>
                                  <AvField
                                    name="section_filter"
                                    type="select"
                                    value={selectedSectionFilter}
                                    onChange={(e) => {
                                      setSelectedSectionFilter(e.target.value);
                                      setSelectedCategoryFilter("");
                                      if (e.target.value) {
                                        var sections = JSON.parse(
                                          JSON.stringify(allSections)
                                        );
                                        let arr = sections.filter(
                                          (section) =>
                                            section.section === e.target.value
                                        );
                                        setAllCategoriesFilter(arr[0].category);
                                      } else {
                                        setAllCategoriesFilter([]);
                                      }
                                    }}
                                  >
                                    <option value="">
                                      {props.t("All Sections")}
                                    </option>
                                    {allSections &&
                                      allSections.map((section) => {
                                        return (
                                          <option value={section.section}>
                                            {section.section}
                                          </option>
                                        );
                                      })}
                                  </AvField>
                                </Col>
                                <Col lg={6}>
                                  <AvField
                                    name="category_filter"
                                    type="select"
                                    errorMessage={props.t(
                                      "Category cannot be empty"
                                    )}
                                    onChange={(e) =>
                                      setSelectedCategoryFilter(e.target.value)
                                    }
                                    value={selectedCategoryFilter}
                                  >
                                    <option value="">
                                      {props.t("All Categories")}
                                    </option>
                                    {allCategoriesFilter &&
                                      allCategoriesFilter.map((category) => {
                                        return (
                                          <option value={category}>
                                            {category}
                                          </option>
                                        );
                                      })}
                                  </AvField>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          //pagination={paginationFactory(pagination_options)}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>

                  <Modal
                    size="lg"
                    isOpen={editProductModal}
                    toggle={() => setEditProductModal(!editProductModal)}
                  >
                    <ModalHeader
                      toggle={() => setEditProductModal(!editProductModal)}
                    >
                      {props.t("Update Product")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={handleValidUpdate}
                                ref={refContainer}
                              >
                                <Row>
                                  <Col lg={6}>
                                    <Row>
                                      <Col lg={12}>
                                        <AvField
                                          name="name"
                                          label={props.t("Name")}
                                          value={
                                            selectedProduct &&
                                            selectedProduct.name
                                          }
                                          placeholder={props.t(
                                            "Enter Product Name"
                                          )}
                                          type="text"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          errorMessage={props.t(
                                            "Name cannot be empty"
                                          )}
                                        />
                                      </Col>
                                      <Col lg={12}>
                                        <Row>
                                          <Col lg={8}>
                                            <AvField
                                              name="section"
                                              label={props.t("Section")}
                                              type="select"
                                              value={selectedSection}
                                              validate={{
                                                required: { value: true },
                                              }}
                                              errorMessage={props.t(
                                                "Section cannot be empty"
                                              )}
                                              onChange={(e) => {
                                                setSelectedSection(
                                                  e.target.value
                                                );
                                                if (e.target.value) {
                                                  var sections = JSON.parse(
                                                    JSON.stringify(allSections)
                                                  );
                                                  let arr = sections.filter(
                                                    (section) =>
                                                      section.section ===
                                                      e.target.value
                                                  );
                                                  setAllCategories(
                                                    arr[0].category
                                                  );
                                                } else {
                                                  setAllCategories([]);
                                                }
                                              }}
                                            >
                                              <option value="">
                                                {props.t("--Select Section--")}
                                              </option>
                                              {allSections &&
                                                allSections.map((section) => {
                                                  return (
                                                    <option
                                                      value={section.section}
                                                    >
                                                      {section.section}
                                                    </option>
                                                  );
                                                })}
                                            </AvField>
                                          </Col>
                                          <Col lg={4}>
                                            <Label>&nbsp;</Label>
                                            <br />
                                            <Link
                                              class="btn btn-success waves-effect waves-light btn-sm"
                                              onClick={() => {
                                                setNewSectionModal(
                                                  !newSectionModal
                                                );
                                              }}
                                            >
                                              <i class="mdi mdi-file-plus"></i>{" "}
                                              {props.t("Add New")}
                                            </Link>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col lg={12}>
                                        <Row>
                                          <Col lg={8}>
                                            <AvField
                                              name="category"
                                              label={props.t("Category")}
                                              type="select"
                                              required
                                              errorMessage={props.t(
                                                "Category cannot be empty"
                                              )}
                                              value={selectedCategory}
                                            >
                                              <option value="">
                                                {props.t("--Select Category--")}
                                              </option>
                                              {allCategories &&
                                                allCategories.map(
                                                  (category) => {
                                                    return (
                                                      <option value={category}>
                                                        {category}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </AvField>
                                          </Col>
                                          <Col lg={4}>
                                            <Label>&nbsp;</Label>
                                            <br />
                                            <Link
                                              class="btn btn-success waves-effect waves-light btn-sm"
                                              onClick={() => {
                                                if (selectedSection) {
                                                  setNewCategoryModal(
                                                    !newCategoryModal
                                                  );
                                                } else {
                                                  showNotification(
                                                    props.t(
                                                      "Select a section first!"
                                                    ),
                                                    props.t("Error")
                                                  );
                                                }
                                              }}
                                            >
                                              <i class="mdi mdi-file-plus"></i>{" "}
                                              {props.t("Add New")}
                                            </Link>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col lg={12}>
                                        <AvField
                                          name="cost_price"
                                          placeholder={props.t(
                                            "Enter Cost Price"
                                          )}
                                          label={props.t("Cost Price")}
                                          value={
                                            selectedProduct &&
                                            selectedProduct.cost_price.toString()
                                          }
                                          type="number"
                                          min="0.00"
                                          step="0.01"
                                          validate={{
                                            required: {
                                              value: true,
                                              errorMessage: props.t(
                                                "Cost price cannot be empty."
                                              ),
                                            },
                                          }}
                                          errorMessage="Invalid cost price."
                                        />
                                      </Col>
                                      <Col lg={12}>
                                        <AvField
                                          name="remarks"
                                          label={props.t("Remarks")}
                                          placeholder={props.t("Enter Remarks")}
                                          type="textarea"
                                          value={
                                            selectedProduct &&
                                            selectedProduct.remarks
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={6}>
                                    <Row>
                                      <Col lg={12}>
                                        <Row>
                                          <Col lg={8}>
                                            <AvField
                                              name="unit"
                                              label={props.t("Unit")}
                                              type="select"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              errorMessage={props.t(
                                                "Unit cannot be empty"
                                              )}
                                              value={selectedUnit}
                                              onChange={(e) => {
                                                setSelectedUnit(e.target.value);
                                              }}
                                            >
                                              {" "}
                                              <option value="">
                                                {props.t("--Select Unit--")}
                                              </option>
                                              {allUnits &&
                                                allUnits.map((unit) => {
                                                  return (
                                                    <option value={unit.unit}>
                                                      {unit.unit}
                                                    </option>
                                                  );
                                                })}
                                            </AvField>
                                          </Col>
                                          <Col lg={4}>
                                            <Label>&nbsp;</Label>
                                            <br />
                                            <Link
                                              class="btn btn-success waves-effect waves-light btn-sm"
                                              onClick={() => {
                                                setNewUnitModal(!newUnitModal);
                                              }}
                                            >
                                              <i class="mdi mdi-file-plus"></i>{" "}
                                              {props.t("Add New")}
                                            </Link>
                                          </Col>
                                        </Row>
                                      </Col>

                                      <Col lg={12}>
                                        <AvField
                                          name="threshold"
                                          value={
                                            selectedProduct &&
                                            selectedProduct.threshold.toString()
                                          }
                                          label={props.t("Centre Threshold")}
                                          placeholder={props.t(
                                            "Enter Threshold Quantity for Centre"
                                          )}
                                          type="text"
                                          validate={{
                                            required: {
                                              value: true,
                                              errorMessage: props.t(
                                                "Threshold cannot be empty."
                                              ),
                                            },
                                            pattern: {
                                              value: "^[0-9]+$",
                                              errorMessage: props.t(
                                                "Only numbers are allowed."
                                              ),
                                            },
                                          }}
                                        />
                                      </Col>
                                      <Col lg={12}>
                                        <AvField
                                          name="expiry_days"
                                          label={props.t(
                                            "Expiry (No. of Days)"
                                          )}
                                          placeholder={props.t("Enter Expiry")}
                                          type="text"
                                          value={
                                            selectedProduct &&
                                            selectedProduct.expiry_days.toString()
                                          }
                                          validate={{
                                            required: {
                                              value: true,
                                              errorMessage: props.t(
                                                "Expiry cannot be empty."
                                              ),
                                            },
                                            pattern: {
                                              value: "^[0-9]+$",
                                              errorMessage: props.t(
                                                "Only numbers are allowed."
                                              ),
                                            },
                                          }}
                                        />{" "}
                                      </Col>
                                      <Col lg={12}>
                                        <AvField
                                          name="selling_price"
                                          placeholder={props.t(
                                            "Enter Selling Price"
                                          )}
                                          label={props.t("Selling Price")}
                                          value={
                                            selectedProduct &&
                                            selectedProduct.selling_price.toString()
                                          }
                                          type="number"
                                          min="0.00"
                                          step="0.01"
                                          validate={{
                                            required: {
                                              value: true,
                                              errorMessage: props.t(
                                                "Selling price cannot be empty."
                                              ),
                                            },
                                          }}
                                          errorMessage="Invalid selling price."
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Submit")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>

                  <Modal
                    size="md"
                    isOpen={newUnitModal}
                    toggle={() => setNewUnitModal(!newUnitModal)}
                  >
                    <ModalHeader toggle={() => setNewUnitModal(!newUnitModal)}>
                      {props.t("Add new unit")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewUnit(e, v);
                                }}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="unit"
                                      label={props.t("Name of Unit") + " *"}
                                      placeholder={props.t("Enter Unit Name")}
                                      type="text"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: props.t(
                                            "Name cannot be empty."
                                          ),
                                        },
                                      }}
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="md"
                    isOpen={newSectionModal}
                    toggle={() => setNewSectionModal(!newSectionModal)}
                  >
                    <ModalHeader
                      toggle={() => setNewSectionModal(!newSectionModal)}
                    >
                      {props.t("Add new section")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewSection(e, v);
                                }}
                                ref={refNewSectionForm}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Section")}
                                      placeholder={props.t(
                                        "Enter Section Name"
                                      )}
                                      type="text"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: props.t(
                                            "Name cannot be empty"
                                          ),
                                        },
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <Label>{props.t("Category Name")}</Label> (
                                    {props.t("press ENTER/TAB to add")})
                                    <TagsInput
                                      value={newCategories}
                                      onChange={(tags) => {
                                        setNewCategories(tags);
                                      }}
                                      onlyUnique={true}
                                      inputProps={{
                                        className: "react-tagsinput-input",
                                        placeholder: props.t("Add..."),
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="md"
                    isOpen={newCategoryModal}
                    toggle={() => setNewCategoryModal(!newCategoryModal)}
                  >
                    <ModalHeader
                      toggle={() => setNewCategoryModal(!newCategoryModal)}
                    >
                      {props.t("Add new category")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewCategory(e, v);
                                }}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Category")}
                                      placeholder={props.t(
                                        "Enter Category Name"
                                      )}
                                      type="text"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: props.t(
                                            "Name cannot be empty"
                                          ),
                                        },
                                      }}
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(connect(null, {})(withNamespaces()(AllProducts)));
