import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const ItemOrdersInProcess = (props) => {
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }

  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }

  const { SearchBar } = Search;
  const [editModal, setEditModal] = useState();
  const [remarks, setRemarks] = useState();
  useEffect(() => {
    loadAllBranches();
    loadAllCategories();
  }, []);
  const [confirmClose, setConfirmClose] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [selectedCategory, setSelectedCategory] = useState("All");

  const [centralAvailableStatus, setCentralAvailableStatus] = useState(true);
  const [isDispatched, setIsDispatched] = useState(false);

  useEffect(() => {
    loadAllOrders();
  }, [selectedCategory, selectedBranch]);

  const [allOrders, setAllOrders] = useState([]);
  const loadAllOrders = async () => {
    preloader(true);
    let url = process.env.REACT_APP_BASEURL + "itemorders/getall";
    const response = await postSubmitForm(url, {
      status: [
        "Approved",
        "Dispatched",
        "Partially Dispatched",
        "Delivered",
        "Partially Delivered",
      ],
      branch: selectedBranch,
      category: selectedCategory,
    });
    if (response && response.status === 1) {
      // let expandedRows = [];
      // response.data.map((item) => {
      //   expandedRows.push(item._id);
      // });
      preloader(false);
      setAllOrders(response.data);
      // setExpanded(expandedRowsetAllOrderss);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };
  const refForForm = useRef(null);
  const refSelectItem = useRef(null);
  const [itemsForOrder, setItemsForOrder] = useState([]);
  const handleAddItemForOrder = async (e, v) => {
    var new_array_items = JSON.parse(JSON.stringify(itemsForOrder));
    const existing_item = new_array_items.filter(function (item) {
      return item.item_id === selectedItem._id;
    });

    if (existing_item.length > 0) {
      showNotification(
        props.t("This item is already added."),
        props.t("Error")
      );
    } else if (selectedItem) {
      let item_to_add = {};
      item_to_add.item_id = selectedItem._id;
      item_to_add.name = selectedItem.name;
      item_to_add.category = selectedItem.category;
      item_to_add.sub_category = selectedItem.sub_category;
      item_to_add.brand = selectedItem.brand;
      item_to_add.unit = selectedItem.unit;
      if (selectedItem.sub_unit) {
        item_to_add.sub_unit = selectedItem.sub_unit;
      }
      item_to_add.formula = selectedItem.formula;
      item_to_add.available = selectedItem.available;
      item_to_add.quantity = selectedItem.sub_unit
        ? { in_unit: v.in_unit, in_sub_unit: v.in_sub_unit }
        : { in_unit: v.quantity, in_sub_unit: 0 };
      setItemsForOrder([...itemsForOrder, item_to_add]);
      refSelectItem.current.select.clearValue();
      refForForm.current.reset();
    } else {
      showNotification(props.t("Select an item first."), props.t("Error"));
    }
  };
  const [isUrgent, setIsUrgent] = useState(false);
  const handleValidUpdate = async (e, v) => {
    try {
      const object = {
        id: selectedOrder._id,
        item_details: itemsForOrder,
        //is_urgent: isUrgent,
        remarks: v.remarks,
      };
      let url = process.env.REACT_APP_BASEURL + "itemorders/update";
      let response = await postSubmitForm(url, object);
      if (response.status === 1) {
        loadAllOrders();
        setEditModal(!editModal);
        setRemarks();
        setItemsForOrder([]);
        setIsUrgent(false);
        setSelectedItem();
        refSelectItem.current.select.clearValue();
        refForForm.current.reset();

        showNotification(response.message, "Success");

        refForForm.current.reset();
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };
  const [allBranches, setAllBranches] = useState([]);
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [allCategories, setAllCategories] = useState([]);
  const loadAllCategories = async () => {
    let url = process.env.REACT_APP_BASEURL + "itemcategories/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllCategories(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Sub-Category"),
      dataField: "sub_category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Available"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.available.in_unit +
                " " +
                row.unit +
                " " +
                row.available.in_sub_unit +
                " " +
                row.sub_unit
              : row.available.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Order Quantity"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "central_available",
      text: props.t("Central Avl. Qty."),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.central_available.in_unit +
                " " +
                row.unit +
                " " +
                row.central_available.in_sub_unit +
                " " +
                row.sub_unit
              : row.central_available.in_unit + " " + row.unit}
          </>
        );
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const [selectedOrder, setSelectedOrder] = useState();
  const handleCloseOrder = async () => {
    let url = process.env.REACT_APP_BASEURL + "itemorders/set_closed";
    const response = await postSubmitForm(url, {
      itemorder_id: selectedOrder._id,
      admin_remarks: adminRemarks,
    });

    if (response && response.status === 1) {
      setAdminRemarks("");
      loadAllOrders();
      setRemarksModal(!remarksModal);
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [remarksModal, setRemarksModal] = useState();
  const [adminRemarks, setAdminRemarks] = useState();
  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      dataField: "order_id",
      text: props.t("Order ID"),

      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "branch_details.name",
      text: props.t("Branch Name"),

      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),

      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },

      style: { cursor: "pointer" },
    },
    {
      text: props.t("Order Date"),
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },

      dataField: "createdAt",
      // formatter: (cell, row) => {
      //   return moment(row.createdAt).format("DD-MM-YYYY HH:mm");
      // },
      style: { cursor: "pointer" },
    },
    // {
    //   text: props.t("Is Urgent?"),
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "12%" };
    //   },
    //   formatter: (cell, row) => {
    //     if (row.is_urgent) {
    //       return props.t("Yes");
    //     } else {
    //       return props.t("No");
    //     }
    //   },
    //   style: { cursor: "pointer" },
    // },

    {
      dataField: "status",
      text: props.t("Status"),
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "",
      text: props.t("Action"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <button
              class="btn btn-secondary btn-sm mr-2"
              onClick={() => {
                setSelectedOrder(row);
                setItemsForDelivery([]);
                setSelectedItem();
                setSelectedBatch();
                setQuantity();
                loadItemsInOrder(row._id);
                setOrderDispatchModal(!orderDispatchModal);
                if (row.status === "Approved") {
                  setIsPartialDelivery(false);
                  handleSetItemsForFullDelivery(row.item_details);
                } else setIsPartialDelivery(true);
                setCentralAvailableStatus(row.central_available_status);
                setIsDispatched(false);
              }}
            >
              {props.t("Dispatch")}
            </button>
            <button
              class="btn btn-danger btn-sm mr-2"
              onClick={() => {
                setRemarksModal(!remarksModal);
                setSelectedOrder(row);
              }}
            >
              {props.t("Close")}
            </button>

            {/* <button
              class="btn btn-info btn-sm mr-2"
              onClick={() => {
                setEditModal(!editModal);
                loadItemsInOrder(row._id);
                setItemsForOrder(row.item_details);
                setSelectedOrder(row);
              }}
            >
              {props.t("Edit")}
            </button> */}
          </>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
  ];
  const handleSetItemsForFullDelivery = async (items) => {
    if (items.length > 0) {
      let new_arr = items.map((item) => {
        let obj = {
          name: item.name,
          category: item.category,
          sub_category: item.sub_category,
          item_id: item.item_id,
          delivered_quantity: item.quantity,
          brand: item.brand,
          unit: item.unit,
          sub_unit: item.sub_unit,
          formula: item.formula,
        };
        return obj;
      });
      setItemsForDelivery(new_arr);
    } else {
      setItemsForDelivery([]);
    }
  };
  const columns_fulldelivery = [
    {
      dataField: "item_id",
      hidden: true,
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Sub-Category"),
      dataField: "sub_category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Delivered Qty."),
      formatter: (cell, row) => {
        return (
          <>
            {row.delivered_quantity.in_sub_unit
              ? row.delivered_quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.delivered_quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.delivered_quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      setExpanded([...expanded, row._id]);
    } else {
      setExpanded(expanded.filter((x) => x !== row._id));
    }
  };
  const [expanded, setExpanded] = useState([]);
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.item_details}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
    expanded: expanded,
    onExpand: handleOnExpand,
  };
  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.status === "Dispatched") {
      style.backgroundColor = "#b0ff9e";
    } else if (row.status === "Partially Delivered") {
      style.backgroundColor = "#bb87cc";
    } else {
      style.backgroundColor = "#fffbb8";
    }
    return style;
  };

  //#region Dispatch order related code
  const [itemsForDelivery, setItemsForDelivery] = useState([]);
  const [quantity, setQuantity] = useState();
  const [quantityInUnit, setQuantityInUnit] = useState();
  const [quantityInSubUnit, setQuantityInSubUnit] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [orderDispatchModal, setOrderDispatchModal] = useState();
  const [itemBatches, setItemBatches] = useState();
  const [selectedBatch, setSelectedBatch] = useState();
  const [allItems, setAllItems] = useState([]);
  const loadItemsInOrder = async (itemorder_id) => {
    let url = process.env.REACT_APP_BASEURL + "itemorders/get_items_in_order";

    const response = await postSubmitForm(url, { itemorder_id });

    if (response && response.status === 1) {
      setAllItems(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const loadBatches = async (item_details) => {
    let url =
      process.env.REACT_APP_BASEURL + "iteminventories/get_for_delivery";

    const response = await postSubmitForm(url, {
      item_id: item_details.value,
    });
    if (response && response.status === 1) {
      setItemBatches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const handleAddItemForDelivery = async () => {
    if (!selectedBatch) {
      showNotification(props.t("Please select item batch."), props.t("Error"));
    } else {
      var new_array = JSON.parse(JSON.stringify(itemsForDelivery));
      const existing_item = new_array.filter(function (item) {
        return item.batch === selectedBatch.batch;
      });
      if (existing_item.length > 0) {
        showNotification(props.t("This item batch is already added."), "Error");
      } else {
        if (selectedItem.sub_unit) {
          if (selectedBatch.quantity.in_unit < quantityInUnit) {
            showNotification(props.t("Quantity not in stock."), "Error");
          } else if (
            selectedBatch.quantity.in_unit === quantityInUnit &&
            selectedBatch.quantity.in_sub_unit < quantityInSubUnit
          ) {
            showNotification(props.t("Quantity not in stock."), "Error");
          } else {
            setItemsForDelivery([
              ...itemsForDelivery,
              {
                batch: selectedBatch.batch,
                name: selectedItem.name,
                brand: selectedItem.brand,
                category: selectedItem.category,
                sub_category: selectedItem.sub_category,
                item_id: selectedItem.value,
                delivered_quantity: {
                  in_unit: quantityInUnit,
                  in_sub_unit: quantityInSubUnit,
                },
                unit: selectedItem.unit,
                sub_unit: selectedItem.sub_unit,
                formula: selectedItem.formula,
              },
            ]);

            setQuantityInUnit(0);
            setQuantityInSubUnit(0);
            setSelectedItem();
          }
        } else {
          if (selectedBatch.quantity < quantityInUnit) {
            showNotification(props.t("Quantity not in stock."), "Error");
          } else {
            setItemsForDelivery([
              ...itemsForDelivery,
              {
                batch: selectedBatch.batch,
                name: selectedItem.name,
                brand: selectedItem.brand,
                category: selectedItem.category,
                sub_category: selectedItem.sub_category,
                item_id: selectedItem.value,
                delivered_quantity: {
                  in_unit: quantityInUnit,
                  in_sub_unit: 0,
                },
                unit: selectedItem.unit,
              },
            ]);

            setQuantityInUnit(0);
            setQuantityInSubUnit(0);
            setSelectedItem();
          }
        }
      }
    }
  };
  function deleteFormatter(cell, row, rowIndex, extraData) {
    return (
      <span className="text-danger">
        <i
          className="bx bxs-trash font-size-15"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            let arr = extraData.filter((item) => item.batch !== row.batch);

            setItemsForDelivery(arr);
          }}
        ></i>
      </span>
    );
  }

  function deleteFormatter_edit(cell, row, rowIndex, extraData) {
    return (
      <>
        <span className="text-danger">
          <i
            className="bx bxs-trash font-size-15"
            title="Click to Delete"
            style={{ cursor: "pointer" }}
            onClick={() => {
              let arr = extraData.filter(
                (item) => item.item_id !== row.item_id
              );

              setItemsForOrder(arr);
            }}
          ></i>
        </span>
      </>
    );
  }
  const columns_items = [
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Delivered Qty."),
      formatter: (cell, row) => {
        return (
          <>
            {row.delivered_quantity.in_sub_unit
              ? row.delivered_quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.delivered_quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.delivered_quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      formatter: deleteFormatter,
      formatExtraData: itemsForDelivery,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
  ];

  const columns_items_edit = [
    {
      dataField: "category",
      text: props.t("Category"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "sub_category",
      text: props.t("Sub-Category"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "name",
      text: props.t("Name"),
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      sort: true,
    },
    {
      dataField: "unit",
      text: props.t("Available"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.available.in_unit +
                " " +
                row.unit +
                " " +
                row.available.in_sub_unit +
                " " +
                row.sub_unit
              : row.available.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Order Quantity"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      formatter: deleteFormatter_edit,
      formatExtraData: itemsForOrder,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];

  const columns_delivered_items = [
    {
      text: props.t("Category, Sub-Category, Item"),
      dataField: "section",
      formatter: (cell, row) => {
        return row.category + ", " + row.sub_category + ", " + row.name;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },

    {
      text: props.t("Dispatch Time"),
      dataField: "date_of_transaction",
      formatter: (cell, row) => {
        return moment(row.date_of_transaction).format("DD/MM/YYYY HH:mm");
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Delivered Qty."),
      formatter: (cell, row) => {
        return (
          <>
            {row.delivered_quantity.in_sub_unit
              ? row.delivered_quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.delivered_quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.delivered_quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Rec. Qty."),
      formatter: (cell, row) => {
        return (
          <>
            {row.received_quantity.in_sub_unit
              ? row.received_quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.received_quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.received_quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    // {
    //   text: props.t("Rec. Qty."),
    //   dataField: "received_quantity",
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "9%" };
    //   },
    // },
    {
      text: props.t("Received by Branch?"),
      dataField: "is_received",
      formatter: (cell, row) => {
        return row.is_received ? "Yes" : "No";
      },
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const handleDispatchOrder = async () => {
    if (isDispatched) {
      return showNotification("Already dispatched.", "Error");
    }
    setIsDispatched(true);

    if (itemsForDelivery.length > 0) {
      let data_to_send = {};
      data_to_send.itemorder_id = selectedOrder._id;
      data_to_send.branch_id = selectedOrder.branch_details._id;
      data_to_send.delivery_details = itemsForDelivery;
      data_to_send.isPartialDelivery = isPartialDelivery;
      let url = process.env.REACT_APP_BASEURL + "itemorders/dispatch";

      //console.log(data_to_send);

      const response = await postSubmitForm(url, data_to_send);
      if (response && response.status === 1) {
        loadAllOrders();
        showNotification(response.message, "Success");
        setOrderDispatchModal(!orderDispatchModal);
      } else {
        showNotification(response.message, "Error");
      }
    } else {
      showNotification("Add at least one item.", "Error");
    }
  };
  //#endregion

  const [isPartialDelivery, setIsPartialDelivery] = useState(false);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Items")}
            title={props.t("Central Store")}
            breadcrumbItem1={props.t("Item Order")}
            breadcrumbItem2={props.t("Orders In Process")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Orders In Process")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all orders in process here")}
                  </CardSubtitle>
                  {/* <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row> */}
                  <ToolkitProvider
                    keyField="_id"
                    data={allOrders && allOrders}
                    columns={columns_external}
                    noDataIndication={props.t("No data to display.")}
                    bootstrap4
                    search
                  >
                    {(props_table) => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4}>
                              <Label>{props.t("Search")}</Label>
                              <SearchBar
                                {...props_table.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}>
                              <AvField
                                name="branch"
                                label={props.t("Branch")}
                                type="select"
                                value={selectedBranch}
                                onChange={(e) => {
                                  setSelectedBranch(e.target.value);
                                }}
                              >
                                <option value="All">{props.t("All")}</option>
                                {allBranches &&
                                  allBranches.map((branch) => {
                                    return (
                                      <option value={branch._id}>
                                        {branch.name}
                                      </option>
                                    );
                                  })}
                              </AvField>
                            </Col>
                            <Col lg={4}>
                              <AvField
                                name="category"
                                label={props.t("Category")}
                                type="select"
                                value={selectedCategory}
                                onChange={(e) => {
                                  setSelectedCategory(e.target.value);
                                }}
                              >
                                <option value="All">{props.t("All")}</option>
                                {allCategories &&
                                  allCategories.map((category) => {
                                    return (
                                      <option value={category.category}>
                                        {category.category}
                                      </option>
                                    );
                                  })}
                              </AvField>
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          striped
                          hover
                          condensed
                          expandRow={expandRow}
                          rowStyle={rowStyle}
                          {...props_table.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                  <Modal
                    size="lg"
                    style={{ width: "925px", minWidth: "925px" }}
                    isOpen={orderDispatchModal}
                    toggle={() => setOrderDispatchModal(!orderDispatchModal)}
                  >
                    <ModalHeader
                      toggle={() => setOrderDispatchModal(!orderDispatchModal)}
                    >
                      {props.t("Dispatch Order")}
                    </ModalHeader>
                    <ModalBody>
                      <>
                        <Row>
                          <Col lg={12}>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col lg={12}>
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        printDiv("printOrdered");
                                      }}
                                      className="btn btn-sm btn-success mr-2"
                                    >
                                      <i className="fa fa-print"></i> Print
                                    </Link>
                                    <div id="printOrdered">
                                      <Col lg={12} md={12}>
                                        <Label>Ordered Items</Label>
                                      </Col>

                                      <Col lg={12}>
                                        <BootstrapTable
                                          bootstrap4
                                          keyField="name"
                                          data={
                                            selectedOrder &&
                                            selectedOrder.item_details
                                          }
                                          columns={columns}
                                          noDataIndication="No data to display."
                                          striped
                                          hover
                                          condensed
                                        />

                                        <hr />
                                      </Col>
                                    </div>
                                  </Col>

                                  {selectedOrder &&
                                  selectedOrder.status === "Approved" ? (
                                    <Col lg={12}>
                                      <div class="form-check">
                                        <input
                                          type="checkbox"
                                          name="isPartialDelivery"
                                          id="isPartialDelivery"
                                          className="is-untouched is-pristine av-valid form-check-input"
                                          checked={isPartialDelivery}
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setIsPartialDelivery(true);
                                              setItemsForDelivery([]);
                                            } else {
                                              handleSetItemsForFullDelivery(
                                                selectedOrder.item_details
                                              );
                                              setIsPartialDelivery(false);
                                            }
                                          }}
                                        />
                                        <label for="isPartialDelivery">
                                          {" "}
                                          {props.t("Partial Delivery")}
                                        </label>
                                      </div>

                                      <br />
                                    </Col>
                                  ) : null}
                                  {selectedOrder &&
                                  selectedOrder.delivery_details &&
                                  selectedOrder.delivery_details.length > 0 ? (
                                    <>
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          printDiv("printDispatched");
                                        }}
                                        className="btn btn-sm btn-success mr-2"
                                      >
                                        <i className="fa fa-print"></i> Print
                                      </Link>
                                      <div id="printDispatched">
                                        <Col lg={12} md={12}>
                                          <Label>
                                            <b>
                                              {props.t("Dispatched Items")}{" "}
                                            </b>
                                          </Label>
                                        </Col>
                                        <Col lg={12} className="mb-4">
                                          <BootstrapTable
                                            bootstrap4
                                            keyField="_id"
                                            data={
                                              selectedOrder.delivery_details
                                            }
                                            columns={columns_delivered_items}
                                            noDataIndication="No data to display."
                                            striped
                                            hover
                                            condensed
                                          />
                                          <hr />
                                        </Col>
                                      </div>
                                    </>
                                  ) : null}
                                </Row>
                                {isPartialDelivery && (
                                  <Col lg={12}>
                                    <AvForm
                                      onValidSubmit={handleAddItemForDelivery}
                                    >
                                      <Row>
                                        <Col lg={3}>
                                          <Label>{props.t("Add Item")}</Label>
                                          <Select
                                            value={selectedItem && selectedItem}
                                            key={selectedItem}
                                            placeholder={props.t("Select...")}
                                            onChange={(e) => {
                                              setSelectedItem(e);
                                              loadBatches(e);
                                              setSelectedBatch();
                                            }}
                                            options={allItems}
                                            classNamePrefix="select2-selection"
                                            required
                                          />
                                        </Col>

                                        {selectedItem &&
                                        selectedItem.sub_unit ? (
                                          selectedItem.available.in_unit > 0 ||
                                          selectedItem.available.in_sub_unit >
                                            0 ? (
                                            <>
                                              <Col lg={3}>
                                                <Label>
                                                  {props.t("Batch")}
                                                </Label>
                                                <Select
                                                  value={
                                                    selectedBatch &&
                                                    selectedBatch
                                                  }
                                                  key={selectedBatch}
                                                  onChange={(e) => {
                                                    setSelectedBatch(e);
                                                  }}
                                                  options={
                                                    itemBatches && itemBatches
                                                  }
                                                  classNamePrefix="select2-selection"
                                                  required
                                                />
                                              </Col>

                                              <Col lg={4}>
                                                <Label>
                                                  {props.t("Quantity")}{" "}
                                                  {" (1 " +
                                                    selectedItem.unit +
                                                    " = " +
                                                    selectedItem.formula +
                                                    " " +
                                                    selectedItem.sub_unit +
                                                    ")"}
                                                </Label>
                                                <Row>
                                                  <Col lg={6}>
                                                    <AvField
                                                      name="in_unit"
                                                      placeholder={
                                                        selectedItem.unit
                                                      }
                                                      type="text"
                                                      onChange={(e) => {
                                                        setQuantityInUnit(
                                                          e.target.value
                                                        );
                                                      }}
                                                      validate={{
                                                        required: {
                                                          value: true,
                                                          errorMessage:
                                                            "Quantity cannot be empty.",
                                                        },
                                                        pattern: {
                                                          value: "^[0-9]+$",
                                                          errorMessage: props.t(
                                                            "Only numbers are allowed."
                                                          ),
                                                        },
                                                        max: {
                                                          value:
                                                            selectedBatch &&
                                                            selectedBatch
                                                              .quantity.in_unit,
                                                          errorMessage:
                                                            selectedBatch &&
                                                            props.t("Only ") +
                                                              selectedBatch
                                                                .quantity
                                                                .in_unit +
                                                              " available in inventory.",
                                                        },
                                                      }}
                                                    />
                                                  </Col>
                                                  <Col lg={6}>
                                                    <AvField
                                                      name="in_sub_unit"
                                                      placeholder={
                                                        selectedItem.sub_unit
                                                      }
                                                      type="text"
                                                      onChange={(e) => {
                                                        setQuantityInSubUnit(
                                                          e.target.value
                                                        );
                                                      }}
                                                      validate={{
                                                        required: {
                                                          value: true,
                                                          errorMessage:
                                                            "Quantity cannot be empty.",
                                                        },
                                                        pattern: {
                                                          value: "^[0-9]+$",
                                                          errorMessage: props.t(
                                                            "Only numbers are allowed."
                                                          ),
                                                        },
                                                        max: {
                                                          value:
                                                            selectedBatch &&
                                                            (+selectedBatch
                                                              .quantity
                                                              .in_unit ===
                                                            +quantityInUnit
                                                              ? selectedBatch
                                                                  .quantity
                                                                  .in_sub_unit
                                                              : +selectedItem.formula -
                                                                1),
                                                          errorMessage:
                                                            selectedBatch &&
                                                            (+selectedBatch
                                                              .quantity
                                                              .in_unit ===
                                                            +quantityInUnit
                                                              ? props.t(
                                                                  "Cannot enter more than "
                                                                ) +
                                                                +selectedBatch
                                                                  .quantity
                                                                  .in_sub_unit +
                                                                " " +
                                                                selectedItem.sub_unit +
                                                                "."
                                                              : props.t(
                                                                  "Cannot enter more than "
                                                                ) +
                                                                (+selectedItem.formula -
                                                                  1) +
                                                                "."),
                                                        },
                                                      }}
                                                    />
                                                  </Col>
                                                </Row>
                                              </Col>
                                              <Col lg={2}>
                                                <Label>&nbsp;</Label>
                                                <br />
                                                <Button
                                                  type="submit"
                                                  color="primary"
                                                  className="btn btn-info waves-effect waves-light btn-sm"
                                                >
                                                  <i class="bx bx-add-to-queue"></i>{" "}
                                                  {props.t("Add")}
                                                </Button>
                                              </Col>
                                            </>
                                          ) : (
                                            <Col lg={6}>
                                              <p class="text-muted mb-2">
                                                &nbsp;
                                              </p>
                                              <div
                                                class="alert alert-danger fade show text-center"
                                                role="alert"
                                              >
                                                {props.t(
                                                  "This item is out of stock."
                                                )}
                                              </div>
                                            </Col>
                                          )
                                        ) : selectedItem ? (
                                          <>
                                            <Col lg={3}>
                                              <Label>{props.t("Batch")}</Label>
                                              <Select
                                                value={
                                                  selectedBatch && selectedBatch
                                                }
                                                key={selectedBatch}
                                                onChange={(e) => {
                                                  setSelectedBatch(e);
                                                }}
                                                options={
                                                  itemBatches && itemBatches
                                                }
                                                classNamePrefix="select2-selection"
                                                required
                                              />
                                            </Col>

                                            <Col lg={4}>
                                              <Label>
                                                {props.t("Quantity")}{" "}
                                                {selectedItem
                                                  ? " (in " +
                                                    selectedItem.unit +
                                                    ")"
                                                  : ""}
                                              </Label>

                                              <AvField
                                                name="quantity"
                                                placeholder={props.t(
                                                  "Enter Quantity"
                                                )}
                                                onChange={(e) => {
                                                  setQuantityInUnit(
                                                    e.target.value
                                                  );
                                                }}
                                                errorMessage="Quantity cannot be empty."
                                                type="text"
                                                validate={{
                                                  required: { value: true },
                                                  pattern: {
                                                    value: "^[0-9]+$",
                                                    errorMessage: props.t(
                                                      "Only numbers are allowed."
                                                    ),
                                                  },
                                                  max: {
                                                    value:
                                                      selectedBatch &&
                                                      selectedBatch.quantity
                                                        .in_unit,
                                                    errorMessage:
                                                      selectedBatch &&
                                                      props.t("Only ") +
                                                        selectedBatch.quantity
                                                          .in_unit +
                                                        " available in inventory.",
                                                  },
                                                }}
                                              />
                                            </Col>
                                            <Col lg={2}>
                                              <Label>&nbsp;</Label>
                                              <br />
                                              <Button
                                                type="submit"
                                                color="primary"
                                                className="btn btn-info waves-effect waves-light btn-sm"
                                              >
                                                <i class="bx bx-add-to-queue"></i>{" "}
                                                {props.t("Add")}
                                              </Button>
                                            </Col>
                                          </>
                                        ) : null}
                                      </Row>
                                    </AvForm>
                                  </Col>
                                )}
                                <Row>
                                  {itemsForDelivery &&
                                  itemsForDelivery.length > 0 ? (
                                    <>
                                      <Col lg={12} md={12}>
                                        <br />
                                        <Label>
                                          <b>{props.t("Items to Deliver")} </b>
                                        </Label>
                                      </Col>
                                      <Col lg={12}>
                                        <BootstrapTable
                                          bootstrap4
                                          keyField="name"
                                          key="name"
                                          data={
                                            itemsForDelivery && itemsForDelivery
                                          }
                                          columns={
                                            isPartialDelivery
                                              ? columns_items
                                              : columns_fulldelivery
                                          }
                                          noDataIndication="No data to display."
                                          striped
                                          hover
                                          condensed
                                        />
                                      </Col>
                                    </>
                                  ) : null}
                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          onClick={handleDispatchOrder}
                                          color="primary"
                                          className="mr-1"
                                          disabled={
                                            !centralAvailableStatus ||
                                            isDispatched
                                          }
                                        >
                                          {centralAvailableStatus &&
                                          centralAvailableStatus
                                            ? "Submit"
                                            : "Not enough stock at center"}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    </ModalBody>
                  </Modal>

                  <Modal
                    size="md"
                    isOpen={remarksModal}
                    toggle={() => setRemarksModal(!remarksModal)}
                  >
                    <ModalHeader toggle={() => setRemarksModal(!remarksModal)}>
                      Order ID: {selectedOrder && selectedOrder.order_id}
                    </ModalHeader>
                    <ModalBody>
                      <>
                        <Row>
                          <Col lg={12}>
                            <Card>
                              <CardBody>
                                <AvForm
                                  onValidSubmit={(e, v) =>
                                    setConfirmClose(true)
                                  }
                                >
                                  <Row>
                                    <Col lg={12}>
                                      <AvField
                                        name="admin_remarks"
                                        placeholder="Enter remarks"
                                        type="textarea"
                                        onChange={(e) =>
                                          setAdminRemarks(e.target.value)
                                        }
                                        value={adminRemarks}
                                        label="Remarks"
                                        validate={{
                                          required: {
                                            value: true,
                                            errorMessage:
                                              "Remarks cannot be empty.",
                                          },
                                        }}
                                      />
                                    </Col>

                                    <Col lg={12}>
                                      <hr />
                                      <FormGroup className="mb-0 text-center">
                                        <div>
                                          <Button
                                            type="submit"
                                            color="primary"
                                            className="mr-1"
                                          >
                                            {props.t("Submit")}
                                          </Button>{" "}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    </ModalBody>
                  </Modal>

                  {confirmClose && (
                    <SweetAlert
                      title={props.t("Are you sure?")}
                      warning
                      showCancel
                      cancelBtnText={props.t("No")}
                      confirmBtnText={props.t("Yes")}
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        setConfirmClose(false);
                        handleCloseOrder();
                      }}
                      onCancel={() => {
                        setConfirmClose(false);
                      }}
                    >
                      {props.t(
                        "Once you close this order, it cannot be revoked."
                      )}
                    </SweetAlert>
                  )}

                  {/* Edit Modal starts */}
                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update Order")}
                    </ModalHeader>
                    <ModalBody>
                      {selectedOrder && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm
                                    onValidSubmit={handleAddItemForOrder}
                                    ref={refForForm}
                                  >
                                    <Row>
                                      <Col lg={5}>
                                        <Label>{props.t("Item")}</Label>
                                        <Select
                                          ref={refSelectItem}
                                          value={selectedItem}
                                          onChange={(selected) => {
                                            setSelectedItem(selected);
                                          }}
                                          options={allItems}
                                          classNamePrefix="select2-selection"
                                          components={animatedComponents}
                                          required
                                        />
                                      </Col>
                                      {selectedItem && selectedItem.sub_unit ? (
                                        <Col lg={5}>
                                          <Label>
                                            {props.t("Quantity")}{" "}
                                            {" (1 " +
                                              selectedItem.unit +
                                              " = " +
                                              selectedItem.formula +
                                              " " +
                                              selectedItem.sub_unit +
                                              ")"}
                                          </Label>
                                          <Row>
                                            <Col lg={6}>
                                              <AvField
                                                name="in_unit"
                                                placeholder={selectedItem.unit}
                                                type="text"
                                                onChange={(e) => {
                                                  setQuantityInUnit(
                                                    e.target.value
                                                  );
                                                }}
                                                validate={{
                                                  required: {
                                                    value: true,
                                                    errorMessage:
                                                      "Quantity cannot be empty.",
                                                  },
                                                  pattern: {
                                                    value: "^[0-9]+$",
                                                    errorMessage: props.t(
                                                      "Only numbers are allowed."
                                                    ),
                                                  },
                                                  // max: {
                                                  //   value:
                                                  //     selectedItem &&
                                                  //     selectedItem.max_order_limit,
                                                  //   errorMessage:
                                                  //     selectedItem &&
                                                  //     props.t(
                                                  //       "Cannot order more than "
                                                  //     ) +
                                                  //       selectedItem.max_order_limit +
                                                  //       ".",
                                                  // },
                                                }}
                                              />
                                            </Col>
                                            <Col lg={6}>
                                              <AvField
                                                name="in_sub_unit"
                                                placeholder={
                                                  selectedItem.sub_unit
                                                }
                                                type="text"
                                                validate={{
                                                  required: {
                                                    value: true,
                                                    errorMessage:
                                                      "Quantity cannot be empty.",
                                                  },
                                                  pattern: {
                                                    value: "^[0-9]+$",
                                                    errorMessage: props.t(
                                                      "Only numbers are allowed."
                                                    ),
                                                  },
                                                  // max: {
                                                  //   value:
                                                  //     selectedItem &&
                                                  //     (+selectedItem.max_order_limit ===
                                                  //     +quantityInUnit
                                                  //       ? 0
                                                  //       : +selectedItem.formula -
                                                  //         1),
                                                  //   errorMessage:
                                                  //     selectedItem &&
                                                  //     (+selectedItem.max_order_limit ===
                                                  //     +quantityInUnit
                                                  //       ? props.t(
                                                  //           "Cannot order more than "
                                                  //         ) +
                                                  //         +selectedItem.max_order_limit +
                                                  //         " " +
                                                  //         selectedItem.unit +
                                                  //         "."
                                                  //       : props.t(
                                                  //           "Cannot enter more than "
                                                  //         ) +
                                                  //         (+selectedItem.formula -
                                                  //           1) +
                                                  //         "."),
                                                  // },
                                                }}
                                              />
                                            </Col>
                                          </Row>
                                        </Col>
                                      ) : (
                                        <Col lg={5}>
                                          <Label>
                                            {props.t("Quantity")}{" "}
                                            {selectedItem
                                              ? " (in " +
                                                selectedItem.unit +
                                                ")"
                                              : ""}
                                          </Label>

                                          <AvField
                                            name="quantity"
                                            placeholder={props.t(
                                              "Enter Quantity"
                                            )}
                                            errorMessage="Quantity cannot be empty."
                                            type="text"
                                            validate={{
                                              required: { value: true },
                                              pattern: {
                                                value: "^[0-9]+$",
                                                errorMessage: props.t(
                                                  "Only numbers are allowed."
                                                ),
                                              },
                                              // max: {
                                              //   value:
                                              //     selectedItem &&
                                              //     selectedItem.max_order_limit,
                                              //   errorMessage:
                                              //     selectedItem &&
                                              //     props.t(
                                              //       "Cannot order more than "
                                              //     ) +
                                              //       selectedItem.max_order_limit +
                                              //       ".",
                                              // },
                                            }}
                                          />
                                        </Col>
                                      )}
                                      <Col lg={2} className="mb-4">
                                        <Label>&nbsp;</Label>
                                        <br />
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="btn btn-info waves-effect waves-light btn-sm"
                                        >
                                          <i class="bx bx-add-to-queue"></i>{" "}
                                          {props.t("Add")}
                                        </Button>
                                      </Col>
                                    </Row>
                                    {/* <Button type="submit">Submit</Button> */}
                                  </AvForm>
                                  <hr />
                                  {itemsForOrder &&
                                    itemsForOrder.length > 0 && (
                                      <>
                                        <BootstrapTable
                                          bootstrap4
                                          keyField="_id"
                                          data={itemsForOrder && itemsForOrder}
                                          columns={columns_items_edit}
                                          noDataIndication="No data to display."
                                          striped
                                          hover
                                          condensed
                                        />
                                        <AvForm
                                          onValidSubmit={handleValidUpdate}
                                        >
                                          <Row>
                                            <Col lg={6}>
                                              <AvField
                                                name="remarks"
                                                value={remarks}
                                                onChange={(e) => {
                                                  setRemarks(e.target.value);
                                                }}
                                                label={props.t("Remarks")}
                                                placeholder={props.t(
                                                  "Enter Remarks"
                                                )}
                                                type="textarea"
                                              />
                                            </Col>
                                            {/* <Col lg={12}>
                                            <div class="form-check">
                                              <input
                                                type="checkbox"
                                                name="is_urgent"
                                                className="is-untouched is-pristine av-valid form-check-input"
                                                checked={isUrgent}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    setIsUrgent(true);
                                                  } else {
                                                    setIsUrgent(false);
                                                  }
                                                }}
                                              />
                                              <label for="select_all">
                                                {" "}
                                                {props.t(
                                                  "This is an urgent order."
                                                )}
                                              </label>
                                            </div>
                                          </Col> */}
                                            <Col lg={12}>
                                              <hr />
                                              <FormGroup className="mb-0 text-left">
                                                <div>
                                                  <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="mr-1"
                                                  >
                                                    {props.t("Update Order")}
                                                  </Button>{" "}
                                                </div>
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                        </AvForm>
                                      </>
                                    )}
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>

                  {/* Edit Modal ends */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(
  connect(null, {})(withNamespaces()(ItemOrdersInProcess))
);
