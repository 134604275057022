import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
//i18n
import { withNamespaces } from "react-i18next";

class Pie extends Component {
  getOption = () => {
    let data = this.props.orders_pie_data;
    let chartdata = [
      { value: 0, name: this.props.t("Placed") },
      { value: 0, name: this.props.t("Approved") },
      { value: 0, name: this.props.t("Dispatched") },
      { value: 0, name: this.props.t("Partially Dispatched") },
      { value: 0, name: this.props.t("Closed") },
    ];
    if (data) {
      chartdata = [
        { name: "Placed", value: data.placed },
        { name: "Approved", value: data.approved },
        { name: "Dispatched", value: data.dispatched },
        { name: "Partially Dispatched", value: data.partially_dispatched },
        { name: "Closed", value: data.closed },
      ];
    }
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: "left",
        data: [
          "Placed",
          "Approved",
          "Dispatched",
          "Partially Dispatched",
          "Closed",
        ],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#f8b425", "#3c4ccf", "#9e32e6", "#ec4561", "#02a499"],
      series: [
        {
          name: "Total orders",
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: chartdata,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  };
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "400px" }} option={this.getOption()} />
      </React.Fragment>
    );
  }
}
export default withNamespaces()(Pie);
