import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import swal from "sweetalert2";

// Login Redux States
import { CHANGE_PASSWORD, LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { loginSuccess, apiError, changePasswordSuccess } from "./actions"; //logoutUserSuccess

//Include Helper Files with needed methods
import {
  postSubmitFormNoAuth,
  postSubmitForm,
} from "../../../helpers/forms_helper";

import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../../../pages/Authentication/Login";
import Logout from "../../../pages/Authentication/Logout";
import Register from "../../../pages/Authentication/Register";
import ForgetPwd from "../../../pages/Authentication/ForgetPassword";

// Users
import AddUser from "../../../pages/Users/AddUser";
import AllUsers from "../../../pages/Users/AllUsers";
import ChangePassword from "../../../pages/ChangePassword";

// Master Data
import AddBranches from "../../../pages/Branches/AddBranches";
import UpdateBranches from "../../../pages/Branches/UpdateBranches";
import BranchItems from "../../../pages/Branches/BranchItems";
import BranchProducts from "../../../pages/Branches/BranchProducts";

// Suupliers
import AddSupplier from "../../../pages/Suppliers/AddSupplier";
import UpdateSupplier from "../../../pages/Suppliers/UpdateSupplier";

// Items
import AddItem from "../../../pages/Items/AddItem";
import AllItems from "../../../pages/Items/AllItems";
import AddItemCategory from "../../../pages/Items/AddItemCategory";
import AllItemCategories from "../../../pages/Items/AllItemCategories";

// Products
import AddProduct from "../../../pages/Products/AddProduct";
import AllProducts from "../../../pages/Products/AllProducts";
import AddProductCategory from "../../../pages/Products/AddProductCategory";
import AllProductCategories from "../../../pages/Products/AllProductCategories";

// Dashboard
import Dashboard from "../../../pages/Dashboard/index";

// Orders
import AddOrder from "../../../pages/Orders/AddOrder";
import AllOrders from "../../../pages/Orders/AllOrders";

import FinishedProductAddOrder from "../../../pages/Orders/Branch/FinishedProducts/AddOrder";
import FinishedProductAllOrders from "../../../pages/Orders/Branch/FinishedProducts/AllOrders";

import ItemAddOrder from "../../../pages/Orders/Branch/Items/AddOrder";
import ItemAllOrders from "../../../pages/Orders/Branch/Items/AllOrders";

import FinishedProductNewOrders from "../../../pages/Orders/Centre/FinishedProducts/NewOrders";
import FinishedProductOrdersInProcess from "../../../pages/Orders/Centre/FinishedProducts/OrdersInProcess";
import FinishedProductOrdersInProcessForDashboard from "../../../pages/Orders/Centre/FinishedProducts/FinishedProductOrdersInProcessForDashboard";
import FinishedProductClosedOrders from "../../../pages/Orders/Centre/FinishedProducts/ClosedOrders";
import FinishedProductAllOrders_New from "../../../pages/Orders/Centre/FinishedProducts/AllOrders_New";

import ItemNewOrders from "../../../pages/Orders/Centre/Items/NewOrders";
import ItemOrdersInProcess from "../../../pages/Orders/Centre/Items/OrdersInProcess";
import ItemEditOrders from "../../../pages/Orders/Centre/Items/EditOrders";
import ItemClosedOrders from "../../../pages/Orders/Centre/Items/ClosedOrders";

// Inventory
import FinishedProductUpdateInventory from "../../../pages/Inventory/Centre/Products/UpdateInventory";
import FinishedProductViewInventory from "../../../pages/Inventory/Centre/Products/ViewInventory";
import FinishedProductTransferInventory from "../../../pages/Inventory/Centre/Products/Branch/TransferInventory";
import FinishedProductViewInventory_Branch_ByCentre from "../../../pages/Inventory/Centre/Products/Branch/ViewInventory";
import ItemUpdateInventory from "../../../pages/Inventory/Centre/Items/UpdateInventory";
import ItemViewInventory from "../../../pages/Inventory/Centre/Items/ViewInventory";
import ItemUsedInventory from "../../../pages/Inventory/Centre/Items/UsedInventory";
import ItemTransferInventory from "../../../pages/Inventory/Centre/Items/Branch/TransferInventory";
import ItemViewInventory_Branch_ByCentre from "../../../pages/Inventory/Centre/Items/Branch/ViewInventory";

import ItemViewInventory_Branch from "../../../pages/Inventory/Branch/Items/ViewInventory";
import ItemViewInventory_Branch_Kitchen from "../../../pages/Inventory/Branch/Items/ViewInventory_Kitchen";
import ItemViewInventory_Branch_Store from "../../../pages/Inventory/Branch/Items/ViewInventory_Store";
import ItemTransferInventory_Branch from "../../../pages/Inventory/Branch/Items/TransferInventory";
import ItemTransferInventory_ByBranch from "../../../pages/Inventory/Branch/Items/TransferInventoryByBranch";
import ItemViewInventory_AllBranches from "../../../pages/Inventory/Branch/Items/ViewInventoryAllBranches";
import ItemDiscardedInventory_Branch from "../../../pages/Inventory/Branch/Items/DiscardedInventory";
import ItemWastedInventory_Branch from "../../../pages/Inventory/Branch/Items/WastedInventory";
import ItemExpiredInventory_Branch from "../../../pages/Inventory/Branch/Items/ExpiredInventory";

import FinishedProductViewInventory_Branch from "../../../pages/Inventory/Branch/Products/ViewInventory";
import FinishedProductViewInventory_Branch_Kitchen from "../../../pages/Inventory/Branch/Products/ViewInventory_Kitchen";
import FinishedProductTransferInventory_Branch from "../../../pages/Inventory/Branch/Products/TransferInventory";
import FinishedProductTransferInventory_ByBranch from "../../../pages/Inventory/Branch/Products/TransferInventoryByBranch";
import FinishedProductViewInventory_AllBranches from "../../../pages/Inventory/Branch/Products/ViewInventoryAllBranches";
import FinishedProductDiscardedInventory_Branch from "../../../pages/Inventory/Branch/Products/DiscardedInventory";
import FinishedProductWastedInventory_Branch from "../../../pages/Inventory/Branch/Products/WastedInventory";
import FinishedProductExpiredInventory_Branch from "../../../pages/Inventory/Branch/Products/ExpiredInventory";

// Procurement
import AddRequisition from "../../../pages/Procurement/AddRequisition";
import BranchRequisitionRequest from "../../../pages/Procurement/Branch/BranchRequisitionRequest";
import BranchWiseRequisition from "../../../pages/Procurement/Centre/BranchWiseRequisition";
import SupplierWiseRequisition from "../../../pages/Procurement/Centre/SupplierWiseRequisition";
import ViewPO from "../../../pages/Procurement/Centre/ViewPO";

import ViewPO_Owner from "../../../pages/Procurement/Centre/ViewPO_Owner";
import BranchPORequests from "../../../pages/Procurement/Centre/BranchPORequests";
import BranchPORequests_Branch from "../../../pages/Procurement/Branch/BranchPORequests";
import BranchAllPO from "../../../pages/Procurement/Branch/BranchAllPO";
import BranchPOGeneration from "../../../pages/Procurement/Centre/BranchPOGeneration";
import PurchaseOrder from "../../../pages/Procurement/PurchaseOrder";

//Closing
import BranchFinishedProductClosing from "../../../pages/BranchClosing/Products/Closing";
import BranchItemClosing from "../../../pages/BranchClosing/Items/Closing";

//Setting
import AppSettings from "../../../pages/Settings/AppSettings";

//Notifications
import Notifications from "../../../pages/Notifications";

// Reports

import BranchwiseSummaryReport from "../../../pages/Reports/Centre/BranchwiseSummaryReport";
import UserBasedTransactionReport from "../../../pages/Reports/Centre/UserBasedTransactionReport";
import POCostingReport from "../../../pages/Reports/Centre/Items/POCostingReport";
import POCostDifferenceReport from "../../../pages/Reports/Centre/Items/POCostDifferenceReport";
import ItemArrangementReport from "../../../pages/Reports/Centre/Items/ItemArrangementReport";
import ProductArrangementReport from "../../../pages/Reports/Centre/Products/ProductArrangementReport";
import ProductOrderReport from "../../../pages/Reports/Centre/Products/ProductOrderReport";
import BranchwiseProductOrderReport from "../../../pages/Reports/Centre/Products/BranchwiseProductOrderReport";
import ProductwiseOrderReport from "../../../pages/Reports/Centre/Products/ProductwiseOrderReport";
import ProductSectionwiseOrderReport from "../../../pages/Reports/Centre/Products/ProductSectionwiseOrderReport";
import ItemOrderReport from "../../../pages/Reports/Centre/Items/ItemOrderReport";
import BranchwiseItemOrderReport from "../../../pages/Reports/Centre/Items/BranchwiseItemOrderReport";
import ItemwiseOrderReport from "../../../pages/Reports/Centre/Items/ItemwiseOrderReport";
import ItemCategorywiseOrderReport from "../../../pages/Reports/Centre/Items/ItemCategorywiseOrderReport";

import BranchwiseProductUsedInventoriesReport from "../../../pages/Reports/Centre/Products/BranchwiseProductUsedInventoriesReport";
import BranchwiseItemUsedInventoriesReport from "../../../pages/Reports/Centre/Items/BranchwiseItemUsedInventoriesReport";
import BranchwiseProductBalanceInventoriesReport from "../../../pages/Reports/Centre/Products/BranchwiseProductBalanceInventoriesReport";
import BranchwiseItemBalanceInventoriesReport from "../../../pages/Reports/Centre/Items/BranchwiseItemBalanceInventoriesReport";
import BranchwiseProductExpiringInventoriesReport from "../../../pages/Reports/Centre/Products/BranchwiseProductExpiringInventoriesReport";
import BranchwiseItemExpiringInventoriesReport from "../../../pages/Reports/Centre/Items/BranchwiseItemExpiringInventoriesReport";
import BranchwiseProductDiscardedInventoriesReport from "../../../pages/Reports/Centre/Products/BranchwiseProductDiscardedInventoriesReport";
import BranchwiseItemDiscardedInventoriesReport from "../../../pages/Reports/Centre/Items/BranchwiseItemDiscardedInventoriesReport";
import BranchwiseProductWastedInventoriesReport from "../../../pages/Reports/Centre/Products/BranchwiseProductWastedInventoriesReport";
import BranchwiseItemWastedInventoriesReport from "../../../pages/Reports/Centre/Items/BranchwiseItemWastedInventoriesReport";
import ProductDeficiencyReport from "../../../pages/Reports/Centre/Products/ProductDeficiencyReport";
import ItemDeficiencyReport from "../../../pages/Reports/Centre/Items/ItemDeficiencyReport";
import HighlyUsedProductReport from "../../../pages/Reports/Centre/Products/HighlyUsedProductReport";
import HighlyUsedItemReport from "../../../pages/Reports/Centre/Items/HighlyUsedItemReport";
import HighlyOrderedProductReport from "../../../pages/Reports/Centre/Products/HighlyOrderedProductReport";
import HighlyOrderedItemReport from "../../../pages/Reports/Centre/Items/HighlyOrderedItemReport";
import PartialDeliveryFromCentralKitchenReport from "../../../pages/Reports/Centre/Products/PartialDeliveryFromCentralKitchenReport";
import PartialDeliveryFromCentralStoreReport from "../../../pages/Reports/Centre/Items/PartialDeliveryFromCentralStoreReport";
import PartialDeliveryFromSupplierReport from "../../../pages/Reports/Centre/Items/PartialDeliveryFromSupplierReport";
import SupplierMonthlyReport from "../../../pages/Reports/Centre/Items/SupplierMonthlyReport";
import ItemCostTrendReport from "../../../pages/Reports/Centre/Items/ItemCostTrendReport";
import CostOfGoodsSoldReport from "../../../pages/Reports/Centre/CostOfGoodsSoldReport";
import CostOfGoodsSoldProductsReport from "../../../pages/Reports/Centre/Products/CostOfGoodsSoldProductsReport";
import CostOfGoodsSoldItemsReport from "../../../pages/Reports/Centre/Items/CostOfGoodsSoldItemsReport";
import BranchItemTransferReport from "../../../pages/Reports/Centre/Items/BranchItemTransferReport";
import BranchProductTransferReport from "../../../pages/Reports/Centre/Products/BranchProductTransferReport";

function showNotification(message, type) {
  var title = type;
  if (type === "Success") swal.fire(type, message, "success");
  else swal.fire(type, message, "error");
}
function* loginUser({ payload: { user, history } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "adminusers/login";

    const response = yield call(postSubmitFormNoAuth, url, user);

    if (response.status === 1) {
      let routes = [];
      const role = response.data.role;
      if (role == "Branch Supervisor") {
        const is_store = response.data.branch_details.is_store;
      }

      if (role == "admin" || role == "Owner") {
        routes = [
          { path: "/dashboard", component: Dashboard },
          { path: "/app_settings", component: AppSettings },
          { path: "/notifications", component: Notifications },

          //Reports
          {
            path: "/branchwise_summary_report",
            component: BranchwiseSummaryReport,
          },
          {
            path: "/products/partial_delivery_from_central_kitchen_report",
            component: PartialDeliveryFromCentralKitchenReport,
          },
          {
            path: "/items/partial_delivery_from_central_store_report",
            component: PartialDeliveryFromCentralStoreReport,
          },
          {
            path: "/items/partial_delivery_from_supplier_report",
            component: PartialDeliveryFromSupplierReport,
          },
          {
            path: "/user_based_transaction_report",
            component: UserBasedTransactionReport,
          },
          {
            path: "/po_costing_report",
            component: POCostingReport,
          },
          {
            path: "/po_cost_difference_report",
            component: POCostDifferenceReport,
          },
          {
            path: "/items/item_arrangement_report",
            component: ItemArrangementReport,
          },

          {
            path: "/items/item_order_report",
            component: ItemOrderReport,
          },
          {
            path: "/items/branchwise_item_order_report",
            component: BranchwiseItemOrderReport,
          },
          {
            path: "/items/itemwise_order_report",
            component: ItemwiseOrderReport,
          },
          {
            path: "/items/item_categorywise_order_report",
            component: ItemCategorywiseOrderReport,
          },
          {
            path: "/products/product_order_report",
            component: ProductOrderReport,
          },
          {
            path: "/products/branchwise_product_order_report",
            component: BranchwiseProductOrderReport,
          },
          {
            path: "/products/productwise_order_report",
            component: ProductwiseOrderReport,
          },
          {
            path: "/products/branchwise_used_report",
            component: BranchwiseProductUsedInventoriesReport,
          },
          {
            path: "/items/branchwise_used_report",
            component: BranchwiseItemUsedInventoriesReport,
          },
          {
            path: "/products/branchwise_balance_report",
            component: BranchwiseProductBalanceInventoriesReport,
          },
          {
            path: "/items/branchwise_balance_report",
            component: BranchwiseItemBalanceInventoriesReport,
          },
          {
            path: "/products/branchwise_expiring_report",
            component: BranchwiseProductExpiringInventoriesReport,
          },
          {
            path: "/items/branchwise_expiring_report",
            component: BranchwiseItemExpiringInventoriesReport,
          },
          {
            path: "/products/branchwise_discarded_report",
            component: BranchwiseProductDiscardedInventoriesReport,
          },
          {
            path: "/items/branchwise_discarded_report",
            component: BranchwiseItemDiscardedInventoriesReport,
          },
          {
            path: "/products/branchwise_wasted_report",
            component: BranchwiseProductWastedInventoriesReport,
          },
          {
            path: "/items/branchwise_wasted_report",
            component: BranchwiseItemWastedInventoriesReport,
          },
          {
            path: "/products/deficiency_report",
            component: ProductDeficiencyReport,
          },
          {
            path: "/items/deficiency_report",
            component: ItemDeficiencyReport,
          },
          {
            path: "/products/highly_used_product_report",
            component: HighlyUsedProductReport,
          },
          {
            path: "/items/highly_used_item_report",
            component: HighlyUsedItemReport,
          },
          {
            path: "/products/highly_ordered_product_report",
            component: HighlyOrderedProductReport,
          },
          {
            path: "/items/highly_ordered_item_report",
            component: HighlyOrderedItemReport,
          },
          {
            path: "/items/supplier_monthly_report",
            component: SupplierMonthlyReport,
          },
          {
            path: "/items/item_cost_trend_report",
            component: ItemCostTrendReport,
          },
          {
            path: "/cost_of_goods_sold_report",
            component: CostOfGoodsSoldReport,
          },
          {
            path: "/cost_of_goods_sold_products_report",
            component: CostOfGoodsSoldProductsReport,
          },
          {
            path: "/cost_of_goods_sold_items_report",
            component: CostOfGoodsSoldItemsReport,
          },
          {
            path: "/branch_item_transfer_report",
            component: BranchItemTransferReport,
          },
          {
            path: "/branch_product_transfer_report",
            component: BranchProductTransferReport,
          },
          // Procurement
          { path: "/add_requisition", component: AddRequisition },
          { path: "/branchwise_requisition", component: BranchWiseRequisition },
          { path: "/branch_po_requests", component: BranchPORequests },
          { path: "/branch_po_generation", component: BranchPOGeneration },
          {
            path: "/supplierwise_requisition",
            component: SupplierWiseRequisition,
          },
          { path: "/all_po", component: ViewPO },

          { path: "/all_po_owner", component: ViewPO_Owner },

          { path: "/add_branches", component: AddBranches },
          { path: "/update_branch", component: UpdateBranches },
          { path: "/branch_items", component: BranchItems },
          { path: "/branch_products", component: BranchProducts },

          { path: "/add_order", component: AddOrder },
          { path: "/all_orders", component: AllOrders },
          {
            path: "/finished_products/new_orders",
            component: FinishedProductNewOrders,
          },
          {
            path: "/finished_products/orders_in_process",
            component: FinishedProductOrdersInProcess,
          },
          {
            path: "/finished_products/closed_orders",
            component: FinishedProductClosedOrders,
          },
          {
            path: "/finished_products/all_orders_new",
            component: FinishedProductAllOrders_New,
          },

          {
            path: "/items/new_orders",
            component: ItemNewOrders,
          },
          {
            path: "/items/orders_in_process",
            component: ItemOrdersInProcess,
          },
          {
            path: "/items/edit_orders",
            component: ItemEditOrders,
          },
          {
            path: "/items/closed_orders",
            component: ItemClosedOrders,
          },

          // Inventory Pages
          {
            path: "/finished_products/update_inventory",
            component: FinishedProductUpdateInventory,
          },
          {
            path: "/finished_products/view_inventory",
            component: FinishedProductViewInventory,
          },
          {
            path: "/finished_products/transfer_inventory",
            component: FinishedProductTransferInventory,
          },
          {
            path: "/finished_products/view_branchwise_inventory",
            component: FinishedProductViewInventory_Branch_ByCentre,
          },
          {
            path: "/items/update_inventory",
            component: ItemUpdateInventory,
          },
          {
            path: "/items/view_inventory",
            component: ItemViewInventory,
          },
          {
            path: "/items/used_inventory",
            component: ItemUsedInventory,
          },
          {
            path: "/items/transfer_inventory",
            component: ItemTransferInventory,
          },
          {
            path: "/items/view_branchwise_inventory",
            component: ItemViewInventory_Branch_ByCentre,
          },

          { path: "/supplier-Update", component: UpdateSupplier },
          { path: "/add_supplier", component: AddSupplier },

          { path: "/users-adduser", component: AddUser },
          { path: "/users-updateuser", component: AllUsers },

          { path: "/add_item", component: AddItem },
          { path: "/all_items", component: AllItems },
          { path: "/item_categories", component: AddItemCategory },
          { path: "/categories-update", component: AllItemCategories },

          { path: "/add_product", component: AddProduct },
          { path: "/all_products", component: AllProducts },
          { path: "/add_product_category", component: AddProductCategory },
          { path: "/product_categories", component: AllProductCategories },

          { path: "/user-changepassword", component: ChangePassword },

          // this route should be at the end of all other routes
          {
            path: "/",
            exact: true,
            component: () => <Redirect to="/dashboard" />,
          },
        ];
      } else if (role === "Operations Manager") {
        routes = [
          { path: "/dashboard", component: Dashboard },
          { path: "/app_settings", component: AppSettings },
          { path: "/notifications", component: Notifications },

          //Reports
          {
            path: "/branchwise_summary_report",
            component: BranchwiseSummaryReport,
          },
          {
            path: "/products/partial_delivery_from_central_kitchen_report",
            component: PartialDeliveryFromCentralKitchenReport,
          },
          {
            path: "/items/partial_delivery_from_central_store_report",
            component: PartialDeliveryFromCentralStoreReport,
          },
          {
            path: "/items/partial_delivery_from_supplier_report",
            component: PartialDeliveryFromSupplierReport,
          },
          {
            path: "/user_based_transaction_report",
            component: UserBasedTransactionReport,
          },
          {
            path: "/items/view_branch_inventory",
            component: ItemViewInventory_Branch,
          },
          {
            path: "/items/expired_inventory",
            component: ItemExpiredInventory_Branch,
          },
          {
            path: "/po_costing_report",
            component: POCostingReport,
          },
          {
            path: "/po_cost_difference_report",
            component: POCostDifferenceReport,
          },
          {
            path: "/items/item_arrangement_report",
            component: ItemArrangementReport,
          },
          {
            path: "/items/item_order_report",
            component: ItemOrderReport,
          },
          {
            path: "/items/view_branch_inventory_kitchen",
            component: ItemViewInventory_Branch_Kitchen,
          },
          {
            path: "/items/branchwise_item_order_report",
            component: BranchwiseItemOrderReport,
          },
          {
            path: "/items/itemwise_order_report",
            component: ItemwiseOrderReport,
          },
          {
            path: "/items/item_categorywise_order_report",
            component: ItemCategorywiseOrderReport,
          },
          {
            path: "/products/product_order_report",
            component: ProductOrderReport,
          },
          {
            path: "/products/branchwise_product_order_report",
            component: BranchwiseProductOrderReport,
          },
          {
            path: "/products/productwise_order_report",
            component: ProductwiseOrderReport,
          },
          {
            path: "/products/branchwise_used_report",
            component: BranchwiseProductUsedInventoriesReport,
          },
          {
            path: "/items/branchwise_used_report",
            component: BranchwiseItemUsedInventoriesReport,
          },
          {
            path: "/products/branchwise_balance_report",
            component: BranchwiseProductBalanceInventoriesReport,
          },
          {
            path: "/items/branchwise_balance_report",
            component: BranchwiseItemBalanceInventoriesReport,
          },
          {
            path: "/products/branchwise_expiring_report",
            component: BranchwiseProductExpiringInventoriesReport,
          },
          {
            path: "/items/branchwise_expiring_report",
            component: BranchwiseItemExpiringInventoriesReport,
          },
          {
            path: "/products/branchwise_discarded_report",
            component: BranchwiseProductDiscardedInventoriesReport,
          },
          {
            path: "/items/branchwise_discarded_report",
            component: BranchwiseItemDiscardedInventoriesReport,
          },
          {
            path: "/products/branchwise_wasted_report",
            component: BranchwiseProductWastedInventoriesReport,
          },
          {
            path: "/items/branchwise_wasted_report",
            component: BranchwiseItemWastedInventoriesReport,
          },
          {
            path: "/products/deficiency_report",
            component: ProductDeficiencyReport,
          },
          {
            path: "/items/deficiency_report",
            component: ItemDeficiencyReport,
          },
          {
            path: "/products/highly_used_product_report",
            component: HighlyUsedProductReport,
          },
          {
            path: "/items/highly_used_item_report",
            component: HighlyUsedItemReport,
          },
          {
            path: "/products/highly_ordered_product_report",
            component: HighlyOrderedProductReport,
          },
          {
            path: "/items/highly_ordered_item_report",
            component: HighlyOrderedItemReport,
          },
          {
            path: "/items/supplier_monthly_report",
            component: SupplierMonthlyReport,
          },
          {
            path: "/items/item_cost_trend_report",
            component: ItemCostTrendReport,
          },
          {
            path: "/cost_of_goods_sold_report",
            component: CostOfGoodsSoldReport,
          },
          {
            path: "/cost_of_goods_sold_products_report",
            component: CostOfGoodsSoldProductsReport,
          },
          {
            path: "/cost_of_goods_sold_items_report",
            component: CostOfGoodsSoldItemsReport,
          },
          {
            path: "/branch_item_transfer_report",
            component: BranchItemTransferReport,
          },
          {
            path: "/branch_product_transfer_report",
            component: BranchProductTransferReport,
          },
          // Procurement
          { path: "/add_requisition", component: AddRequisition },
          { path: "/branchwise_requisition", component: BranchWiseRequisition },
          {
            path: "/supplierwise_requisition",
            component: SupplierWiseRequisition,
          },
          { path: "/all_po", component: ViewPO },

          { path: "/branch_po_requests", component: BranchPORequests },
          { path: "/branch_po_generation", component: BranchPOGeneration },

          { path: "/add_branches", component: AddBranches },
          { path: "/update_branch", component: UpdateBranches },
          { path: "/branch_items", component: BranchItems },
          { path: "/branch_products", component: BranchProducts },

          { path: "/add_order", component: AddOrder },
          { path: "/all_orders", component: AllOrders },
          {
            path: "/finished_products/new_orders",
            component: FinishedProductNewOrders,
          },
          {
            path: "/finished_products/orders_in_process",
            component: FinishedProductOrdersInProcess,
          },
          {
            path: "/finished_products/closed_orders",
            component: FinishedProductClosedOrders,
          },
          {
            path: "/finished_products/all_orders_new",
            component: FinishedProductAllOrders_New,
          },

          {
            path: "/items/new_orders",
            component: ItemNewOrders,
          },
          {
            path: "/items/orders_in_process",
            component: ItemOrdersInProcess,
          },
          {
            path: "/items/edit_orders",
            component: ItemEditOrders,
          },
          {
            path: "/items/closed_orders",
            component: ItemClosedOrders,
          },

          // Inventory Pages
          {
            path: "/finished_products/update_inventory",
            component: FinishedProductUpdateInventory,
          },
          {
            path: "/finished_products/view_inventory",
            component: FinishedProductViewInventory,
          },
          {
            path: "/finished_products/transfer_inventory",
            component: FinishedProductTransferInventory,
          },
          {
            path: "/finished_products/view_branchwise_inventory",
            component: FinishedProductViewInventory_Branch_ByCentre,
          },

          {
            path: "/items/update_inventory",
            component: ItemUpdateInventory,
          },
          {
            path: "/items/view_inventory",
            component: ItemViewInventory,
          },
          {
            path: "/items/used_inventory",
            component: ItemUsedInventory,
          },
          {
            path: "/items/transfer_inventory",
            component: ItemTransferInventory,
          },
          {
            path: "/items/view_branchwise_inventory",
            component: ItemViewInventory_Branch_ByCentre,
          },

          { path: "/supplier-Update", component: UpdateSupplier },
          { path: "/add_supplier", component: AddSupplier },

          { path: "/users-adduser", component: AddUser },
          { path: "/users-updateuser", component: AllUsers },

          { path: "/add_item", component: AddItem },
          { path: "/all_items", component: AllItems },
          { path: "/item_categories", component: AddItemCategory },
          { path: "/categories-update", component: AllItemCategories },

          { path: "/add_product", component: AddProduct },
          { path: "/all_products", component: AllProducts },
          { path: "/add_product_category", component: AddProductCategory },
          { path: "/product_categories", component: AllProductCategories },

          { path: "/user-changepassword", component: ChangePassword },

          // this route should be at the end of all other routes
          {
            path: "/",
            exact: true,
            component: () => <Redirect to="/dashboard" />,
          },
        ];
      } else if (role === "Central Store Manager") {
        routes = [
          { path: "/dashboard", component: Dashboard },
          { path: "/app_settings", component: AppSettings },
          { path: "/notifications", component: Notifications },

          //Reports
          {
            path: "/items/item_arrangement_report",
            component: ItemArrangementReport,
          },

          {
            path: "/items/item_order_report",
            component: ItemOrderReport,
          },
          {
            path: "/items/branchwise_item_order_report",
            component: BranchwiseItemOrderReport,
          },
          {
            path: "/items/itemwise_order_report",
            component: ItemwiseOrderReport,
          },
          {
            path: "/items/item_categorywise_order_report",
            component: ItemCategorywiseOrderReport,
          },
          {
            path: "/po_costing_report",
            component: POCostingReport,
          },

          // Procurement
          { path: "/add_requisition", component: AddRequisition },
          { path: "/branchwise_requisition", component: BranchWiseRequisition },
          { path: "/branch_po_requests", component: BranchPORequests },
          { path: "/branch_po_generation", component: BranchPOGeneration },
          {
            path: "/supplierwise_requisition",
            component: SupplierWiseRequisition,
          },
          { path: "/all_po", component: ViewPO },

          { path: "/add_branches", component: AddBranches },
          { path: "/update_branch", component: UpdateBranches },
          { path: "/branch_items", component: BranchItems },
          { path: "/branch_products", component: BranchProducts },

          { path: "/add_order", component: AddOrder },
          { path: "/all_orders", component: AllOrders },
          {
            path: "/finished_products/new_orders",
            component: FinishedProductNewOrders,
          },
          {
            path: "/finished_products/orders_in_process",
            component: FinishedProductOrdersInProcess,
          },
          {
            path: "/finished_products/closed_orders",
            component: FinishedProductClosedOrders,
          },
          {
            path: "/finished_products/all_orders_new",
            component: FinishedProductAllOrders_New,
          },

          {
            path: "/items/new_orders",
            component: ItemNewOrders,
          },
          {
            path: "/items/orders_in_process",
            component: ItemOrdersInProcess,
          },
          {
            path: "/items/edit_orders",
            component: ItemEditOrders,
          },
          {
            path: "/items/closed_orders",
            component: ItemClosedOrders,
          },

          // Inventory Pages
          {
            path: "/finished_products/update_inventory",
            component: FinishedProductUpdateInventory,
          },
          {
            path: "/finished_products/view_inventory",
            component: FinishedProductViewInventory,
          },
          {
            path: "/finished_products/transfer_inventory",
            component: FinishedProductTransferInventory,
          },
          {
            path: "/finished_products/view_branchwise_inventory",
            component: FinishedProductViewInventory_Branch_ByCentre,
          },
          {
            path: "/items/update_inventory",
            component: ItemUpdateInventory,
          },
          {
            path: "/items/view_inventory",
            component: ItemViewInventory,
          },
          {
            path: "/items/used_inventory",
            component: ItemUsedInventory,
          },
          {
            path: "/items/transfer_inventory",
            component: ItemTransferInventory,
          },
          {
            path: "/items/view_branchwise_inventory",
            component: ItemViewInventory_Branch_ByCentre,
          },

          { path: "/supplier-Update", component: UpdateSupplier },
          { path: "/add_supplier", component: AddSupplier },

          { path: "/users-adduser", component: AddUser },
          { path: "/users-updateuser", component: AllUsers },

          { path: "/add_item", component: AddItem },
          { path: "/all_items", component: AllItems },
          { path: "/item_categories", component: AddItemCategory },
          { path: "/categories-update", component: AllItemCategories },

          { path: "/add_product", component: AddProduct },
          { path: "/all_products", component: AllProducts },
          { path: "/add_product_category", component: AddProductCategory },
          { path: "/product_categories", component: AllProductCategories },

          { path: "/user-changepassword", component: ChangePassword },

          // this route should be at the end of all other routes
          {
            path: "/",
            exact: true,
            component: () => <Redirect to="/dashboard" />,
          },
        ];
      } else if (role === "Central Store Assistant") {
        routes = [
          { path: "/dashboard", component: Dashboard },
          { path: "/user-changepassword", component: ChangePassword },
          { path: "/all_po", component: ViewPO },

          {
            path: "/items/new_orders",
            component: ItemNewOrders,
          },
          {
            path: "/items/orders_in_process",
            component: ItemOrdersInProcess,
          },
          {
            path: "/items/edit_orders",
            component: ItemEditOrders,
          },
          {
            path: "/items/closed_orders",
            component: ItemClosedOrders,
          },
          {
            path: "/items/update_inventory",
            component: ItemUpdateInventory,
          },
          {
            path: "/items/view_inventory",
            component: ItemViewInventory,
          },
          { path: "/supplier-Update", component: UpdateSupplier },
          { path: "/branch_items", component: BranchItems },
          { path: "/users-adduser", component: AddUser },
          { path: "/users-updateuser", component: AllUsers },
          {
            path: "/items/item_arrangement_report",
            component: ItemArrangementReport,
          },
          {
            path: "/products/deficiency_report",
            component: ProductDeficiencyReport,
          },
          {
            path: "/items/deficiency_report",
            component: ItemDeficiencyReport,
          },
          // this route should be at the end of all other routes
          {
            path: "/",
            exact: true,
            component: () => <Redirect to="/dashboard" />,
          },
        ];
      } else if (role === "Storekeeper") {
        routes = [
          { path: "/dashboard", component: Dashboard },
          { path: "/user-changepassword", component: ChangePassword },
          { path: "/all_po", component: ViewPO },

          {
            path: "/items/update_inventory",
            component: ItemUpdateInventory,
          },
          {
            path: "/items/view_inventory",
            component: ItemViewInventory,
          },

          {
            path: "/items/item_arrangement_report",
            component: ItemArrangementReport,
          },
          {
            path: "/products/deficiency_report",
            component: ProductDeficiencyReport,
          },
          {
            path: "/items/deficiency_report",
            component: ItemDeficiencyReport,
          },
          // this route should be at the end of all other routes
          {
            path: "/",
            exact: true,
            component: () => <Redirect to="/dashboard" />,
          },
        ];
      } else if (role === "Central Kitchen Manager") {
        routes = [
          { path: "/dashboard", component: Dashboard },
          { path: "/app_settings", component: AppSettings },
          { path: "/notifications", component: Notifications },

          // Reports
          {
            path: "/products/product_arrangement_report",
            component: ProductArrangementReport,
          },

          {
            path: "/products/product_order_report",
            component: ProductOrderReport,
          },
          {
            path: "/finished_products/orders_in_process/dashboard",
            component: FinishedProductOrdersInProcessForDashboard,
          },
          {
            path: "/products/branchwise_product_order_report",
            component: BranchwiseProductOrderReport,
          },
          {
            path: "/products/productwise_order_report",
            component: ProductwiseOrderReport,
          },
          {
            path: "/products/product_sectionwise_order_report",
            component: ProductSectionwiseOrderReport,
          },

          // Branch Item Order Functionality
          {
            path: "/items/add_order",
            component: ItemAddOrder,
          },
          {
            path: "/items/all_orders",
            component: ItemAllOrders,
          },
          {
            path: "/items/view_branch_inventory",
            component: ItemViewInventory_Branch,
          },
          {
            path: "/items/view_branch_inventory_kitchen",
            component: ItemViewInventory_Branch_Kitchen,
          },
          {
            path: "/items/expired_inventory",
            component: ItemExpiredInventory_Branch,
          },

          // Procurement
          { path: "/branch_all_po", component: BranchAllPO },
          { path: "/add_requisition", component: AddRequisition },
          { path: "/branchwise_requisition", component: BranchWiseRequisition },
          {
            path: "/supplierwise_requisition",
            component: SupplierWiseRequisition,
          },
          { path: "/all_po", component: ViewPO },

          { path: "/add_branches", component: AddBranches },
          { path: "/update_branch", component: UpdateBranches },
          { path: "/branch_items", component: BranchItems },
          { path: "/branch_products", component: BranchProducts },

          { path: "/add_order", component: AddOrder },
          { path: "/all_orders", component: AllOrders },
          {
            path: "/finished_products/new_orders",
            component: FinishedProductNewOrders,
          },
          {
            path: "/finished_products/orders_in_process",
            component: FinishedProductOrdersInProcess,
          },
          {
            path: "/finished_products/closed_orders",
            component: FinishedProductClosedOrders,
          },
          {
            path: "/finished_products/all_orders_new",
            component: FinishedProductAllOrders_New,
          },

          {
            path: "/items/new_orders",
            component: ItemNewOrders,
          },
          {
            path: "/items/orders_in_process",
            component: ItemOrdersInProcess,
          },
          {
            path: "/items/edit_orders",
            component: ItemEditOrders,
          },
          {
            path: "/items/closed_orders",
            component: ItemClosedOrders,
          },

          // Inventory Pages
          {
            path: "/finished_products/update_inventory",
            component: FinishedProductUpdateInventory,
          },
          {
            path: "/finished_products/view_inventory",
            component: FinishedProductViewInventory,
          },
          {
            path: "/finished_products/transfer_inventory",
            component: FinishedProductTransferInventory,
          },
          {
            path: "/finished_products/view_branchwise_inventory",
            component: FinishedProductViewInventory_Branch_ByCentre,
          },
          {
            path: "/items/update_inventory",
            component: ItemUpdateInventory,
          },
          {
            path: "/items/view_inventory",
            component: ItemViewInventory,
          },
          {
            path: "/items/used_inventory",
            component: ItemUsedInventory,
          },
          {
            path: "/items/transfer_inventory",
            component: ItemTransferInventory,
          },
          {
            path: "/items/view_branchwise_inventory",
            component: ItemViewInventory_Branch_ByCentre,
          },

          { path: "/supplier-Update", component: UpdateSupplier },
          { path: "/add_supplier", component: AddSupplier },

          { path: "/users-adduser", component: AddUser },
          { path: "/users-updateuser", component: AllUsers },

          { path: "/add_item", component: AddItem },
          { path: "/all_items", component: AllItems },
          { path: "/item_categories", component: AddItemCategory },
          { path: "/categories-update", component: AllItemCategories },

          { path: "/add_product", component: AddProduct },
          { path: "/all_products", component: AllProducts },
          { path: "/add_product_category", component: AddProductCategory },
          { path: "/product_categories", component: AllProductCategories },

          { path: "/user-changepassword", component: ChangePassword },

          // this route should be at the end of all other routes
          {
            path: "/",
            exact: true,
            component: () => <Redirect to="/dashboard" />,
          },
        ];
      } else if (role == "Branch Supervisor") {
        routes = [
          { path: "/dashboard", component: Dashboard },
          { path: "/user-changepassword", component: ChangePassword },
          { path: "/notifications", component: Notifications },
          {
            path: "/branch_requisition_request",
            component: BranchRequisitionRequest,
          },
          {
            path: "/branch_po_requests",
            component: BranchPORequests_Branch,
          },
          {
            path: "/branch_all_po",
            component: BranchAllPO,
          },
          {
            path: "/finished_products/add_order",
            component: FinishedProductAddOrder,
          },
          {
            path: "/finished_products/all_orders",
            component: FinishedProductAllOrders,
          },

          {
            path: "/items/add_order",
            component: ItemAddOrder,
          },
          {
            path: "/items/all_orders",
            component: ItemAllOrders,
          },

          {
            path: "/items/view_branch_inventory",
            component: ItemViewInventory_Branch,
          },
          {
            path: "/items/view_branch_inventory_kitchen",
            component: ItemViewInventory_Branch_Kitchen,
          },
          {
            path: "/items/view_branch_inventory_store",
            component: ItemViewInventory_Branch_Store,
          },
          {
            path: "/items/transfer_inventory",
            component: ItemTransferInventory_Branch,
          },
          {
            path: "/items/transfer_inventory_by_branch",
            component: ItemTransferInventory_ByBranch,
          },
          {
            path: "/finished_products/view_branch_inventory",
            component: FinishedProductViewInventory_Branch,
          },
          {
            path: "/finished_products/view_branch_inventory_kitchen",
            component: FinishedProductViewInventory_Branch_Kitchen,
          },
          {
            path: "/finished_products/view_all_branch_inventory",
            component: FinishedProductViewInventory_AllBranches,
          },
          {
            path: "/items/view_all_branch_inventory",
            component: ItemViewInventory_AllBranches,
          },
          {
            path: "/finished_products/transfer_inventory",
            component: FinishedProductTransferInventory_Branch,
          },
          {
            path: "/finished_products/transfer_inventory_by_branch",
            component: FinishedProductTransferInventory_ByBranch,
          },
          {
            path: "/closing/items",
            component: BranchItemClosing,
          },
          {
            path: "/closing/finished_products",
            component: BranchFinishedProductClosing,
          },
          {
            path: "/finished_products/discarded_inventory",
            component: FinishedProductDiscardedInventory_Branch,
          },
          {
            path: "/items/discarded_inventory",
            component: ItemDiscardedInventory_Branch,
          },
          {
            path: "/finished_products/wasted_inventory",
            component: FinishedProductWastedInventory_Branch,
          },
          {
            path: "/items/wasted_inventory",
            component: ItemWastedInventory_Branch,
          },
          {
            path: "/finished_products/expired_inventory",
            component: FinishedProductExpiredInventory_Branch,
          },
          {
            path: "/items/expired_inventory",
            component: ItemExpiredInventory_Branch,
          },
          // this route should be at the end of all other routes
          {
            path: "/",
            exact: true,
            component: () => <Redirect to="/dashboard" />,
          },
        ];
      } else {
        routes = [
          // this route should be at the end of all other routes
          {
            path: "/",
            exact: true,
            component: () => <Redirect to="/dashboard" />,
          },
        ];
      }
      response.routes = routes;

      localStorage.setItem("username", response.data.username);
      localStorage.setItem("name", response.data.name);
      if (response.data.role == "Branch Supervisor") {
        localStorage.setItem("branch_name", response.data.branch_details.name);
        localStorage.setItem("is_store", response.data.branch_details.is_store);
        localStorage.setItem("branch_id", response.data.branch_details._id);
        localStorage.setItem(
          "transfer_privilege",
          response.data.branch_details.transfer_privilege
        );
      }
      localStorage.setItem("mobile", response.data.mobile);
      localStorage.setItem("employee_id", response.data.employee_id);
      localStorage.setItem("role", response.data.role);
      localStorage.setItem("userToken", response.data.token);
      yield put(loginSuccess(response));
      history.push("/dashboard");
    } else {
      yield put(apiError(response.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "adminusers/logout";
    const token = localStorage.getItem("userToken");
    if (token) {
      const response = yield call(postSubmitForm, url, {});
      //console.log(response);
      if (response.status === 1) {
        localStorage.clear();
        localStorage.removeItem("username");
        localStorage.removeItem("name");
        localStorage.removeItem("mobile");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        localStorage.removeItem("userToken");
        history.push("/login");
      }
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* changePassword({ payload: { password_details, history } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "adminusers/changepassword";

    const response = yield call(postSubmitForm, url, password_details);

    if (response.status === 1) {
      showNotification("Password changed successfully.", "Success");
      yield put(changePasswordSuccess(response));
    } else {
      showNotification(response.message, "Error");
      yield put(apiError(response.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export function* watchChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchChangePassword),
  ]);
}

export default authSaga;
