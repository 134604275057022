import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import moment from "moment";
import { postSubmitForm } from "../../../../helpers/forms_helper";

import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const AddInventory = (props) => {
  useEffect(() => {
    loadAllItems();
    if (props.selectedItem) {
      setSelectedItem(props.selectedItem);
    }
  }, []);
  const [currentDate] = useState(() => {
    var d1 = new Date();
    var d = moment(d1).add(1, "days").toDate();
    var month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  });
  const refContainer = useRef(null);
  const refSelectItem = useRef(null);
  const handleValidSubmit = async (e, v) => {
    try {
      let data_to_send = {};

      data_to_send.item_id = selectedItem._id;
      data_to_send.quantity = selectedItem.sub_unit
        ? { in_unit: v.in_unit, in_sub_unit: v.in_sub_unit }
        : { in_unit: v.quantity, in_sub_unit: 0 };
      data_to_send.expiry_date = v.expiry_date;
      data_to_send.remarks = v.remarks;
      data_to_send.cost_price = v.cost_price;
      data_to_send.selling_price = v.selling_price;

      let url = process.env.REACT_APP_BASEURL + "iteminventories/insert";

      const response = await postSubmitForm(url, data_to_send);
      if (response && response.status === 1) {
        showNotification(response.message, "Success");
        refSelectItem.current.select.clearValue();
        refContainer.current.reset();
        if (props.isRefreshData) {
          props.refreshData();
          props.handleClosePopup();
        }
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error, "Error");
    }
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const [allItems, setAllItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const loadAllItems = async () => {
    let url = process.env.REACT_APP_BASEURL + "items/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllItems(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <AvForm
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
                ref={refContainer}
              >
                <CardTitle>{props.t("Update Inventory")}</CardTitle>
                <CardSubtitle className="mb-3">
                  {props.t(
                    "Enter the following details to add items to inventory"
                  )}
                </CardSubtitle>
                <Row>
                  <Col lg={6}>
                    <Label>{props.t("Item")}</Label>
                    <Select
                      ref={refSelectItem}
                      value={selectedItem}
                      onChange={(selected) => {
                        setSelectedItem(selected);
                      }}
                      options={allItems}
                      classNamePrefix="select2-selection"
                      components={animatedComponents}
                      required
                    />
                  </Col>
                  {selectedItem && selectedItem.sub_unit ? (
                    <Col lg={6}>
                      <Label>
                        {props.t("Quantity")}{" "}
                        {" (1 " +
                          selectedItem.unit +
                          " = " +
                          selectedItem.formula +
                          " " +
                          selectedItem.sub_unit +
                          ")"}
                      </Label>
                      <Row>
                        <Col lg={6}>
                          <AvField
                            name="in_unit"
                            placeholder={selectedItem.unit}
                            type="text"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Quantity cannot be empty.",
                              },
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage: props.t(
                                  "Only numbers are allowed."
                                ),
                              },
                            }}
                          />
                        </Col>
                        <Col lg={6}>
                          <AvField
                            name="in_sub_unit"
                            placeholder={selectedItem.sub_unit}
                            type="text"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Quantity cannot be empty.",
                              },
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage: props.t(
                                  "Only numbers are allowed."
                                ),
                              },
                              max: {
                                value:
                                  selectedItem && +selectedItem.formula - 1,
                                errorMessage:
                                  selectedItem &&
                                  props.t("Cannot enter more than ") +
                                    (+selectedItem.formula - 1) +
                                    ".",
                              },
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ) : (
                    <Col lg={6}>
                      <Label>
                        {props.t("Quantity")}{" "}
                        {selectedItem ? " (in " + selectedItem.unit + ")" : ""}
                      </Label>

                      <AvField
                        name="quantity"
                        placeholder={props.t("Enter Quantity")}
                        errorMessage="Quantity cannot be empty."
                        type="text"
                        validate={{
                          required: { value: true },
                          pattern: {
                            value: "^[0-9]+$",
                            errorMessage: props.t("Only numbers are allowed."),
                          },
                        }}
                      />
                    </Col>
                  )}
                  <Col lg={6}>
                    <Label>
                      {props.t("Cost Price")}
                      {selectedItem && " (per " + selectedItem.unit + ")"}
                    </Label>
                    <AvField
                      name="cost_price"
                      placeholder={props.t("Enter Cost Price")}
                      type="text"
                      validate={{
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          errorMessage: props.t("Only numbers are allowed."),
                        },
                      }}
                    />
                  </Col>
                  <Col lg={6}>
                    <Label>
                      {props.t("Selling Price")}
                      {selectedItem && " (per " + selectedItem.unit + ")"}
                    </Label>
                    <AvField
                      name="selling_price"
                      placeholder={props.t("Enter Selling Price")}
                      type="text"
                      validate={{
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          errorMessage: props.t("Only numbers are allowed."),
                        },
                      }}
                    />
                  </Col>
                  <Col lg={6}>
                    <AvField
                      name="expiry_date"
                      label={props.t("Expiry Date") + " *"}
                      placeholder={props.t("Select Expiry Date")}
                      type="date"
                      min={currentDate}
                      required
                      errorMessage="Expiry date cannot be empty"
                    />
                  </Col>
                  <Col lg={6}>
                    <AvField
                      name="remarks"
                      label={props.t("Remarks")}
                      placeholder={props.t("Enter Remarks")}
                      type="textarea"
                    />
                  </Col>
                  <Col lg={12}>
                    <hr />
                    <FormGroup className="mb-0 text-center">
                      <div>
                        <Button type="submit" color="primary" className="mr-1">
                          {props.t("Submit")}
                        </Button>{" "}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(AddInventory))
);
