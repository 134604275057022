export const SUBMIT_ORDER = "SUBMIT_ORDER";
export const SUBMIT_ORDER_SUCCESS = "SUBMIT_ORDER_SUCCESS";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const SUBMIT_ORDER_LIVE = "SUBMIT_ORDER_LIVE";
export const SUBMIT_ORDER_LIVE_SUCCESS = "SUBMIT_ORDER_LIVE_SUCCESS";
export const LOAD_ORDERS = "LOAD_ORDERS";
export const LOAD_ORDERS_SUCCESS = "LOAD_ORDERS_SUCCESS";
export const LOAD_ALLOTED_ORDERS = "LOAD_ALLOTED_ORDERS";
export const LOAD_ALLOTED_ORDERS_SUCCESS = "LOAD_ALLOTED_ORDERS_SUCCESS";
export const MARK_DELIVERED = "MARK_DELIVERED";
export const MARK_DELIVERED_SUCCESS = "MARK_DELIVERED_SUCCESS";
export const MARK_PAID_SELLER = "MARK_PAID_SELLER";
export const MARK_PAID_SELLER_SUCCESS = "MARK_PAID_SELLER_SUCCESS";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const LOAD_ORDER_FOR_INVOICE = "LOAD_ORDER_FOR_INVOICE";
export const LOAD_ORDER_FOR_INVOICE_SUCCESS = "LOAD_ORDER_FOR_INVOICE_SUCCESS";
export const ORDER_API_ERROR = "ORDER_API_ERROR";
