import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import showNotification from "../../helpers/show_notification";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";
// actions
import exportFromJSON from "export-from-json";
import TagsInput from "react-tagsinput";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();
const AllSuppliers = (props) => {
  const { SearchBar } = Search;
  useEffect(() => {
    loadAllCategories();
  }, []);
  const [allCategories, setAllCategories] = useState();
  useEffect(() => {
    if (allCategories) {
      loadAllSuppliers();
      //loadAllCities();
    }
  }, [allCategories]);
  const refNewCategoryForm = useRef(null);
  const refSelectCategory = useRef(null);

  const [allSuppliers, setAllSuppliers] = useState([]);
  const loadAllSuppliers = async () => {
    let url = process.env.REACT_APP_BASEURL + "suppliers/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllSuppliers(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [selectedCategories, setSelectedCategories] = useState([]);
  const loadAllCategories = async () => {
    let url = process.env.REACT_APP_BASEURL + "itemcategories/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      response.data.map((category) => {
        category.label = category.category;
        category.value = category._id;
      });
      setAllCategories(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  //const [allCities, setAllCities] = useState([]);
  //const [selectedCity, setSelectedCity] = useState();
  // const loadAllCities = async () => {
  //   let url = process.env.REACT_APP_BASEURL + "cities/getall";
  //   let response = await postSubmitForm(url, "");
  //   if (response.status === 1) {
  //     setAllCities(response.data);
  //   } else {
  //     showNotification(response.message, "Error");
  //   }
  // };

  const [newCategoryModal, setNewCategoryModal] = useState();
  const [newSubCategories, setNewSubCategories] = useState([]);
  const handleAddNewCategory = async (e, v) => {
    try {
      const object = {
        category: v.name,
        sub_categories: newSubCategories,
      };
      let url = process.env.REACT_APP_BASEURL + "itemcategories/insert";
      let response = await postSubmitForm(url, object);
      if (response.status === 1) {
        loadAllCategories();

        let selected_categories = JSON.parse(
          JSON.stringify(selectedCategories)
        );
        response.data.label = response.data.category;
        response.data.value = response.data._id;
        selected_categories.push(response.data);
        setSelectedCategories(selected_categories);

        setNewSubCategories([]);
        setNewCategoryModal(!newCategoryModal);
        refNewCategoryForm.current.reset();
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };

  //const [newCityModal, setNewCityModal] = useState();
  // const handleAddNewCity = async (e, v) => {
  //   let url = process.env.REACT_APP_BASEURL + "cities/insert";
  //   const response = await postSubmitForm(url, v);
  //   if (response && response.status === 1) {
  //     loadAllCities();
  //     setSelectedCity(v.name);
  //     setNewCityModal(!newCityModal);
  //   } else {
  //     showNotification(response.message, "Error");
  //   }
  // };

  const [selectedSupplier, setSelectedSupplier] = useState();
  const [editModal, setEditModal] = useState();
  const handleValidUpdate = async (e, v) => {
    try {
      let selected_categories = [];

      selectedCategories.map((category) => {
        selected_categories.push(category.category);
      });
      if (selected_categories.length > 0) {
        const object = {
          id: selectedSupplier._id,
          name: v.name,
          contact_person: v.contact_person,
          category: selected_categories,
          mobile: v.mobile,
          phone: v.phone,
          address: v.address,
          //city: v.city,
          email: v.email,
          remarks: v.remarks,
        };

        let url = process.env.REACT_APP_BASEURL + "suppliers/update";

        let response = await postSubmitForm(url, object);
        if (response.status === 1) {
          setSelectedCategories();
          loadAllSuppliers();
          setEditModal(!editModal);
          showNotification(response.message, "Success");
          refSelectCategory.current.select.clearValue();
        } else {
          showNotification(response.message, "Error");
        }
      } else {
        showNotification(props.t("Select at least one category."), "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const columns = [
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "contact_person",
      text: props.t("Contact Person"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "mobile",
      text: props.t("Mobile"),
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
    },
    {
      dataField: "phone",
      text: props.t("Phone"),
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
    },
    {
      dataField: "address",
      text: props.t("Address"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    // {
    //   dataField: "city",
    //   text: props.t("City"),
    //   sort: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "6%" };
    //   },
    // },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "category",
      text: props.t("Categories"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      formatter: (cell, row) => {
        return (
          <>
            {row.category.map((category) => {
              return (
                <span class="font-size-12 mr-2 badge-soft-info badge badge-info badge-pill">
                  {category}
                </span>
              );
            })}
          </>
        );
      },
    },
    {
      dataField: "",
      text: props.t("Action"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
      formatter: (cell, row) => {
        return (
          <span className="text-info">
            <i
              className="bx bxs-edit font-size-15"
              title="Click to Edit"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedSupplier(row);
                //setSelectedCity(row.city);

                let all_categories = [...allCategories];

                //console.log(allCategories);
                const arr = all_categories.filter((categ) => {
                  return row.category.includes(categ.category);
                });
                console.log(allCategories);
                console.log(arr);
                setSelectedCategories(arr);
                // preloader(true);
                // setTimeout(function () {
                //   setEditModal(!editModal);
                //   preloader(false);
                // }, 5000);
              }}
            ></i>
          </span>
        );
      },
    },
  ];
  useEffect(() => {
    if (selectedCategories && selectedCategories.length > 0) {
      setEditModal(true);
    }
  }, [selectedCategories]);

  const handleDownload = async () => {
    const fileName = "allSuppliers";
    const exportType = "xls";

    let data_to_export = allSuppliers;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          name: v.name,
          mobile: v.mobile,
          phone: v.phone,
          contact_person: v.contact_person,
          address: v.address,
          remarks: v.remarks,
          category: v.category.toString(),
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Suppliers")}
            breadcrumbItem={props.t("Update Supplier")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Update Supplier")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View/Update all your existing suppliers here")}
                  </CardSubtitle>

                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"  "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>

                  <div id="printDiv">
                    <ToolkitProvider
                      bootstrap4
                      keyField="_id"
                      data={allSuppliers && allSuppliers}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}>
                              {" "}
                              <CardTitle>Update Supplier</CardTitle>
                            </Col>
                          </Row>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>

                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update Supplier")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm onValidSubmit={handleValidUpdate}>
                                <Row>
                                  <Col lg={6}>
                                    <AvField
                                      name="name"
                                      label={props.t("Name")}
                                      placeholder={props.t("Enter Name")}
                                      type="text"
                                      value={
                                        selectedSupplier &&
                                        selectedSupplier.name
                                      }
                                      validate={{ required: { value: true } }}
                                      errorMessage={props.t(
                                        "Name cannot be empty"
                                      )}
                                    />
                                  </Col>
                                  <Col lg={6}>
                                    <AvField
                                      name="contact_person"
                                      label={props.t("Contact Person")}
                                      placeholder={props.t(
                                        "Enter Contact Person Name"
                                      )}
                                      value={
                                        selectedSupplier &&
                                        selectedSupplier.contact_person
                                      }
                                      type="text"
                                      validate={{ required: { value: true } }}
                                      errorMessage={props.t(
                                        "Contact person name cannot be empty"
                                      )}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={6}>
                                        <Row>
                                          <Col lg={8}>
                                            <Label>
                                              {props.t("Categories")}
                                            </Label>
                                            <Select
                                              ref={refSelectCategory}
                                              value={selectedCategories}
                                              isMulti={true}
                                              onChange={(selected) => {
                                                setSelectedCategories(selected);
                                              }}
                                              options={allCategories}
                                              classNamePrefix="select2-selection"
                                              closeMenuOnSelect={false}
                                              components={animatedComponents}
                                              required
                                            />
                                          </Col>
                                          <Col lg={4}>
                                            {" "}
                                            <Label>&nbsp;</Label>
                                            <br />
                                            <Link
                                              class="btn btn-success waves-effect waves-light btn-sm"
                                              onClick={() => {
                                                setNewCategoryModal(
                                                  !newCategoryModal
                                                );
                                              }}
                                            >
                                              <i class="mdi mdi-file-plus"></i>{" "}
                                              {props.t("Add New")}
                                            </Link>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="mobile"
                                          value={
                                            selectedSupplier &&
                                            selectedSupplier.mobile
                                          }
                                          label={props.t("Mobile")}
                                          placeholder={props.t("Enter Mobile")}
                                          type="text"
                                          validate={{
                                            required: {
                                              value: true,
                                              errorMessage: props.t(
                                                "Mobile cannot be empty."
                                              ),
                                            },
                                            pattern: {
                                              value: "^[0-9]+$",
                                              errorMessage: props.t(
                                                "Only numbers are allowed."
                                              ),
                                            },
                                            maxLength: {
                                              value: 8,
                                              errorMessage: props.t(
                                                "Enter valid mobile."
                                              ),
                                            },
                                            minLength: {
                                              value: 8,
                                              errorMessage: props.t(
                                                "Enter valid mobile."
                                              ),
                                            },
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col lg={6}>
                                    <AvField
                                      name="phone"
                                      label={props.t("Phone")}
                                      value={
                                        selectedSupplier &&
                                        selectedSupplier.phone
                                      }
                                      type="text"
                                      errorMessage={props.t(
                                        "Phone cannot be empty"
                                      )}
                                      placeholder={props.t("Enter Phone")}
                                      validate={{
                                        pattern: {
                                          value: "^[0-9]+$",
                                          errorMessage: props.t(
                                            "Only numbers are allowed."
                                          ),
                                        },
                                        // maxLength: {
                                        //   value: 8,
                                        //   errorMessage: props.t("Enter valid mobile."),
                                        // },
                                        // minLength: {
                                        //   value: 8,
                                        //   errorMessage: props.t("Enter valid mobile."),
                                        // },
                                      }}
                                    />
                                  </Col>

                                  <Col lg={6}>
                                    <AvField
                                      name="email"
                                      value={
                                        selectedSupplier &&
                                        selectedSupplier.email
                                      }
                                      label={props.t("Email")}
                                      type="email"
                                      placeholder={props.t("Enter Email")}
                                    />
                                  </Col>

                                  <Col lg={6}>
                                    <AvField
                                      name="address"
                                      value={
                                        selectedSupplier &&
                                        selectedSupplier.address
                                      }
                                      label={props.t("Address")}
                                      placeholder={props.t("Enter Address")}
                                      type="textarea"
                                      validate={{ required: { value: true } }}
                                      errorMessage={props.t(
                                        "Address cannot be empty"
                                      )}
                                    />
                                  </Col>
                                  <Col lg={6}>
                                    <AvField
                                      name="remarks"
                                      value={
                                        selectedSupplier &&
                                        selectedSupplier.remarks
                                      }
                                      label={props.t("Remark")}
                                      type="textarea"
                                      errorMessage={props.t(
                                        "Please Enter Remark"
                                      )}
                                      placeholder={props.t("Enter Remark")}
                                    />
                                    {/* <Row>
                                      <Col lg={8}>
                                        <AvField
                                          name="city"
                                          label={props.t("Select City")}
                                          value={selectedCity}
                                          type="select"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          errorMessage={props.t(
                                            "City cannot be empty"
                                          )}
                                        >
                                          <option value="">
                                            {props.t("-- Select City --")}
                                          </option>
                                          {allCities &&
                                            allCities.map((e) => (
                                              <option
                                                key={e._id}
                                                value={e.name}
                                              >
                                                {e.name}
                                              </option>
                                            ))}
                                        </AvField>
                                      </Col>
                                      <Col lg={4}>
                                        {" "}
                                        <Label>&nbsp;</Label>
                                        <br />
                                        <Link
                                          class="btn btn-success waves-effect waves-light btn-sm"
                                          onClick={() => {
                                            setNewCityModal(!newCityModal);
                                          }}
                                        >
                                          <i class="mdi mdi-file-plus"></i>{" "}
                                          {props.t("Add New")}
                                        </Link>
                                      </Col>
                                    </Row> */}
                                  </Col>

                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Submit")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="md"
                    isOpen={newCategoryModal}
                    toggle={() => setNewCategoryModal(!newCategoryModal)}
                  >
                    <ModalHeader
                      toggle={() => setNewCategoryModal(!newCategoryModal)}
                    >
                      {props.t("Add new category")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewCategory(e, v);
                                }}
                                ref={refNewCategoryForm}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Category")}
                                      placeholder={props.t(
                                        "Enter Category Name"
                                      )}
                                      type="text"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: props.t(
                                            "Name cannot be empty"
                                          ),
                                        },
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <Label>
                                      {props.t("Sub-Category Name")}
                                    </Label>{" "}
                                    ({props.t("press ENTER/TAB to add")})
                                    <TagsInput
                                      value={newSubCategories}
                                      onChange={(tags) => {
                                        setNewSubCategories(tags);
                                      }}
                                      onlyUnique={true}
                                      inputProps={{
                                        className: "react-tagsinput-input",
                                        placeholder: props.t("Add..."),
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  {/* <Modal
                    size="md"
                    isOpen={newCityModal}
                    toggle={() => setNewCityModal(!newCityModal)}
                  >
                    <ModalHeader toggle={() => setNewCityModal(!newCityModal)}>
                      {props.t("Add new city")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewCity(e, v);
                                }}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Name of City") + " *"}
                                      placeholder={props.t("Enter City Name")}
                                      type="text"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: props.t(
                                            "Name cannot be empty."
                                          ),
                                        },
                                      }}
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withNamespaces()(AllSuppliers)));
