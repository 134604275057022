import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Input,
  CardTitle,
  CardSubtitle,
  Container,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import csc from "country-state-city";
import showNotification from "../../helpers/show_notification";

import ReactAvatarEditor from "react-avatar-editor";

import {
  postSubmitForm,
  postSubmitForm_withformdata,
} from "../../helpers/forms_helper";

const AddUser = (props) => {
  useEffect(() => {
    setAllCountries(csc.getAllCountries);
    loadAllUsers();
    loadAllBranches();
  }, []);
  const [allUsers, setAllUsers] = useState([]);
  const loadAllUsers = async () => {
    let url = process.env.REACT_APP_BASEURL + "adminusers/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllUsers(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [allBranches, setAllBranches] = useState([]);
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const refContainer = useRef(null);

  const [qatarID, setQatarID] = useState();
  const [passport, setPassport] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [scale, setScale] = useState(1);
  const [allowZoomOut] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState();
  const onPhotoChange = (event) => {
    setSelectedPhoto(event.target.files[0]);
  };

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  const editorRef = useRef();
  // setEditorRef = (editor) => (this.editor = editor);
  const [rotate] = useState(0);
  const [borderRadius] = useState(0);

  function srcToFile(src, fileName, mimeType) {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      });
  }
  const handleValidSubmit = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "adminusers/insert";
    srcToFile(
      editorRef.current.getImageScaledToCanvas().toDataURL("image/png"),
      "new_image.png",
      "image/png"
    ).then(function (file) {
      let formData = new FormData();
      formData.set("username", v.username);
      formData.set("name", v.name);
      formData.set("pwd", v.pwd);
      formData.set("role", v.role);
      if (v.branch_id) {
        formData.set("branch_id", v.branch_id);
      }
      if (v.qatarid !== "") {
        formData.set("qatarid", v.qatarid);
      }
      if (v.passport !== "") {
        formData.set("passport", v.passport);
      }
      formData.set("mobile", v.mobile);
      formData.set("email", v.email);
      formData.set("nationality", v.nationality);
      formData.append("image_file", file);

      postSubmitForm_withformdata(url, formData).then((api_response) => {
        if (api_response.status === 1 && api_response.data) {
          loadAllUsers();
          refContainer.current.reset();
          setSelectedPhoto();
          showNotification(api_response.message, "Success");
        } else {
          showNotification(api_response.message, "Error");
        }
      });
    });
  };

  function showPhotoFormatter(cell, row) {
    return (
      <img
        src={row.image_url}
        alt={row.name}
        class="avatar-md rounded-circle img-thumbnail"
      ></img>
    );
  }
  function showStatusFormatter(cell, row) {
    if (row.is_active) {
      return (
        <span class="font-size-12 badge-soft-success badge badge-success badge-pill">
          {props.t("Active")}
        </span>
      );
    } else {
      return (
        <span class="font-size-12 badge-soft-danger badge badge-danger badge-pill">
          {props.t("Inactive")}
        </span>
      );
    }
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Username"),
      //formatter: employeeFormatter,
      dataField: "username",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "role",
      text: props.t("Role"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Name"),
      //formatter: employeeFormatter,
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "mobile",
      text: props.t("Mobile"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "qatarid",
      text: props.t("Qatar ID"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "passport",
      text: props.t("Passport"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Photo"),
      formatter: showPhotoFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Status"),
      formatter: showStatusFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Users")}
            breadcrumbItem={props.t("Add User")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm onValidSubmit={handleValidSubmit} ref={refContainer}>
                    <CardTitle>{props.t("Add User")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t("Enter the following details to add a new user")}
                    </CardSubtitle>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="name"
                          label={props.t("Name") + " *"}
                          placeholder={props.t("Enter Name")}
                          type="text"
                          errorMessage="Name cannot be empty."
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="role"
                          value={selectedRole}
                          onChange={(e) => {
                            setSelectedRole(e.target.value);
                          }}
                          label={props.t("Role") + " *"}
                          placeholder={props.t("Enter Role")}
                          type="select"
                          //onChange={handleChange}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Select Role")}
                        >
                          <option value="">
                            -- {props.t("Select Role")} --
                          </option>

                          <option value="Accounts Manager">
                            {props.t("Accounts Manager")}
                          </option>
                          <option value="Branch Supervisor">
                            {props.t("Branch Supervisor")}
                          </option>
                          <option value="Central Store Assistant">
                            {props.t("Central Store Assistant")}
                          </option>
                          <option value="Central Store Manager">
                            {props.t("Central Store Manager")}
                          </option>
                          <option value="Central Kitchen Manager">
                            {props.t("Central Kitchen Manager")}
                          </option>
                          <option value="Operations Manager">
                            {props.t("Operations Manager")}
                          </option>
                          <option value="Owner">{props.t("Owner")}</option>
                          <option value="Storekeeper">
                            {props.t("Storekeeper")}
                          </option>
                        </AvField>
                      </Col>
                      {selectedRole === "Branch Supervisor" && (
                        <Col lg={6}>
                          <AvField
                            name="branch_id"
                            label={props.t("Branch") + " *"}
                            type="select"
                            //onChange={handleChange}
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Select Branch")}
                          >
                            <option value="">
                              -- {props.t("Select Branch")} --
                            </option>

                            {allBranches &&
                              allBranches.map((branch) => (
                                <option key={branch._id} value={branch._id}>
                                  {branch.name}
                                </option>
                              ))}
                          </AvField>
                        </Col>
                      )}

                      <Col lg={6}>
                        <AvField
                          name="username"
                          label={props.t("Username") + " *"}
                          placeholder={props.t("Enter Username")}
                          type="text"
                          errorMessage="Username cannot be empty."
                          validate={{
                            required: { value: true },
                            pattern: {
                              value: "^[0-9a-zA-Z]+$",
                              errorMessage: props.t(
                                "Cannot use space/special characters."
                              ),
                            },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="pwd"
                          label={props.t("Password") + " *"}
                          placeholder={props.t("Enter Password")}
                          type="password"
                          // value="123456"
                          // hidden={true}
                          validate={{
                            required: { value: true },
                            minLength: {
                              value: 8,
                              errorMessage: props.t(
                                "Minimum 8 characters required."
                              ),
                            },
                          }}
                          errorMessage={props.t("Password cannot be empty.")}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="mobile"
                          label={props.t("Mobile") + " *"}
                          placeholder={props.t("Enter Mobile")}
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: props.t("Mobile cannot be empty."),
                            },
                            pattern: {
                              value: "^[0-9]+$",
                              errorMessage: props.t(
                                "Only numbers are allowed."
                              ),
                            },
                            maxLength: {
                              value: 8,
                              errorMessage: props.t("Enter valid mobile."),
                            },
                            minLength: {
                              value: 8,
                              errorMessage: props.t("Enter valid mobile."),
                            },
                          }}
                        />
                      </Col>

                      <Col lg={6}>
                        <AvField
                          name="nationality"
                          label={props.t("Nationality") + " *"}
                          type="select"
                          value="Qatar"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Select Nationality")}
                        >
                          <option value="">
                            -- {props.t("Select Nationality")} --
                          </option>
                          {allCountries &&
                            allCountries.map((item) => {
                              return (
                                <option value={item.name}>{item.name}</option>
                              );
                            })}
                        </AvField>
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="email"
                          label={props.t("Email")}
                          placeholder={props.t("Enter Email")}
                          errorMessage={props.t("Please enter valid email.")}
                          type="email"
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="qatarid"
                          label={props.t("Qatar ID") + " *"}
                          placeholder={props.t("Enter Qatar ID")}
                          errorMessage={props.t(
                            "Both Qatar ID and Passport Number cannot be empty."
                          )}
                          type="text"
                          onChange={(e) => {
                            setQatarID(e.target.value);
                          }}
                          validate={{
                            required: { value: passport ? false : true },
                            pattern: {
                              value: "^[0-9]+$",
                              errorMessage: props.t(
                                "Only numbers are allowed."
                              ),
                            },

                            maxLength: {
                              value: 11,
                              errorMessage: props.t("Enter valid qatar id."),
                            },
                            minLength: {
                              value: 11,
                              errorMessage: props.t("Enter valid qatar id."),
                            },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="passport"
                          label={props.t("Passport Number") + " *"}
                          placeholder={props.t("Enter Passport Number")}
                          errorMessage={props.t(
                            "Both Qatar ID and Passport Number cannot be empty."
                          )}
                          type="text"
                          onChange={(e) => {
                            setPassport(e.target.value);
                          }}
                          validate={{
                            required: { value: qatarID ? false : true },
                            maxLength: {
                              value: 25,
                              errorMessage: props.t(
                                "Enter valid passport number."
                              ),
                            },
                            minLength: {
                              value: 6,
                              errorMessage: props.t(
                                "Enter valid passport number."
                              ),
                            },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <Label>Photo</Label>
                        <br />
                        <ReactAvatarEditor
                          ref={editorRef}
                          scale={parseFloat(scale)}
                          width={100}
                          height={100}
                          position={position}
                          onPositionChange={(position) => {
                            setPosition(position);
                          }}
                          rotate={rotate}
                          borderRadius={100 / (100 / borderRadius)}
                          image={selectedPhoto && selectedPhoto}
                          className="editor-canvas"
                        />
                        <br />
                        <br />
                        <input
                          name="scale"
                          type="range"
                          onChange={handleScale}
                          min={allowZoomOut ? "0.1" : "1"}
                          max="2"
                          step="0.01"
                          defaultValue="1"
                        />
                        <br />
                        <br />
                        <Input
                          type="file"
                          id="resume"
                          className="form-control-file"
                          onChange={onPhotoChange}
                        />
                      </Col>
                      <Col lg={12}>
                        <hr />
                        <FormGroup className="mb-0 text-center">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                            >
                              {props.t("Submit")}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Existing Users")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing users here")}
                  </CardSubtitle>
                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allUsers && allUsers}
                    columns={columns}
                    noDataIndication="No data to display."
                    striped
                    hover
                    condensed
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withNamespaces()(AddUser)));
