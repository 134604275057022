import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import moment from "moment";
import { postSubmitForm } from "../../../../helpers/forms_helper";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const AddInventory = (props) => {
  const mystyle = {
    input: {
      "input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  };

  useEffect(() => {
    loadAllProducts();
    if (props.selectedProduct) {
      setSelectedProduct(props.selectedProduct);
    }
  }, []);

  const refContainer = useRef(null);
  const refSelectProduct = useRef(null);
  const handleValidSubmit = async (e, v) => {
    try {
      let data_to_send = {};

      data_to_send.product_id = selectedProduct._id;
      data_to_send.quantity = v.quantity;
      data_to_send.cost_price = v.cost_price;
      data_to_send.selling_price = v.selling_price;
      data_to_send.remarks = v.remarks;

      let url = process.env.REACT_APP_BASEURL + "productinventories/insert";
      const response = await postSubmitForm(url, data_to_send);
      if (response && response.status === 1) {
        showNotification(response.message, "Success");
        refSelectProduct.current.select.clearValue();
        refContainer.current.reset();
        if (props.isRefreshData) {
          props.refreshData();
          props.handleClosePopup();
        }
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error, "Error");
    }
  };
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const loadAllProducts = async () => {
    let url = process.env.REACT_APP_BASEURL + "products/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllProducts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <AvForm
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
                ref={refContainer}
              >
                <CardTitle>{props.t("Update Inventory")}</CardTitle>
                <CardSubtitle className="mb-3">
                  {props.t(
                    "Enter the following details to add products to inventory"
                  )}
                </CardSubtitle>
                <Row>
                  <Col lg={6}>
                    <Label>{props.t("Product")}</Label>
                    <Select
                      ref={refSelectProduct}
                      value={selectedProduct}
                      onChange={(selected) => {
                        setSelectedProduct(selected);
                      }}
                      options={allProducts}
                      classNamePrefix="select2-selection"
                      components={animatedComponents}
                      required
                    />
                  </Col>
                  <Col lg={6}>
                    <Label>{props.t("Quantity")}</Label>

                    <AvField
                      name="quantity"
                      placeholder={props.t("Enter Quantity")}
                      errorMessage="Quantity cannot be empty."
                      type="text"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage: props.t("Only numbers are allowed."),
                        },
                      }}
                    />
                  </Col>
                  <Col lg={6}>
                    <Label>
                      {props.t("Cost Price")}
                      {selectedProduct && " (per " + selectedProduct.unit + ")"}
                    </Label>
                    <AvField
                      name="cost_price"
                      placeholder={props.t("Enter Cost Price")}
                      type="text"
                      value={selectedProduct && selectedProduct.cost_price}
                      //disabled={true}
                      validate={{
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          errorMessage: props.t("Only numbers are allowed."),
                        },
                      }}
                    />
                  </Col>
                  <Col lg={6}>
                    <Label>
                      {props.t("Selling Price")}
                      {selectedProduct && " (per " + selectedProduct.unit + ")"}
                    </Label>
                    <AvField
                      name="selling_price"
                      placeholder={props.t("Enter Selling Price")}
                      type="text"
                      value={selectedProduct && selectedProduct.selling_price}
                      //disabled={true}
                      validate={{
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          errorMessage: props.t("Only numbers are allowed."),
                        },
                      }}
                    />
                  </Col>
                  <Col lg={6}>
                    <AvField
                      name="remarks"
                      label={props.t("Remarks")}
                      placeholder={props.t("Enter Remarks")}
                      type="textarea"
                    />
                  </Col>
                  <Col lg={12}>
                    <hr />
                    <FormGroup className="mb-0 text-center">
                      <div>
                        <Button type="submit" color="primary" className="mr-1">
                          {props.t("Submit")}
                        </Button>{" "}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(AddInventory))
);
