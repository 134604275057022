import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import { AvForm, AvField } from "availity-reactstrap-validation";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
  Table,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import Line from "../../../AllCharts/echart/linechart";

const animatedComponents = makeAnimated();

const BranchItemTransferReport = (props) => {
  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const { SearchBar } = Search;
  const [monthYear, setMonthYear] = useState();

  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [allBranches, setAllBranches] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    //setDateFrom(formatDate(firstDay));
    setDateFrom(formatDate(currentDay));
    setDateTo(formatDate(currentDay));
    loadOrders();
    loadAllBranches();
  }, []);

  // useEffect(() => {
  //      if (dateFrom > dateTo) {
  //           loadOrders();
  //      }
  // }, [dateFrom, dateTo, selectedBranch]);

  const [allOrders, setAllOrders] = useState([]);
  const loadOrders = async () => {
    preloader(true);
    let url =
      process.env.REACT_APP_BASEURL + "reports/branchwise_transfer_items";
    const response = await postSubmitForm(url, {
      branch_id: selectedBranch,
      date_from: dateFrom,
      date_to: dateTo,
    });
    if (response && response.status === 1) {
      preloader(false);
      //console.log(response.data.length);

      setAllOrders(response.data);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };
  const [orderStatus, setOrderStatus] = useState("All");
  const [filteredOrders, setFilteredOrders] = useState([]);
  // useEffect(() => {
  //   if (orderStatus === "All") {
  //     setFilteredOrders([...allOrders]);
  //   } else {
  //     let arr = allOrders.filter((order) => order.status === orderStatus);
  //     setFilteredOrders(arr);
  //   }
  // }, [allOrders, orderStatus]);

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [purchaseModal, setPurchaseModal] = useState(false);
  const [directPurchaseModal, setDirectPurchaseModal] = useState(false);
  const [productModal, setProductModal] = useState(false);
  const [itemModal, setItemModal] = useState(false);
  function detailsFormatter(cell, row) {
    return (
      <>
        <span className="text-info" style={{ marginLeft: "5px" }}>
          <i
            className="bx bx-news font-size-15"
            title="View"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedRow(row);

              //getPatientDetails(row.patient_details.patient_id);
              setItemModal(!itemModal);
              //console.log(row);
              //setSelectedPackage(row._id);
              //setPackagesForPatient(selectedPackage)
            }}
          ></i>
        </span>
      </>
    );
  }
  const [grandTotalCost, setGrandTotalCost] = useState(0);
  const columns = [
    {
      dataField: "id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("Order Id"),

      dataField: "order_id",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Branch from"),

      formatter: (cell, row, rowIndex) => {
        return row.branch_from.name;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Branch to"),

      formatter: (cell, row, rowIndex) => {
        return row.branch_to.name;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },

    {
      text: props.t("Status"),
      dataField: "status",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      filter: selectFilter({
        options: () => {
          const unique = [
            ...new Map(
              [
                ...new Set(
                  allOrders.map(({ status: value }) => ({
                    value: value,
                    label: value,
                  }))
                ),
              ].map((item) => [item["value"], item])
            ).values(),
          ];

          unique.sort(dynamicSort("label"));
          return unique;
        },
      }),
    },

    {
      text: props.t("Remarks"),
      dataField: "remarks",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Date"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      sort: true,
      dataField: "createdAt",
      formatter: (cell, row, rowIndex) => {
        return moment(row.createdAt).format("DD/MM/YYYY HH:mm");
      },
      style: { cursor: "pointer" },
    },
    {
      text: props.t("Total Cost"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      sort: true,
      dataField: "row.item_details",
      formatter: (cell, row, rowIndex) => {
        const total_cost = row.item_details.reduce(
          (acc, item) => Number(acc) + Number(item.total_cost),
          0
        );
        return total_cost.toFixed(2);
      },
      style: { cursor: "pointer" },
    },

    {
      text: props.t("Action"),
      formatter: detailsFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
  ];

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.status === "Completed") {
      style.backgroundColor = "#b0ff9e";
    } else if (row.status === "Booked") {
      style.backgroundColor = "#9ec3ff";
    } else if (row.status === "In Process") {
      style.backgroundColor = "#fffd9e";
    } else if (row.status === "Cancelled") {
      style.backgroundColor = "#ff9e9e";
    }

    return style;
  };

  const handleDownload = async () => {
    const fileName = "branch_item_transfer_report";
    const exportType = "xls";

    let data_to_export = allOrders;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        const total_cost = v.item_details.reduce(
          (acc, item) => Number(acc) + Number(item.total_cost),
          0
        );
        data.push({
          OrderId: v.order_id,
          BranchFrom: v.branch_from.name,
          BranchTo: v.branch_to.name,
          Status: v.status,
          Remarks: v.remarks,
          Date: moment(v.updatedAt).format("DD/MM/YYYY"),
          TotalCost: total_cost.toFixed(2),
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }

  const columns_item = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Sub-Category"),
      dataField: "sub_category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },

    {
      dataField: "unit",
      text: props.t("Order Quantity"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      text: props.t("Cost"),
      dataField: "total_cost",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb
            alternateTitle={props.t("Branch Item Transfer Report")}
            title={props.t("Reports")}
            breadcrumbItem={props.t("Branch Item Transfer Report")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={3}>
                        <AvField
                          name="branch"
                          label={props.t("Branch")}
                          type="select"
                          value={selectedBranch}
                          onChange={(e) => {
                            setSelectedBranch(e.target.value);
                          }}
                        >
                          <option value="All">{props.t("Select")}</option>
                          {allBranches &&
                            allBranches.map((branch) => {
                              return (
                                <option value={branch._id}>
                                  {branch.name}
                                </option>
                              );
                            })}
                        </AvField>
                      </Col>
                      <Col lg={3}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={3}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={3}>
                        <Button
                          type="submit"
                          className="btn btn-md btn-success mt-4"
                          onClick={() => {
                            if (dateFrom && dateTo) {
                              loadOrders();
                              loadOrders();
                            }
                          }}
                        >
                          <i className="mdi mdi-checkbox-marked-circle"></i>{" "}
                          {props.t("Check")}
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"   "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>

                  <div id="printDiv">
                    <CardTitle className="text-center">
                      {props.t("Branch Item Transfer Report")}
                    </CardTitle>
                    <ToolkitProvider
                      keyField="id"
                      data={allOrders && allOrders}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      bootstrap4
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}></Col>
                            <Col lg={4}></Col>
                          </Row>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            rowStyle={rowStyle}
                            filter={filterFactory()}
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>

                  <Modal
                    size="lg"
                    isOpen={itemModal}
                    toggle={() => setItemModal(!itemModal)}
                  >
                    <ModalHeader toggle={() => setItemModal(!itemModal)}>
                      {"    "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv1");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                    </ModalHeader>
                    <ModalBody>
                      <Row id="printDiv1">
                        <Col lg={12}>
                          <CardTitle className="text-center">
                            {props.t("Item transfer order details")}
                          </CardTitle>
                          <Card>
                            <CardBody>
                              <Row>
                                <Col lg={12}>
                                  {/* <h5> Branch: {selectedRow.name}</h5> */}
                                  <BootstrapTable
                                    keyField="_id"
                                    data={
                                      selectedRow.item_details &&
                                      selectedRow.item_details
                                    }
                                    columns={columns_item}
                                    striped
                                    hover
                                    condensed
                                    rowStyle={rowStyle}
                                    {...props.baseProps}
                                  />
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(BranchItemTransferReport))
);
