import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";

// Import menuDropdown
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logo from "../../assets/images/karak_logo_new.png";
import megamenuImg from "../../assets/images/megamenu-img.png";
/* import logoLight from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png"; */

import { postSubmitForm } from "../../helpers/forms_helper";
//i18n
import { withNamespaces } from "react-i18next";
import { getAllByRole } from "@testing-library/react";
const Header = (props) => {
  const [menu, setMenu] = useState(false);
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  useEffect(() => {
    loadNotifications();
  }, []);
  const [notifications, setNotifications] = useState([]);
  const loadNotifications = async () => {
    let url = process.env.REACT_APP_BASEURL + "notifications/get_last_ten";
    const response = await postSubmitForm(url, "");
    if (response && response.status === 1) {
      setNotifications(response.data);
    }
  };
  const handleSetNotificationsRead = async () => {
    let url = process.env.REACT_APP_BASEURL + "notifications/set_read";
    const response = await postSubmitForm(url, "");
    if (response && response.status === 1) {
      loadNotifications();
    }
  };
  const role = localStorage.getItem("role");
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="17" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img
                    src={logo}
                    alt=""
                    height="40"
                    style={{ borderRadius: "5px" }}
                  />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
            {(role === "admin" ||
              role === "Owner" ||
              role === "Operations Manager") && (
              <>
                <Dropdown
                  //className="dropdown-mega d-none d-lg-block ms-2"
                  className="dropdown-mega ms-2"
                  isOpen={menu}
                  toggle={() => setMenu(!menu)}
                >
                  <DropdownToggle
                    className="btn header-item waves-effect"
                    caret
                    tag="button"
                  >
                    {" "}
                    <i className="bx bxs-report font-size-16 align-middle mr-1 "></i>
                    {props.t("Reports")} <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-megamenu">
                    <Row>
                      <Col md={12}>
                        <Row>
                          <Col md={3}>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Order Tracking Reports")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/items/item_order_report">
                                  <span>{props.t("Item Order Report")}</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/items/branchwise_item_order_report">
                                  <span>
                                    {props.t("Branch-wise Item Order Report")}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/items/itemwise_order_report">
                                  <span>
                                    {props.t("Item-wise Order Report")}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/product_order_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>{props.t("Product Order Report")}</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/branchwise_product_order_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t(
                                      "Branch-wise Product Order Report"
                                    )}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/productwise_order_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Product-wise Order Report")}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Costing Reports")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/branchwise_summary_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Branchwise Summary Report")}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/po_costing_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>{props.t("PO Costing Report")}</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/branchwise_used_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t(
                                      "Used Finished Products Cost Report"
                                    )}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/items/branchwise_used_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Used Items Cost Report")}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/branchwise_balance_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t(
                                      "Balance Finished Products Cost Report"
                                    )}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/items/branchwise_balance_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Balance Items Cost Report")}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/po_cost_difference_report">
                                  <span>
                                    {props.t("PO Cost Difference Report")}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </Col>
                          <Col md={3}>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Expiring Reports")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/products/branchwise_expiring_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t(
                                      "Expiring Finished Products Report"
                                    )}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/items/branchwise_expiring_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Expiring Items Report")}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Expired Reports")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/products/branchwise_discarded_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t(
                                      "Discarded Finished Products Report"
                                    )}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/items/branchwise_discarded_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Discarded Items Report")}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Wastage Reports")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/products/branchwise_wasted_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Wasted Finished Products Report")}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/items/branchwise_wasted_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>{props.t("Wasted Items Report")}</span>
                                </Link>
                              </li>
                            </ul>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("User Reports")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/user_based_transaction_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("User Based Transaction Report")}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </Col>
                          <Col md={3}>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Deficiency Reports")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/products/deficiency_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t(
                                      "Finished Product Deficiency Report"
                                    )}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/items/deficiency_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Item Deficiency Report")}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("High Usage Reports")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/products/highly_used_product_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t(
                                      "Highly Used Finished Product Report"
                                    )}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/items/highly_used_item_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Highly Used Item Report")}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/highly_ordered_product_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t(
                                      "Highly Ordered Finished Product Report"
                                    )}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/items/highly_ordered_item_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Highly Ordered Item Report")}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Partial Delivery Reports")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/products/partial_delivery_from_central_kitchen_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t(
                                      "Partial Delivery From Central Kitchen"
                                    )}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/items/partial_delivery_from_central_store_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t(
                                      "Partial Delivery From Central Store"
                                    )}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/items/partial_delivery_from_supplier_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Partial Delivery From Supplier")}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </Col>
                          <Col md={3}>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Supplier Reports")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/items/supplier_monthly_report">
                                  <span>
                                    {props.t("Supplier Monthly Report")}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Item Reports")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/items/item_cost_trend_report">
                                  <span>
                                    {props.t("Item Cost Trend Report")}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("COGS Reports")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/cost_of_goods_sold_products_report">
                                  <span>
                                    {props.t(
                                      "Cost of Goods Sold Report (Products)"
                                    )}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/cost_of_goods_sold_items_report">
                                  <span>
                                    {props.t(
                                      "Cost of Goods Sold Report (Items)"
                                    )}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Transfer Reports")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/branch_product_transfer_report">
                                  <span>
                                    {props.t(
                                      "Branch Transfer Report (Products)"
                                    )}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/branch_item_transfer_report">
                                  <span>
                                    {props.t("Branch Transfer Report (Items)")}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                            <img
                              src={megamenuImg}
                              alt=""
                              style={{ height: "100px" }}
                              className="img-fluid mx-auto d-block"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </DropdownMenu>
                </Dropdown>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Branchwise Summary Report"
                  >
                    <Link to="/branchwise_summary_report">
                      <i className="mdi mdi-source-branch"></i>
                    </Link>
                  </button>
                </div>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="PO Cost Difference Report"
                  >
                    <Link to="/po_cost_difference_report">
                      <i className="bx bx-dollar-circle"></i>
                    </Link>
                  </button>
                </div>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Cost of Goods Sold Report"
                  >
                    <Link to="/cost_of_goods_sold_report">
                      <i className="bx bx-money"></i>
                    </Link>
                  </button>
                </div>
              </>
            )}
            {role === "Branch Supervisor" && (
              <Dropdown
                className="dropdown-mega d-none d-lg-block ms-2"
                isOpen={menu}
                toggle={() => setMenu(!menu)}
              >
                <DropdownToggle
                  className="btn header-item waves-effect"
                  caret
                  tag="button"
                >
                  {" "}
                  <i className="bx bxs-report font-size-16 align-middle mr-1 "></i>
                  {props.t("Reports")} <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-megamenu">
                  <Row>
                    <Col sm={9}>
                      <Row>
                        <Col md={3}>
                          <h5 className="font-size-14 mt-0">
                            <u> {props.t("Items")}</u>
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="/items/discarded_inventory">
                                {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                <span>{props.t("Discarded Items")}</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/items/wasted_inventory">
                                {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                <span>{props.t("Wasted Items")}</span>
                              </Link>
                            </li>
                          </ul>

                          <h5 className="font-size-14 mt-0">
                            <u> {props.t("Finished Products")}</u>
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="/finished_products/discarded_inventory">
                                {/* <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> */}
                                <span>{props.t("Discarded Products")}</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/finished_products/wasted_inventory">
                                {/* <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> */}
                                <span>{props.t("Wasted Products")}</span>
                              </Link>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={3}>
                      <Row>
                        <Col sm={6}></Col>

                        <Col sm={5}>
                          <div>
                            <img
                              src={megamenuImg}
                              alt=""
                              className="img-fluid mx-auto d-block"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </DropdownMenu>
              </Dropdown>
            )}
            {role === "Central Kitchen Manager" && (
              <>
                <Dropdown
                  className="dropdown-mega d-none d-lg-block ms-2"
                  isOpen={menu}
                  toggle={() => setMenu(!menu)}
                >
                  <DropdownToggle
                    className="btn header-item waves-effect"
                    caret
                    tag="button"
                  >
                    {" "}
                    <i className="bx bxs-report font-size-16 align-middle mr-1 "></i>
                    {props.t("Reports")} <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-megamenu">
                    <Row>
                      <Col sm={9}>
                        <Row>
                          <Col md={3}>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Orders")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/products/product_arrangement_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>{props.t("Arrangement Report")}</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/product_order_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>{props.t("Product Order Report")}</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/productwise_order_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Product-wise Order Report")}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/product_sectionwise_order_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Section-wise Order Report")}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={3}>
                        <Row>
                          <Col sm={6}></Col>

                          <Col sm={5}>
                            <div>
                              <img
                                src={megamenuImg}
                                alt=""
                                className="img-fluid mx-auto d-block"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </DropdownMenu>
                </Dropdown>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Orders In Process"
                  >
                    <Link to="/finished_products/orders_in_process">
                      <i className="mdi mdi-cart-arrow-right"></i>
                    </Link>
                  </button>
                </div>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Add Inventory"
                  >
                    <Link to="/finished_products/update_inventory">
                      <i className="mdi mdi-cart-plus"></i>
                    </Link>
                  </button>
                </div>

                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="View Inventory"
                  >
                    <Link to="/finished_products/view_inventory">
                      <i className="mdi mdi-cart-outline"></i>
                    </Link>
                  </button>
                </div>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="View Branch Inventory"
                  >
                    <Link to="/finished_products/view_branchwise_inventory">
                      <i className="mdi mdi-source-branch"></i>
                    </Link>
                  </button>
                </div>
              </>
            )}

            {role === "Central Store Manager" && (
              <>
                <Dropdown
                  className="dropdown-mega d-none d-lg-block ms-2"
                  isOpen={menu}
                  toggle={() => setMenu(!menu)}
                >
                  <DropdownToggle
                    className="btn header-item waves-effect"
                    caret
                    tag="button"
                  >
                    {" "}
                    <i className="bx bxs-report font-size-16 align-middle mr-1 "></i>
                    {props.t("Reports")} <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-megamenu">
                    <Row>
                      <Col sm={9}>
                        <Row>
                          <Col md={3}>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Orders")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/items/item_arrangement_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>{props.t("Arrangement Report")}</span>
                                </Link>
                              </li>

                              <li>
                                <Link to="/items/item_order_report">
                                  <span>{props.t("Item Order Report")}</span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/items/itemwise_order_report">
                                  <span>
                                    {props.t("Item-wise Order Report")}
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/po_costing_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>{props.t("PO Costing Report")}</span>
                                </Link>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={3}>
                        <Row>
                          <Col sm={6}></Col>

                          <Col sm={5}>
                            <div>
                              <img
                                src={megamenuImg}
                                alt=""
                                className="img-fluid mx-auto d-block"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </DropdownMenu>
                </Dropdown>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Orders In Process"
                  >
                    <Link to="/items/orders_in_process">
                      <i className="mdi mdi-cart-arrow-right"></i>
                    </Link>
                  </button>
                </div>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Add Inventory"
                  >
                    <Link to="/items/update_inventory">
                      <i className="mdi mdi-cart-plus"></i>
                    </Link>
                  </button>
                </div>

                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="View Inventory"
                  >
                    <Link to="/items/view_inventory">
                      <i className="mdi mdi-cart-outline"></i>
                    </Link>
                  </button>
                </div>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="View Branch Inventory"
                  >
                    <Link to="/items/view_branchwise_inventory">
                      <i className="mdi mdi-source-branch"></i>
                    </Link>
                  </button>
                </div>
              </>
            )}
            {role === "Central Store Assistant" && (
              <>
                <Dropdown
                  className="dropdown-mega d-none d-lg-block ms-2"
                  isOpen={menu}
                  toggle={() => setMenu(!menu)}
                >
                  <DropdownToggle
                    className="btn header-item waves-effect"
                    caret
                    tag="button"
                  >
                    {" "}
                    <i className="bx bxs-report font-size-16 align-middle mr-1 "></i>
                    {props.t("Reports")} <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-megamenu">
                    <Row>
                      <Col sm={9}>
                        <Row>
                          <Col md={3}>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Orders")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/items/item_arrangement_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>{props.t("Arrangement Report")}</span>
                                </Link>
                              </li>
                            </ul>
                          </Col>
                          <Col md="3">
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Deficiency Reports")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              {/* <li>
                                <Link to="/products/deficiency_report">
                                  <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i>
                                  <span>
                                    {props.t(
                                      "Finished Product Deficiency Report"
                                    )}
                                  </span>
                                </Link>
                              </li> */}
                              <li>
                                <Link to="/items/deficiency_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Item Deficiency Report")}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={3}>
                        <Row>
                          <Col sm={6}></Col>

                          <Col sm={5}>
                            <div>
                              <img
                                src={megamenuImg}
                                alt=""
                                className="img-fluid mx-auto d-block"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </DropdownMenu>
                </Dropdown>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Orders In Process"
                  >
                    <Link to="/items/orders_in_process">
                      <i className="mdi mdi-cart-arrow-right"></i>
                    </Link>
                  </button>
                </div>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Add Inventory"
                  >
                    <Link to="/items/update_inventory">
                      <i className="mdi mdi-cart-plus"></i>
                    </Link>
                  </button>
                </div>

                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="View Inventory"
                  >
                    <Link to="/items/view_inventory">
                      <i className="mdi mdi-cart-outline"></i>
                    </Link>
                  </button>
                </div>
                {/* <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="View Branch Inventory"
                  >
                    <Link to="/items/view_branchwise_inventory">
                      <i className="mdi mdi-source-branch"></i>
                    </Link>
                  </button>
                </div> */}
              </>
            )}
            {role === "Storekeeper" && (
              <>
                <Dropdown
                  className="dropdown-mega d-none d-lg-block ms-2"
                  isOpen={menu}
                  toggle={() => setMenu(!menu)}
                >
                  <DropdownToggle
                    className="btn header-item waves-effect"
                    caret
                    tag="button"
                  >
                    {" "}
                    <i className="bx bxs-report font-size-16 align-middle mr-1 "></i>
                    {props.t("Reports")} <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-megamenu">
                    <Row>
                      <Col sm={9}>
                        <Row>
                          <Col md={3}>
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Orders")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/items/item_arrangement_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>{props.t("Arrangement Report")}</span>
                                </Link>
                              </li>
                            </ul>
                          </Col>
                          <Col md="3">
                            <h5 className="font-size-14 mt-0">
                              <u> {props.t("Deficiency Reports")}</u>
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              {/* <li>
                                <Link to="/products/deficiency_report">
                                  <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i>
                                  <span>
                                    {props.t(
                                      "Finished Product Deficiency Report"
                                    )}
                                  </span>
                                </Link>
                              </li> */}
                              <li>
                                <Link to="/items/deficiency_report">
                                  {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                  <span>
                                    {props.t("Item Deficiency Report")}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={3}>
                        <Row>
                          <Col sm={6}></Col>

                          <Col sm={5}>
                            <div>
                              <img
                                src={megamenuImg}
                                alt=""
                                className="img-fluid mx-auto d-block"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </DropdownMenu>
                </Dropdown>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Orders In Process"
                  >
                    <Link to="/items/orders_in_process">
                      <i className="mdi mdi-cart-arrow-right"></i>
                    </Link>
                  </button>
                </div>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="Add Inventory"
                  >
                    <Link to="/items/update_inventory">
                      <i className="mdi mdi-cart-plus"></i>
                    </Link>
                  </button>
                </div>

                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="View Inventory"
                  >
                    <Link to="/items/view_inventory">
                      <i className="mdi mdi-cart-outline"></i>
                    </Link>
                  </button>
                </div>
                {/* <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    title="View Branch Inventory"
                  >
                    <Link to="/items/view_branchwise_inventory">
                      <i className="mdi mdi-source-branch"></i>
                    </Link>
                  </button>
                </div> */}
              </>
            )}
          </div>

          <div className="d-flex">
            <LanguageDropdown />

            <div className="dropdown d-none d-lg-inline-block ml-1">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                onClick={() => {
                  toggleFullscreen();
                }}
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen"></i>
              </button>
            </div>
            <NotificationDropdown
              data={notifications}
              setNotificationsRead={handleSetNotificationsRead}
            />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withNamespaces()(Header));
