import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";

import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";

const UsedInventory = (props) => {
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const [dateFrom, setDateFrom] = useState(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    return firstDay;
  });
  const [dateTo, setDateTo] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const { SearchBar } = Search;
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    setDateFrom(formatDate(firstDay));
    setDateTo(formatDate(currentDay));

  }, []);

  const is_store = localStorage.getItem("is_store");
  const [allItems, setAllItems] = useState([]);
  const loadItems = async () => {
    const days_diff = moment(dateTo).diff(dateFrom, "days");
    if (days_diff > 30) {
      setAllItems([]);
      showNotification("Total number of days cannot be more than 31.", "Error");
      return false;
    } else if (days_diff < 0) {
      setAllItems([]);
      showNotification("Invalid date range.", "Error");
      return false;
    }
    console.log(days_diff);
    preloader(true);
    let date_from = moment(dateFrom).format("YYYY-MM-DD"),
      date_to = moment(dateTo).format("YYYY-MM-DD");
    console.log(date_from, date_to);
    let url = process.env.REACT_APP_BASEURL + "items/getall";
    const response = await postSubmitForm(url, { date_from, date_to });
    if (response && response.status === 1) {
      preloader(false);
      setAllItems(response.data);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const [transactionsModal, setTransactionsModal] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [selectedItem_Transactions, setSelectedItem_Transactions] = useState(
    []
  );
  const handleViewDetailsClick = async (row) => {
    let url = process.env.REACT_APP_BASEURL + "itemusedinventories/get_by_item";

    const response = await postSubmitForm(url, {
      item_id: row._id,
      // inventory_type: "Kitchen",
    });

    if (response && response.status === 1) {
      setSelectedItem_Transactions(response.data);
      setSelectedItem(row);

      setTransactionsModal(!transactionsModal);
    } else {
      showNotification(response.message, "Error");
    }
  };
  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="mdi mdi-text-box-search font-size-15"
          title="View Details"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleViewDetailsClick(row);
          }}
        ></i>
      </span>
    );
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
    },
    {
      dataField: "category",
      text: props.t("Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "sub_category",
      text: props.t("Sub-Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    // {
    //   dataField: "unit",
    //   text: "Available ",
    //   formatter: (cell, row) => {
    //     return row.sub_unit
    //       ? row.available_kitchen.in_unit +
    //           " " +
    //           row.unit +
    //           " " +
    //           row.available_kitchen.in_sub_unit +
    //           " " +
    //           row.sub_unit
    //       : row.available_kitchen.in_unit + " " + row.unit;
    //   },
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "12%" };
    //   },
    // },
    // {
    //   dataField: "unit",
    //   text: props.t("Available (Total)"),
    //   formatter: (cell, row) => {
    //     return row.available.in_unit < row.threshold ? (
    //       <span className="font-size-12 badge-soft-danger badge badge-danger badge-pill mr-1 mb-1">
    //         {row.sub_unit
    //           ? row.available.in_unit +
    //             " " +
    //             row.unit +
    //             " " +
    //             row.available.in_sub_unit +
    //             " " +
    //             row.sub_unit
    //           : row.available.in_unit + " " + row.unit}
    //       </span>
    //     ) : (
    //       <>
    //         {row.sub_unit
    //           ? row.available.in_unit +
    //             " " +
    //             row.unit +
    //             " " +
    //             row.available.in_sub_unit +
    //             " " +
    //             row.sub_unit
    //           : row.available.in_unit + " " + row.unit}
    //       </>
    //     );
    //   },
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "12%" };
    //   },
    // },
    // {
    //   dataField: "threshold",
    //   text: props.t("Threshold"),
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "12%" };
    //   },
    // },
    // {
    //   dataField: "formula",
    //   text: props.t("Formula"),
    //   formatter: (cell, row) => {
    //     if (row.sub_unit) {
    //       return (
    //         <span>
    //           {"1 " + row.unit + " = " + row.formula + " " + row.sub_unit}
    //         </span>
    //       );
    //     } else {
    //       return <span>{props.t("NA")}</span>;
    //     }
    //   },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "12%" };
    //   },
    // },
    {
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} - {to} of {size} records.
    </span>
  );

  const columns_transaction = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "quantity",
      text: props.t("Quantity"),
      formatter: (cell, row) => {
        return (
          <>
            {selectedItem.sub_unit
              ? row.quantity.in_unit +
                " " +
                selectedItem.unit +
                " " +
                row.quantity.in_sub_unit +
                " " +
                selectedItem.sub_unit
              : row.quantity.in_unit + " " + selectedItem.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
    },

    // {
    //   dataField: "cost_price",
    //   text: props.t("Cost Price"),
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "8%" };
    //   },
    // },
    {
      dataField: "selling_price",
      text: props.t("Selling Price"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    // {
    //   dataField: "remarks",
    //   text: props.t("Remarks"),
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "8%" };
    //   },
    // },
    {
      text: props.t("Expiry Date"),
      sort: false,
      formatter: (cell, row) => {
        return moment(row.expiry_date).format("DD/MM/YYYY HH:mm");
      },
      headerStyle: (colum, colIndex) => {
        return { width: "16%" };
      },
    },
    {
      dataField: "",
      text: props.t("Entry Date"),
      sort: false,
      formatter: (cell, row) => {
        return moment(row.createdAt).format("DD/MM/YYYY HH:mm");
      },
      headerStyle: (colum, colIndex) => {
        return { width: "16%" };
      },
    },
  ];
  const pagination_options = {
    paginationSize: 5,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: selectedItem_Transactions && selectedItem_Transactions.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };

  const handleDownload = async () => {
    const fileName = "used_inventory";
    const exportType = "xls";

    let data_to_export = allItems;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          name: v.name,
          description: v.description,
          category: v.category,
          sub_category: v.sub_category,
          threshold: v.threshold,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Used Item Inventory")}
            title={props.t("Inventory")}
            breadcrumbItem1={props.t("Used Items")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={4}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={4}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={4}>
                        <Button
                          type="submit"
                          className="btn btn-md btn-success mt-4"
                          onClick={() => {
                            if (dateFrom && dateTo) {
                              loadItems();
                            }
                          }}
                        >
                          <i className="mdi mdi-checkbox-marked-circle"></i>{" "}
                          {props.t("Check")}
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Used Item Inventory")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all used items here")}
                  </CardSubtitle>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"  "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <div id="printDiv">
                    <ToolkitProvider
                      bootstrap4
                      keyField="_id"
                      data={allItems && allItems}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}></Col>
                          </Row>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                  <Modal
                    size="lg"
                    style={{
                      width: "940px",
                      maxWidth: "950px",
                    }}
                    isOpen={transactionsModal}
                    toggle={() => setTransactionsModal(!transactionsModal)}
                  >
                    <ModalHeader
                      toggle={() => setTransactionsModal(!transactionsModal)}
                    >
                      Item Name: {selectedItem && selectedItem.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedItem && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col lg={12}>
                                      <ToolkitProvider
                                        keyField="_id"
                                        data={
                                          selectedItem_Transactions &&
                                          selectedItem_Transactions
                                        }
                                        columns={columns_transaction}
                                      >
                                        {(props) => (
                                          <div>
                                            <BootstrapTable
                                              {...props.baseProps}
                                              pagination={paginationFactory(
                                                pagination_options
                                              )}
                                              noDataIndication="No data to display."
                                              striped
                                              hover
                                              condensed
                                            />
                                          </div>
                                        )}
                                      </ToolkitProvider>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(UsedInventory))
);
