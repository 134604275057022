import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Button,
  CardTitle,
  Label,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";

import showNotification from "../../helpers/show_notification";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";

const BranchItems = (props) => {
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const [openingModal, setOpeningModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const refForForm = useRef(null);
  useEffect(() => {
    loadAllBranches();
  }, []);
  const refContainer = useRef(null);

  const [allBranches, setAllBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState();
  const loadAllBranches = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [branchItems, setBranchItems] = useState([]);
  const loadBranchItems = async (branch_id) => {
    preloader(true);
    let url = process.env.REACT_APP_BASEURL + "branchitems/get_mapping";
    let response = await postSubmitForm(url, { branch_id: branch_id });
    if (response.status === 1) {
      setBranchItems(response.data);
      preloader(false);
    } else {
      showNotification(response.message, "Error");
      preloader(false);
    }
  };
  const handleItemAction = async (item, type, extraData, n) => {
    //console.log(item);
    //console.log(type);

    let arr = extraData.filter(
      (data_item) => data_item.item_id === item.item_id
    );

    const data_to_send = {
      branch_id: selectedBranch.branch_id,
      branch_name: selectedBranch.branch_name,
      item_id: arr[0].item_id,
      threshold: arr[0].threshold,
      max_order_limit: arr[0].max_order_limit,
      action: type,
    };
    let url = process.env.REACT_APP_BASEURL + "branchitems/set_mapping";
    const response = await postSubmitForm(url, data_to_send);
    if (response && response.status === 1) {
      loadBranchItems(selectedBranch.branch_id);
      if (type === "add") showToast(props.t("Branch item added."));
      if (type === "edit") showToast(props.t("Branch item updated."));
      if (type === "delete") showToast(props.t("Branch item deleted."));
    } else {
      showNotification(response.message, "Error");
    }
  };
  function showPoPermission(cell, row) {
    if (row.is_selected) {
      return (
        <>
          <div className="custom-control custom-switch mb-2" dir="ltr">
            <input
              type="checkbox"
              title="Click to change status."
              className="custom-control-input"
              id={"customSwitch1" + row._id}
              checked={row.is_po_allowed}
            />
            <label
              title="Click to change status."
              className="custom-control-label"
              htmlFor={"customSwitch1" + row._id}
              style={{ "font-weight": "100", cursor: "pointer" }}
              onClick={() => {
                handlePOPermission(row);
              }}
            ></label>
          </div>

          {row.is_po_allowed ? (
            <span class="font-size-12 badge-soft-success badge badge-success badge-pill">
              Yes
            </span>
          ) : (
            <span class="font-size-12 badge-soft-danger badge badge-danger badge-pill">
              No
            </span>
          )}
        </>
      );
    }
    // else {
    //   return (
    //     <span class="font-size-12 badge-soft-danger badge badge-danger badge-pill">
    //       No
    //     </span>
    //   );
    // }
  }

  const columns = [
    {
      dataField: "item_id",
      hidden: true,
      csvExport: false,
    },
    {
      dataField: "item_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      csvExport: false,
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
    },
    {
      dataField: "category",
      text: props.t("Category"),
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "sub_category",
      text: props.t("Sub-Category"),
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Unit"),
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "sub_unit",
      text: props.t("Sub-Unit"),
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "formula",
      text: props.t("Formula"),
      editable: false,
      formatter: (cell, row) => {
        if (row.sub_unit != "" && row.sub_unit) {
          return (
            <span>
              {"1 " + row.unit + " = " + row.formula + " " + row.sub_unit}
            </span>
          );
        } else {
          return <span>{props.t("NA")}</span>;
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "threshold",
      text: props.t("Threshold"),
      sort: true,
      editable: true,
      type: "number",
      attrs: { title: "Click to Edit" },
      style: { cursor: "pointer" },
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      dataField: "max_order_limit",
      text: props.t("Max Order Limit"),
      sort: true,
      editable: true,
      type: "number",
      attrs: { title: "Click to Edit" },
      style: { cursor: "pointer" },
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "formula",
      text: props.t("Formula"),
      editable: false,
      formatter: (cell, row) => {
        if (row.sub_unit != "" && row.sub_unit) {
          return (
            <span>
              {"1 " + row.unit + " = " + row.formula + " " + row.sub_unit}
            </span>
          );
        } else {
          return <span>{props.t("NA")}</span>;
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    // {
    //   dataField: "is_po_allowed",
    //   text: "PO Allowed",
    //   editable: (cell, row) => {
    //     return row.is_selected;
    //   },
    //   editor: {
    //     type: Type.CHECKBOX,
    //     value: "Yes:No",
    //   },
    //   attrs: { title: "Click to Edit" },
    //   style: (cell, row) => {
    //     return row.is_selected
    //       ? { cursor: "pointer" }
    //       : { cursor: "not-allowed" };
    //   },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "8%" };
    //   },
    // },
    {
      text: props.t("PO Allowed"),
      formatter: showPoPermission,
      editable: false,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      csvExport: false,
    },

    {
      text: props.t("Action"),
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: false,
      formatter: (cell, row, rowIndex, extraData) => {
        if (row.is_selected) {
          return (
            <>
              <button
                class="btn btn-secondary btn-sm btn btn-primary mr-2"
                onClick={() => {
                  handleItemAction(row, "edit", extraData);
                }}
              >
                {props.t("Update")}
              </button>
              <button
                class="btn btn-danger btn-sm btn btn-primary"
                onClick={() => {
                  handleItemAction(row, "delete", extraData);
                }}
              >
                {props.t("Delete")}
              </button>

              {/* {(selectedBranch.branch_id == "6114f39424652400083ce9e2" ||
                selectedBranch.branch_id == "6114f43e24652400083ce9e7" ||
                selectedBranch.branch_id == "61f51c5aa200bb0009ea07b4" ||
                selectedBranch.branch_id == "6232163c4999efa246dd4624" ||
                selectedBranch.branch_id == "621b37a4e5ed7b0009938370" ||
                selectedBranch.branch_id == "624d44b82db71e29a0a29d1a" ||
                selectedBranch.branch_id == "6114f52b24652400083ce9ef" ||
                selectedBranch.branch_id == "6114f23a24652400083ce9d9" ||
                selectedBranch.branch_id == "6114f5c024652400083ce9f5" ||
                selectedBranch.branch_id == "62514d654b284d30993be23d" ||
                selectedBranch.branch_id == "6114f40324652400083ce9e5" ||
                selectedBranch.branch_id == "6262f9b0f426340da59a40f0" ||
                selectedBranch.branch_id == "62a326f3bcbbfc786bb812c8") && (
                <button
                  class="btn btn-success btn-sm btn btn-primary"
                  onClick={() => {
                    setSelectedRow(row);
                    setOpeningModal(!openingModal);
                  }}
                >
                  {props.t("Opening")}
                </button>
              )} */}
            </>
          );
        } else {
          return (
            <button
              class="btn btn-primary btn-sm btn btn-primary"
              onClick={() => {
                handleItemAction(row, "add", extraData);
              }}
            >
              {props.t("Add")}
            </button>
          );
        }
      },
      formatExtraData: branchItems,
      csvExport: false,
    },
  ];

  const MyExportCSV = (props) => {
    const handleClick = () => {
      // passing my custom data
      props.onExport(branchItems.filter((r) => r.is_selected));
    };
    return (
      <div>
        <button className="btn btn-success" onClick={handleClick}>
          Export Selected to CSV
        </button>
      </div>
    );
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} - {to} of {size} records.
    </span>
  );
  const pagination_options = {
    paginationSize: 50,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: branchItems && branchItems.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };
  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.is_unsaved) {
      style.backgroundColor = "#ffcc00";
    } else if (row.is_selected) {
      style.backgroundColor = "#c7dced";
    } else {
      style.backgroundColor = "#dadada";
    }

    return style;
  };
  const handlePOPermission = async (item) => {
    const data_to_send = {
      branch_id: selectedBranch.branch_id,
      item_id: item.item_id,
      is_po_allowed: !item.is_po_allowed,
    };
    let url = process.env.REACT_APP_BASEURL + "branchitems/set_po_permission";
    const response = await postSubmitForm(url, data_to_send);
    if (response && response.status === 1) {
      loadBranchItems(selectedBranch.branch_id);
      showToast(props.t("PO permission updated."));
    } else {
      showNotification(response.message, "Error");
    }
  };
  const showToast = (message_to_show) => {
    var ele = document.getElementsByName("toastType");
    var position = document.getElementsByName("positions");
    var toastType = "info";
    var title = "";
    var message = message_to_show;

    //Close Button
    var closeButton = true;

    //Progressbar
    var progressBar = true;

    //Duplicates
    var preventDuplicates = false;

    //Newest on Top
    var newestOnTop = true;

    //position class
    var positionClass = "toast-bottom-right";

    //show duration
    var showDuration = 200;

    //Hide duration
    var hideDuration = 1000;

    //timeout
    var timeOut = 2000;

    //extended timeout
    var extendedTimeOut = 1000;

    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showDuration: showDuration,
      hideDuration: hideDuration,
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  };

  const handleValidOpening = async (e, v) => {
    const data_to_send = {
      branch_id: selectedBranch.branch_id,
      item_id: selectedRow.item_id,
      quantity: { in_unit: v.in_unit, in_sub_unit: v.in_sub_unit },
    };
    //console.log(data_to_send);
    let url = process.env.REACT_APP_BASEURL + "branchiteminventories/opening";
    const response = await postSubmitForm(url, data_to_send);
    if (response && response.status === 1) {
      loadBranchItems(selectedBranch.branch_id);
      setOpeningModal(!openingModal);
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Branch")}
            breadcrumbItem={props.t("Branch Items")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm ref={refForForm}>
                    <CardTitle>{props.t("Branch Items")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Select a branch to add/update/delete a branch item"
                      )}
                    </CardSubtitle>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="branch"
                          label={props.t("Select Branch")}
                          type="select"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Branch cannot be empty")}
                          onChange={(e) => {
                            const selectedIndex =
                              e.target.options.selectedIndex;

                            setSelectedBranch({
                              branch_id: e.target.value,
                              branch_name:
                                e.target.options[selectedIndex].getAttribute(
                                  "name"
                                ),
                            });
                            loadBranchItems(e.target.value);
                          }}
                        >
                          <option value="">
                            {props.t("-- Select Branch --")}
                          </option>
                          {allBranches &&
                            allBranches.map((e) => (
                              <option key={e._id} value={e._id} name={e.name}>
                                {e.name}
                              </option>
                            ))}
                        </AvField>
                      </Col>
                    </Row>
                    {/* <Button type="submit">Submit</Button> */}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Branch Items")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all branch items for selected branch here")}
                  </CardSubtitle>
                  <ToolkitProvider
                    bootstrap4
                    keyField="item_id"
                    data={branchItems && branchItems}
                    columns={columns}
                    search
                    exportCSV
                  >
                    {(propst) => (
                      <div>
                        <Row>
                          <Col lg={4}>
                            <SearchBar
                              {...propst.searchProps}
                              style={{ width: "300px" }}
                            />
                          </Col>
                          <Col lg={4}>&nbsp;</Col>
                          <Col lg={4} className="text-right">
                            <MyExportCSV {...propst.csvProps} />
                          </Col>
                        </Row>
                        <BootstrapTable
                          keyField="item_id"
                          {...propst.baseProps}
                          //pagination={paginationFactory(pagination_options)}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                          rowStyle={rowStyle}
                          cellEdit={cellEditFactory({
                            mode: "click",
                            blurToSave: true,
                            afterSaveCell: (
                              oldValue,
                              newValue,
                              row,
                              column
                            ) => {
                              if (column.dataField === "is_po_allowed") {
                                if (newValue !== oldValue)
                                  handlePOPermission(row, newValue);
                              }
                            },
                          })}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="sm"
            isOpen={openingModal}
            toggle={() => setOpeningModal(!openingModal)}
          >
            <ModalHeader toggle={() => setOpeningModal(!openingModal)}>
              {props.t("Update Opening Balance")}
            </ModalHeader>
            <ModalBody>
              {selectedRow && (
                <>
                  <Row>
                    <Col lg={12}>
                      <Card>
                        <CardBody>
                          <AvForm onValidSubmit={handleValidOpening}>
                            <Row>
                              <Col lg={12}>
                                <AvField
                                  name="in_unit"
                                  label={
                                    props.t("In Unit (") +
                                    selectedRow.unit +
                                    ")"
                                  }
                                  value="0"
                                  step="1"
                                  min="0"
                                  type="number"
                                />
                              </Col>
                              <Col lg={12} hidden={!selectedRow.sub_unit}>
                                <AvField
                                  name="in_sub_unit"
                                  label={
                                    props.t("In Sub Unit (") +
                                    selectedRow.sub_unit +
                                    ")"
                                  }
                                  min="0"
                                  max={
                                    selectedRow.formula &&
                                    Number(selectedRow.formula) - 1
                                  }
                                  step="1"
                                  value="0"
                                  type="number"
                                />
                                <span>
                                  <strong>Formula :</strong> 1{" "}
                                  {selectedRow.unit} ={" "}
                                  {selectedRow.sub_unit && selectedRow.formula}{" "}
                                  {selectedRow.sub_unit && selectedRow.sub_unit}
                                </span>
                              </Col>

                              <Col lg={12}>
                                <FormGroup className="mb-0 text-center">
                                  <Button
                                    type="submit"
                                    color="primary"
                                    className="mr-1"
                                  >
                                    {props.t("Update")}
                                  </Button>
                                </FormGroup>
                              </Col>
                            </Row>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withNamespaces()(BranchItems)));
