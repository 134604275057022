import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";

import { postSubmitForm } from "../../../helpers/forms_helper";
import moment from "moment";

const ItemClosing = (props) => {
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  useEffect(() => {
    loadItemsForClosing();
  }, []);

  const [closingDate, setClosingDate] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    setClosingDate(formatDate(currentDay));
  }, []);

  useEffect(() => {
    if (closingDate) loadItemsForClosing();
  }, [closingDate]);
  const [allItems, setAllItems] = useState([]);
  const [isClosureDone, setIsClosureDone] = useState();
  const loadItemsForClosing = async () => {
    preloader(true);
    if (closingDate) {
      let url = process.env.REACT_APP_BASEURL + "branchitems/get_for_closure";
      let data_to_send = {
        date_of_closure: closingDate,
      };

      const response = await postSubmitForm(url, data_to_send);

      if (response && response.status === 1) {
        preloader(false);
        setAllItems(response.data);
        setIsClosureDone(response.is_closure_done);
      } else {
        preloader(false);
        showNotification(response.message, "Error");
      }
    }
  };
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const fontSmall = { fontSize: "11px" };
  const columns = [
    {
      dataField: "_id",
      hidden: true,
      csvExport: false,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
      csvExport: false,
    },
    {
      text: props.t("Item"),
      dataField: "name",
      formatter: (cell, row) =>
        `${row.name} (${row.category} - ${row.sub_category})`,
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "available.in_unit",
      text: props.t("Balance"),
      sort: true,
      editable: false,
      formatter: (cell, row) =>
        `${row.available.in_unit} ${row.unit} ${
          row.sub_unit ? `${row.available.in_sub_unit} ${row.sub_unit}` : ``
        }`,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "discarded.in_unit",
      text: props.t("Expired"),
      sort: true,
      editable: false,
      formatter: (cell, row) =>
        `${row.discarded.in_unit} ${row.unit} ${
          row.sub_unit ? `${row.discarded.in_sub_unit} ${row.sub_unit}` : ``
        }`,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "formula",
      text: props.t("Formula"),
      sort: true,
      editable: false,
      formatter: (cell, row) => {
        if (row.sub_unit) {
          return (
            <span>
              {"1 " + row.unit + " = " + row.formula + " " + row.sub_unit}
            </span>
          );
        } else {
          return <span>{props.t("NA")}</span>;
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "used.in_unit",
      text: props.t("Used (in Unit)"),
      headerFormatter: () => {
        return (
          <span>
            Used
            <br />
            <span style={fontSmall}>(in Unit)</span>
          </span>
        );
      },
      sort: true,
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          };
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          };
        }
        return true;
      },
      editable: isClosureDone ? false : true,
      attrs: (cell, row, rowIndex, colIndex) => {
        return isClosureDone ? {} : { title: "Click to Edit" };
      },
      style: (cell, row, rowIndex, colIndex) => {
        return isClosureDone
          ? {}
          : { cursor: "pointer", background: "#ffffaa" };
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "used.in_sub_unit",
      text: props.t("Used (in Sub-Unit)"),
      headerFormatter: () => {
        return (
          <span>
            Used
            <br />
            <span style={fontSmall}>(in Sub-Unit)</span>
          </span>
        );
      },
      sort: true,
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          };
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          };
        }
        return true;
      },
      editable: isClosureDone ? false : true,
      attrs: (cell, row, rowIndex, colIndex) => {
        return isClosureDone ? {} : { title: "Click to Edit" };
      },
      style: (cell, row, rowIndex, colIndex) => {
        return isClosureDone
          ? {}
          : { cursor: "pointer", background: "#ffffaa" };
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },

    {
      dataField: "balance_in_hand.in_unit",
      text: "Balance (in hand, Unit)",
      headerFormatter: () => {
        return (
          <span>
            Balance
            <br />
            <span style={fontSmall}>(in hand, Unit)</span>
          </span>
        );
      },
      sort: true,
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          };
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          };
        }
        return true;
      },
      editable: isClosureDone ? false : true,
      attrs: (cell, row, rowIndex, colIndex) => {
        return isClosureDone ? {} : { title: "Click to Edit" };
      },
      style: (cell, row, rowIndex, colIndex) => {
        return isClosureDone
          ? {}
          : { cursor: "pointer", background: "#aae6ff" };
      },
      // formatter: (cell, row) => {
      //   return row.available < row.threshold ? (
      //     <span className="font-size-12 badge-soft-danger badge badge-danger badge-pill mr-1 mb-1">
      //       {row.available}
      //     </span>
      //   ) : (
      //     row.available
      //   );
      // },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "balance_in_hand.in_sub_unit",
      text: "Balance (in hand, Sub-Unit)",
      headerFormatter: () => {
        return (
          <span>
            Balance
            <br />
            <span style={fontSmall}>(in hand, Sub-Unit)</span>
          </span>
        );
      },
      sort: true,
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          };
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          };
        }
        return true;
      },
      editable: isClosureDone ? false : true,
      attrs: (cell, row, rowIndex, colIndex) => {
        return isClosureDone ? {} : { title: "Click to Edit" };
      },
      style: (cell, row, rowIndex, colIndex) => {
        return isClosureDone
          ? {}
          : { cursor: "pointer", background: "#aae6ff" };
      },
      // formatter: (cell, row) => {
      //   return row.available < row.threshold ? (
      //     <span className="font-size-12 badge-soft-danger badge badge-danger badge-pill mr-1 mb-1">
      //       {row.available}
      //     </span>
      //   ) : (
      //     row.available
      //   );
      // },
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },

    {
      dataField: "waste.in_unit",
      text: "Waste (in Unit)",
      headerFormatter: () => {
        return (
          <span>
            Waste
            <br />
            <span style={fontSmall}>(in Unit)</span>
          </span>
        );
      },
      sort: true,
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          };
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          };
        }
        return true;
      },
      editable: isClosureDone ? false : true,
      attrs: (cell, row, rowIndex, colIndex) => {
        return isClosureDone ? {} : { title: "Click to Edit" };
      },
      style: (cell, row, rowIndex, colIndex) => {
        return isClosureDone
          ? {}
          : { cursor: "pointer", background: "#ffaaaa" };
      },
      // formatter: (cell, row) => `${row.waste.in_unit} ${row.unit}`,
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
    },
    {
      dataField: "waste.in_sub_unit",
      text: "Waste (in Sub-Unit)",
      headerFormatter: () => {
        return (
          <span>
            Waste
            <br />
            <span style={fontSmall}>(in Sub-Unit)</span>
          </span>
        );
      },
      sort: true,
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          };
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          };
        }
        return true;
      },
      editable: isClosureDone ? false : true,
      attrs: (cell, row, rowIndex, colIndex) => {
        return isClosureDone ? {} : { title: "Click to Edit" };
      },
      style: (cell, row, rowIndex, colIndex) => {
        return isClosureDone
          ? {}
          : { cursor: "pointer", background: "#ffaaaa" };
      },
      // formatter: (cell, row) =>
      //   `${row.sub_unit ? `${row.waste.in_sub_unit} ${row.sub_unit}` : `NA`}`,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },

    {
      dataField: "remarks",
      text: props.t("Remarks"),
      sort: true,
      editable: isClosureDone ? false : true,
      attrs: (cell, row, rowIndex, colIndex) => {
        return isClosureDone ? {} : { title: "Click to Edit" };
      },
      style: (cell, row, rowIndex, colIndex) => {
        return isClosureDone
          ? {}
          : { cursor: "pointer", background: "#ffffaa" };
      },
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} - {to} of {size} records.
    </span>
  );

  const pagination_options = {
    paginationSize: 50,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: allItems && allItems.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };
  const handleSubmitClosure = async (e, v) => {
    try {
      console.log(allItems);
      let arr_items = JSON.parse(JSON.stringify(allItems));
      let isBalanceNotUpdated = arr_items.some(
        (item) =>
          item.balance_in_hand.in_unit === "" ||
          item.balance_in_hand.in_sub_unit === ""
      );
      let isRemarkNotUpdated = arr_items.some(
        (item) =>
          (+item.waste.in_unit > 0 || +item.waste.in_sub_unit > 0) &&
          item.remarks === ""
      );

      let isNotValidInputs = arr_items.some(
        (item) =>
          GetUpdatedValue(
            GetUpdatedValue(
              item.waste_to_compare,
              item.available_to_compare,
              "Credit",
              +(item.formula ? item.formula : 1)
            ),
            item.used_to_compare,
            "Credit",
            +(item.formula ? item.formula : 1)
          ).in_unit !==
            GetUpdatedValue(
              GetUpdatedValue(
                item.waste,
                item.balance_in_hand,
                "Credit",
                +(item.formula ? item.formula : 1)
              ),
              item.used,
              "Credit",
              +(item.formula ? item.formula : 1)
            ).in_unit ||
          GetUpdatedValue(
            GetUpdatedValue(
              item.waste_to_compare,
              item.available_to_compare,
              "Credit",
              +(item.formula ? item.formula : 1)
            ),
            item.used_to_compare,
            "Credit",
            +(item.formula ? item.formula : 1)
          ).in_sub_unit !==
            GetUpdatedValue(
              GetUpdatedValue(
                item.waste,
                item.balance_in_hand,
                "Credit",
                +(item.formula ? item.formula : 1)
              ),
              item.used,
              "Credit",
              +(item.formula ? item.formula : 1)
            ).in_sub_unit
      );
      if (isBalanceNotUpdated) {
        showNotification(
          "Enter balance in hand (in unit and sub-unit) for all items.",
          "Error"
        );
      } else if (isRemarkNotUpdated) {
        showNotification("Enter remarks for all wasted items.", "Error");
      } else if (isNotValidInputs) {
        showNotification(
          "Invalid values entered in Used/Balance/Waste.",
          "Error"
        );
      } else {
        const object = {
          date_of_closure: closingDate,
          closure_details: allItems,
        };
        console.log(object);
        let url = process.env.REACT_APP_BASEURL + "branchitemclosures/insert";
        let response = await postSubmitForm(url, object);
        if (response.status === 1) {
          loadItemsForClosing();
          showNotification(response.message, "Success");
        } else {
          showNotification(response.message, "Error");
        }
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };
  const showErrorAndRollback = (dataField, oldValue, row, ErrorMessage) => {
    let arr_str = dataField.split(".");
    let arr = [...allItems];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]._id === row._id) {
        switch (arr_str.length) {
          case 1:
            arr[i][arr_str[0]] = oldValue;
            break;
          case 2:
            arr[i][arr_str[0]][arr_str[1]] = oldValue;
            break;
          case 3:
            arr[i][arr_str[0]][arr_str[1]][arr_str[2]] = oldValue;
            break;
        }
      }
    }
    setAllItems(arr);
    showNotification(ErrorMessage, "Error");
  };
  const GetUpdatedValue = (current_value, change_value, type, formula) => {
    let in_unit = 0,
      in_sub_unit = 0,
      total_current_value_in_sub_unit = 0,
      total_change_value_in_sub_unit = 0,
      total_in_sub_unit = 0;

    if (type == "Credit") {
      in_unit = Number(current_value.in_unit) + Number(change_value.in_unit);
      in_sub_unit =
        Number(current_value.in_sub_unit) + Number(change_value.in_sub_unit);
      if (in_sub_unit >= Number(formula)) {
        in_unit += Math.floor(in_sub_unit / Number(formula));
        in_sub_unit = in_sub_unit % Number(formula);
      }
    } else if (type == "Debit") {
      total_current_value_in_sub_unit =
        Number(current_value.in_unit) * Number(formula) +
        Number(current_value.in_sub_unit);
      total_change_value_in_sub_unit =
        Number(change_value.in_unit) * Number(formula) +
        Number(change_value.in_sub_unit);

      total_in_sub_unit =
        total_current_value_in_sub_unit - total_change_value_in_sub_unit;

      in_unit = Math.floor(total_in_sub_unit / Number(formula));
      in_sub_unit = total_in_sub_unit % Number(formula);
    }

    return { in_unit, in_sub_unit };
  };
  const tableCellAfterSave = (oldValue, newValue, row, column) => {
    if (
      (+newValue < 0 || (isNaN(newValue) && !isFinite(newValue))) &&
      column.dataField !== "remarks"
    ) {
      showErrorAndRollback(
        column.dataField,
        oldValue,
        row,
        "Only positive numbers allowed."
      );
    } else if (column.dataField === "used.in_unit") {
      if (+newValue < +row.used_to_compare.in_unit) {
        showErrorAndRollback(
          column.dataField,
          oldValue,
          row,
          `Cannot enter less than ${oldValue}.`
        );
      } else {
        if (
          +newValue >
          GetUpdatedValue(
            row.available,
            row.used_to_compare,
            "Credit",
            +row.formula
          ).in_unit
        ) {
          showErrorAndRollback(
            column.dataField,
            oldValue,
            row,
            `Cannot enter more than ${
              GetUpdatedValue(
                row.available,
                row.used_to_compare,
                "Credit",
                +row.formula
              ).in_unit
            }.`
          );
        } else if (
          +newValue ===
            GetUpdatedValue(
              row.available,
              row.used_to_compare,
              "Credit",
              +row.formula
            ).in_unit &&
          +row.used.in_sub_unit >
            GetUpdatedValue(
              row.available,
              row.used_to_compare,
              "Credit",
              +row.formula
            ).in_sub_unit
        ) {
          showErrorAndRollback(
            column.dataField,
            oldValue,
            row,
            `Cannot enter more than ${
              GetUpdatedValue(
                row.available,
                row.used_to_compare,
                "Credit",
                +row.formula
              ) - 1
            }.`
          );
        }
        // else {
        //   let change_in_value = +newValue - +oldValue;
        //   let arr = [...allItems];
        //   for (let i = 0; i < arr.length; i++) {
        //     if (arr[i]._id === row._id) {
        //       arr[i].available = {
        //         in_unit: +arr[i].available.in_unit - +change_in_value,
        //         in_sub_unit: arr[i].available.in_sub_unit,
        //       };
        //     }
        //   }
        //   setAllItems(arr);
        // }
      }
    } else if (column.dataField === "used.in_sub_unit") {
      if (
        +newValue < +row.used_to_compare.in_sub_unit &&
        +row.used.in_unit === +row.used_to_compare.in_unit
      ) {
        showErrorAndRollback(
          column.dataField,
          oldValue,
          row,
          `Cannot enter less than ${+row.used_to_compare.in_sub_unit}.`
        );
      } else {
        if (
          +newValue >
            GetUpdatedValue(
              row.available,
              row.used_to_compare,
              "Credit",
              +row.formula
            ).in_sub_unit &&
          +row.used.in_unit ===
            GetUpdatedValue(
              row.available,
              row.used_to_compare,
              "Credit",
              +row.formula
            ).in_unit
        ) {
          showErrorAndRollback(
            column.dataField,
            oldValue,
            row,
            `Cannot enter more than ${
              GetUpdatedValue(
                row.available,
                row.used_to_compare,
                "Credit",
                +row.formula
              ).in_sub_unit
            }.`
          );
        } else if (+newValue > row.formula - 1) {
          showErrorAndRollback(
            column.dataField,
            oldValue,
            row,
            `Cannot enter more than ${row.formula - 1}.`
          );
        }
        // else {
        //   let change_in_value = +newValue - +oldValue;
        //   let arr = [...allItems];
        //   for (let i = 0; i < arr.length; i++) {
        //     if (arr[i]._id === row._id) {
        //       arr[i].available = GetUpdatedValue(
        //         arr[i].available,
        //         { in_unit: 0, in_sub_unit: +change_in_value },
        //         "Debit",
        //         row.formula
        //       );
        //       break;
        //     }
        //   }
        //   setAllItems(arr);
        // }
      }
    } else if (column.dataField === "balance_in_hand.in_unit") {
      if (+newValue > +row.available.in_unit) {
        showErrorAndRollback(
          column.dataField,
          oldValue,
          row,
          `Cannot enter more than ${+row.available.in_unit}.`
        );
      } else {
        if (
          +newValue === +row.available.in_unit &&
          +row.balance_in_hand.in_sub_unit > +row.available.in_sub_unit
        ) {
          showErrorAndRollback(
            column.dataField,
            oldValue,
            row,
            `Cannot enter more than ${+row.available.in_unit - 1}.`
          );
        }
        // else {
        //   let arr = [...allItems];
        //   for (let i = 0; i < arr.length; i++) {
        //     if (arr[i]._id === row._id) {
        //       if (arr[i].balance_in_hand.in_unit !== "") {
        //         arr[i].waste = {
        //           in_unit: +arr[i].available.in_unit - +newValue,
        //           in_sub_unit: arr[i].waste.in_sub_unit,
        //         };
        //       }
        //       break;
        //     }
        //   }
        //   setAllItems(arr);
        // }
      }
    } else if (column.dataField === "balance_in_hand.in_sub_unit") {
      if (
        +newValue > +row.available.in_sub_unit &&
        +row.balance_in_hand.in_unit === +row.available.in_unit
      ) {
        showErrorAndRollback(
          column.dataField,
          oldValue,
          row,
          `Cannot enter more than ${+row.available.in_sub_unit}.`
        );
      } else {
        if (+newValue > row.formula - 1) {
          showErrorAndRollback(
            column.dataField,
            oldValue,
            row,
            `Cannot enter more than ${row.formula - 1}.`
          );
        }
        // else {
        //   //let change_in_value = +newValue - +oldValue;
        //   let arr = [...allItems];
        //   for (let i = 0; i < arr.length; i++) {
        //     if (arr[i]._id === row._id) {
        //       if (arr[i].balance_in_hand.in_sub_unit !== "") {
        //         arr[i].waste = GetUpdatedValue(
        //           arr[i].available,
        //           {
        //             in_unit:
        //               arr[i].balance_in_hand.in_unit === ""
        //                 ? 0
        //                 : arr[i].balance_in_hand.in_unit,
        //             in_sub_unit: +newValue,
        //           },
        //           "Debit",
        //           row.formula
        //         );
        //       }
        //       break;
        //     }
        //   }

        //   setAllItems(arr);
        // }
      }
    } else if (column.dataField === "waste.in_unit") {
      if (+newValue < +row.waste_to_compare.in_unit) {
        showErrorAndRollback(
          column.dataField,
          oldValue,
          row,
          `Cannot enter less than ${oldValue}.`
        );
      } else {
        if (
          +newValue >
          GetUpdatedValue(
            row.available,
            row.waste_to_compare,
            "Credit",
            +row.formula
          ).in_unit
        ) {
          showErrorAndRollback(
            column.dataField,
            oldValue,
            row,
            `Cannot enter more than ${
              GetUpdatedValue(
                row.available,
                row.waste_to_compare,
                "Credit",
                +row.formula
              ).in_unit
            }.`
          );
        } else if (
          +newValue ===
            GetUpdatedValue(
              row.available,
              row.waste_to_compare,
              "Credit",
              +row.formula
            ).in_unit &&
          +row.waste.in_sub_unit >
            GetUpdatedValue(
              row.available,
              row.waste_to_compare,
              "Credit",
              +row.formula
            ).in_sub_unit
        ) {
          showErrorAndRollback(
            column.dataField,
            oldValue,
            row,
            `Cannot enter more than ${
              GetUpdatedValue(
                row.available,
                row.waste_to_compare,
                "Credit",
                +row.formula
              ).in_unit - 1
            }.`
          );
        }
        // else {
        //   let change_in_value = +newValue - +oldValue;
        //   let arr = [...allItems];
        //   for (let i = 0; i < arr.length; i++) {
        //     if (arr[i]._id === row._id) {
        //       arr[i].available = {
        //         in_unit: +arr[i].available.in_unit - +change_in_value,
        //         in_sub_unit: arr[i].available.in_sub_unit,
        //       };
        //     }
        //   }
        //   setAllItems(arr);
        // }
      }
    } else if (column.dataField === "waste.in_sub_unit") {
      if (
        +newValue < +row.waste_to_compare.in_sub_unit &&
        +row.waste.in_unit === +row.waste_to_compare.in_unit
      ) {
        showErrorAndRollback(
          column.dataField,
          oldValue,
          row,
          `Cannot enter less than ${+row.waste_to_compare.in_sub_unit}.`
        );
      } else {
        if (
          +newValue >
            GetUpdatedValue(
              row.available,
              row.waste_to_compare,
              "Credit",
              +row.formula
            ).in_sub_unit &&
          +row.waste.in_unit ===
            GetUpdatedValue(
              row.available,
              row.waste_to_compare,
              "Credit",
              +row.formula
            ).in_unit
        ) {
          showErrorAndRollback(
            column.dataField,
            oldValue,
            row,
            `Cannot enter more than ${
              GetUpdatedValue(
                row.available,
                row.waste_to_compare,
                "Credit",
                +row.formula
              ).in_sub_unit
            }.`
          );
        } else if (+newValue > row.formula - 1) {
          showErrorAndRollback(
            column.dataField,
            oldValue,
            row,
            `Cannot enter more than ${row.formula - 1}.`
          );
        }
        // else {
        //   let change_in_value = +newValue - +oldValue;
        //   let arr = [...allItems];
        //   for (let i = 0; i < arr.length; i++) {
        //     if (arr[i]._id === row._id) {
        //       arr[i].available = GetUpdatedValue(
        //         arr[i].available,
        //         { in_unit: 0, in_sub_unit: +change_in_value },
        //         "Debit",
        //         row.formula
        //       );
        //       break;
        //     }
        //   }
        //   setAllItems(arr);
        // }
      }
    }
  };
  const handleDownload = async () => {
    const fileName = "item_closure";
    const exportType = "xls";

    let data_to_export = allItems;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Name: v.name,
          Category: v.category,
          SubCategory: v.category,
          Balance: v.sub_unit
            ? v.available.in_unit +
              " " +
              v.unit +
              " " +
              v.available.in_sub_unit +
              " " +
              v.sub_unit
            : v.available.in_unit + " " + v.unit,
          Expired: v.sub_unit
            ? v.discarded.in_unit +
              " " +
              v.unit +
              " " +
              v.discarded.in_sub_unit +
              " " +
              v.sub_unit
            : v.discarded.in_unit + " " + v.unit,

          Formula: v.sub_unit
            ? "1 " + v.unit + " = " + v.formula + " " + v.sub_unit
            : "NA",
          Used_In_Unit: v.used.in_unit,
          Used_In_Sub_Unit: v.used.in_sub_unit,
          Balance_In_Unit: v.balance_in_hand.in_unit,
          Balance_In_Sub_Unit: v.balance_in_hand.in_sub_unit,
          Waste_In_Unit: v.waste.in_unit,
          Waste_In_Sub_Unit: v.waste.in_sub_unit,

          Remarks: v.remarks,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Item Closing")}
            title={props.t("Closing")}
            breadcrumbItem1={props.t("Items")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm onValidSubmit={handleSubmitClosure}>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="closing_date"
                          label={props.t("Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={closingDate}
                          onChange={(e, v) => {
                            setClosingDate(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        {isClosureDone && isClosureDone ? (
                          <>
                            {" "}
                            <Label>&nbsp;</Label> <br />
                            <p style={{ color: "green" }}>
                              {props.t("Closure done for selected date.")}
                            </p>
                          </>
                        ) : (
                          <>
                            <Label>&nbsp;</Label> <br />
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                            >
                              {props.t("Submit Closure")}
                            </Button>{" "}
                          </>
                        )}
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"   "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <div id="printDiv">
                    <CardTitle className="text-center">
                      {props.t("Item Closure")}
                    </CardTitle>
                    <ToolkitProvider
                      bootstrap4
                      keyField="_id"
                      data={allItems && allItems}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      search
                      exportCSV
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}></Col>
                            <Col lg={4} className="text-right"></Col>
                          </Row>
                          <BootstrapTable
                            striped
                            cellEdit={cellEditFactory({
                              mode: "click",
                              blurToSave: true,
                              afterSaveCell: async (
                                oldValue,
                                newValue,
                                row,
                                column
                              ) => {
                                tableCellAfterSave(
                                  oldValue,
                                  newValue,
                                  row,
                                  column
                                );
                              },
                            })}
                            hover
                            condensed
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(ItemClosing))
);
