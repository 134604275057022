import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import swal from "sweetalert2";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import Select from "react-select";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";

// For notifications
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

import NotificationDashboard from "./Dashboard/NotificationDashboard";
// actions
import { changePassword } from "../store/actions";
import { postSubmitForm } from "../helpers/forms_helper";

const Notifications = (props) => {
  function showNotification(message, type) {
    var title = type;
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  useEffect(() => {
    loadNotifications();
  }, []);
  const [notifications, setNotifications] = useState([]);
  const loadNotifications = async () => {
    let url = process.env.REACT_APP_BASEURL + "notifications/get_all";
    const response = await postSubmitForm(url, "");
    if (response && response.status === 1) {
      let notification_data = {
        unread_notifications: 0,
        notifications: response.data,
      };
      setNotifications(notification_data);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Notifications")}
            breadcrumbItem={props.t("All Notifications")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <NotificationDashboard
                    data={notifications}
                    setNotificationsRead={() => {}}
                    viewAll={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { error, message, message_type } = state.Login;
  return { error, message, message_type };
};
export default withRouter(
  connect(mapStatetoProps, {
    changePassword,
  })(withNamespaces()(Notifications))
);
