import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Button,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";

const ItemClosedOrders = (props) => {
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const { SearchBar } = Search;
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    setDateFrom(formatDate(currentDay));
    setDateTo(formatDate(currentDay));
  }, []);

  const [selectedBranch, setSelectedBranch] = useState("All");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [allOrders, setAllOrders] = useState([]);

  const loadAllOrders = async () => {
    const days_diff = moment(dateTo).diff(dateFrom, "days");
    if (days_diff > 30) {
      setAllOrders([]);
      showNotification("Total number of days cannot be more than 31.", "Error");
      return false;
    } else if (days_diff < 0) {
      setAllOrders([]);
      showNotification("Invalid date range.", "Error");
      return false;
    }

    preloader(true);

    let date_from = dateFrom,
      date_to = moment(dateFrom)
        .add(days_diff / 4, "days")
        .format("YYYY-MM-DD");

    let url = process.env.REACT_APP_BASEURL + "itemorders/getall_closed";

    console.log("1", date_from, date_to);

    const response1 = await postSubmitForm(url, {
      date_from: date_from,
      date_to: date_to,
    });

    if (response1 && response1.status === 1) {
      console.log(response1.data.length);
    } else {
      showNotification(response1.message, "Error");
      return false;
    }

    date_from = moment(dateFrom)
      .add(days_diff / 4 + 1, "days")
      .format("YYYY-MM-DD");
    date_to = moment(dateFrom)
      .add(days_diff / 2, "days")
      .format("YYYY-MM-DD");

    console.log("2", date_from, date_to);

    const response2 = await postSubmitForm(url, {
      date_from: date_from,
      date_to: date_to,
    });
    if (response2 && response2.status === 1) {
      console.log(response1.data.length);
      //setAllOrders(response2.data.concat(response1.data));
    } else {
      preloader(false);
      showNotification(response2.message, "Error");
      return false;
    }

    date_from = moment(dateFrom)
      .add(days_diff / 2 + 1, "days")
      .format("YYYY-MM-DD");
    date_to = moment(dateFrom)
      .add((days_diff * 3) / 4, "days")
      .format("YYYY-MM-DD");

    console.log("3", date_from, date_to);

    const response3 = await postSubmitForm(url, {
      date_from: date_from,
      date_to: date_to,
    });

    if (response3 && response3.status === 1) {
      console.log(response3.data.length);
    } else {
      preloader(false);
      showNotification(response3.message, "Error");
      return false;
    }

    date_from = moment(dateFrom)
      .add((days_diff * 3) / 4 + 1, "days")
      .format("YYYY-MM-DD");
    date_to = dateTo;

    console.log("4", date_from, date_to);

    const response4 = await postSubmitForm(url, {
      date_from: date_from,
      date_to: date_to,
    });

    if (response4 && response4.status === 1) {
      console.log(response4.data.length);
      preloader(false);
      setAllOrders(
        response4.data
          .concat(response3.data)
          .concat(response2.data)
          .concat(response1.data)
      );
    } else {
      preloader(false);
      showNotification(response4.message, "Error");
      return false;
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Sub-Category"),
      dataField: "sub_category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Available"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.available.in_unit +
                " " +
                row.unit +
                " " +
                row.available.in_sub_unit +
                " " +
                row.sub_unit
              : row.available.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Order Quantity"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const [orderDetailsModal, setOrderDetailsModal] = useState();
  const [selectedOrder, setSelectedOrder] = useState();

  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      dataField: "order_id",
      text: props.t("Order ID"),

      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "branch_details.name",
      text: props.t("Branch Name"),

      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "remarks",
      text: props.t("Branch Remarks"),

      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },

      style: { cursor: "pointer" },
    },
    {
      text: props.t("Order Date"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      dataField: "createdAt",
      // formatter: (cell, row) => {
      //   return moment(row.createdAt).format("DD-MM-YYYY HH:mm");
      // },
      style: { cursor: "pointer" },
    },
    // {
    //   text: props.t("Is Urgent?"),
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "12%" };
    //   },
    //   formatter: (cell, row) => {
    //     if (row.is_urgent) {
    //       return props.t("Yes");
    //     } else {
    //       return props.t("No");
    //     }
    //   },
    //   style: { cursor: "pointer" },
    // },

    {
      dataField: "status",
      text: props.t("Status"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "admin_remarks",
      text: props.t("Centre Remarks"),

      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "",
      text: props.t("Action"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <button
              class="btn btn-secondary btn-sm mr-2"
              onClick={() => {
                setSelectedOrder(row);
                setOrderDetailsModal(!orderDetailsModal);
              }}
            >
              {props.t("Details")}
            </button>
          </>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];

  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      setExpanded([...expanded, row._id]);
    } else {
      setExpanded(expanded.filter((x) => x !== row._id));
    }
  };
  const [expanded, setExpanded] = useState([]);
  const expandRow = {
    //onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.item_details}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
    expanded: expanded,
    onExpand: handleOnExpand,
  };

  const columns_delivered_items = [
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Sub-Category"),
      dataField: "sub_category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Delivered Qty."),
      formatter: (cell, row) => {
        return (
          <>
            {row.delivered_quantity.in_sub_unit
              ? row.delivered_quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.delivered_quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.delivered_quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Rec. Qty."),
      formatter: (cell, row) => {
        return (
          <>
            {row.received_quantity.in_sub_unit
              ? row.received_quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.received_quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.received_quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const rowStyle = (row, rowIndex) => {
    const style = { backgroundColor: "#fffbb8", fontWeight: "500" };
    // if (row.status === "Completed") {
    //   style.backgroundColor = "#b0ff9e";
    // }
    return style;
  };
  const handleDownload = async () => {
    // const fileName = "appointments";
    // const exportType = "xls";
    // let data_to_send_api = [];
    // allAppointments.map((appointment) => {
    //   data_to_send_api.push(appointment._id);
    // });
    // let data_to_export = [];
    // let url = process.env.REACT_APP_BASEURL + "appointments/toexcel"; //"services/getall";
    // const response = await postSubmitForm(url, {
    //   appointment_ids: data_to_send_api,
    // });
    // if (response && response.status === 1) {
    //   data_to_export = response.data;
    // } else {
    //   showNotification(response.message, "Error");
    // }
    // if (data_to_export) {
    //   var data = JSON.parse(JSON.stringify(data_to_export));
    //   data.forEach(function (v) {
    //     v.client_name = v.client_details.client_name;
    //     v.client_mobile = v.client_details.client_mobile;
    //     v.service_name = v.service_details.name;
    //     v.service_duration = v.service_details.duration;
    //     v.service_cost = v.service_details.cost;
    //     delete v.is_active;
    //     delete v._id;
    //     delete v.createdAt;
    //     delete v.updatedAt;
    //     delete v.__v;
    //     delete v.sold_product_details;
    //     delete v.title;
    //     delete v.client_details;
    //     delete v.service_details;
    //     delete v.invoice_number;
    //     delete v.serviceandsale_id;
    //   });
    //   console.log(data);
    //   exportFromJSON({ data, fileName, exportType });
    // }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Items")}
            title={props.t("Central Store")}
            breadcrumbItem1={props.t("Item Order")}
            breadcrumbItem2={props.t("Closed Orders")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={4}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={4}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={4}>
                        <Button
                          type="submit"
                          className="btn btn-md btn-success mt-4"
                          onClick={() => {
                            if (dateFrom && dateTo) {
                              loadAllOrders();
                            }
                          }}
                        >
                          <i className="mdi mdi-checkbox-marked-circle"></i>{" "}
                          {props.t("Check")}
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Closed Orders")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all closed orders here")}
                  </CardSubtitle>
                  {/* <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row> */}
                  <ToolkitProvider
                    keyField="_id"
                    data={allOrders && allOrders}
                    columns={columns_external}
                    noDataIndication={props.t("No data to display.")}
                    bootstrap4
                    search
                  >
                    {(props_table) => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4}>
                              <Label>{props.t("Search")}</Label>
                              <SearchBar
                                {...props_table.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          striped
                          hover
                          condensed
                          expandRow={expandRow}
                          rowStyle={rowStyle}
                          {...props_table.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                  <Modal
                    size="lg"
                    isOpen={orderDetailsModal}
                    toggle={() => setOrderDetailsModal(!orderDetailsModal)}
                  >
                    <ModalHeader
                      toggle={() => setOrderDetailsModal(!orderDetailsModal)}
                    >
                      {props.t("Delivery Details")}
                    </ModalHeader>
                    <ModalBody>
                      <>
                        <Row>
                          <Col lg={12}>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col lg={12} md={12}>
                                    <Label>Ordered Items</Label>
                                  </Col>

                                  <Col lg={12}>
                                    <BootstrapTable
                                      bootstrap4
                                      keyField="name"
                                      data={
                                        selectedOrder &&
                                        selectedOrder.item_details
                                      }
                                      columns={columns}
                                      noDataIndication="No data to display."
                                      striped
                                      hover
                                      condensed
                                    />
                                    <hr />
                                  </Col>

                                  {selectedOrder &&
                                  selectedOrder.delivery_details &&
                                  selectedOrder.delivery_details.length > 0 ? (
                                    <>
                                      <Col lg={12} md={12}>
                                        <br />
                                        <Label>
                                          <b>{props.t("Delivered Items")} </b>
                                        </Label>
                                      </Col>
                                      <Col lg={12} className="mb-4">
                                        <BootstrapTable
                                          bootstrap4
                                          keyField="name"
                                          key="name"
                                          data={selectedOrder.delivery_details}
                                          columns={columns_delivered_items}
                                          noDataIndication="No data to display."
                                          striped
                                          hover
                                          condensed
                                        />
                                        <hr />
                                      </Col>
                                    </>
                                  ) : null}
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(
  connect(null, {})(withNamespaces()(ItemClosedOrders))
);
