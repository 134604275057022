import React from "react";

import { Row, Col, CardBody, Card, Alert, Container, Button } from "reactstrap";
//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { loginUser, apiError } from "../../store/actions";

// import images
import "../../assets/css/login.css";
import logo from "../../assets/images/logo-sm-light.png";
import Label from "reactstrap/lib/Label";
import logo_square from "../../assets/images/km_logo.png";
import bodybg from "../../assets/images/bodybg.jpg";

const Login = (props) => {
  // handleValidSubmit
  function handleValidSubmit(event, values) {
    props.loginUser(values, props.history);
  }
  return (
    <React.Fragment>
      <div className="wrapper-div background-image">
        <div className="account-pages my-5 pt-sm-5 ">
          <Container>
            <Row className="justify-content-center">
              <Col md={12} lg={12} xl={8}>
                <Card className="overflow-hidden">
                  <CardBody className="login-div">
                    <div className="p-2">
                      <Row>
                        <Col
                          lg={4}
                          sm={12}
                          xs={12}
                          md={12}
                          className="justify-content-center"
                        >
                          <img
                            src={logo_square}
                            alt="logo"
                            className="login-logo"
                          ></img>
                        </Col>
                        <Col lg={8} sm={12} xs={12} md={12}>
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                              handleValidSubmit(e, v);
                            }}
                            style={{
                              padding: "15px !important",
                            }}
                          >
                            {props.error &&
                            props.error == "Invalid credentials." ? (
                              <Alert color="danger">{props.error}</Alert>
                            ) : null}
                            <div className="login-form-group">
                              <div
                                className={
                                  localStorage.getItem("lang") === "eng"
                                    ? "input-icons-n-ltr"
                                    : "input-icons-n"
                                }
                              >
                                <i
                                  class={
                                    localStorage.getItem("lang") === "eng"
                                      ? "fa fa-user icon icon-style-ltr"
                                      : "fa fa-user icon icon-style"
                                  }
                                ></i>
                                <AvField
                                  name="username"
                                  className="form-control input-field"
                                  placeholder={props.t("Enter Username")}
                                  type="text"
                                  style={{
                                    border: "1px solid #dddddd",
                                    backgroundColor: "#ffffff",
                                  }}
                                  required
                                  errorMessage={props.t(
                                    "Username cannot be blank"
                                  )}
                                />
                              </div>
                            </div>{" "}
                            <div className="login-form-group">
                              <div
                                className={
                                  localStorage.getItem("lang") === "eng"
                                    ? "input-icons-n-ltr"
                                    : "input-icons-n"
                                }
                              >
                                <i
                                  class={
                                    localStorage.getItem("lang") === "eng"
                                      ? "fa fa-lock icon icon-style-ltr"
                                      : "fa fa-lock icon icon-style"
                                  }
                                ></i>
                                {console.log(localStorage.getItem("lang"))}
                                <AvField
                                  name="pwd"
                                  type="password"
                                  className="form-control input-field"
                                  style={{
                                    border: "1px solid #dddddd",
                                    backgroundColor: "#ffffff",
                                  }}
                                  required
                                  placeholder={props.t("Enter Password")}
                                  errorMessage={props.t(
                                    "Password cannot be blank"
                                  )}
                                />
                              </div>
                            </div>
                            <div className="login-form-group">
                              <Button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                {props.t("Login")}
                              </Button>
                            </div>
                          </AvForm>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  withNamespaces()(connect(mapStatetoProps, { loginUser, apiError })(Login))
);
