import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import AddInventoryComp from "./AddInventoryComp";
import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";

const ViewInventory = (props) => {
  const { SearchBar } = Search;
  useEffect(() => {
    loadItems();
  }, []);

  const [allItems, setAllItems] = useState([]);
  const loadItems = async () => {
    let url = process.env.REACT_APP_BASEURL + "items/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllItems(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const [transactionsModal, setTransactionsModal] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [selectedItem_Transactions, setSelectedItem_Transactions] = useState(
    []
  );
  const handleViewDetailsClick = async (row) => {
    let url = process.env.REACT_APP_BASEURL + "iteminventories/get_by_item_id";
    const response = await postSubmitForm(url, { item_id: row._id });
    if (response && response.status === 1) {
      setSelectedItem_Transactions(response.data);
      setSelectedItem(row);

      setTransactionsModal(!transactionsModal);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [selectedItemToAdd, setSelectedItemToAdd] = useState();
  function editFormatter(cell, row) {
    return (
      <>
        <span className="text-info mr-2">
          <i
            className="mdi mdi-text-box-search font-size-15"
            title="View Details"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleViewDetailsClick(row);
            }}
          ></i>
        </span>
        <span className="text-info">
          <i
            className="mdi mdi-cart-plus font-size-15"
            title="Add Inventory"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedItemToAdd(row);
              setAddInventoryModal(!addInventoryModal);
            }}
          ></i>
        </span>
      </>
    );
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
    },
    {
      text: props.t("Description"),
      dataField: "description",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
    },
    {
      dataField: "category",
      text: props.t("Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "sub_category",
      text: props.t("Sub-Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "threshold",
      text: props.t("Centre Threshold"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Available"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.available.in_unit +
                " " +
                row.unit +
                " " +
                row.available.in_sub_unit +
                " " +
                row.sub_unit
              : row.available.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "formula",
      text: props.t("Formula"),
      formatter: (cell, row) => {
        if (row.sub_unit) {
          return (
            <span>
              {"1 " + row.unit + " = " + row.formula + " " + row.sub_unit}
            </span>
          );
        } else {
          return <span>{props.t("NA")}</span>;
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} - {to} of {size} records.
    </span>
  );
  const [selectedBatch, setSelectedBatch] = useState();
  const [transferModal, setTransferModal] = useState();
  const [quantityInUnit, setQuantityInUnit] = useState();
  const [quantityinSubUnit, setQuantityInSubUnit] = useState();
  const handleTransferClicked = async () => {
    const object = {
      iteminventory_id: selectedBatch._id,
      quantity: {
        in_unit: quantityInUnit,
        in_sub_unit: quantityinSubUnit ? quantityinSubUnit : 0,
      },
    };
    let url =
      process.env.REACT_APP_BASEURL +
      "iteminventories/centralstore_to_centralkitchen";

    const response = await postSubmitForm(url, object);

    if (response && response.status === 1) {
      loadItems();
      showNotification(response.message, "Success");
      setTransactionsModal(!transactionsModal);
      setTransferModal(!transferModal);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const columns_transaction = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "quantity",
      text: props.t("Quantity"),
      formatter: (cell, row) => {
        return (
          <>
            {selectedItem.sub_unit
              ? row.quantity.in_unit +
                " " +
                selectedItem.unit +
                " " +
                row.quantity.in_sub_unit +
                " " +
                selectedItem.sub_unit
              : row.quantity.in_unit + " " + selectedItem.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },

    {
      dataField: "cost_price",
      text: props.t("Cost Price"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "selling_price",
      text: props.t("Selling Price"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Expiry Date"),
      sort: false,
      formatter: (cell, row) => {
        return moment(row.expiry_date).format("DD/MM/YYYY HH:mm");
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "",
      text: props.t("Entry Date"),
      sort: false,
      formatter: (cell, row) => {
        return moment(row.createdAt).format("DD/MM/YYYY HH:mm");
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Transfer"),
      formatter: (cell, row) => {
        return (
          <span className="text-info">
            <i
              className="bx bx-transfer font-size-22"
              title="Transfer"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedBatch(row);
                setTransferModal(!transferModal);
              }}
            ></i>
          </span>
        );
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
  ];
  const pagination_options = {
    paginationSize: 5,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: selectedItem_Transactions && selectedItem_Transactions.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };

  const [addInventoryModal, setAddInventoryModal] = useState(false);

  const handleClosePopup = async () => {
    setAddInventoryModal(!addInventoryModal);
  };

  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }
  const handleDownload = async () => {
    const fileName = "view_inventory";
    const exportType = "xls";

    let data_to_export = allItems;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          name: v.name,
          description: v.description,
          category: v.category,
          sub_category: v.sub_category,
          centre_threshold: v.threshold,
          formula: v.formula
            ? "1 " + v.unit + " = " + v.formula + " " + v.sub_unit
            : "NA",
          available: v.formula
            ? v.available.in_unit +
              " " +
              v.unit +
              " " +
              v.available.in_sub_unit +
              " " +
              v.sub_unit
            : v.available.in_unit + " " + v.unit,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            title={props.t("Inventory")}
            breadcrumbItem1={props.t("Items")}
            breadcrumbItem2={props.t("View Inventory")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("View Inventory")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all products in your inventory here")}
                  </CardSubtitle>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"   "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <div id="printDiv">
                    <ToolkitProvider
                      bootstrap4
                      keyField="_id"
                      data={allItems && allItems}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}></Col>
                            <Col lg={4} className="text-right">
                              {/* <button
                              className="btn-sm btn btn-info"
                              onClick={() => {
                                setAddInventoryModal(!addInventoryModal);
                              }}
                            >
                              Add Item
                            </button> */}
                            </Col>
                          </Row>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>

                  <Modal
                    size="lg"
                    style={{
                      width: "940px",
                      maxWidth: "950px",
                    }}
                    isOpen={transactionsModal}
                    toggle={() => setTransactionsModal(!transactionsModal)}
                  >
                    <ModalHeader
                      toggle={() => setTransactionsModal(!transactionsModal)}
                    >
                      Item Name: {selectedItem && selectedItem.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedItem && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col lg={12}>
                                      <ToolkitProvider
                                        keyField="_id"
                                        data={
                                          selectedItem_Transactions &&
                                          selectedItem_Transactions
                                        }
                                        columns={columns_transaction}
                                      >
                                        {(props) => (
                                          <div>
                                            <BootstrapTable
                                              {...props.baseProps}
                                              pagination={paginationFactory(
                                                pagination_options
                                              )}
                                              noDataIndication="No data to display."
                                              striped
                                              hover
                                              condensed
                                            />
                                          </div>
                                        )}
                                      </ToolkitProvider>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="lg"
                    style={{
                      width: "940px",
                      maxWidth: "950px",
                    }}
                    isOpen={addInventoryModal}
                    toggle={() => setAddInventoryModal(!addInventoryModal)}
                  >
                    <ModalHeader
                      toggle={() => setAddInventoryModal(!addInventoryModal)}
                    >
                      Add Item
                    </ModalHeader>
                    <ModalBody>
                      <AddInventoryComp
                        isRefreshData={true}
                        refreshData={loadItems}
                        selectedItem={selectedItemToAdd}
                        handleClosePopup={handleClosePopup}
                      />
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="md"
                    isOpen={transferModal}
                    toggle={() => setTransferModal(!transferModal)}
                  >
                    <ModalHeader
                      toggle={() => setTransferModal(!transferModal)}
                    >
                      {props.t("Batch")}: {selectedBatch && selectedBatch.batch}
                    </ModalHeader>
                    <ModalBody>
                      {selectedBatch && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm onValidSubmit={handleTransferClicked}>
                                    <Row>
                                      <Col lg={12}>
                                        <Label>
                                          {props.t("Quantity Available")}:{" "}
                                          {selectedBatch.item_details.sub_unit
                                            ? selectedBatch.quantity.in_unit +
                                              " " +
                                              selectedBatch.item_details.unit +
                                              " " +
                                              selectedBatch.quantity
                                                .in_sub_unit +
                                              " " +
                                              selectedBatch.item_details
                                                .sub_unit
                                            : selectedBatch.quantity.in_unit +
                                              " " +
                                              selectedBatch.item_details.unit}
                                        </Label>

                                        <hr />
                                      </Col>
                                      {selectedBatch &&
                                      selectedBatch.item_details.sub_unit ? (
                                        <Col lg={12}>
                                          <Label>
                                            {props.t("Quantity to Transfer")}{" "}
                                            {" (1 " +
                                              selectedBatch.item_details.unit +
                                              " = " +
                                              selectedBatch.item_details
                                                .formula +
                                              " " +
                                              selectedBatch.item_details
                                                .sub_unit +
                                              ")"}
                                          </Label>
                                          <Row>
                                            <Col lg={6}>
                                              <AvField
                                                name="in_unit"
                                                placeholder={
                                                  selectedBatch.item_details
                                                    .unit
                                                }
                                                type="text"
                                                onChange={(e) => {
                                                  setQuantityInUnit(
                                                    e.target.value
                                                  );
                                                }}
                                                validate={{
                                                  required: {
                                                    value: true,
                                                    errorMessage:
                                                      "Quantity cannot be empty.",
                                                  },
                                                  pattern: {
                                                    value: "^[0-9]+$",
                                                    errorMessage: props.t(
                                                      "Only numbers are allowed."
                                                    ),
                                                  },
                                                  max: {
                                                    value:
                                                      selectedBatch &&
                                                      selectedBatch.quantity
                                                        .in_unit,
                                                    errorMessage:
                                                      selectedBatch &&
                                                      props.t(
                                                        "Cannot enter more than "
                                                      ) +
                                                        selectedBatch.quantity
                                                          .in_unit +
                                                        ".",
                                                  },
                                                }}
                                              />
                                            </Col>
                                            <Col lg={6}>
                                              <AvField
                                                name="in_sub_unit"
                                                placeholder={
                                                  selectedBatch.item_details
                                                    .sub_unit
                                                }
                                                type="text"
                                                onChange={(e) => {
                                                  setQuantityInSubUnit(
                                                    e.target.value
                                                  );
                                                }}
                                                validate={{
                                                  required: {
                                                    value: true,
                                                    errorMessage:
                                                      "Quantity cannot be empty.",
                                                  },
                                                  pattern: {
                                                    value: "^[0-9]+$",
                                                    errorMessage: props.t(
                                                      "Only numbers are allowed."
                                                    ),
                                                  },
                                                  max: {
                                                    value:
                                                      selectedBatch &&
                                                      (+selectedBatch.quantity
                                                        .in_unit ===
                                                      +quantityInUnit
                                                        ? selectedBatch.quantity
                                                            .in_sub_unit
                                                        : +selectedBatch
                                                            .item_details
                                                            .formula - 1),
                                                    errorMessage:
                                                      selectedBatch &&
                                                      (+selectedBatch.quantity
                                                        .in_unit ===
                                                      +quantityInUnit
                                                        ? props.t(
                                                            "Cannot enter more than "
                                                          ) +
                                                          +selectedBatch
                                                            .quantity
                                                            .in_sub_unit +
                                                          " " +
                                                          selectedBatch
                                                            .item_details
                                                            .sub_unit +
                                                          "."
                                                        : props.t(
                                                            "Cannot enter more than "
                                                          ) +
                                                          (+selectedBatch
                                                            .item_details
                                                            .formula -
                                                            1) +
                                                          "."),
                                                  },
                                                }}
                                              />
                                            </Col>
                                          </Row>
                                        </Col>
                                      ) : (
                                        <Col lg={12}>
                                          <Label>
                                            {props.t("Quantity")}{" "}
                                            {selectedBatch
                                              ? " (in " +
                                                selectedBatch.item_details
                                                  .unit +
                                                ")"
                                              : ""}
                                          </Label>

                                          <AvField
                                            name="quantity"
                                            placeholder={props.t(
                                              "Enter Quantity"
                                            )}
                                            errorMessage="Quantity cannot be empty."
                                            type="text"
                                            onChange={(e) => {
                                              setQuantityInUnit(e.target.value);
                                            }}
                                            validate={{
                                              required: { value: true },
                                              pattern: {
                                                value: "^[0-9]+$",
                                                errorMessage: props.t(
                                                  "Only numbers are allowed."
                                                ),
                                              },
                                              max: {
                                                value:
                                                  selectedBatch &&
                                                  selectedBatch.quantity
                                                    .in_unit,
                                                errorMessage:
                                                  selectedBatch &&
                                                  props.t(
                                                    "Cannot enter more than "
                                                  ) +
                                                    selectedBatch.quantity
                                                      .in_unit +
                                                    ".",
                                              },
                                            }}
                                          />
                                        </Col>
                                      )}

                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                            >
                                              {props.t("Submit")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(ViewInventory))
);
