import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Button,
  CardTitle,
  Label,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb

import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";
import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import showNotification from "../../../../helpers/show_notification";

import { postSubmitForm } from "../../../../helpers/forms_helper";

import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();
const FinishedProductAddOrder = (props) => {
  const refForForm = useRef(null);
  const refSelectProduct = useRef(null);
  useEffect(() => {
    loadAllProducts();
  }, []);
  const refContainer = useRef(null);

  const [isUrgent, setIsUrgent] = useState(false);
  const [remarks, setRemarks] = useState();
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const loadAllProducts = async () => {
    let url = process.env.REACT_APP_BASEURL + "branchproducts/getforbranch";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllProducts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [productsForOrder, setProductsForOrder] = useState([]);
  const handleAddProductForOrder = async (e, v) => {
    var new_array_products = JSON.parse(JSON.stringify(productsForOrder));
    const existing_product = new_array_products.filter(function (product) {
      return product.product_id === selectedProduct.product_id;
    });

    if (existing_product.length > 0) {
      showNotification(
        props.t("This product is already added."),
        props.t("Error")
      );
    } else if (selectedProduct) {
      // let url =
      //   process.env.REACT_APP_BASEURL + "productorders/is_product_allowed";
      // let response = await postSubmitForm(url, {
      //   product_id: selectedProduct.product_id,
      // });
      //if (response.status === 1) {
      let product_to_add = {};
      product_to_add.product_id = selectedProduct.product_id;
      product_to_add.name = selectedProduct.name;
      product_to_add.section = selectedProduct.section;
      product_to_add.category = selectedProduct.category;
      product_to_add.available = selectedProduct.available;
      product_to_add.unit = selectedProduct.unit;
      product_to_add.quantity = +v.quantity;
      product_to_add.new_available = +v.new_available;
      setProductsForOrder([...productsForOrder, product_to_add]);

      refSelectProduct.current.select.clearValue();
      refForForm.current.reset();
      // } else {
      //   showNotification(props.t(response.message), props.t("Error"));
      // }
    } else {
      showNotification(props.t("Select a product first."), props.t("Error"));
    }
  };
  const handleValidSubmit = async (e, v) => {
    try {
      const object = {
        product_details: productsForOrder,
        is_urgent: isUrgent,
        remarks: v.remarks,
      };
      let url = process.env.REACT_APP_BASEURL + "productorders/insert";
      console.log(productsForOrder);
      let response = await postSubmitForm(url, object);
      if (response.status === 1) {
        loadAllProducts();
        setRemarks();
        setProductsForOrder([]);
        setIsUrgent(false);
        setSelectedProduct();
        refSelectProduct.current.select.clearValue();
        showNotification(response.message, "Success");
        refForForm.current.reset();
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };
  function deleteFormatter(cell, row, rowIndex, extraData) {
    return (
      <>
        <span className="text-danger">
          <i
            className="bx bxs-trash font-size-15"
            title="Click to Delete"
            style={{ cursor: "pointer" }}
            onClick={() => {
              let arr = extraData.filter(
                (item) => item.product_id !== row.product_id
              );

              setProductsForOrder(arr);
            }}
          ></i>
        </span>
      </>
    );
  }
  const columns = [
    {
      dataField: "section",
      text: props.t("Section"),
    },
    {
      dataField: "category",
      text: props.t("Category"),
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "new_available",
      text: props.t("Available Quantity"),
    },
    {
      dataField: "quantity",
      text: props.t("Order Quantity"),
    },
    {
      formatter: deleteFormatter,
      formatExtraData: productsForOrder,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Finished Products")}
            title={props.t("Orders")}
            breadcrumbItem1={props.t("Finished Products")}
            breadcrumbItem2={props.t("Add Order")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    onValidSubmit={handleAddProductForOrder}
                    ref={refForForm}
                  >
                    <CardTitle>{props.t("Add New Order")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Enter the following details to place order for finished products"
                      )}
                    </CardSubtitle>
                    <Row>
                      <Col lg={4}>
                        <Label>{props.t("Product")}</Label>
                        <Select
                          ref={refSelectProduct}
                          value={selectedProduct}
                          onChange={(selected) => {
                            setSelectedProduct(selected);
                          }}
                          options={allProducts}
                          classNamePrefix="select2-selection"
                          components={animatedComponents}
                          required
                        />
                      </Col>
                      {selectedProduct && (
                        <>
                          <Col lg={2}>
                            <AvField
                              disabled
                              name="new_available"
                              label={props.t("Available Quantity")}
                              placeholder={props.t("Enter Quantity")}
                              value={selectedProduct.available.toString()}
                              type="number"
                              min="0"
                              step="1"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: props.t(
                                    "Quantity cannot be empty."
                                  ),
                                },
                                pattern: {
                                  value: "^[0-9]+$",
                                  errorMessage: props.t(
                                    "Only numbers are allowed."
                                  ),
                                },
                                max: {
                                  value:
                                    selectedProduct &&
                                    selectedProduct.available,
                                  errorMessage:
                                    selectedProduct &&
                                    props.t("Cannot enter more than ") +
                                      selectedProduct.available +
                                      ".",
                                },
                              }}
                            />
                            {/* <Label>Available Quantity</Label>
                            <br />
                            <Label>{selectedProduct.available}</Label> */}
                          </Col>
                          <Col lg={2}>
                            <Label>Threshold Quantity</Label>
                            <br />
                            <Label>{selectedProduct.threshold}</Label>
                          </Col>
                        </>
                      )}

                      <Col lg={2}>
                        <AvField
                          name="quantity"
                          label={props.t("Quantity")}
                          placeholder={props.t("Enter Quantity")}
                          type="number"
                          min="1"
                          step="1"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: props.t(
                                "Quantity cannot be empty."
                              ),
                            },
                            pattern: {
                              value: "^[0-9]+$",
                              errorMessage: props.t(
                                "Only numbers are allowed."
                              ),
                            },
                            max: {
                              value:
                                selectedProduct &&
                                selectedProduct.max_order_limit,
                              errorMessage:
                                selectedProduct &&
                                props.t("Cannot order more than ") +
                                  selectedProduct.max_order_limit +
                                  ".",
                            },
                          }}
                        />
                      </Col>
                      <Col lg={2} className="mb-4">
                        <Label>&nbsp;</Label>
                        <br />
                        <Button
                          type="submit"
                          color="primary"
                          className="btn btn-info waves-effect waves-light btn-sm"
                        >
                          <i class="bx bx-add-to-queue"></i> {props.t("Add")}
                        </Button>
                      </Col>
                    </Row>
                    {/* <Button type="submit">Submit</Button> */}
                  </AvForm>
                  <hr />
                  {productsForOrder && productsForOrder.length > 0 && (
                    <>
                      <BootstrapTable
                        bootstrap4
                        keyField="_id"
                        data={productsForOrder && productsForOrder}
                        columns={columns}
                        noDataIndication="No data to display."
                        striped
                        hover
                        condensed
                      />
                      <AvForm onValidSubmit={handleValidSubmit}>
                        <Row>
                          <Col lg={6}>
                            <AvField
                              name="remarks"
                              value={remarks}
                              onChange={(e) => {
                                setRemarks(e.target.value);
                              }}
                              label={props.t("Remarks")}
                              placeholder={props.t("Enter Remarks")}
                              type="textarea"
                            />
                          </Col>
                          <Col lg={12}>
                            <div class="form-check">
                              <input
                                type="checkbox"
                                name="is_urgent"
                                className="is-untouched is-pristine av-valid form-check-input"
                                checked={isUrgent}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setIsUrgent(true);
                                  } else {
                                    setIsUrgent(false);
                                  }
                                }}
                              />
                              <label for="select_all">
                                {" "}
                                {props.t("This is an urgent order.")}
                              </label>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <hr />
                            <FormGroup className="mb-0 text-left">
                              <div>
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="mr-1"
                                >
                                  {props.t("Place Order")}
                                </Button>{" "}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </AvForm>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(
  connect(null, {})(withNamespaces()(FinishedProductAddOrder))
);
