export const SUBMIT_CAR = "SUBMIT_CAR";
export const SUBMIT_CAR_SUCCESS = "SUBMIT_CAR_SUCCESS";
export const CARS_API_ERROR = "CARS_API_ERROR";
export const LOAD_CARS = "LOAD_CARS";
export const LOAD_CARS_SUCCESS = "LOAD_CARS_SUCCESS";
export const LOAD_CAR_MAKES = "LOAD_CAR_MAKES";
export const LOAD_CAR_MAKES_SUCCESS = "LOAD_CAR_MAKES_SUCCESS";
export const SUBMIT_CAR_MAKE = "SUBMIT_CAR_MAKE";
export const SUBMIT_CAR_MAKE_SUCCESS = "SUBMIT_CAR_MAKE_SUCCESS";
export const SUBMIT_CAR_MODEL = "SUBMIT_CAR_MODEL";
export const SUBMIT_CAR_MODEL_SUCCESS = "SUBMIT_CAR_MODEL_SUCCESS";
export const SUBMIT_CAR_VARIANT = "SUBMIT_CAR_VARIANT";
export const SUBMIT_CAR_VARIANT_SUCCESS = "SUBMIT_CAR_VARIANT_SUCCESS";
