import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";

//i18n
import { withNamespaces } from "react-i18next";

import moment from "moment";

const NotificationDashboard = (props) => {
  // Declare a new state variable, which we'll call "menu"

  const role = localStorage.getItem("role");
  const getLink = (notification) => {
    let pageLink = "";
    if (
      role === "admin" ||
      role == "Operations Manager" ||
      role === "Central Kitchen Manager"
    ) {
      switch (notification) {
        case "New product order":
          pageLink = "/finished_products/new_orders";
          break;

        case "Product order received":
          pageLink = "/finished_products/orders_in_process";
          break;
      }
    }
    if (
      role === "admin" ||
      role == "Operations Manager" ||
      role === "Central Store Manager"
    ) {
      switch (notification) {
        case "New item order":
          pageLink = "/items/new_orders";
          break;

        case "Item order received":
          pageLink = "/items/orders_in_process";
          break;
      }
    }
    if (role === "Branch Supervisor") {
      switch (notification) {
        case "Product order approved":
          pageLink = "/finished_products/all_orders";
          break;
        case "Item order approved":
          pageLink = "/items/all_orders";
          break;

        case "Product order rejected":
          pageLink = "/finished_products/all_orders";
          break;
        case "Item order rejected":
          pageLink = "/items/all_orders";
          break;

        case "Product order dispatched":
          pageLink = "/finished_products/all_orders";
          break;
        case "Item order dispatched":
          pageLink = "/items/all_orders";
          break;

        case "Product order closed":
          pageLink = "/finished_products/all_orders";
          break;
        case "Item order closed":
          pageLink = "/items/all_orders";
          break;
      }
    }

    return pageLink;
  };
  return (
    <>
      {" "}
      <Card>
        <CardBody>
          <CardTitle>Notifications</CardTitle>
          <Row>
            <Col lg={12}>
              {props.data.notifications &&
              props.data.notifications.length > 0 ? (
                <>
                  <SimpleBar style={{ height: "330px" }}>
                    {props.data.notifications &&
                      props.data.notifications.map((notification) => {
                        return (
                          <Link
                            to={() => {
                              return getLink(notification.action);
                            }}
                            className="text-reset notification-item"
                          >
                            <div className="media">
                              <div className="avatar-xs mr-3">
                                <span className="avatar-title bg-primary rounded-circle font-size-16">
                                  <i className="bx bx-cart"></i>
                                </span>
                              </div>
                              <div className="media-body">
                                <h6 className="mt-0 mb-1">
                                  {notification.title}
                                </h6>
                                <div className="font-size-12 text-muted">
                                  <p className="mb-1">{notification.body}</p>
                                  <p className="mb-0">
                                    <i className="mdi mdi-clock-outline"></i>{" "}
                                    {moment(notification.createdAt).fromNow()}{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                  </SimpleBar>
                  {props.viewAll && (
                    <div className="p-2 border-top d-grid text-center">
                      <Link
                        className="btn btn-sm btn-link font-size-14 text-center"
                        to="/notifications"
                      >
                        <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                        <span key="t-view-more">{props.t("View All")}</span>
                      </Link>
                    </div>
                  )}
                </>
              ) : (
                <SimpleBar style={{ height: "80px" }}>
                  <Link to="" className="text-reset notification-item">
                    <div className="media">
                      <div className="media-body">
                        <h6 className="mt-0 mb-1"></h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">There are no notifications.</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SimpleBar>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default withNamespaces()(NotificationDashboard);
