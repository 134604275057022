import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
  Table,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const ItemArrangementReport = (props) => {
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    { dataField: "item", hidden: true },
    { dataField: "item", text: "Product Name" },
    { dataField: "category", text: "Category" },
    { dataField: "sub_category", text: "Sub Category" },
    {
      text: "Quantity",
      formatter: (cell, row) => {
        let quantity = `${row.quantity.in_unit} ${row.unit}`;
        if (
          (row.quantity.in_sub_unit || row.quantity.in_sub_unit === 0) &&
          row.sub_unit
        ) {
          quantity = quantity + ` ${row.quantity.in_sub_unit} ${row.sub_unit}`;
        }
        return quantity;
      },
    },
  ];

  useEffect(() => {
    loadAllTimeStamps();
  }, []);
  const [allTimeStamps, setAllTimeStamps] = useState([]);
  const [selectedTimestamp, setSelectedTimestamp] = useState();
  const loadAllTimeStamps = async () => {
    let url =
      process.env.REACT_APP_BASEURL + "itemorders/get_arrangement_marks";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllTimeStamps(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  useEffect(() => {
    loadProducts();
  }, [selectedTimestamp]);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [arrData, setArrData] = useState([]);

  const loadProducts = async () => {
    preloader(true);
    let data_to_send = {
      mark_time:
        selectedTimestamp && selectedTimestamp !== ""
          ? selectedTimestamp
          : null,
    };

    let url =
      process.env.REACT_APP_BASEURL + "itemorders/arrangement_report_new";
    const response = await postSubmitForm(url, data_to_send);

    if (response && response.status === 1 && response.data) {
      setArrData(response.data);
    } else {
      showNotification(response.message, "Error");
    }
    let url1 =
      process.env.REACT_APP_BASEURL +
      "itemorders/arrangement_report_branchwise";
    const response1 = await postSubmitForm(url1, data_to_send);
    if (response1 && response1.status === 1) {
      setData1(response1.data);
    } else {
      showNotification(response.message, "Error");
    }
    preloader(false);
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const getColumns = (values) => {
    const columns = [
      { dataField: "_id", hidden: true },
      {
        dataField: "_id",
        formatter: (cell, row, rowIndex) => {
          return rowIndex + 1;
        },
        text: props.t("#"),
        headerStyle: (colum, colIndex) => {
          return { width: "50px" };
        },
      },
    ];

    for (let key in values[0]) {
      if (key == "Item Name") {
        columns.push({
          text: key,
          dataField: key,
          headerStyle: (colum, colIndex) => {
            return { width: "300px" };
          },
        });
      } else if (key != "_id") {
        columns.push({
          text: key,
          dataField: key,
          headerStyle: (colum, colIndex) => {
            return { width: "100px" };
          },
          formatter: (cell, row, rowIndex) => {
            return (
              <span>
                {cell.in_unit}
                {". "}
                {cell.in_sub_unit}
              </span>
            );
          },
        });
      }
    }

    return columns;
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName).innerHTML;

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            width: 100%;
            color: #495057;
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }

  const printReport = async () => {
    if (!selectedTimestamp) {
      let url =
        process.env.REACT_APP_BASEURL + "itemorders/mark_arrangement_report";
      let response = await postSubmitForm(url, "");
      if (response.status === 1) {
        loadAllTimeStamps();
        window.print();
      } else {
        showNotification(response.message, "Error");
      }
    } else {
      window.print();
    }
  };
  const handleDownload = async (divName) => {
    console.log(divName, "div");
    const fileName = "item_arrangement_report";
    const exportType = "xls";
    let alldata = [divName];
    let d3;
    let d = alldata.map((i) => {
      console.log(i);
      return i.items;
    });

    let d1 = d.map((i) => {
      console.log(i, "cfgfcg");
      return (d3 = i);
    });

    if (d3) {
      var data = [];
      JSON.parse(JSON.stringify(d3)).forEach(function (v) {
        console.log(v);
        data.push({
          product: v.item,
          Category: v.category,
          SubCategory: v.sub_category,
          Quantity: v.sub_unit
            ? v.quantity.in_unit +
              " " +
              v.unit +
              " " +
              v.quantity.in_sub_unit +
              " " +
              v.sub_unit
            : v.quantity.in_unit + " " + v.unit,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  const handleDownload2 = async () => {
    const fileName = "item_arrangement_report";
    const exportType = exportFromJSON.types.xls;

    if (arrData) {
      var dwdata = [];
      JSON.parse(JSON.stringify(arrData)).forEach(function (v) {
        let obj = {};
        for (let key in v) {
          if (key == "Item Name") {
            obj[key] = v[key];
          } else if (key != "_id") {
            obj[key] = v[key].in_unit + ". " + v[key].in_sub_unit;
          }
        }

        dwdata.push(obj);
      });

      exportFromJSON({ data: dwdata, fileName, exportType });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Arrangement Report")}
            title={props.t("Reports")}
            breadcrumbItem1={props.t("Orders")}
            breadcrumbItem2={props.t("Arrangement Report")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={12}>
                        <AvField
                          name="timestamp"
                          label={props.t("Timestamp")}
                          value={selectedTimestamp}
                          type="select"
                          onChange={(e) => setSelectedTimestamp(e.target.value)}
                        >
                          <option value="">{props.t("Latest")}</option>
                          {allTimeStamps &&
                            allTimeStamps.map((e) => (
                              <option key={e._id} value={e.mark_time}>
                                {moment(e.mark_time).format("DD/MM/YYYY HH:mm")}
                              </option>
                            ))}
                        </AvField>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="d-print-none">
                    <div className="float-right">
                      <Link
                        to="#"
                        onClick={() => {
                          printReport();
                        }}
                        className="btn btn-success waves-effect waves-light mr-2"
                      >
                        <i className="fa fa-print"></i> Print Report
                      </Link>
                    </div>
                  </div>
                  <div id="printableArea">
                    <h3 className="text-center">Production Report</h3>
                    <hr />
                    {data1 &&
                      data1.map((category) => {
                        return (
                          <>
                            <div className="d-print-none">
                              <Link
                                to="#"
                                onClick={() => {
                                  printDiv(category.branch);
                                }}
                                className="btn btn-sm btn-success mr-2"
                              >
                                <i className="fa fa-print"></i> Print Branch
                              </Link>
                            </div>
                            <div className="float-right">
                              <Button
                                type="submit"
                                className="btn btn-sm btn-secondary"
                                onClick={() => {
                                  handleDownload(category);
                                }}
                              >
                                <i className="mdi mdi-file-export"></i>{" "}
                                {props.t("Export CSV")}
                              </Button>
                            </div>
                            <div id={category.branch}>
                              <CardTitle className="for-print-heading">
                                {category.branch}
                              </CardTitle>

                              <ToolkitProvider
                                bootstrap4
                                keyField="item"
                                data={category.items}
                                columns={columns}
                                noDataIndication={props.t(
                                  "No data to display."
                                )}
                                search
                                exportCSV
                              >
                                {(props) => (
                                  <div>
                                    <BootstrapTable
                                      striped
                                      hover
                                      condensed
                                      {...props.baseProps}
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                              <br />
                              <hr />
                            </div>
                          </>
                        );
                      })}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div id="printableArea">
                    <h3 className="text-center">Arrangement Report</h3>
                    <hr />
                    {arrData && (
                      <>
                        <Row>
                          <Col lg={12}>
                            <div className="d-print-none float-right">
                              <Button
                                type="submit"
                                className="btn btn-sm btn-secondary"
                                onClick={() => {
                                  handleDownload2();
                                }}
                              >
                                <i className="mdi mdi-file-export"></i>{" "}
                                {props.t("Export CSV")}
                              </Button>
                            </div>
                          </Col>
                          <Col lg={12}>
                            {" "}
                            <div
                              style={{
                                height: "400px",
                                overflow: "scroll",
                              }}
                            >
                              <ToolkitProvider
                                bootstrap4
                                keyField="_id"
                                data={arrData && arrData}
                                columns={getColumns(arrData)}
                                noDataIndication={props.t(
                                  "No data to display."
                                )}
                                search
                                exportCSV
                              >
                                {(props) => (
                                  <BootstrapTable
                                    striped
                                    hover
                                    condensed
                                    {...props.baseProps}
                                  />
                                )}
                              </ToolkitProvider>
                            </div>
                            <br />
                            <hr />
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(ItemArrangementReport))
);
