import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const FinishedProductNewOrders = (props) => {
  const { SearchBar } = Search;
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("section")) {
      setSelectedSection(urlParams.get("section"));
    }
    loadAllBranches();
    loadAllSections();
  }, []);
  const [confirmApprove, setConfirmApprove] = useState(false);
  const [confirmReject, setConfirmReject] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [selectedSection, setSelectedSection] = useState("All");
  useEffect(() => {
    loadAllOrders();
  }, [selectedSection, selectedBranch]);

  const [allOrders, setAllOrders] = useState([]);
  const loadAllOrders = async () => {
    let url = process.env.REACT_APP_BASEURL + "productorders/getall";
    const response = await postSubmitForm(url, {
      status: ["Placed"],
      branch: selectedBranch,
      section: selectedSection,
    });
    if (response && response.status === 1) {
      // let expandedRows = [];
      // response.data.map((item) => {
      //   expandedRows.push(item._id);
      // });
      setAllOrders(response.data);
      // setExpanded(expandedRows);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [allSections, setAllSections] = useState([]);
  const loadAllSections = async () => {
    let url = process.env.REACT_APP_BASEURL + "productsections/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllSections(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [allBranches, setAllBranches] = useState([]);
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Section"),
      dataField: "section",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Product"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
    },
    {
      dataField: "quantity",
      text: props.t("Order Quantity"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "available",
      text: props.t("Branch Avl. Qty."),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "central_available",
      text: props.t("Central Avl. Qty."),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const [selectedOrder, setSelectedOrder] = useState();
  const handleApproveOrder = async () => {
    let url = process.env.REACT_APP_BASEURL + "productorders/set_approved";

    const response = await postSubmitForm(url, {
      productorder_id: selectedOrder._id,
    });
    //console.log(data_to_send);
    if (response && response.status === 1) {
      loadAllOrders();
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [rejectRemarks, setRejectRemarks] = useState();
  const [rejectModal, setRejectModal] = useState();
  const handleRejectOrder = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "productorders/set_rejected";

    const response = await postSubmitForm(url, {
      productorder_id: selectedOrder._id,
      admin_remarks: rejectRemarks,
    });
    //console.log(data_to_send);
    if (response && response.status === 1) {
      loadAllOrders();
      setRejectModal(!rejectModal);
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };
  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      dataField: "order_id",
      text: props.t("Order ID"),

      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "branch_details.name",
      text: props.t("Branch Name"),

      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),

      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },

      style: { cursor: "pointer" },
    },
    {
      text: props.t("Order Date"),
      dataField: "date_to_process",
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      // formatter: (cell, row) => {
      //   return moment(row.date_to_process).format("DD-MM-YYYY HH:mm");
      // },
      style: { cursor: "pointer" },
    },
    {
      text: props.t("Is Urgent?"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      formatter: (cell, row) => {
        if (row.is_urgent) {
          return props.t("Yes");
        } else {
          return props.t("No");
        }
      },
      style: { cursor: "pointer" },
    },

    {
      dataField: "status",
      text: props.t("Status"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "",
      text: props.t("Action"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <button
              class="btn btn-info btn-sm mr-2"
              onClick={() => {
                setConfirmApprove(true);
                setSelectedOrder(row);
              }}
            >
              {props.t("Accept")}
            </button>
            <button
              class="btn btn-danger btn-sm mr-2"
              onClick={() => {
                setRejectModal(!rejectModal);
                setSelectedOrder(row);
              }}
            >
              {props.t("Reject")}
            </button>
          </>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
  ];
  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      setExpanded([...expanded, row._id]);
    } else {
      setExpanded(expanded.filter((x) => x !== row._id));
    }
  };
  const [expanded, setExpanded] = useState([]);
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.product_details}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
    expanded: expanded,
    onExpand: handleOnExpand,
  };
  const rowStyle = (row, rowIndex) => {
    const style = { backgroundColor: "#fffbb8", fontWeight: "500" };
    // if (row.status === "Completed") {
    //   style.backgroundColor = "#b0ff9e";
    // }
    return style;
  };
  const handleDownload = async () => {
    // const fileName = "appointments";
    // const exportType = "xls";
    // let data_to_send_api = [];
    // allAppointments.map((appointment) => {
    //   data_to_send_api.push(appointment._id);
    // });
    // let data_to_export = [];
    // let url = process.env.REACT_APP_BASEURL + "appointments/toexcel"; //"services/getall";
    // const response = await postSubmitForm(url, {
    //   appointment_ids: data_to_send_api,
    // });
    // if (response && response.status === 1) {
    //   data_to_export = response.data;
    // } else {
    //   showNotification(response.message, "Error");
    // }
    // if (data_to_export) {
    //   var data = JSON.parse(JSON.stringify(data_to_export));
    //   data.forEach(function (v) {
    //     v.client_name = v.client_details.client_name;
    //     v.client_mobile = v.client_details.client_mobile;
    //     v.service_name = v.service_details.name;
    //     v.service_duration = v.service_details.duration;
    //     v.service_cost = v.service_details.cost;
    //     delete v.is_active;
    //     delete v._id;
    //     delete v.createdAt;
    //     delete v.updatedAt;
    //     delete v.__v;
    //     delete v.sold_product_details;
    //     delete v.title;
    //     delete v.client_details;
    //     delete v.service_details;
    //     delete v.invoice_number;
    //     delete v.serviceandsale_id;
    //   });
    //   console.log(data);
    //   exportFromJSON({ data, fileName, exportType });
    // }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Finished Products")}
            title={props.t("Central Kitchen")}
            breadcrumbItem1={props.t("FP Order")}
            breadcrumbItem2={props.t("New Orders")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("New Orders")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t(
                      "View all new orders which are yet to be approved"
                    )}
                  </CardSubtitle>
                  {/* <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row> */}
                  <ToolkitProvider
                    keyField="_id"
                    data={allOrders && allOrders}
                    columns={columns_external}
                    noDataIndication={props.t("No data to display.")}
                    bootstrap4
                    search
                  >
                    {(props_table) => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4}>
                              <Label>{props.t("Search")}</Label>
                              <SearchBar
                                {...props_table.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}>
                              <AvField
                                name="branch"
                                label={props.t("Branch")}
                                type="select"
                                value={selectedBranch}
                                onChange={(e) => {
                                  setSelectedBranch(e.target.value);
                                }}
                              >
                                <option value="All">{props.t("All")}</option>
                                {allBranches &&
                                  allBranches.map((branch) => {
                                    return (
                                      <option value={branch._id}>
                                        {branch.name}
                                      </option>
                                    );
                                  })}
                              </AvField>
                            </Col>
                            <Col lg={4}>
                              <AvField
                                name="section"
                                label={props.t("Section")}
                                type="select"
                                value={selectedSection}
                                onChange={(e) => {
                                  setSelectedSection(e.target.value);
                                }}
                              >
                                <option value="All">{props.t("All")}</option>
                                {allSections &&
                                  allSections.map((section) => {
                                    return (
                                      <option value={section.section}>
                                        {section.section}
                                      </option>
                                    );
                                  })}
                              </AvField>
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          striped
                          hover
                          condensed
                          expandRow={expandRow}
                          rowStyle={rowStyle}
                          {...props_table.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                  {confirmApprove && (
                    <SweetAlert
                      title={props.t("Are you sure?")}
                      warning
                      showCancel
                      cancelBtnText={props.t("No")}
                      confirmBtnText={props.t("Yes")}
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        setConfirmApprove(false);
                        handleApproveOrder();
                      }}
                      onCancel={() => {
                        setConfirmApprove(false);
                      }}
                    >
                      {props.t(
                        "Once you accept this order, it cannot be revoked."
                      )}
                    </SweetAlert>
                  )}
                  <Modal
                    size="md"
                    isOpen={rejectModal}
                    toggle={() => setRejectModal(!rejectModal)}
                  >
                    <ModalHeader toggle={() => setRejectModal(!rejectModal)}>
                      {props.t("Reject Product Order")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  setConfirmReject(true);
                                }}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="admin_remarks"
                                      onChange={(e) => {
                                        setRejectRemarks(e.target.value);
                                      }}
                                      label={props.t("Remarks")}
                                      placeholder={props.t("Enter Remarks")}
                                      type="textarea"
                                      errorMessage="Remarks cannot be empty."
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Submit")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>

                  {confirmReject && (
                    <SweetAlert
                      title={props.t("Are you sure?")}
                      warning
                      showCancel
                      cancelBtnText={props.t("No")}
                      confirmBtnText={props.t("Yes")}
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        setConfirmReject(false);
                        handleRejectOrder();
                      }}
                      onCancel={() => {
                        setConfirmReject(false);
                      }}
                    >
                      {props.t(
                        "Once you reject this order, it cannot be revoked."
                      )}
                    </SweetAlert>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(
  connect(null, {})(withNamespaces()(FinishedProductNewOrders))
);
