import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
  Table,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const PartialDeliveryFromCentralKitchenReport = (props) => {
  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const { SearchBar } = Search;
  const [monthYear, setMonthYear] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [selectedRow, setSelectedRow] = useState([]);
  const [allBranches, setAllBranches] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    // setDateFrom(formatDate(firstDay));
    // setDateTo(formatDate(currentDay));

    setDateFrom(formatDate(firstDay));
    setDateTo(formatDate(currentDay));
 
  }, []);

  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  useEffect(() => {
    if (dateFrom > dateTo) {
      setFilteredOrders([]);
    }
  }, [dateFrom, dateTo]);

  const [allOrders, setAllOrders] = useState([]);
  const loadOrders = async () => {
    const days_diff = moment(dateTo).diff(dateFrom, "days");
    if (days_diff > 30) {
      setFilteredOrders([]);
      showNotification("Total number of days cannot be more than 31.", "Error");
      return false;
    } else if (days_diff < 0) {
      setFilteredOrders([]);
      showNotification("Invalid date range.", "Error");
      return false;
    }
    console.log(days_diff);
    preloader(true);
    let date_from = moment(dateFrom).format("YYYY-MM-DD"),
      date_to = moment(dateFrom)
        .add(days_diff / 4, "days")
        .format("YYYY-MM-DD");
    console.log(date_from, date_to);
    let url = process.env.REACT_APP_BASEURL + "reports/partial_delivery_from_central_kitchen";
    const response1 = await postSubmitForm(url, {
     
      date_from: date_from,
      date_to: date_to,
    });
    if (response1 && response1.status === 1) {
      console.log("response1");
    } else {
      preloader(false);
      showNotification(response1.message, "Error");
      return false;
    }

    //2
    date_from = moment(dateFrom)
      .add(days_diff / 4 + 1, "days")
      .format("YYYY-MM-DD");
    date_to = moment(dateFrom)
      .add(days_diff / 2, "days")
      .format("YYYY-MM-DD");
    console.log(date_from, date_to);
    const response2 = await postSubmitForm(url, {
     
      date_from: date_from,
      date_to: date_to,
    });
    if (response2 && response2.status === 1) {
      console.log("response2");
      preloader(false);
      //setAllOrders(response2.data.concat(response1.data));
    } else {
      preloader(false);
      showNotification(response2.message, "Error");
      return false;
    }

    //3
    date_from = moment(dateFrom)
      .add(days_diff / 2 + 1, "days")
      .format("YYYY-MM-DD");
    date_to = moment(dateFrom)
      .add((days_diff * 3) / 4, "days")
      .format("YYYY-MM-DD");
    console.log(date_from, date_to);
    const response3 = await postSubmitForm(url, {
    
      date_from: date_from,
      date_to: date_to,
    });
    if (response3 && response3.status === 1) {
      console.log("response3");
      preloader(false);
      //setAllOrders(response3.data.concat(response1.data));
    } else {
      preloader(false);
      showNotification(response3.message, "Error");
      return false;
    }
    //4
    date_from = moment(dateFrom)
      .add((days_diff * 3) / 4 + 1, "days")
      .format("YYYY-MM-DD");
    date_to = dateTo;
    console.log(date_from, date_to);
    const response4 = await postSubmitForm(url, {
 
      date_from: date_from,
      date_to: date_to,
    });
    if (response4 && response4.status === 1) {
      console.log("response4");
      preloader(false);
      console.log(
        response1.data.length,
        response2.data.length,
        response3.data.length,
        response4.data.length
      );
      setAllOrders(
        response1.data.concat(response2.data, response3.data, response4.data)
      );
    } else {
      preloader(false);
      showNotification(response4.message, "Error");
      return false;
    }


  };

  const [orderStatus, setOrderStatus] = useState("All");
  const [filteredOrders, setFilteredOrders] = useState([]);
  useEffect(() => {
    if (allOrders.length > 0) {
      if (orderStatus === "All") {
        setFilteredOrders([...allOrders]);
      } else {
        let arr = allOrders.filter((order) => order.status === orderStatus);
        setFilteredOrders(arr);
      }
    }
  }, [allOrders, orderStatus]);

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const [purchaseModal, setPurchaseModal] = useState(false);
  const [directPurchaseModal, setDirectPurchaseModal] = useState(false);
  const [productModal, setProductModal] = useState(false);
  const [itemModal, setItemModal] = useState(false);

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },

    {
      text: props.t("Order"),
      dataField: "order_id",
      formatter: (cell, row, rowIndex) => {
        return (
          <span
            className="text-info mr-2"
            title="View Details"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedRow(row);
              setOrderDetailsModal(true);
            }}
          >
            {row.order_id}
          </span>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "branch_details.name",
      text: props.t("Branch Name"),

      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      filter: selectFilter({
        options: () => {
          const unique = [
            ...new Map(
              [
                ...new Set(
                  allOrders.map(({ branch_details: value }) => ({
                    value: value.name,
                    label: value.name,
                  }))
                ),
              ].map((item) => [item["value"], item])
            ).values(),
          ];

          unique.sort(dynamicSort("label"));
          return unique;
        },
      }),
      style: { cursor: "pointer" },
    },
    {
      dataField: "remarks",
      text: props.t("Branch Remarks"),

      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "admin_remarks",
      text: props.t("Admin Remarks"),

      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },

      style: { cursor: "pointer" },
    },
    {
      text: props.t("Status"),
      dataField: "status",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      filter: selectFilter({
        options: () => {
          const unique = [
            ...new Map(
              [
                ...new Set(
                  allOrders.map(({ status: value }) => ({
                    value: value,
                    label: value,
                  }))
                ),
              ].map((item) => [item["value"], item])
            ).values(),
          ];

          unique.sort(dynamicSort("label"));
          return unique;
        },
      }),
    },
    {
      text: props.t("Date"),
      dataField: "date_to_process",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      formatter: (cell, row, rowIndex) => {
        return moment(row.date_to_process).format("DD/MM/YYYY");
      },
    },
  ];

  const [orderDetailsModal, setOrderDetailsModal] = useState();

  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      setExpanded([...expanded, row._id]);
    } else {
      setExpanded(expanded.filter((x) => x !== row._id));
    }
  };
  const [expanded, setExpanded] = useState([]);

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.item_details}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
    expanded: expanded,
    onExpand: handleOnExpand,
  };

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.status === "Completed") {
      style.backgroundColor = "#b0ff9e";
    } else if (row.status === "Booked") {
      style.backgroundColor = "#9ec3ff";
    } else if (row.status === "In Process") {
      style.backgroundColor = "#fffd9e";
    } else if (row.status === "Cancelled") {
      style.backgroundColor = "#ff9e9e";
    }

    return style;
  };

  const handleDownload = async () => {
    const fileName = "partial_delivery_from_central_kitchen_report";
    const exportType = "xls";

    let data_to_export = filteredOrders;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Order: v.order_id,
          Branch_Name: v.branch_details.name,
          Branch_Remarks: v.remarks,
          Admin_Remarks: v.admin_remarks,
          Status: v.status,
          Date: moment(v.date_to_process).format("DD/MM/YYYY"),
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }

  const columns_ordered_products = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Section"),
      dataField: "section",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Product"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
    },
    {
      dataField: "quantity",
      text: props.t("Order Quantity"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "available",
      text: props.t("Avail. Quantity"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];

  const columns_delivered_products = [
    {
      text: props.t("Section"),
      dataField: "section",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Product"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Del. Qty."),
      dataField: "delivered_quantity",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      text: props.t("Rec. Qty."),
      dataField: "received_quantity",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb
            alternateTitle={props.t(
              "Partial Delivery From Central Kitchen Report"
            )}
            title={props.t("Reports")}
            breadcrumbItem={props.t(
              "Partial Delivery From Central Kitchen Report"
            )}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={4}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={4}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={4}>
                        <Button
                          type="submit"
                          className="btn btn-md btn-success mt-4"
                          onClick={() => {
                            if (dateFrom && dateTo) {
                              loadOrders();
                            }
                          }}
                        >
                          <i className="mdi mdi-checkbox-marked-circle"></i>{" "}
                          {props.t("Check")}
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"   "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <div id="printDiv">
                    <CardTitle className="text-center">
                      {props.t("Partial Delivery From Central Kitchen Report")}
                    </CardTitle>
                    <ToolkitProvider
                      keyField="_id"
                      data={filteredOrders && filteredOrders}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      bootstrap4
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}></Col>
                            <Col lg={4}></Col>
                          </Row>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            rowStyle={rowStyle}
                            filter={filterFactory()}
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>

                  <Modal
                    size="lg"
                    isOpen={orderDetailsModal}
                    toggle={() => setOrderDetailsModal(!orderDetailsModal)}
                  >
                    <ModalHeader
                      toggle={() => setOrderDetailsModal(!orderDetailsModal)}
                    >
                      {props.t("Order Details")}
                    </ModalHeader>
                    <ModalBody>
                      <>
                        <Row>
                          <Col lg={12}>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col lg={12} md={12}>
                                    <Label>Ordered Products</Label>
                                  </Col>

                                  <Col lg={12}>
                                    <BootstrapTable
                                      bootstrap4
                                      keyField="name"
                                      data={
                                        selectedRow &&
                                        selectedRow.product_details
                                      }
                                      columns={columns_ordered_products}
                                      noDataIndication="No data to display."
                                      striped
                                      hover
                                      condensed
                                    />
                                    <hr />
                                  </Col>

                                  {selectedRow &&
                                    selectedRow.delivery_details &&
                                    selectedRow.delivery_details.length > 0 ? (
                                    <>
                                      <Col lg={12} md={12}>
                                        <br />
                                        <Label>
                                          <b>
                                            {props.t("Delivered Products")}{" "}
                                          </b>
                                        </Label>
                                      </Col>
                                      <Col lg={12} className="mb-4">
                                        <BootstrapTable
                                          bootstrap4
                                          keyField="name"
                                          key="name"
                                          data={selectedRow.delivery_details}
                                          columns={columns_delivered_products}
                                          noDataIndication="No data to display."
                                          striped
                                          hover
                                          condensed
                                        />
                                        <hr />
                                      </Col>
                                    </>
                                  ) : null}
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => { };
export default withRouter(
  connect(
    mapStatetoProps,
    {}
  )(withNamespaces()(PartialDeliveryFromCentralKitchenReport))
);
