import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import showNotification from "../../helpers/show_notification";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import exportFromJSON from "export-from-json";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import TagsInput from "react-tagsinput";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { postSubmitForm } from "../../helpers/forms_helper";
// actions

const AllItems = (props) => {
  const { SearchBar } = Search;
  const refContainer = useRef(null);
  const refNewCategoryForm = useRef(null);

  useEffect(() => {
    loadAllCategories();
    loadAllUnits();
    loadAllItems();
    loadAllBrands();
  }, []);

  const [allItems, setAllItems] = useState([]);
  const [allItemsFiltered, setAllItemsFiltered] = useState([]);
  const loadAllItems = async () => {
    let url = process.env.REACT_APP_BASEURL + "items/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllItems(response.data);
      setAllItemsFiltered(response.data);
      setSelectedCategoryFilter("");
      setSelectedSubCategoryFilter("");
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const loadAllCategories = async () => {
    let url = process.env.REACT_APP_BASEURL + "itemcategories/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllCategories(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [allBrands, setAllBrands] = useState([]);
  const loadAllBrands = async () => {
    let url = process.env.REACT_APP_BASEURL + "brands/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBrands(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [selectedBrand, setSelectedBrand] = useState();
  const [newBrandModal, setNewBrandModal] = useState();
  const handleAddNewBrand = async (e, v) => {
    const object = {
      name: v.name,
    };
    let url = process.env.REACT_APP_BASEURL + "brands/insert";
    const response = await postSubmitForm(url, object);
    if (response && response.status === 1) {
      loadAllBrands();
      setSelectedBrand(v.name);
      setNewBrandModal(!newBrandModal);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [allUnits, setAllUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState();
  const [allSubUnits, setAllSubUnits] = useState([]);
  const [selectedSubUnit, setSelectedSubUnit] = useState();
  const loadAllUnits = async () => {
    let url = process.env.REACT_APP_BASEURL + "itemunits/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllUnits(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [newUnitModal, setNewUnitModal] = useState();
  const [newSubUnits, setNewSubUnits] = useState([]);
  const handleAddNewUnit = async (e, v) => {
    const object = {
      unit: v.name,
      sub_unit: newSubUnits,
    };
    let url = process.env.REACT_APP_BASEURL + "itemunits/insert";
    const response = await postSubmitForm(url, object);
    if (response && response.status === 1) {
      loadAllUnits();
      setSelectedUnit(v.name);
      setSelectedSubUnit();
      setAllSubUnits(newSubUnits);
      setNewSubUnits([]);
      setNewUnitModal(!newUnitModal);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [newSubUnitModal, setNewSubUnitModal] = useState();
  const handleAddNewSubUnit = async (e, v) => {
    try {
      const object = {
        unit: selectedUnit,
        sub_unit: v.name,
      };
      let url = process.env.REACT_APP_BASEURL + "itemunits/insert_subunit";
      let response = await postSubmitForm(url, object);
      if (response.status === 1) {
        loadAllUnits();
        let all_sub_unit = [...allSubUnits];
        all_sub_unit.push(v.name);
        setAllSubUnits(all_sub_unit);
        setSelectedSubUnit(v.name);
        setNewSubUnitModal(!newSubUnitModal);
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };

  const [newCategoryModal, setNewCategoryModal] = useState();
  const [newSubCategories, setNewSubCategories] = useState([]);
  const handleAddNewCategory = async (e, v) => {
    try {
      const object = {
        category: v.name,
        sub_category: newSubCategories,
      };
      let url = process.env.REACT_APP_BASEURL + "itemcategories/insert";
      let response = await postSubmitForm(url, object);
      if (response.status === 1) {
        loadAllCategories();
        setSelectedCategory(v.name);
        setAllSubCategories(newSubCategories);
        setNewSubCategories([]);
        setNewCategoryModal(!newCategoryModal);
        refNewCategoryForm.current.reset();
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };

  const [newSubCategoryModal, setNewSubCategoryModal] = useState();
  const handleAddNewSubCategory = async (e, v) => {
    try {
      const object = {
        category: selectedCategory,
        sub_category: v.name,
      };
      let url =
        process.env.REACT_APP_BASEURL + "itemcategories/insert_subcategory";
      let response = await postSubmitForm(url, object);
      if (response.status === 1) {
        loadAllCategories();
        let all_sub_cat = [...allSubCategories];
        all_sub_cat.push(v.name);
        setAllSubCategories(all_sub_cat);
        setSelectedSubCategory(v.name);
        setNewSubCategoryModal(!newSubCategoryModal);
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };

  const handleItemStatusUpdate = async (row) => {
    let url = process.env.REACT_APP_BASEURL + "items/activate_deactivate";

    const response = await postSubmitForm(url, {
      item_id: row._id,
      is_active: !row.is_active,
    });

    if (response && response.status === 1) {
      loadAllItems();
    } else {
      showNotification(response.message, props.t("Error"));
    }
  };
  function showStatusFormatter(cell, row) {
    // if (row.is_active) {
    return (
      <>
        <div className="custom-control custom-switch mb-2" dir="ltr">
          <input
            type="checkbox"
            title="Click to change status."
            className="custom-control-input"
            id={"customSwitch1" + row._id}
            checked={row.is_active}
          />
          <label
            title="Click to change status."
            className="custom-control-label"
            htmlFor={"customSwitch1" + row._id}
            style={{ "font-weight": "100", cursor: "pointer" }}
            onClick={() => {
              handleItemStatusUpdate(row);
            }}
          ></label>
        </div>

        {row.is_active ? (
          <span class="font-size-12 badge-soft-success badge badge-success badge-pill">
            {props.t("Active")}
          </span>
        ) : (
          <span class="font-size-12 badge-soft-danger badge badge-danger badge-pill">
            {props.t("Inactive")}
          </span>
        )}
      </>
    );
  }

  const [editItemModal, setEditItemModal] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        return rowNumber;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
    },
    {
      text: props.t("Description"),
      dataField: "description",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
    },
    {
      dataField: "category",
      text: props.t("Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "sub_category",
      text: props.t("Sub-Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "threshold",
      text: props.t("Centre Store Threshold"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Unit"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "sub_unit",
      text: props.t("Sub-Unit"),
      sort: true,
      formatter: (cell, row) => {
        if (row.sub_unit) {
          return <span>{row.sub_unit}</span>;
        } else {
          return <span>{props.t("NA")}</span>;
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "formula",
      text: props.t("Formula"),
      formatter: (cell, row) => {
        if (row.sub_unit) {
          return (
            <span>
              {"1 " + row.unit + " = " + row.formula + " " + row.sub_unit}
            </span>
          );
        } else {
          return <span>{props.t("NA")}</span>;
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Status"),
      formatter: showStatusFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Action"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <span className="text-info">
            <i
              className="bx bxs-edit font-size-15"
              title="Click to Edit"
              style={{ cursor: "pointer" }}
              onClick={() => {
                let categories = [...extraData[0]];
                let arr = categories.filter(
                  (category) => category.category === row.category
                );
                if (arr.length > 0) {
                  setAllSubCategories(arr[0].sub_category);
                }

                let units = [...extraData[1]];
                let arr_units = units.filter((unit) => unit.unit === row.unit);
                setAllSubUnits(arr_units[0].sub_unit);

                setSelectedBrand(row.brand);
                setSelectedCategory(row.category);
                setSelectedSubCategory(row.sub_category);
                setSelectedUnit(row.unit);
                setSelectedSubUnit(row.sub_unit);
                setEditItemModal(!editItemModal);
                setSelectedItem(row);
              }}
            ></i>
          </span>
        );
      },
      formatExtraData: [allCategories, allUnits],
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} - {to} of {size} records.
    </span>
  );

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const pagination_options = {
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page);
      setPageSize(sizePerPage);
    },
    paginationSize: 50,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: allItems && allItems.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };

  const handleValidUpdate = async (e, v) => {
    try {
      if (v.sub_unit === "") {
        delete v.sub_unit;
      }
      v.id = selectedItem._id;
      let url = process.env.REACT_APP_BASEURL + "Items/update";
      let response = await postSubmitForm(url, v);
      if (response.status === 1) {
        setEditItemModal(!editItemModal);
        showNotification(response.message, "Success");
        refContainer.current.reset();
        loadAllItems();
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };

  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState();
  const [allSubCategoriesFilter, setAllSubCategoriesFilter] = useState([]);
  const [selectedSubCategoryFilter, setSelectedSubCategoryFilter] = useState();
  useEffect(() => {
    if (selectedCategoryFilter) {
      let arr = JSON.parse(JSON.stringify(allItems));
      if (selectedSubCategoryFilter) {
        let new_arr = arr.filter(
          (item) =>
            item.category === selectedCategoryFilter &&
            item.sub_category === selectedSubCategoryFilter
        );
        setAllItemsFiltered(new_arr);
      } else {
        let new_arr = arr.filter(
          (prod) => prod.category === selectedCategoryFilter
        );
        setAllItemsFiltered(new_arr);
      }
    } else {
      setAllItemsFiltered(allItems);
    }
  }, [selectedCategoryFilter, selectedSubCategoryFilter]);

  const handleDownload = async () => {
    const fileName = "all_items";
    const exportType = "xls";

    let data_to_export = allItemsFiltered;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          name: v.name,
          decription: v.description,
          category: v.category,
          sub_category: v.sub_category,
          brand: v.brand,
          unit: v.unit,
          sub_unit: v.sub_unit,
          formula: v.formula,
          remarks: v.remarks,
          threshold: v.threshold,
          available_in_unit: v.available.in_unit,
          available_in_sub_unit: v.available.in_sub_unit,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Items")}
            breadcrumbItem={props.t("All Items")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>

                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <CardTitle>{props.t("All Items")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View/Update all existing items here")}
                  </CardSubtitle>
                  <ToolkitProvider
                    keyField="_id"
                    data={allItemsFiltered && allItemsFiltered}
                    columns={columns}
                    search
                  >
                    {(propst) => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>

                            <Col lg={8} className="text-right">
                              <Row>
                                <Col lg={6}>
                                  <AvField
                                    name="category_filter"
                                    type="select"
                                    value={selectedCategoryFilter}
                                    onChange={(e) => {
                                      setSelectedCategoryFilter(e.target.value);
                                      setSelectedSubCategoryFilter("");
                                      if (e.target.value) {
                                        var categories = JSON.parse(
                                          JSON.stringify(allCategories)
                                        );
                                        let arr = categories.filter(
                                          (category) =>
                                            category.category === e.target.value
                                        );
                                        setAllSubCategoriesFilter(
                                          arr[0].sub_category
                                        );
                                      } else {
                                        setAllSubCategoriesFilter([]);
                                      }
                                    }}
                                  >
                                    <option value="">
                                      {props.t("All Categories")}
                                    </option>
                                    {allCategories &&
                                      allCategories.map((category) => {
                                        return (
                                          <option value={category.category}>
                                            {category.category}
                                          </option>
                                        );
                                      })}
                                  </AvField>
                                </Col>
                                <Col lg={6}>
                                  <AvField
                                    name="sub_category_filter"
                                    type="select"
                                    errorMessage={props.t(
                                      "Category cannot be empty"
                                    )}
                                    onChange={(e) =>
                                      setSelectedSubCategoryFilter(
                                        e.target.value
                                      )
                                    }
                                    value={selectedSubCategoryFilter}
                                  >
                                    <option value="">
                                      {props.t("All Sub-Categories")}
                                    </option>
                                    {allSubCategoriesFilter &&
                                      allSubCategoriesFilter.map(
                                        (sub_category) => {
                                          return (
                                            <option value={sub_category}>
                                              {sub_category}
                                            </option>
                                          );
                                        }
                                      )}
                                  </AvField>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          pagination={paginationFactory(pagination_options)}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>

                  <Modal
                    size="lg"
                    isOpen={editItemModal}
                    toggle={() => setEditItemModal(!editItemModal)}
                  >
                    <ModalHeader
                      toggle={() => setEditItemModal(!editItemModal)}
                    >
                      {props.t("Update Item")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={handleValidUpdate}
                                ref={refContainer}
                              >
                                <Row>
                                  <Col lg={6}>
                                    <Row>
                                      <Col lg={12}>
                                        <AvField
                                          name="name"
                                          label={props.t("Name")}
                                          value={
                                            selectedItem && selectedItem.name
                                          }
                                          placeholder={props.t(
                                            "Enter Item Name"
                                          )}
                                          type="text"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          errorMessage={props.t(
                                            "Name cannot be empty"
                                          )}
                                        />
                                      </Col>
                                      <Col lg={12}>
                                        <Row>
                                          <Col lg={8}>
                                            <AvField
                                              name="category"
                                              label={props.t("Category")}
                                              type="select"
                                              value={selectedCategory}
                                              validate={{
                                                required: { value: true },
                                              }}
                                              errorMessage={props.t(
                                                "Category cannot be empty"
                                              )}
                                              onChange={(e) => {
                                                setSelectedCategory(
                                                  e.target.value
                                                );
                                                if (e.target.value) {
                                                  var categories = JSON.parse(
                                                    JSON.stringify(
                                                      allCategories
                                                    )
                                                  );

                                                  let arr = categories.filter(
                                                    (category) =>
                                                      category.category ===
                                                      e.target.value
                                                  );
                                                  setAllSubCategories(
                                                    arr[0].sub_category
                                                  );
                                                } else {
                                                  setAllSubCategories([]);
                                                }
                                              }}
                                            >
                                              <option value="">
                                                {props.t("--Select Category--")}
                                              </option>
                                              {allCategories &&
                                                allCategories.map(
                                                  (category) => {
                                                    return (
                                                      <option
                                                        value={
                                                          category.category
                                                        }
                                                      >
                                                        {category.category}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </AvField>
                                          </Col>
                                          <Col lg={4}>
                                            <Label>&nbsp;</Label>
                                            <br />
                                            <Link
                                              class="btn btn-success waves-effect waves-light btn-sm"
                                              onClick={() => {
                                                setNewCategoryModal(
                                                  !newCategoryModal
                                                );
                                              }}
                                            >
                                              <i class="mdi mdi-file-plus"></i>{" "}
                                              {props.t("Add New")}
                                            </Link>
                                          </Col>
                                        </Row>
                                      </Col>

                                      <Col lg={12}>
                                        <Row>
                                          <Col lg={8}>
                                            <AvField
                                              name="sub_category"
                                              label={props.t("Sub-Category")}
                                              type="select"
                                              required
                                              errorMessage={props.t(
                                                "Sub-Category cannot be empty"
                                              )}
                                              value={selectedSubCategory}
                                              onChange={(e) => {
                                                setSelectedSubCategory(
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              <option value="">
                                                {props.t(
                                                  "--Select Sub-Category--"
                                                )}
                                              </option>
                                              {allSubCategories &&
                                                allSubCategories.map(
                                                  (subcategory) => {
                                                    return (
                                                      <option
                                                        value={subcategory}
                                                      >
                                                        {subcategory}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </AvField>
                                          </Col>
                                          <Col lg={4}>
                                            <Label>&nbsp;</Label>
                                            <br />
                                            <Link
                                              class="btn btn-success waves-effect waves-light btn-sm"
                                              onClick={() => {
                                                if (selectedCategory) {
                                                  setNewSubCategoryModal(
                                                    !newSubCategoryModal
                                                  );
                                                } else {
                                                  showNotification(
                                                    props.t(
                                                      "Select a category first!"
                                                    ),
                                                    props.t("Error")
                                                  );
                                                }
                                              }}
                                            >
                                              <i class="mdi mdi-file-plus"></i>{" "}
                                              {props.t("Add New")}
                                            </Link>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col lg={12}>
                                        <AvField
                                          name="threshold"
                                          value={
                                            selectedItem &&
                                            selectedItem.threshold.toString()
                                          }
                                          label={props.t("Centre Threshold")}
                                          placeholder={props.t(
                                            "Enter Threshold Quantity for Centre"
                                          )}
                                          type="text"
                                          validate={{
                                            required: {
                                              value: true,
                                              errorMessage: props.t(
                                                "Threshold cannot be empty."
                                              ),
                                            },
                                            pattern: {
                                              value: "^[0-9]+$",
                                              errorMessage: props.t(
                                                "Only numbers are allowed."
                                              ),
                                            },
                                          }}
                                        />
                                      </Col>
                                      <Col lg={12}>
                                        <AvField
                                          name="remarks"
                                          label={props.t("Remarks")}
                                          placeholder={props.t("Enter Remarks")}
                                          type="textarea"
                                          value={
                                            selectedItem && selectedItem.remarks
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </Col>{" "}
                                  <Col lg={6}>
                                    <Row>
                                      <Col lg={12}>
                                        <Row>
                                          <Col lg={8}>
                                            <AvField
                                              name="brand"
                                              label={props.t("Brand")}
                                              type="select"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              errorMessage={props.t(
                                                "Brand cannot be empty"
                                              )}
                                              value={selectedBrand}
                                              onChange={(e) => {
                                                setSelectedBrand(
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              {" "}
                                              <option value="">
                                                {props.t("--Select Brand--")}
                                              </option>
                                              {allBrands &&
                                                allBrands.map((brand) => {
                                                  return (
                                                    <option value={brand.name}>
                                                      {brand.name}
                                                    </option>
                                                  );
                                                })}
                                            </AvField>
                                          </Col>
                                          <Col lg={4}>
                                            <Label>&nbsp;</Label>
                                            <br />
                                            <Link
                                              class="btn btn-success waves-effect waves-light btn-sm"
                                              onClick={() => {
                                                setNewBrandModal(
                                                  !newBrandModal
                                                );
                                              }}
                                            >
                                              <i class="mdi mdi-file-plus"></i>{" "}
                                              {props.t("Add New")}
                                            </Link>
                                          </Col>
                                        </Row>
                                      </Col>

                                      <Col lg={12}>
                                        <Row>
                                          <Col lg={8}>
                                            <AvField
                                              name="unit"
                                              label={props.t("Default Unit")}
                                              type="select"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              errorMessage={props.t(
                                                "Please Select A measuring unit"
                                              )}
                                              value={selectedUnit}
                                              onChange={(e) => {
                                                setSelectedUnit(e.target.value);

                                                setSelectedSubUnit();
                                                if (e.target.value) {
                                                  var categories = JSON.parse(
                                                    JSON.stringify(allUnits)
                                                  );
                                                  let arr = categories.filter(
                                                    (unit) =>
                                                      unit.unit ===
                                                      e.target.value
                                                  );
                                                  setAllSubUnits(
                                                    arr[0].sub_unit
                                                  );
                                                } else {
                                                  setAllSubUnits([]);
                                                }
                                              }}
                                            >
                                              {" "}
                                              <option value="">
                                                {props.t("--Select Unit--")}
                                              </option>
                                              {allUnits &&
                                                allUnits.map((unit) => {
                                                  return (
                                                    <option value={unit.unit}>
                                                      {unit.unit}
                                                    </option>
                                                  );
                                                })}
                                            </AvField>
                                          </Col>
                                          <Col lg={4}>
                                            <Label>&nbsp;</Label>
                                            <br />
                                            <Link
                                              class="btn btn-success waves-effect waves-light btn-sm"
                                              onClick={() => {
                                                setNewUnitModal(!newUnitModal);
                                              }}
                                            >
                                              <i class="mdi mdi-file-plus"></i>{" "}
                                              {props.t("Add New")}
                                            </Link>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col lg={12}>
                                        <Row>
                                          <Col lg={8}>
                                            <AvField
                                              name="sub_unit"
                                              label={props.t("Sub-Unit")}
                                              type="select"
                                              value={selectedSubUnit}
                                              onChange={(e) => {
                                                setSelectedSubUnit(
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              <option value="">
                                                {props.t("--Select Sub-Unit--")}
                                              </option>
                                              {allSubUnits &&
                                                allSubUnits.map((subunit) => {
                                                  return (
                                                    <option value={subunit}>
                                                      {subunit}
                                                    </option>
                                                  );
                                                })}
                                            </AvField>
                                          </Col>
                                          <Col lg={4}>
                                            <Label>&nbsp;</Label>
                                            <br />
                                            <Link
                                              class="btn btn-success waves-effect waves-light btn-sm"
                                              onClick={() => {
                                                if (selectedUnit) {
                                                  setNewSubUnitModal(
                                                    !newSubUnitModal
                                                  );
                                                } else {
                                                  showNotification(
                                                    props.t(
                                                      "Select a unit first!"
                                                    ),
                                                    props.t("Error")
                                                  );
                                                }
                                              }}
                                            >
                                              <i class="mdi mdi-file-plus"></i>{" "}
                                              {props.t("Add New")}
                                            </Link>
                                          </Col>
                                        </Row>
                                      </Col>
                                      {selectedSubUnit && (
                                        <Col lg={12}>
                                          <Row>
                                            <Col lg={12}>
                                              <br />
                                            </Col>
                                            <Col lg={4}>
                                              <Label>
                                                {"1 " + selectedUnit + " = "}
                                              </Label>
                                            </Col>
                                            <Col lg={3}>
                                              <AvField
                                                name="formula"
                                                type="text"
                                                value={
                                                  selectedItem &&
                                                  selectedItem.formula
                                                }
                                                validate={{
                                                  required: {
                                                    value: true,
                                                    errorMessage: props.t(
                                                      "Formula cannot be empty."
                                                    ),
                                                  },
                                                  pattern: {
                                                    value: "^[0-9]+$",
                                                    errorMessage: props.t(
                                                      "Only numbers are allowed."
                                                    ),
                                                  },
                                                }}
                                              />
                                            </Col>
                                            <Col lg={5}>
                                              <Label>
                                                {" " + selectedSubUnit}
                                              </Label>
                                            </Col>
                                          </Row>
                                        </Col>
                                      )}
                                      <Col lg={12}>
                                        <AvField
                                          name="description"
                                          label={props.t("Description")}
                                          value={
                                            selectedItem &&
                                            selectedItem.description
                                          }
                                          placeholder={props.t(
                                            "Enter Description"
                                          )}
                                          type="text"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Submit")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                {/* <Button type="submit">Submit</Button> */}
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="md"
                    isOpen={newBrandModal}
                    toggle={() => setNewBrandModal(!newBrandModal)}
                  >
                    <ModalHeader
                      toggle={() => setNewBrandModal(!newBrandModal)}
                    >
                      {props.t("Add new brand")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewBrand(e, v);
                                }}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Name of Brand") + " *"}
                                      placeholder={props.t("Enter Brand Name")}
                                      type="text"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: props.t(
                                            "Name cannot be empty."
                                          ),
                                        },
                                      }}
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>

                  <Modal
                    size="md"
                    isOpen={newUnitModal}
                    toggle={() => setNewUnitModal(!newUnitModal)}
                  >
                    <ModalHeader toggle={() => setNewUnitModal(!newUnitModal)}>
                      {props.t("Add new unit")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewUnit(e, v);
                                }}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Name of Unit") + " *"}
                                      placeholder={props.t("Enter Unit Name")}
                                      type="text"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: props.t(
                                            "Name cannot be empty."
                                          ),
                                        },
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <Label>{props.t("Sub-Unit")}</Label> (
                                    {props.t("press ENTER/TAB to add")})
                                    <TagsInput
                                      value={newSubUnits}
                                      onChange={(tags) => {
                                        setNewSubUnits(tags);
                                      }}
                                      onlyUnique={true}
                                      inputProps={{
                                        className: "react-tagsinput-input",
                                        placeholder: props.t("Add..."),
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="md"
                    isOpen={newSubUnitModal}
                    toggle={() => setNewSubUnitModal(!newSubUnitModal)}
                  >
                    <ModalHeader
                      toggle={() => setNewSubUnitModal(!newSubUnitModal)}
                    >
                      {props.t("Add new sub-unit")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewSubUnit(e, v);
                                }}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Sub-Unit")}
                                      placeholder={props.t(
                                        "Enter Sub-Unit Name"
                                      )}
                                      type="text"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: props.t(
                                            "Name cannot be empty"
                                          ),
                                        },
                                      }}
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>

                  <Modal
                    size="md"
                    isOpen={newCategoryModal}
                    toggle={() => setNewCategoryModal(!newCategoryModal)}
                  >
                    <ModalHeader
                      toggle={() => setNewCategoryModal(!newCategoryModal)}
                    >
                      {props.t("Add new category")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewCategory(e, v);
                                }}
                                ref={refNewCategoryForm}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Category")}
                                      placeholder={props.t(
                                        "Enter Category Name"
                                      )}
                                      type="text"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: props.t(
                                            "Name cannot be empty"
                                          ),
                                        },
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <Label>
                                      {props.t("Sub-Category Name")}
                                    </Label>{" "}
                                    ({props.t("press ENTER/TAB to add")})
                                    <TagsInput
                                      value={newSubCategories}
                                      onChange={(tags) => {
                                        setNewSubCategories(tags);
                                      }}
                                      onlyUnique={true}
                                      inputProps={{
                                        className: "react-tagsinput-input",
                                        placeholder: props.t("Add..."),
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="md"
                    isOpen={newSubCategoryModal}
                    toggle={() => setNewSubCategoryModal(!newSubCategoryModal)}
                  >
                    <ModalHeader
                      toggle={() =>
                        setNewSubCategoryModal(!newSubCategoryModal)
                      }
                    >
                      {props.t("Add new sub-category")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewSubCategory(e, v);
                                }}
                                ref={refNewCategoryForm}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Sub-Category")}
                                      placeholder={props.t(
                                        "Enter Sub-Category Name"
                                      )}
                                      type="text"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: props.t(
                                            "Name cannot be empty"
                                          ),
                                        },
                                      }}
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(connect(null, {})(withNamespaces()(AllItems)));
