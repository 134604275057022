import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";

const TransferInventory = (props) => {
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  useEffect(() => {
    loadTransfers();
  }, []);

  const [allTransfers, setAllTransfers] = useState([]);
  const [allTransfersFiltered, setAllTransfersFiltered] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const loadTransfers = async () => {
    let url =
      process.env.REACT_APP_BASEURL + "producttransferorders/get_for_branch";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllTransfers(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  useEffect(() => {
    if (selectedStatus) {
      if (selectedStatus === "All") {
        setAllTransfersFiltered(allTransfers);
      } else {
        var data = JSON.parse(JSON.stringify(allTransfers));
        setAllTransfersFiltered(
          data.filter((transfer) => transfer.status === selectedStatus)
        );
      }
    }
  }, [allTransfers, selectedStatus]);

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const [detailsModal, setDetailsModal] = useState();
  const [selectedTransfer, setSelectedTransfer] = useState();
  const [selectedTransfer_Products, setSelectedTransfer_Products] = useState(
    []
  );
  const [confirmReceive, setConfirmReceive] = useState(false);
  const [confirmRelease, setConfirmRelease] = useState(false);
  function editFormatter(cell, row) {
    if (row.action === "release") {
      if (row.status === "Placed") {
        return (
          <button
            class="btn btn-info btn-sm mr-2"
            onClick={() => {
              setConfirmRelease(true);
              setSelectedTransfer(row);
            }}
          >
            {props.t("Release")}
          </button>
        );
      } else {
        return <span>No Action Required</span>;
      }
    } else {
      if (row.status === "Released") {
        return (
          <button
            class="btn btn-info btn-sm mr-2"
            onClick={() => {
              setConfirmReceive(true);
              setSelectedTransfer(row);
            }}
          >
            {props.t("Receive")}
          </button>
        );
      } else {
        return <span>No Action Required</span>;
      }
    }
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
      csvExport: false,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      csvExport: false,
    },
    {
      dataField: "order_id",
      text: props.t("Transfer ID"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Branch Name"),
      dataField: "name",
      formatter: (cell, row) => {
        if (row.action === "release") {
          return row.branch_to.name;
        } else {
          return row.branch_from.name;
        }
      },
      csvFormatter: (cell, row) => {
        if (row.action === "release") {
          return row.branch_to.name;
        } else {
          return row.branch_from.name;
        }
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "createdAt",
      text: props.t("Date"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "status",
      text: props.t("Status"),
      formatter: (cell, row) => {
        if (row.status === "Released") {
          return (
            <span className="font-size-12 badge-soft-info badge badge-success badge-pill mr-1 mb-1">
              {row.status}
            </span>
          );
        } else if (row.status === "Received") {
          return (
            <span className="font-size-12 badge-soft-success badge badge-success badge-pill mr-1 mb-1">
              {row.status}
            </span>
          );
        } else {
          return (
            <span className="font-size-12 badge-soft-danger badge badge-success badge-pill mr-1 mb-1">
              {row.status}
            </span>
          );
        }
      },
      csvFormatter: (cell, row) => cell,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Details"),
      formatter: (cell, row) => {
        return (
          <span className="text-info">
            <i
              className="mdi mdi-text-box-search font-size-15"
              title="View Details"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setDetailsModal(!detailsModal);
                setSelectedTransfer_Products(row.product_details);
              }}
            ></i>
          </span>
        );
      },
      csvExport: false,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      csvExport: false,
    },
  ];

  const columns_products = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      text: props.t("Section"),
      dataField: "section",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "quantity",
      text: props.t("Quantity"),
      formatter: (cell, row) => {
        return row.quantity + " " + row.unit;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },

    {
      dataField: "selling_price",
      text: props.t("Selling Price"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Expiry Date"),
      sort: false,
      formatter: (cell, row) => {
        return moment(row.expiry_date).format("DD/MM/YYYY HH:mm");
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    // {
    //   dataField: "",
    //   text: props.t("Entry Date"),
    //   sort: false,
    //   formatter: (cell, row) => {
    //     return moment(row.createdAt).format("DD/MM/YYYY HH:mm");
    //   },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "8%" };
    //   },
    // },
  ];
  const handleReceiveTransfer = async () => {
    let url = process.env.REACT_APP_BASEURL + "producttransferorders/receive";

    const response = await postSubmitForm(url, {
      producttransferorder_id: selectedTransfer._id,
    });
    //console.log(data_to_send);
    if (response && response.status === 1) {
      loadTransfers();
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };
  const handleReleaseTransfer = async () => {
    let url = process.env.REACT_APP_BASEURL + "producttransferorders/release";

    const response = await postSubmitForm(url, {
      producttransferorder_id: selectedTransfer._id,
    });
    //console.log(data_to_send);
    if (response && response.status === 1) {
      loadTransfers();
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };
  const handleDownload = async () => {
    const fileName = "finished_product_transfer_inventory";
    const exportType = "xls";

    let data_to_export = allTransfers;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          TransferID: v.order_id,
          Name: v.action === "release" ? v.branch_to.name : v.branch_from.name,
          Remarks: v.remarks,
          Date: v.createdAt,
          Status: v.status,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Finished Product Transfer Inventory ")}
            title={props.t("Finished Product Transfer Inventory")}
            breadcrumbItem1={props.t("Finished Products")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"   "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <div id="printDiv">
                    <CardTitle className="text-center">
                      {props.t("Finished Product Transfer Inventory")}
                    </CardTitle>

                    <ToolkitProvider
                      bootstrap4
                      keyField="_id"
                      data={allTransfersFiltered && allTransfersFiltered}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      search
                      exportCSV
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={5}></Col>
                            <Col lg={3} className="text-right">
                              <select
                                name="status"
                                className="form-control"
                                value={selectedStatus}
                                onChange={(e) => {
                                  setSelectedStatus(e.target.value);
                                }}
                              >
                                <option value="All">All</option>
                                <option value="Placed">Placed</option>
                                <option value="Released">Released</option>
                                <option value="Received">Received</option>
                              </select>
                            </Col>
                          </Row>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                  <Modal
                    size="lg"
                    style={{
                      width: "940px",
                      maxWidth: "950px",
                    }}
                    isOpen={detailsModal}
                    toggle={() => setDetailsModal(!detailsModal)}
                  >
                    <ModalHeader toggle={() => setDetailsModal(!detailsModal)}>
                      Transfer Product Details
                    </ModalHeader>
                    <ModalBody>
                      {selectedTransfer_Products && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col lg={12}>
                                      <ToolkitProvider
                                        keyField="_id"
                                        data={
                                          selectedTransfer_Products &&
                                          selectedTransfer_Products
                                        }
                                        columns={columns_products}
                                      >
                                        {(props) => (
                                          <div>
                                            <BootstrapTable
                                              {...props.baseProps}
                                              noDataIndication="No data to display."
                                              striped
                                              hover
                                              condensed
                                            />
                                          </div>
                                        )}
                                      </ToolkitProvider>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>

                  {confirmRelease && (
                    <SweetAlert
                      title={props.t("Are you sure?")}
                      warning
                      showCancel
                      cancelBtnText={props.t("No")}
                      confirmBtnText={props.t("Yes")}
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        setConfirmRelease(false);
                        handleReleaseTransfer();
                      }}
                      onCancel={() => {
                        setConfirmRelease(false);
                      }}
                    >
                      {props.t(
                        "Once you release this transfer, it cannot be revoked."
                      )}
                    </SweetAlert>
                  )}
                  {confirmReceive && (
                    <SweetAlert
                      title={props.t("Are you sure?")}
                      warning
                      showCancel
                      cancelBtnText={props.t("No")}
                      confirmBtnText={props.t("Yes")}
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        setConfirmReceive(false);
                        handleReceiveTransfer();
                      }}
                      onCancel={() => {
                        setConfirmReceive(false);
                      }}
                    >
                      {props.t(
                        "Once you receive this transfer, it cannot be revoked."
                      )}
                    </SweetAlert>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(TransferInventory))
);
