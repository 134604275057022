import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withNamespaces } from "react-i18next";

import moment from "moment";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const role = localStorage.getItem("role");
  const getLink = (notification) => {
    let pageLink = "";
    if (
      role === "admin" ||
      role == "Operations Manager" ||
      role === "Central Kitchen Manager"
    ) {
      switch (notification) {
        case "New product order":
          pageLink = "/finished_products/new_orders";
          break;

        case "Product order received":
          pageLink = "/finished_products/orders_in_process";
          break;

        case "Product transfer order":
          pageLink = "/finished_products/transfer_inventory";
          break;

        case "New item order":
          pageLink = "/items/new_orders";
          break;

        case "Item order received":
          pageLink = "/items/orders_in_process";
          break;

        case "Item transfer order":
          pageLink = "/items/transfer_inventory";
          break;
      }
    }
    if (role === "Owner") {
      switch (notification) {
        case "New Purchase Order Limit Approval":
          pageLink = "/all_po_owner";
          break;
      }
    }
    if (role === "Branch Supervisor") {
      switch (notification) {
        case "Product order approved":
          pageLink = "/finished_products/all_orders";
          break;
        case "Item order approved":
          pageLink = "/items/all_orders";
          break;

        case "Product order rejected":
          pageLink = "/finished_products/all_orders";
          break;
        case "Item order rejected":
          pageLink = "/items/all_orders";
          break;

        case "Product order dispatched":
          pageLink = "/finished_products/all_orders";
          break;
        case "Item order dispatched":
          pageLink = "/items/all_orders";
          break;

        case "Product order closed":
          pageLink = "/finished_products/all_orders";
          break;
        case "Item order closed":
          pageLink = "/items/all_orders";
          break;

        case "Item transfer order":
          pageLink = "/items/transfer_inventory";
          break;
        case "Product transfer order":
          pageLink = "/finished_products/transfer_inventory";
          break;
      }
    }

    return pageLink;
  };
  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i
            className={
              props.data && props.data.unread_notifications === 0
                ? "bx bx-bell"
                : "bx bx-bell bx-tada"
            }
            onClick={props.setNotificationsRead}
          ></i>
          <span className="badge badge-danger badge-pill">
            {props.data && props.data.unread_notifications === 0
              ? ""
              : props.data.unread_notifications}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              {/* {props.data.notifications &&
              props.data.notifications.length > 0 ? (
                <div className="col-auto">
                  <a href="#!" className="small">
                    {" "}
                    View All
                  </a>
                </div>
              ) : null} */}
            </Row>
          </div>
          {props.data.notifications && props.data.notifications.length > 0 ? (
            <>
              <SimpleBar style={{ height: "230px" }}>
                {props.data.notifications &&
                  props.data.notifications.map((notification) => {
                    return (
                      <Link
                        to={() => {
                          return getLink(notification.action);
                        }}
                        className="text-reset notification-item"
                      >
                        <div className="media">
                          <div className="avatar-xs mr-3">
                            <span className="avatar-title bg-primary rounded-circle font-size-16">
                              <i className="bx bx-cart"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6 className="mt-0 mb-1">{notification.title}</h6>
                            <div className="font-size-12 text-muted">
                              <p className="mb-1">{notification.body}</p>
                              <p className="mb-0">
                                <i className="mdi mdi-clock-outline"></i>{" "}
                                {moment(notification.createdAt).fromNow()}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
              </SimpleBar>

              <div className="p-2 border-top d-grid text-center">
                <Link
                  className="btn btn-sm btn-link font-size-14 text-center"
                  to="/notifications"
                >
                  <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                  <span key="t-view-more">{props.t("View All")}</span>
                </Link>
              </div>
            </>
          ) : (
            <SimpleBar style={{ height: "80px" }}>
              <Link to="" className="text-reset notification-item">
                <div className="media">
                  <div className="media-body">
                    <h6 className="mt-0 mb-1"></h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">There are no notifications.</p>
                    </div>
                  </div>
                </div>
              </Link>
            </SimpleBar>
          )}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withNamespaces()(NotificationDropdown);
