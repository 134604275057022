import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
  Table,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import Line from "../../../AllCharts/echart/linechart";
import Pie from "../../../AllCharts/echart/piechart";

const animatedComponents = makeAnimated();

const ItemOrderReport = (props) => {
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const { SearchBar } = Search;
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [allBranches, setAllBranches] = useState([]);
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    setDateFrom(formatDate(firstDay));
    setDateTo(formatDate(currentDay));
    loadAllBranches();
  }, []);

  // useEffect(() => {
  //   if (dateFrom && dateTo) {
  //     loadOrders();
  //     loadOrdersPieChartData();
  //     loadOrdersLineChartData();
  //   }
  // }, [dateFrom, dateTo, selectedBranch]);
  const [selectedRow, setSelectedRow] = useState([]);
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [editModal, setEditModal] = useState();

  const [allOrders, setAllOrders] = useState([]);

  const loadOrders = async () => {
    const days_diff = moment(dateTo).diff(dateFrom, "days");
    if (days_diff > 30) {
      setFilteredOrders([]);
      showNotification("Total number of days cannot be more than 31.", "Error");
      return false;
    } else if (days_diff < 0) {
      setFilteredOrders([]);
      showNotification("Invalid date range.", "Error");
      return false;
    }
    console.log(days_diff);
    preloader(true);
    let date_from = moment(dateFrom).format("YYYY-MM-DD"),
      date_to = moment(dateFrom)
        .add(days_diff / 4, "days")
        .format("YYYY-MM-DD");
    console.log(date_from, date_to);
    let url = process.env.REACT_APP_BASEURL + "itemorders/get_datewise";
    const response1 = await postSubmitForm(url, {
      branch_id: selectedBranch,
      date_from: date_from,
      date_to: date_to,
    });
    if (response1 && response1.status === 1) {
      console.log("response1");
    } else {
      preloader(false);
      showNotification(response1.message, "Error");
      return false;
    }

    //2
    date_from = moment(dateFrom)
      .add(days_diff / 4 + 1, "days")
      .format("YYYY-MM-DD");
    date_to = moment(dateFrom)
      .add(days_diff / 2, "days")
      .format("YYYY-MM-DD");
    console.log(date_from, date_to);
    const response2 = await postSubmitForm(url, {
      branch_id: selectedBranch,
      date_from: date_from,
      date_to: date_to,
    });
    if (response2 && response2.status === 1) {
      console.log("response2");
      preloader(false);
      //setAllOrders(response2.data.concat(response1.data));
    } else {
      preloader(false);
      showNotification(response2.message, "Error");
      return false;
    }

    //3
    date_from = moment(dateFrom)
      .add(days_diff / 2 + 1, "days")
      .format("YYYY-MM-DD");
    date_to = moment(dateFrom)
      .add((days_diff * 3) / 4, "days")
      .format("YYYY-MM-DD");
    console.log(date_from, date_to);
    const response3 = await postSubmitForm(url, {
      branch_id: selectedBranch,
      date_from: date_from,
      date_to: date_to,
    });
    if (response3 && response3.status === 1) {
      console.log("response3");
      preloader(false);
      //setAllOrders(response3.data.concat(response1.data));
    } else {
      preloader(false);
      showNotification(response3.message, "Error");
      return false;
    }
    //4
    date_from = moment(dateFrom)
      .add((days_diff * 3) / 4 + 1, "days")
      .format("YYYY-MM-DD");
    date_to = dateTo;
    console.log(date_from, date_to);
    const response4 = await postSubmitForm(url, {
      branch_id: selectedBranch,
      date_from: date_from,
      date_to: date_to,
    });
    if (response4 && response4.status === 1) {
      console.log("response4");
      preloader(false);
      console.log(
        response1.data.length,
        response2.data.length,
        response3.data.length,
        response4.data.length
      );
      setAllOrders(
        response1.data.concat(response2.data, response3.data, response4.data)
      );
    } else {
      preloader(false);
      showNotification(response4.message, "Error");
      return false;
    }
  };
  const [orderStatus, setOrderStatus] = useState("All");
  const [filteredOrders, setFilteredOrders] = useState([]);
  useEffect(() => {
    if (orderStatus === "All") {
      setFilteredOrders([...allOrders]);
    } else {
      let arr = allOrders.filter((order) => order.status === orderStatus);
      setFilteredOrders(arr);
    }
  }, [allOrders, orderStatus]);

  const [ordersPieChartData, setOrdersPieChartData] = useState();
  const loadOrdersPieChartData = async () => {
    let url = process.env.REACT_APP_BASEURL + "itemorders/report_daterange_pie";
    const response = await postSubmitForm(url, {
      branch_id: selectedBranch,
      date_from: dateFrom,
      date_to: dateTo,
    });
    if (response && response.status === 1) {
      setOrdersPieChartData(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [ordersLineChartStatus, setOrdersLineChartStatus] = useState("Closed");
  const [ordersLineChartData, setOrdersLineChartData] = useState();
  useEffect(() => {
    if (dateFrom && dateTo && ordersLineChartStatus) {
      loadOrdersLineChartData();
    }
  }, [ordersLineChartStatus]);
  const loadOrdersLineChartData = async () => {
    let url =
      process.env.REACT_APP_BASEURL + "itemorders/report_daterange_line";
    const response = await postSubmitForm(url, {
      branch_id: selectedBranch,
      date_from: dateFrom,
      date_to: dateTo,
      status: ordersLineChartStatus,
    });
    if (response && response.status === 1) {
      setOrdersLineChartData(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  function detailsFormatter(cell, row) {
    return (
      <>
        <span className="text-info" style={{ marginLeft: "5px" }}>
          <i
            className="bx bx-news font-size-15"
            title="View"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedRow(row);

              //getPatientDetails(row.patient_details.patient_id);
              setEditModal(!editModal);
              //console.log(row);
              //setSelectedPackage(row._id);
              //setPackagesForPatient(selectedPackage)
            }}
          ></i>
        </span>
      </>
    );
  }

  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("Order Date"),
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      sort: true,
      dataField: "createdAt",
      formatter: (cell, row, rowIndex) => {
        return moment(row.createdAt).format("YYYY-MM-DD HH:mm");
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "order_id",
      text: props.t("Order ID"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "branch_details.name",
      text: props.t("Branch Name"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },

      style: { cursor: "pointer" },
    },

    {
      dataField: "status",
      text: props.t("Status"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),

      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },

      style: { cursor: "pointer" },
    },
    {
      text: props.t("Action"),
      formatter: detailsFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
  ];

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Sub-Category"),
      dataField: "sub_category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },

    {
      dataField: "unit",
      text: props.t("Order Quantity"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];

  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      setExpanded([...expanded, row._id]);
    } else {
      setExpanded(expanded.filter((x) => x !== row._id));
    }
  };
  const [expanded, setExpanded] = useState([]);
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.item_details}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
    expanded: expanded,
    onExpand: handleOnExpand,
  };

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.status === "Completed") {
      style.backgroundColor = "#b0ff9e";
    } else if (row.status === "Booked") {
      style.backgroundColor = "#9ec3ff";
    } else if (row.status === "In Process") {
      style.backgroundColor = "#fffd9e";
    } else if (row.status === "Cancelled") {
      style.backgroundColor = "#ff9e9e";
    }

    return style;
  };

  const handleDownload = async () => {
    const fileName = "items_Order_report";
    const exportType = "xls";

    let data_to_export = filteredOrders;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Order_Date: v.createdAt,
          Order_Id: v.order_id,
          Branch_Name: v.branch_details.name,
          Status: v.status,
          Remarks: v.remarks,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Item Order Report")}
            title={props.t("Reports")}
            breadcrumbItem1={props.t("Orders")}
            breadcrumbItem2={props.t("Item Order Report")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={3}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={3}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={3}>
                        <AvField
                          name="branch"
                          label={props.t("Branch")}
                          type="select"
                          value={selectedBranch}
                          onChange={(e) => {
                            setSelectedBranch(e.target.value);
                          }}
                        >
                          <option value="All">{props.t("All")}</option>
                          {allBranches &&
                            allBranches.map((branch) => {
                              return (
                                <option value={branch._id}>
                                  {branch.name}
                                </option>
                              );
                            })}
                        </AvField>
                      </Col>
                      <Col lg={3}>
                        {" "}
                        <Button
                          type="submit"
                          className="btn btn-md btn-success mt-4"
                          onClick={() => {
                            if (dateFrom && dateTo) {
                              loadOrders();
                              loadOrdersPieChartData();
                              loadOrdersLineChartData();
                            }
                          }}
                        >
                          <i className="mdi mdi-checkbox-marked-circle"></i>{" "}
                          {props.t("Check")}
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={6}>
                      <CardTitle>
                        {ordersLineChartStatus &&
                          props.t(ordersLineChartStatus + "Orders Chart")}
                      </CardTitle>
                    </Col>
                    <Col lg={6}>
                      <AvForm>
                        <AvField
                          name="orderStatusLineChart"
                          label={""}
                          type="select"
                          value={ordersLineChartStatus}
                          onChange={(e, v) => {
                            setOrdersLineChartStatus(e.target.value);
                          }}
                        >
                          <option value="Placed">{props.t("Placed")}</option>
                          <option value="Approved">
                            {props.t("Approved")}
                          </option>

                          <option value="Dispatched">
                            {props.t("Dispatched")}
                          </option>
                          <option value="Partially Dispatched">
                            {props.t("Partially Dispatched")}
                          </option>
                          <option value="Closed">{props.t("Closed")}</option>
                        </AvField>
                      </AvForm>
                    </Col>
                  </Row>

                  <div id="line-chart" className="e-chart">
                    <Line
                      line_data={ordersLineChartData && ordersLineChartData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Orders Pie")}</CardTitle>
                  <div id="pie-chart" className="e-chart">
                    <Pie
                      orders_pie_data={ordersPieChartData && ordersPieChartData}
                      name={props.t("Orders Pie")}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Orders")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all orders here")}
                  </CardSubtitle>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <ToolkitProvider
                    keyField="_id"
                    data={filteredOrders && filteredOrders}
                    columns={columns_external}
                    noDataIndication={props.t("No data to display.")}
                    bootstrap4
                    search
                  >
                    {(props) => (
                      <div>
                        <Row>
                          <Col lg={4}>
                            <SearchBar
                              {...props.searchProps}
                              style={{ width: "300px" }}
                            />
                          </Col>
                          <Col lg={4}></Col>
                          <Col lg={4} className="text-right">
                            <select
                              name="status"
                              className="form-control"
                              value={orderStatus}
                              onChange={(e) => {
                                setOrderStatus(e.target.value);
                              }}
                            >
                              <option value="All">All</option>
                              <option value="Placed">Placed</option>
                              <option value="Approved">Approved</option>
                              <option value="Dispatched">Dispatched</option>
                              <option value="Partially Dispatched">
                                Partially Dispatched
                              </option>
                              <option value="Closed">Closed</option>
                            </select>
                          </Col>
                        </Row>
                        <BootstrapTable
                          striped
                          hover
                          condensed
                          rowStyle={rowStyle}
                          {...props.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
          >
            <ModalHeader toggle={() => setEditModal(!editModal)}>
              {"    "}
              <Link
                to="#"
                onClick={() => {
                  printDiv("printDiv1");
                }}
                className="btn btn-sm btn-success mr-2"
              >
                <i className="fa fa-print"></i> Print
              </Link>
            </ModalHeader>
            <ModalBody>
              <Row id="printDiv1">
                <Col lg={12}>
                  <CardTitle className="text-center">
                    {props.t("Item order details")}
                  </CardTitle>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          {/* <h5> Branch: {selectedRow.name}</h5> */}
                          <BootstrapTable
                            keyField="_id"
                            data={
                              selectedRow.item_details &&
                              selectedRow.item_details
                            }
                            columns={columns}
                            striped
                            hover
                            condensed
                            rowStyle={rowStyle}
                            {...props.baseProps}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(ItemOrderReport))
);
