import React, { useEffect, useLayoutEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import swal from "sweetalert2";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { postSubmitForm } from "../../helpers/forms_helper";

const AllBranches = (props) => {
  const { SearchBar } = Search;
  const role = localStorage.getItem("role");
  useLayoutEffect(() => {
    loadAllBranches();
  }, []);

  const [allBranches, setAllBranches] = useState([]);
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const [isStore, setIsStore] = useState(false);
  const [isPrivileged, setIsPrivileged] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState();
  const [editModal, setEditModal] = useState();
  const handleValidUpdate = async (e, v) => {
    try {
      const object = {
        id: selectedBranch._id,
        name: v.name,
        address: v.address,
        mobile: v.mobile,
        email: v.email,
        opening_time: v.opening_time,
        closing_time: v.closing_time,
        is_store: isStore,
        transfer_privilege: isPrivileged,
      };

      let url = process.env.REACT_APP_BASEURL + "branches/update";
      let response = await postSubmitForm(url, object);
      if (response.status === 1) {
        setEditModal(!editModal);
        showNotification(response.message, "Success");
        loadAllBranches();
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };
  const columns = [
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      dataField: "name",
      text: props.t("Name"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      sort: true,
    },
    {
      dataField: "address",
      text: props.t("Address"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "mobile",
      text: props.t("Contact"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "email",
      text: props.t("Email"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "11%" };
      },
    },
    {
      dataField: "is_store",
      text: props.t("Store Exist"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      formatter: (cell, row) => {
        return row.is_store ? "Yes" : "No";
      },
    },
    {
      dataField: "transfer_privilege",
      text: props.t("Transfer Privilege"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
      formatter: (cell, row) => {
        return row.transfer_privilege ? "Yes" : "No";
      },
    },
    {
      dataField: "",
      text: props.t("Action"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      formatter: (cell, row) => {
        if (row.name != "CENTRAL KITCHEN STORE") {
          return (
            <span className="text-info">
              <i
                className="bx bxs-edit font-size-15"
                title="Click to Edit"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditModal(!editModal);
                  setSelectedBranch(row);
                  setIsStore(row.is_store);
                  setIsPrivileged(row.transfer_privilege);
                }}
              ></i>
            </span>
          );
        }
      },
    },
  ];
  const handleDownload = async () => {
    const fileName = "update_branch";
    const exportType = "xls";

    let data_to_export = allBranches;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          name: v.name,
          address: v.address,
          mobile: v.mobile,
          email: v.email,
          is_store: v.is_store,

          transfer_privilege: v.transfer_privilege,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Branch")}
            breadcrumbItem={props.t("Update Branch")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Update Branch")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View/Update all your existing Branches here")}
                  </CardSubtitle>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"  "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>

                  <div id="printDiv">
                    <ToolkitProvider
                      bootstrap4
                      keyField="_id"
                      data={allBranches && allBranches}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}>
                              {" "}
                              <CardTitle>Update Branch</CardTitle>
                            </Col>
                          </Row>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>

                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update branch details")}
                    </ModalHeader>
                    <ModalBody>
                      {selectedBranch && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm onValidSubmit={handleValidUpdate}>
                                    <Row>
                                      <Col lg={6}>
                                        <AvField
                                          name="name"
                                          label={props.t("Name") + "*"}
                                          value={selectedBranch.name}
                                          type="text"
                                          disabled={
                                            role == "Operations Manager"
                                          }
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="address"
                                          label={props.t("Address") + "*"}
                                          value={selectedBranch.address}
                                          type="text"
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="mobile"
                                          value={selectedBranch.mobile}
                                          label={props.t("Contact Number")}
                                          placeholder={props.t(
                                            "Enter Contact Number"
                                          )}
                                          type="text"
                                          // validate={{
                                          //   required: {
                                          //     value: true,
                                          //     errorMessage: props.t(
                                          //       "Contact cannot be empty."
                                          //     ),
                                          //   },
                                          //   pattern: {
                                          //     value: "^[0-9]+$",
                                          //     errorMessage: props.t(
                                          //       "Only numbers are allowed."
                                          //     ),
                                          //   },
                                          //   maxLength: {
                                          //     value: 8,
                                          //     errorMessage: props.t(
                                          //       "Enter valid contact."
                                          //     ),
                                          //   },
                                          //   minLength: {
                                          //     value: 8,
                                          //     errorMessage: props.t(
                                          //       "Enter valid contact."
                                          //     ),
                                          //   },
                                          // }}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="email"
                                          value={selectedBranch.email}
                                          label={props.t("Email")}
                                          placeholder={props.t("Enter Email")}
                                          type="email"
                                          errorMessage={props.t(
                                            "Enter valid email."
                                          )}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="opening_time"
                                          value={selectedBranch.opening_time}
                                          label={props.t("Opening Time")}
                                          placeholder={props.t(
                                            "Enter Opening Time"
                                          )}
                                          type="time"
                                          errorMessage={props.t(
                                            "Opening time cannot be empty"
                                          )}
                                          validate={{
                                            required: { value: true },
                                          }}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="closing_time"
                                          value={selectedBranch.closing_time}
                                          label={props.t("Closing Time")}
                                          placeholder={props.t(
                                            "Enter Closing Time"
                                          )}
                                          type="time"
                                          errorMessage={props.t(
                                            "Closing time cannot be empty"
                                          )}
                                          validate={{
                                            required: { value: true },
                                          }}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvRadioGroup
                                          inline
                                          name="is_store"
                                          value={
                                            selectedBranch.is_store &&
                                            selectedBranch.is_store
                                              ? "true"
                                              : "false"
                                          }
                                          validate={{
                                            required: { value: true },
                                          }}
                                          errorMessage={props.t(
                                            "Select an option"
                                          )}
                                        >
                                          {" "}
                                          <Label>
                                            {props.t("Store Exists?")}
                                          </Label>
                                          <br />
                                          <AvRadio
                                            label={props.t("Yes")}
                                            value="true"
                                            onChange={(e, v) => {
                                              setIsStore(true);
                                            }}
                                          />
                                          <AvRadio
                                            label={props.t("No")}
                                            value="false"
                                            onChange={(e, v) => {
                                              setIsStore(false);
                                            }}
                                          />
                                        </AvRadioGroup>
                                      </Col>
                                      <Col lg={6}>
                                        <AvRadioGroup
                                          inline
                                          name="transfer_privilege"
                                          value={
                                            selectedBranch.transfer_privilege &&
                                            selectedBranch.transfer_privilege
                                              ? "true"
                                              : "false"
                                          }
                                          validate={{
                                            required: { value: true },
                                          }}
                                          errorMessage={props.t(
                                            "Select an option"
                                          )}
                                        >
                                          {" "}
                                          <Label>
                                            {props.t("Transfer Privileged?")}
                                          </Label>
                                          <br />
                                          <AvRadio
                                            label={props.t("Yes")}
                                            value="true"
                                            onChange={(e, v) => {
                                              setIsPrivileged(true);
                                            }}
                                          />
                                          <AvRadio
                                            label={props.t("No")}
                                            value="false"
                                            onChange={(e, v) => {
                                              setIsPrivileged(false);
                                            }}
                                          />
                                        </AvRadioGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                            >
                                              {props.t("Update")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(connect(null, {})(withNamespaces()(AllBranches)));
