import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";

const FinishedProductClosedOrders = (props) => {
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const { SearchBar } = Search;
  const urlParams = new URLSearchParams(window.location.search);
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [selectedSection, setSelectedSection] = useState(
    urlParams.get("section") ? urlParams.get("section") : "All"
  );
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    setDateFrom(formatDate(firstDay));
    setDateTo(formatDate(currentDay));
  }, []);

  useEffect(() => {
    if (dateFrom && dateTo && selectedSection && selectedBranch) {
      loadAllOrders();
    }
  }, [dateFrom, dateTo, selectedSection, selectedBranch]);
  useEffect(() => {
    if (urlParams.get("section")) {
      setSelectedSection(urlParams.get("section"));
    }
    loadAllBranches();
    loadAllSections();
  }, []);

  const [allOrders, setAllOrders] = useState([]);
  const loadAllOrders = async () => {
    preloader(true);
    let url = process.env.REACT_APP_BASEURL + "productorders/getall_closed";
    const response = await postSubmitForm(url, {
      branch: selectedBranch,
      section: selectedSection,
      date_from: dateFrom,
      date_to: dateTo,
    });
    if (response && response.status === 1) {
      console.log(response.data.length);
      // let expandedRows = [];
      // response.data.map((item) => {
      //   expandedRows.push(item._id);
      // });
      preloader(false);
      setAllOrders(response.data);
      // setExpanded(expandedRows);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };
  const [allSections, setAllSections] = useState([]);
  const loadAllSections = async () => {
    let url = process.env.REACT_APP_BASEURL + "productsections/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllSections(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [allBranches, setAllBranches] = useState([]);
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Section"),
      dataField: "section",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Product"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
    },
    {
      dataField: "quantity",
      text: props.t("Order Quantity"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "available",
      text: props.t("Avail. Quantity"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const [orderDetailsModal, setOrderDetailsModal] = useState();
  const [selectedOrder, setSelectedOrder] = useState();

  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      dataField: "order_id",
      text: props.t("Order ID"),

      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "branch_details.name",
      text: props.t("Branch Name"),

      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "remarks",
      text: props.t("Branch Remarks"),

      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },

      style: { cursor: "pointer" },
    },
    {
      text: props.t("Order Date"),
      dataField: "date_to_process",
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      // formatter: (cell, row) => {
      //   return moment(row.date_to_process).format("DD-MM-YYYY HH:mm");
      // },
      style: { cursor: "pointer" },
    },
    {
      text: props.t("Is Urgent?"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      formatter: (cell, row) => {
        if (row.is_urgent) {
          return props.t("Yes");
        } else {
          return props.t("No");
        }
      },
      style: { cursor: "pointer" },
    },

    {
      dataField: "status",
      text: props.t("Status"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "admin_remarks",
      text: props.t("Centre Remarks"),

      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "",
      text: props.t("Action"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <button
              class="btn btn-secondary btn-sm mr-2"
              onClick={() => {
                setSelectedOrder(row);
                setOrderDetailsModal(!orderDetailsModal);
              }}
            >
              {props.t("Details")}
            </button>
          </>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];

  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      setExpanded([...expanded, row._id]);
    } else {
      setExpanded(expanded.filter((x) => x !== row._id));
    }
  };
  const [expanded, setExpanded] = useState([]);
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.product_details}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
    expanded: expanded,
    onExpand: handleOnExpand,
  };
  const rowStyle = (row, rowIndex) => {
    const style = { backgroundColor: "#fffbb8", fontWeight: "500" };
    // if (row.status === "Completed") {
    //   style.backgroundColor = "#b0ff9e";
    // }
    return style;
  };
  const columns_delivered_products = [
    {
      text: props.t("Section"),
      dataField: "section",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Product"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Del. Qty."),
      dataField: "delivered_quantity",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      text: props.t("Rec. Qty."),
      dataField: "received_quantity",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
  ];
  const handleDownload = async () => {
    // const fileName = "appointments";
    // const exportType = "xls";
    // let data_to_send_api = [];
    // allAppointments.map((appointment) => {
    //   data_to_send_api.push(appointment._id);
    // });
    // let data_to_export = [];
    // let url = process.env.REACT_APP_BASEURL + "appointments/toexcel"; //"services/getall";
    // const response = await postSubmitForm(url, {
    //   appointment_ids: data_to_send_api,
    // });
    // if (response && response.status === 1) {
    //   data_to_export = response.data;
    // } else {
    //   showNotification(response.message, "Error");
    // }
    // if (data_to_export) {
    //   var data = JSON.parse(JSON.stringify(data_to_export));
    //   data.forEach(function (v) {
    //     v.client_name = v.client_details.client_name;
    //     v.client_mobile = v.client_details.client_mobile;
    //     v.service_name = v.service_details.name;
    //     v.service_duration = v.service_details.duration;
    //     v.service_cost = v.service_details.cost;
    //     delete v.is_active;
    //     delete v._id;
    //     delete v.createdAt;
    //     delete v.updatedAt;
    //     delete v.__v;
    //     delete v.sold_product_details;
    //     delete v.title;
    //     delete v.client_details;
    //     delete v.service_details;
    //     delete v.invoice_number;
    //     delete v.serviceandsale_id;
    //   });
    //   console.log(data);
    //   exportFromJSON({ data, fileName, exportType });
    // }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Finished Products")}
            title={props.t("Central Kitchen")}
            breadcrumbItem1={props.t("FP Order")}
            breadcrumbItem2={props.t("Closed Orders")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Closed Orders")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all closed orders here")}
                  </CardSubtitle>
                  {/* <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row> */}
                  <ToolkitProvider
                    keyField="_id"
                    data={allOrders && allOrders}
                    columns={columns_external}
                    noDataIndication={props.t("No data to display.")}
                    bootstrap4
                    search
                  >
                    {(props_table) => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4}>
                              <Label>{props.t("Search")}</Label>
                              <SearchBar
                                {...props_table.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}>
                              <AvField
                                name="branch"
                                label={props.t("Branch")}
                                type="select"
                                value={selectedBranch}
                                onChange={(e) => {
                                  setSelectedBranch(e.target.value);
                                }}
                              >
                                <option value="All">{props.t("All")}</option>
                                {allBranches &&
                                  allBranches.map((branch) => {
                                    return (
                                      <option value={branch._id}>
                                        {branch.name}
                                      </option>
                                    );
                                  })}
                              </AvField>
                            </Col>
                            <Col lg={4}>
                              <AvField
                                name="section"
                                label={props.t("Section")}
                                type="select"
                                value={selectedSection}
                                onChange={(e) => {
                                  setSelectedSection(e.target.value);
                                }}
                              >
                                <option value="All">{props.t("All")}</option>
                                {allSections &&
                                  allSections.map((section) => {
                                    return (
                                      <option value={section.section}>
                                        {section.section}
                                      </option>
                                    );
                                  })}
                              </AvField>
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          striped
                          hover
                          condensed
                          expandRow={expandRow}
                          rowStyle={rowStyle}
                          {...props_table.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                  <Modal
                    size="lg"
                    isOpen={orderDetailsModal}
                    toggle={() => setOrderDetailsModal(!orderDetailsModal)}
                  >
                    <ModalHeader
                      toggle={() => setOrderDetailsModal(!orderDetailsModal)}
                    >
                      {props.t("Delivery Details")}
                    </ModalHeader>
                    <ModalBody>
                      <>
                        <Row>
                          <Col lg={12}>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col lg={12} md={12}>
                                    <Label>Ordered Products</Label>
                                  </Col>

                                  <Col lg={12}>
                                    <BootstrapTable
                                      bootstrap4
                                      keyField="name"
                                      data={
                                        selectedOrder &&
                                        selectedOrder.product_details
                                      }
                                      columns={columns}
                                      noDataIndication="No data to display."
                                      striped
                                      hover
                                      condensed
                                    />
                                    <hr />
                                  </Col>

                                  {selectedOrder &&
                                  selectedOrder.delivery_details &&
                                  selectedOrder.delivery_details.length > 0 ? (
                                    <>
                                      <Col lg={12} md={12}>
                                        <br />
                                        <Label>
                                          <b>
                                            {props.t("Delivered Products")}{" "}
                                          </b>
                                        </Label>
                                      </Col>
                                      <Col lg={12} className="mb-4">
                                        <BootstrapTable
                                          bootstrap4
                                          keyField="name"
                                          key="name"
                                          data={selectedOrder.delivery_details}
                                          columns={columns_delivered_products}
                                          noDataIndication="No data to display."
                                          striped
                                          hover
                                          condensed
                                        />
                                        <hr />
                                      </Col>
                                    </>
                                  ) : null}
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(
  connect(null, {})(withNamespaces()(FinishedProductClosedOrders))
);
