import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";

import { postSubmitForm } from "../../../helpers/forms_helper";
import moment from "moment";

const BranchWiseRequisition = (props) => {
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const { SearchBar } = Search;

  const [selectedItems, setSelectedItems] = useState([]);
  const [allBranches, setAllBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");

  useEffect(() => {
    loadItems();
  }, []);
  const [allItems, setAllItems] = useState([]);
  const loadItems = async () => {
    preloader(true);
    let url = process.env.REACT_APP_BASEURL + "items/get_branchwise_for_po";
    const response = await postSubmitForm(url, {
      branch_id: localStorage.getItem("branch_id"),
    });
    if (response && response.status === 1) {
      preloader(false);
      let arr = response.data.map((category) => {
        //console.log(items);
        let out_category = category;
        out_category.items = Array.from(category.items, (x) => {
          let y = x;
          y.quantity = 0;
          // y.price = 0;
          y.total_price = 0;
          y.is_selected = false;
          return y;
        });
        return out_category;
      });
      setAllItems(arr);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      style: { cursor: "pointer" },
    },
    {
      text: props.t("Category Name"),
      dataField: "_id",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      style: { cursor: "pointer" },
    },
    {
      text: props.t("Supplier"),
      dataField: "unit",
      formatter: (cell, row) => {
        return (
          <AvForm>
            <AvField name="supplier" type="select">
              <option value={null}>{props.t("--Select Supplier--")}</option>
              {row.suppliers &&
                row.suppliers.map((e) => (
                  <option key={e._id} value={e._id} name={e.name}>
                    {e.name}
                  </option>
                ))}
            </AvField>
          </AvForm>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
  ];
  const columns_items = [
    {
      dataField: "item_id",
      hidden: true,
    },
    {
      dataField: "item_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      editable: false,
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
      editable: false,
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      editable: false,
    },
    {
      text: props.t("Sub-Category"),
      dataField: "sub_category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      editable: false,
    },
    {
      text: props.t("Brand"),
      dataField: "brand",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      editable: false,
    },
    {
      text: props.t("Quantity"),
      dataField: "quantity",
      sort: true,
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          };
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          };
        }
        return true;
      },
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: (content, row, rowIndex, columnIndex) => {
        return row.is_selected;
      },
      attrs: (cell, row, rowIndex, colIndex) => {
        const title_text = row.is_selected
          ? { title: "Click to Edit" }
          : { title: "Select to Edit" };
        return title_text;
      },
      style: (cell, row, rowIndex, colIndex) => {
        const cur_style = row.is_selected
          ? { cursor: "pointer" }
          : { cursor: "not-allowed" };
        return cur_style;
      },
    },
    {
      text: props.t("Price"),
      dataField: "price",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: (content, row, rowIndex, columnIndex) => {
        return row.is_selected;
      },
      attrs: (cell, row, rowIndex, colIndex) => {
        const title_text = row.is_selected
          ? { title: "Click to Edit" }
          : { title: "Select to Edit" };
        return title_text;
      },
      style: (cell, row, rowIndex, colIndex) => {
        const cur_style = row.is_selected
          ? { cursor: "pointer" }
          : { cursor: "not-allowed" };
        return cur_style;
      },
    },
    {
      text: props.t("Total"),
      dataField: "total_price",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: false,
    },
    // {
    //   text: props.t("Available"),
    //   dataField: "unit",
    //   formatter: (cell, row) => {
    //     return (
    //       row.available.in_unit +
    //       " " +
    //       row.unit +
    //       (row.sub_unit
    //         ? " " + row.available.in_sub_unit + " " + row.sub_unit
    //         : "")
    //     );
    //   },
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "12%" };
    //   },
    // },
  ];

  const handleSupplierSelection = (event) => {
    let arrSelected = JSON.parse(JSON.stringify(selectedItems));
    let selectedCategory = event.target.getAttribute("category");
    let selectedSupplier = event.target.value;
    let existingIndex = arrSelected.findIndex(
      (item) => item.category === selectedCategory
    );
    if (existingIndex >= 0) {
      if (selectedSupplier === "") {
        arrSelected.splice(existingIndex, 1);
      } else {
        arrSelected[existingIndex].supplier = selectedSupplier;
      }
    } else {
      arrSelected.push({
        category: selectedCategory,
        supplier: selectedSupplier,
      });
    }
    setSelectedItems(arrSelected);
  };

  // const handleOnExpand = (row, isExpand, rowIndex, e) => {
  //   console.log(isExpand);
  //   // if (isExpand) {
  //   //   setExpanded([...expanded, row._id]);
  //   // } else {
  //   //   return false;
  //   //   // setExpanded(expanded.filter((x) => x !== row._id));
  //   // }
  // };
  // const [expanded, setExpanded] = useState([]);
  // const expandRow = {
  //   onlyOneExpanding: true,
  //   expanded: expanded,
  //   onExpand: handleOnExpand,
  //   renderer: (row) => (
  //     <div>
  //       <BootstrapTable
  //         bootstrap4
  //         keyField="item_id"
  //         data={row.items}
  //         columns={columns_items}
  //         selectRow={selectRow}
  //         cellEdit={cellEditFactory({
  //           mode: "click",
  //           blurToSave: true,
  //           afterSaveCell: (oldValue, newValue, row, column) => {
  //             // let tota_service_cost = 0;
  //             // selectedAppointmentServices &&
  //             //   selectedAppointmentServices.map(
  //             //     (service) => {
  //             //       tota_service_cost +=
  //             //         +service.cost;
  //             //     }
  //             //   );
  //             // setTotalServiceAmount(
  //             //   tota_service_cost
  //             // );
  //           },
  //         })}
  //         noDataIndication="No data to display."
  //         striped
  //         hover
  //         condensed
  //       />
  //     </div>
  //   ),
  // };
  const handleOnSelectRow = (row, isSelect) => {
    let arr = JSON.parse(JSON.stringify(allItems));
    let selectedCatIndex = arr.findIndex((cat) => {
      return cat._id === row.category;
    });
    let selectedItemIndex = arr[selectedCatIndex].items.findIndex((item) => {
      return item.item_id === row.item_id;
    });

    arr[selectedCatIndex].items[selectedItemIndex].is_selected = isSelect;
    // arr.forEach((category) => {
    //   if (category._id === row.category);
    //   {
    //     let selectedItemIndex = category.items.findIndex((item) => {
    //       return item.item_id === row.item_id;
    //     });
    //     // console.log(selectedItemIndex);
    //     if (selectedItemIndex >= 0) {
    //       category.items[selectedItemIndex].is_selected = isSelect;
    //     }
    //     break;
    //   }
    // });

    setAllItems(arr);
    return true;
  };
  const handleOnSelectAllRows = (is_selected, rows) => {
    let arr = JSON.parse(JSON.stringify(allItems));
    let selectedCatIndex = arr.findIndex((cat) => {
      return cat._id === rows[0].category;
    });

    arr[selectedCatIndex].items.map((item) => {
      item.is_selected = is_selected;
    });
    console.log(arr);
    setAllItems(arr);
  };
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: handleOnSelectRow,
    onSelectAll: handleOnSelectAllRows,
  };
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} - {to} of {size} records.
    </span>
  );

  const pagination_options = {
    paginationSize: 50,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: allItems && allItems.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };
  const handleSubmitRequisition = async () => {
    let validFlag = true;
    let arrSelected = JSON.parse(JSON.stringify(selectedItems));

    let data = arrSelected.map((selected_cat) => {
      if (selected_cat.supplier) {
        const itemIndex = allItems.findIndex(
          (item) => item._id === selected_cat.category
        );
        let selectedCategory_items = allItems[itemIndex].items.filter(
          (item) => {
            if (item.is_selected && +item.quantity === 0) {
              validFlag = false;
              showNotification(
                "Please enter quantity for all selected items.",
                "Error"
              );
            }
            return item.is_selected && item.quantity > 0;
          }
        );
        if (selectedCategory_items.length > 0 && validFlag) {
          selected_cat.items = [...selectedCategory_items];
          return selected_cat;
        } else if (validFlag) {
          validFlag = false;
          showNotification(
            "Please select at least one item for every selected supplier.",
            "Error"
          );
        }
      } else {
        validFlag = false;
        showNotification(
          "Please select supplier for all selected items.",
          "Error"
        );
      }
    });
    if (validFlag) {
      try {
        if (data.length > 0) {
          let url = process.env.REACT_APP_BASEURL + "branchporequests/insert";
          let response = await postSubmitForm(url, data);
          if (response.status === 1) {
            showNotification(response.message, "Success");
            for (let i = 0; i < response.data.length; i++) {
              window.open(
                `/purchase_order?po_id=${response.data[i]}`,
                "_blank"
              );
            }
          } else {
            showNotification(response.message, "Error");
          }
        } else {
          showNotification(
            "Please select at least one item and supplier.",
            "Error"
          );
        }
      } catch (error) {
        showNotification(error.message, "Error");
      }
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Branch PO Request")}
            title={props.t("Procurement")}
            breadcrumbItem1={props.t("Branch PO Request")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {allItems.map((item) => {
                    return (
                      <>
                        <Row>
                          <Col lg={6}>
                            <Label>Category: {item._id}</Label>
                          </Col>
                          <Col lg={6}>
                            <AvForm>
                              <AvField
                                name="supplier"
                                type="select"
                                category={item._id}
                                onChange={handleSupplierSelection}
                              >
                                <option value="">
                                  {props.t("--Select Supplier--")}
                                </option>
                                {item.suppliers &&
                                  item.suppliers.map((e) => (
                                    <option
                                      key={e._id}
                                      value={e._id}
                                      name={e.name}
                                    >
                                      {e.name}
                                    </option>
                                  ))}
                              </AvField>
                            </AvForm>
                          </Col>
                        </Row>
                        <BootstrapTable
                          bootstrap4
                          keyField="item_id"
                          data={item.items}
                          columns={columns_items}
                          selectRow={selectRow}
                          cellEdit={cellEditFactory({
                            mode: "click",
                            blurToSave: true,
                            afterSaveCell: (
                              oldValue,
                              newValue,
                              row,
                              column
                            ) => {
                              if (column.dataField === "quantity") {
                                if (newValue > row.max_order_limit) {
                                  row.quantity = oldValue;
                                  showNotification(
                                    `Cannot order more than ${row.max_order_limit}.`,
                                    "Error"
                                  );
                                }
                              }
                              row.total_price = row.price * row.quantity;
                            },
                          })}
                          noDataIndication="No data to display."
                          striped
                          hover
                          condensed
                        />
                        <hr />
                      </>
                    );
                  })}
                  <Row>
                    <Col lg={12}>
                      <FormGroup className="mb-0 text-center">
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="mr-1"
                            onClick={handleSubmitRequisition}
                          >
                            {props.t("Submit")}
                          </Button>{" "}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <ToolkitProvider
                    bootstrap4
                    keyField="_id"
                    data={allItems && allItems}
                    columns={columns}
                    noDataIndication={props.t("No data to display.")}
                    search
                  >
                    {(props) => (
                      <div>
                        <Row>
                          <Col lg={4}>
                            <SearchBar
                              {...props.searchProps}
                              style={{ width: "300px" }}
                            />
                          </Col>
                          <Col lg={4}></Col>
                        </Row>
                        <BootstrapTable
                          striped
                          hover
                          condensed
                          expandRow={expandRow}
                          {...props.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(BranchWiseRequisition))
);
