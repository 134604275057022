import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Button,
  CardTitle,
  Label,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb

import Breadcrumb_2Items from "../../../../../components/Common/Breadcrumb_2Items";
import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import showNotification from "../../../../../helpers/show_notification";

import { postSubmitForm } from "../../../../../helpers/forms_helper";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import moment from "moment";
const animatedComponents = makeAnimated();
const FinishedProductTransferInventory = (props) => {
  const refForForm = useRef(null);
  const refSelectProduct = useRef(null);
  useEffect(() => {
    loadAllBranches();
  }, []);
  const refContainer = useRef(null);

  const refSelectBranch = useRef(null);
  const [allBranches, setAllBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState();
  const [allToBranches, setAllToBranches] = useState();
  const [selectedToBranch, setSelectedToBranch] = useState();
  const [allFromBranches, setAllFromBranches] = useState();
  const [selectedFromBranch, setSelectedFromBranch] = useState();
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
      setAllToBranches(response.data);
      setAllFromBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  useEffect(() => {
    if (selectedFromBranch) {
      refSelectBranchProduct.current.select.clearValue();
      setSelectedBranchProduct();
      loadAllBranchProducts();
    }
  }, [selectedFromBranch]);

  const refSelectBatch = useRef(null);
  const refSelectBranchProduct = useRef(null);
  const [allBranchProducts, setAllBranchProducts] = useState([]);
  const [selectedBranchProduct, setSelectedBranchProduct] = useState();
  const loadAllBranchProducts = async () => {
    let url = process.env.REACT_APP_BASEURL + "branchproducts/get_by_branch";
    let response = await postSubmitForm(url, {
      branch_id: selectedFromBranch._id,
    });
    if (response.status === 1) {
      setAllBranchProducts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  useEffect(() => {
    if (selectedBranchProduct) {
      // refSelectBatch.current.select.clearValue();
      setSelectedBatch();
      loadAllBranchProductBatches();
    }
  }, [selectedBranchProduct]);
  const [allBatches, setAllBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState();
  const loadAllBranchProductBatches = async () => {
    let url =
      process.env.REACT_APP_BASEURL +
      "branchproductinventories/get_by_product_branch";
    let response = await postSubmitForm(url, {
      branch_id: selectedFromBranch._id,
      product_id: selectedBranchProduct.product_id,
    });
    if (response.status === 1) {
      setAllBatches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [remarks, setRemarks] = useState();
  const [quantity, setQuantity] = useState();
  const [productsForTransfer, setProductsForTransfer] = useState([]);
  const handleAddProductForTransfer = async (e, v) => {
    var new_array_products = JSON.parse(JSON.stringify(productsForTransfer));
    const existing_product = new_array_products.filter(function (product) {
      return product.batch === selectedBatch.batch;
    });

    if (existing_product.length > 0) {
      showNotification(
        props.t("This product batch is already added."),
        props.t("Error")
      );
    } else if (selectedBatch) {
      let product_to_add = {};
      product_to_add.product_id = selectedBranchProduct.product_id;
      product_to_add.name = selectedBranchProduct.name;
      product_to_add.section = selectedBranchProduct.section;
      product_to_add.category = selectedBranchProduct.category;
      product_to_add.quantity = quantity;
      product_to_add.unit = selectedBatch.product_details.unit;
      product_to_add.batch = selectedBatch.batch;
      product_to_add.expiry_date = selectedBatch.expiry_date;
      product_to_add.cost_price = selectedBatch.cost_price;
      product_to_add.selling_price = selectedBatch.selling_price;

      setProductsForTransfer([...productsForTransfer, product_to_add]);

      setSelectedBranchProduct();
      refSelectBranchProduct.current.select.clearValue();
    } else {
      showNotification(
        props.t("Select a product batch first."),
        props.t("Error")
      );
    }
  };

  const handleValidSubmit = async (e, v) => {
    try {
      const object = {
        product_details: productsForTransfer,
        remarks: v.remarks,
        branch_from: {
          branch_id: selectedFromBranch._id,
          name: selectedFromBranch.name,
          email: selectedFromBranch.email,
          mobile: selectedFromBranch.mobile,
        },
        branch_to: {
          branch_id: selectedToBranch._id,
          name: selectedToBranch.name,
          email: selectedToBranch.email,
          mobile: selectedToBranch.mobile,
        },
      };
      let url = process.env.REACT_APP_BASEURL + "producttransferorders/insert";
      let response = await postSubmitForm(url, object);
      if (response.status === 1) {
        setRemarks();
        setProductsForTransfer([]);
        setSelectedBranchProduct();
        refSelectBranchProduct.current.select.clearValue();
        refForForm.current.reset();
        showNotification(response.message, "Success");
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };
  function deleteFormatter(cell, row, rowIndex, extraData) {
    return (
      <>
        <span className="text-danger">
          <i
            className="bx bxs-trash font-size-15"
            title="Click to Delete"
            style={{ cursor: "pointer" }}
            onClick={() => {
              let arr = extraData.filter((item) => item.batch !== row.batch);

              setProductsForTransfer(arr);
            }}
          ></i>
        </span>
      </>
    );
  }
  const columns = [
    {
      dataField: "section",
      text: props.t("Section"),
    },
    {
      dataField: "category",
      text: props.t("Category"),
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "batch",
      text: props.t("Batch"),
    },
    {
      dataField: "expiry_date",
      text: props.t("Expiry Date"),
      formatter: (cell, row) => {
        return moment(row.expiry_date).format("DD/MM/YYYY");
      },
    },
    {
      dataField: "quantity",
      text: props.t("Transfer Quantity"),
    },
    {
      formatter: deleteFormatter,
      formatExtraData: productsForTransfer,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Finished Products")}
            title={props.t("Central Kitchen")}
            breadcrumbItem1={props.t("Branch Inventory")}
            breadcrumbItem2={props.t("Transfer")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    onValidSubmit={handleAddProductForTransfer}
                    ref={refForForm}
                  >
                    <CardTitle>{props.t("Inter-Branch Transfer")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Enter the following details to initiate inter-branch transfer of finished products"
                      )}
                    </CardSubtitle>
                    <Row>
                      <Col lg={4}>
                        <Label>{props.t("To Branch")}</Label>
                        <Select
                          ref={refSelectBranch}
                          value={selectedToBranch}
                          onChange={(selected) => {
                            let arr = allBranches.filter(
                              (item) => item._id !== selected._id
                            );

                            setAllFromBranches(arr);
                            setSelectedToBranch(selected);
                          }}
                          options={allToBranches}
                          classNamePrefix="select2-selection"
                          components={animatedComponents}
                          required
                        />
                      </Col>
                      <Col lg={4}>
                        <Label>{props.t("From Branch")}</Label>
                        <Select
                          ref={refSelectBranch}
                          value={selectedFromBranch}
                          onChange={(selected) => {
                            let arr = allBranches.filter(
                              (item) => item._id !== selected._id
                            );
                            setAllBranchProducts([]);
                            setProductsForTransfer([]);
                            setAllToBranches(arr);
                            setSelectedFromBranch(selected);
                          }}
                          options={allFromBranches}
                          classNamePrefix="select2-selection"
                          components={animatedComponents}
                          required
                        />
                      </Col>
                      {selectedFromBranch && (
                        <Col lg={4}>
                          <Label>{props.t("Product")}</Label>
                          <Select
                            ref={refSelectBranchProduct}
                            value={selectedBranchProduct}
                            onChange={(selected) => {
                              setSelectedBranchProduct(selected);
                            }}
                            options={allBranchProducts}
                            classNamePrefix="select2-selection"
                            components={animatedComponents}
                            required
                          />
                        </Col>
                      )}
                      {selectedBranchProduct && (
                        <>
                          <Col lg={2}>
                            <br />
                            <Label>Available Quantity</Label>
                            <br />
                            <Label>{selectedBranchProduct.available}</Label>
                          </Col>
                          <Col lg={2}>
                            <br />
                            <Label>Threshold Quantity</Label>
                            <br />
                            <Label>{selectedBranchProduct.threshold}</Label>
                          </Col>
                        </>
                      )}

                      {selectedBranchProduct ? (
                        selectedBranchProduct.available > 0 ? (
                          <>
                            <Col lg={3}>
                              <br />
                              <Label>{props.t("Batch")}</Label>
                              <Select
                                ref={refSelectBatch}
                                value={selectedBatch && selectedBatch}
                                key={selectedBatch}
                                onChange={(e) => {
                                  setSelectedBatch(e);
                                }}
                                options={allBatches && allBatches}
                                classNamePrefix="select2-selection"
                                required
                              />
                            </Col>

                            <Col lg={3}>
                              <br />
                              <Label>{props.t("Quantity")}</Label>

                              <AvField
                                name="quantity"
                                placeholder={props.t("Enter Quantity")}
                                type="text"
                                onChange={(e) => {
                                  setQuantity(e.target.value);
                                }}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: props.t(
                                      "Quantity cannot be empty."
                                    ),
                                  },
                                  pattern: {
                                    value: "^[0-9]+$",
                                    errorMessage: props.t(
                                      "Only numbers are allowed."
                                    ),
                                  },
                                  max: {
                                    value:
                                      selectedBatch && selectedBatch.quantity,
                                    errorMessage: selectedBatch
                                      ? props.t("Cannot enter more than ") +
                                        selectedBatch.quantity +
                                        "."
                                      : props.t("Select a batch first."),
                                  },
                                }}
                              />
                            </Col>
                            <Col lg={2}>
                              {" "}
                              <br />
                              <Label>&nbsp;</Label>
                              <br />
                              <Button
                                type="submit"
                                color="primary"
                                className="btn btn-info waves-effect waves-light btn-sm"
                              >
                                <i class="bx bx-add-to-queue"></i>{" "}
                                {props.t("Add")}
                              </Button>
                            </Col>
                          </>
                        ) : (
                          <Col lg={6}>
                            <p class="text-muted mb-2">&nbsp;</p>
                            <div
                              class="alert alert-danger fade show text-center"
                              role="alert"
                            >
                              {props.t("This product is out of stock.")}
                            </div>
                          </Col>
                        )
                      ) : null}
                    </Row>
                    {/* <Button type="submit">Submit</Button> */}
                  </AvForm>
                  <hr />
                  {productsForTransfer && productsForTransfer.length > 0 && (
                    <>
                      <BootstrapTable
                        bootstrap4
                        keyField="_id"
                        data={productsForTransfer && productsForTransfer}
                        columns={columns}
                        noDataIndication="No data to display."
                        striped
                        hover
                        condensed
                      />
                      <AvForm onValidSubmit={handleValidSubmit}>
                        <Row>
                          <Col lg={6}>
                            <AvField
                              name="remarks"
                              value={remarks}
                              onChange={(e) => {
                                setRemarks(e.target.value);
                              }}
                              label={props.t("Remarks")}
                              placeholder={props.t("Enter Remarks")}
                              type="textarea"
                            />
                          </Col>
                          <Col lg={12}>
                            <hr />
                            <FormGroup className="mb-0 text-left">
                              <div>
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="mr-1"
                                >
                                  {props.t("Submit")}
                                </Button>{" "}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </AvForm>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(
  connect(null, {})(withNamespaces()(FinishedProductTransferInventory))
);
