import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const ItemEditOrders = (props) => {
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }

  const { SearchBar } = Search;
  const [editModal, setEditModal] = useState();
  const [remarks, setRemarks] = useState();
  useEffect(() => {
    loadAllBranches();
    loadAllCategories();
  }, []);
  const [confirmClose, setConfirmClose] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [selectedCategory, setSelectedCategory] = useState("All");

  useEffect(() => {
    loadAllOrders();
  }, [selectedCategory, selectedBranch]);

  const [allOrders, setAllOrders] = useState([]);
  const loadAllOrders = async () => {
    preloader(true);
    let url = process.env.REACT_APP_BASEURL + "itemorders/getall";
    const response = await postSubmitForm(url, {
      status: [
        "Approved",
        // "Dispatched",
        // "Partially Dispatched",
        // "Delivered",
        // "Partially Delivered",
      ],
      branch: selectedBranch,
      category: selectedCategory,
    });
    if (response && response.status === 1) {
      // let expandedRows = [];
      // response.data.map((item) => {
      //   expandedRows.push(item._id);
      // });
      preloader(false);
      setAllOrders(response.data);
      // setExpanded(expandedRowsetAllOrderss);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };
  const refForForm = useRef(null);
  const refSelectItem = useRef(null);
  const [itemsForOrder, setItemsForOrder] = useState([]);
  const handleAddItemForOrder = async (e, v) => {
    var new_array_items = JSON.parse(JSON.stringify(itemsForOrder));
    const existing_item = new_array_items.filter(function (item) {
      return item.item_id === selectedItem._id;
    });

    if (existing_item.length > 0) {
      showNotification(
        props.t("This item is already added."),
        props.t("Error")
      );
    } else if (selectedItem) {
      let item_to_add = {};
      item_to_add.item_id = selectedItem._id;
      item_to_add.name = selectedItem.name;
      item_to_add.category = selectedItem.category;
      item_to_add.sub_category = selectedItem.sub_category;
      item_to_add.brand = selectedItem.brand;
      item_to_add.unit = selectedItem.unit;
      if (selectedItem.sub_unit) {
        item_to_add.sub_unit = selectedItem.sub_unit;
      }
      item_to_add.formula = selectedItem.formula;
      item_to_add.available = selectedItem.available;
      item_to_add.quantity = selectedItem.sub_unit
        ? { in_unit: v.in_unit, in_sub_unit: v.in_sub_unit }
        : { in_unit: v.quantity, in_sub_unit: 0 };
      setItemsForOrder([...itemsForOrder, item_to_add]);
      refSelectItem.current.select.clearValue();
      refForForm.current.reset();
    } else {
      showNotification(props.t("Select an item first."), props.t("Error"));
    }
  };
  const [isUrgent, setIsUrgent] = useState(false);
  const handleValidUpdate = async (e, v) => {
    try {
      const object = {
        id: selectedOrder._id,
        item_details: itemsForOrder,
        //is_urgent: isUrgent,
        remarks: v.remarks,
      };
      let url = process.env.REACT_APP_BASEURL + "itemorders/update";
      let response = await postSubmitForm(url, object);
      if (response.status === 1) {
        loadAllOrders();
        setEditModal(!editModal);
        setRemarks();
        setItemsForOrder([]);
        setIsUrgent(false);
        setSelectedItem();
        refSelectItem.current.select.clearValue();
        refForForm.current.reset();

        showNotification(response.message, "Success");

        refForForm.current.reset();
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };
  const [allBranches, setAllBranches] = useState([]);
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [allCategories, setAllCategories] = useState([]);
  const loadAllCategories = async () => {
    let url = process.env.REACT_APP_BASEURL + "itemcategories/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllCategories(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const [selectedOrder, setSelectedOrder] = useState();
  const handleCloseOrder = async () => {
    let url = process.env.REACT_APP_BASEURL + "itemorders/set_closed";
    const response = await postSubmitForm(url, {
      itemorder_id: selectedOrder._id,
      admin_remarks: adminRemarks,
    });

    if (response && response.status === 1) {
      setAdminRemarks("");
      loadAllOrders();
      setRemarksModal(!remarksModal);
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [remarksModal, setRemarksModal] = useState();
  const [adminRemarks, setAdminRemarks] = useState();
  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      dataField: "order_id",
      text: props.t("Order ID"),

      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      dataField: "branch_details.name",
      text: props.t("Branch Name"),

      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),

      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
    },
    {
      text: props.t("Order Date"),
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },

      dataField: "createdAt",
      // formatter: (cell, row) => {
      //   return moment(row.createdAt).format("DD-MM-YYYY HH:mm");
      // },
    },

    {
      dataField: "status",
      text: props.t("Status"),
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "",
      text: props.t("Action"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            {/* <button
              class="btn btn-secondary btn-sm mr-2"
              onClick={() => {
                setSelectedOrder(row);
                setItemsForDelivery([]);
                setSelectedItem();
                setSelectedBatch();
                setQuantity();
                loadItemsInOrder(row._id);
                setOrderDispatchModal(!orderDispatchModal);
                if (row.status === "Approved") {
                  setIsPartialDelivery(false);
                  handleSetItemsForFullDelivery(row.item_details);
                } else setIsPartialDelivery(true);
                setCentralAvailableStatus(row.central_available_status);
                setIsDispatched(false);
              }}
            >
              {props.t("Dispatch")}
            </button>
            <button
              class="btn btn-danger btn-sm mr-2"
              onClick={() => {
                setRemarksModal(!remarksModal);
                setSelectedOrder(row);
              }}
            >
              {props.t("Close")}
            </button> */}
            <button
              class="btn btn-info btn-sm mr-2"
              onClick={() => {
                setEditModal(!editModal);
                loadItemsInOrder(row._id);
                setItemsForOrder(row.item_details);
                setSelectedOrder(row);
              }}
            >
              {props.t("Edit")}
            </button>
          </>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
  ];

  //#region Dispatch order related code
  const [itemsForDelivery, setItemsForDelivery] = useState([]);
  const [quantity, setQuantity] = useState();
  const [quantityInUnit, setQuantityInUnit] = useState();
  const [quantityInSubUnit, setQuantityInSubUnit] = useState();
  const [selectedItem, setSelectedItem] = useState();

  const [allItems, setAllItems] = useState([]);
  const loadItemsInOrder = async (itemorder_id) => {
    let url = process.env.REACT_APP_BASEURL + "itemorders/get_items_in_order";

    const response = await postSubmitForm(url, { itemorder_id });

    if (response && response.status === 1) {
      setAllItems(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function deleteFormatter_edit(cell, row, rowIndex, extraData) {
    return (
      <>
        <span className="text-danger">
          <i
            className="bx bxs-trash font-size-15"
            title="Click to Delete"
            style={{ cursor: "pointer" }}
            onClick={() => {
              let arr = extraData.filter(
                (item) => item.item_id !== row.item_id
              );

              setItemsForOrder(arr);
            }}
          ></i>
        </span>
      </>
    );
  }

  const columns_items_edit = [
    {
      dataField: "category",
      text: props.t("Category"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "sub_category",
      text: props.t("Sub-Category"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "name",
      text: props.t("Name"),
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      sort: true,
    },
    {
      dataField: "unit",
      text: props.t("Available"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.available.in_unit +
                " " +
                row.unit +
                " " +
                row.available.in_sub_unit +
                " " +
                row.sub_unit
              : row.available.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Order Quantity"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      formatter: deleteFormatter_edit,
      formatExtraData: itemsForOrder,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];

  //#endregion

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Items")}
            title={props.t("Central Store")}
            breadcrumbItem1={props.t("Item Order")}
            breadcrumbItem2={props.t("Edit Orders")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Edit Orders")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("Edit orders in process here")}
                  </CardSubtitle>

                  <ToolkitProvider
                    keyField="_id"
                    data={allOrders && allOrders}
                    columns={columns_external}
                    noDataIndication={props.t("No data to display.")}
                    bootstrap4
                    search
                  >
                    {(props_table) => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4}>
                              <Label>{props.t("Search")}</Label>
                              <SearchBar
                                {...props_table.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}>
                              <AvField
                                name="branch"
                                label={props.t("Branch")}
                                type="select"
                                value={selectedBranch}
                                onChange={(e) => {
                                  setSelectedBranch(e.target.value);
                                }}
                              >
                                <option value="All">{props.t("All")}</option>
                                {allBranches &&
                                  allBranches.map((branch) => {
                                    return (
                                      <option value={branch._id}>
                                        {branch.name}
                                      </option>
                                    );
                                  })}
                              </AvField>
                            </Col>
                            <Col lg={4}>
                              <AvField
                                name="category"
                                label={props.t("Category")}
                                type="select"
                                value={selectedCategory}
                                onChange={(e) => {
                                  setSelectedCategory(e.target.value);
                                }}
                              >
                                <option value="All">{props.t("All")}</option>
                                {allCategories &&
                                  allCategories.map((category) => {
                                    return (
                                      <option value={category.category}>
                                        {category.category}
                                      </option>
                                    );
                                  })}
                              </AvField>
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          striped
                          hover
                          condensed
                          {...props_table.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>

                  {confirmClose && (
                    <SweetAlert
                      title={props.t("Are you sure?")}
                      warning
                      showCancel
                      cancelBtnText={props.t("No")}
                      confirmBtnText={props.t("Yes")}
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        setConfirmClose(false);
                        handleCloseOrder();
                      }}
                      onCancel={() => {
                        setConfirmClose(false);
                      }}
                    >
                      {props.t(
                        "Once you close this order, it cannot be revoked."
                      )}
                    </SweetAlert>
                  )}

                  {/* Edit Modal starts */}
                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update Order")}
                    </ModalHeader>
                    <ModalBody>
                      {selectedOrder && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm
                                    onValidSubmit={handleAddItemForOrder}
                                    ref={refForForm}
                                  >
                                    <Row>
                                      <Col lg={5}>
                                        <Label>{props.t("Item")}</Label>
                                        <Select
                                          ref={refSelectItem}
                                          value={selectedItem}
                                          onChange={(selected) => {
                                            setSelectedItem(selected);
                                          }}
                                          options={allItems}
                                          classNamePrefix="select2-selection"
                                          components={animatedComponents}
                                          required
                                        />
                                      </Col>
                                      {selectedItem && selectedItem.sub_unit ? (
                                        <Col lg={5}>
                                          <Label>
                                            {props.t("Quantity")}{" "}
                                            {" (1 " +
                                              selectedItem.unit +
                                              " = " +
                                              selectedItem.formula +
                                              " " +
                                              selectedItem.sub_unit +
                                              ")"}
                                          </Label>
                                          <Row>
                                            <Col lg={6}>
                                              <AvField
                                                name="in_unit"
                                                placeholder={selectedItem.unit}
                                                type="text"
                                                onChange={(e) => {
                                                  setQuantityInUnit(
                                                    e.target.value
                                                  );
                                                }}
                                                validate={{
                                                  required: {
                                                    value: true,
                                                    errorMessage:
                                                      "Quantity cannot be empty.",
                                                  },
                                                  pattern: {
                                                    value: "^[0-9]+$",
                                                    errorMessage: props.t(
                                                      "Only numbers are allowed."
                                                    ),
                                                  },
                                                  // max: {
                                                  //   value:
                                                  //     selectedItem &&
                                                  //     selectedItem.max_order_limit,
                                                  //   errorMessage:
                                                  //     selectedItem &&
                                                  //     props.t(
                                                  //       "Cannot order more than "
                                                  //     ) +
                                                  //       selectedItem.max_order_limit +
                                                  //       ".",
                                                  // },
                                                }}
                                              />
                                            </Col>
                                            <Col lg={6}>
                                              <AvField
                                                name="in_sub_unit"
                                                placeholder={
                                                  selectedItem.sub_unit
                                                }
                                                type="text"
                                                validate={{
                                                  required: {
                                                    value: true,
                                                    errorMessage:
                                                      "Quantity cannot be empty.",
                                                  },
                                                  pattern: {
                                                    value: "^[0-9]+$",
                                                    errorMessage: props.t(
                                                      "Only numbers are allowed."
                                                    ),
                                                  },
                                                  // max: {
                                                  //   value:
                                                  //     selectedItem &&
                                                  //     (+selectedItem.max_order_limit ===
                                                  //     +quantityInUnit
                                                  //       ? 0
                                                  //       : +selectedItem.formula -
                                                  //         1),
                                                  //   errorMessage:
                                                  //     selectedItem &&
                                                  //     (+selectedItem.max_order_limit ===
                                                  //     +quantityInUnit
                                                  //       ? props.t(
                                                  //           "Cannot order more than "
                                                  //         ) +
                                                  //         +selectedItem.max_order_limit +
                                                  //         " " +
                                                  //         selectedItem.unit +
                                                  //         "."
                                                  //       : props.t(
                                                  //           "Cannot enter more than "
                                                  //         ) +
                                                  //         (+selectedItem.formula -
                                                  //           1) +
                                                  //         "."),
                                                  // },
                                                }}
                                              />
                                            </Col>
                                          </Row>
                                        </Col>
                                      ) : (
                                        <Col lg={5}>
                                          <Label>
                                            {props.t("Quantity")}{" "}
                                            {selectedItem
                                              ? " (in " +
                                                selectedItem.unit +
                                                ")"
                                              : ""}
                                          </Label>

                                          <AvField
                                            name="quantity"
                                            placeholder={props.t(
                                              "Enter Quantity"
                                            )}
                                            errorMessage="Quantity cannot be empty."
                                            type="text"
                                            validate={{
                                              required: { value: true },
                                              pattern: {
                                                value: "^[0-9]+$",
                                                errorMessage: props.t(
                                                  "Only numbers are allowed."
                                                ),
                                              },
                                              // max: {
                                              //   value:
                                              //     selectedItem &&
                                              //     selectedItem.max_order_limit,
                                              //   errorMessage:
                                              //     selectedItem &&
                                              //     props.t(
                                              //       "Cannot order more than "
                                              //     ) +
                                              //       selectedItem.max_order_limit +
                                              //       ".",
                                              // },
                                            }}
                                          />
                                        </Col>
                                      )}
                                      <Col lg={2} className="mb-4">
                                        <Label>&nbsp;</Label>
                                        <br />
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="btn btn-info waves-effect waves-light btn-sm"
                                        >
                                          <i class="bx bx-add-to-queue"></i>{" "}
                                          {props.t("Add")}
                                        </Button>
                                      </Col>
                                    </Row>
                                    {/* <Button type="submit">Submit</Button> */}
                                  </AvForm>
                                  <hr />
                                  {itemsForOrder &&
                                    itemsForOrder.length > 0 && (
                                      <>
                                        <BootstrapTable
                                          bootstrap4
                                          keyField="_id"
                                          data={itemsForOrder && itemsForOrder}
                                          columns={columns_items_edit}
                                          noDataIndication="No data to display."
                                          striped
                                          hover
                                          condensed
                                        />
                                        <AvForm
                                          onValidSubmit={handleValidUpdate}
                                        >
                                          <Row>
                                            <Col lg={6}>
                                              <AvField
                                                name="remarks"
                                                value={remarks}
                                                onChange={(e) => {
                                                  setRemarks(e.target.value);
                                                }}
                                                label={props.t("Remarks")}
                                                placeholder={props.t(
                                                  "Enter Remarks"
                                                )}
                                                type="textarea"
                                              />
                                            </Col>
                                            {/* <Col lg={12}>
                                            <div class="form-check">
                                              <input
                                                type="checkbox"
                                                name="is_urgent"
                                                className="is-untouched is-pristine av-valid form-check-input"
                                                checked={isUrgent}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    setIsUrgent(true);
                                                  } else {
                                                    setIsUrgent(false);
                                                  }
                                                }}
                                              />
                                              <label for="select_all">
                                                {" "}
                                                {props.t(
                                                  "This is an urgent order."
                                                )}
                                              </label>
                                            </div>
                                          </Col> */}
                                            <Col lg={12}>
                                              <hr />
                                              <FormGroup className="mb-0 text-left">
                                                <div>
                                                  <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="mr-1"
                                                  >
                                                    {props.t("Update Order")}
                                                  </Button>{" "}
                                                </div>
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                        </AvForm>
                                      </>
                                    )}
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>

                  {/* Edit Modal ends */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(connect(null, {})(withNamespaces()(ItemEditOrders)));
