import React, { Component } from "react";
import { Card, CardBody, CardTitle, Media } from "reactstrap";
import { Link } from "react-router-dom";

class ActivityComp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle className="mb-5">Notifications</CardTitle>
            <ul className="verti-timeline list-unstyled">
              <li className="event-list">
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle font-size-18"></i>
                </div>
                <Media>
                  <div className="mr-3">
                    <h5 className="font-size-10">
                      22 Apr 09:42 AM{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2"></i>
                    </h5>
                  </div>
                  <Media body>
                    <div>New order recieved</div>
                  </Media>
                </Media>
              </li>

              <li className="event-list">
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle font-size-18"></i>
                </div>
                <Media>
                  <div className="mr-3">
                    <h5 className="font-size-10">
                      21 Apr 12:35 PM{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2"></i>
                    </h5>
                  </div>
                  <Media body>
                    <div>Order dispatched</div>
                  </Media>
                </Media>
              </li>
              <li className="event-list">
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle font-size-18"></i>
                </div>
                <Media>
                  <div className="mr-3">
                    <h5 className="font-size-10">
                      22 Apr 09:42 AM{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2"></i>
                    </h5>
                  </div>
                  <Media body>
                    <div>New order recieved</div>
                  </Media>
                </Media>
              </li>

              <li className="event-list">
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle font-size-18"></i>
                </div>
                <Media>
                  <div className="mr-3">
                    <h5 className="font-size-10">
                      21 Apr 12:35 PM{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2"></i>
                    </h5>
                  </div>
                  <Media body>
                    <div>Order dispatched</div>
                  </Media>
                </Media>
              </li>
            </ul>
            <div className="text-center mt-4">
              <Link
                to=""
                className="btn btn-primary waves-effect waves-light btn-sm"
              >
                View All <i className="mdi mdi-arrow-right ml-1"></i>
              </Link>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default ActivityComp;
