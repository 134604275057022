import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Button,
  CardTitle,
  Label,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb

import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";
import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import showNotification from "../../../../helpers/show_notification";

import { postSubmitForm } from "../../../../helpers/forms_helper";

import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();
const ItemAddOrder = (props) => {
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const refForForm = useRef(null);
  const refSelectItem = useRef(null);
  useEffect(() => {
    loadAllItems();
  }, []);
  const refContainer = useRef(null);

  const [isUrgent, setIsUrgent] = useState(false);
  const [remarks, setRemarks] = useState();
  const [quantityInUnit, setQuantityInUnit] = useState();
  const [allItems, setAllItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const loadAllItems = async () => {
    preloader(true);
    let url = process.env.REACT_APP_BASEURL + "branchitems/getforbranch";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      preloader(false);
      setAllItems(response.data);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };
  const [itemsForOrder, setItemsForOrder] = useState([]);
  const handleAddItemForOrder = async (e, v) => {
    var new_array_items = JSON.parse(JSON.stringify(itemsForOrder));
    const existing_item = new_array_items.filter(function (item) {
      return item.item_id === selectedItem.item_id;
    });

    if (existing_item.length > 0) {
      showNotification(
        props.t("This item is already added."),
        props.t("Error")
      );
    } else if (selectedItem) {
      // let url = process.env.REACT_APP_BASEURL + "itemorders/is_item_allowed";
      // let response = await postSubmitForm(url, {
      //   item_id: selectedItem.item_id,
      // });
      // if (response.status === 1) {
      let item_to_add = {};
      item_to_add.item_id = selectedItem.item_id;
      item_to_add.name = selectedItem.name;
      item_to_add.category = selectedItem.category;
      item_to_add.sub_category = selectedItem.sub_category;
      item_to_add.brand = selectedItem.brand;
      item_to_add.unit = selectedItem.unit;
      if (selectedItem.sub_unit) {
        item_to_add.sub_unit = selectedItem.sub_unit;
      }
      item_to_add.formula = selectedItem.formula;
      item_to_add.available = selectedItem.available;
      item_to_add.quantity = selectedItem.sub_unit
        ? { in_unit: +v.in_unit, in_sub_unit: +v.in_sub_unit }
        : { in_unit: +v.quantity, in_sub_unit: 0 };
      item_to_add.new_available = selectedItem.sub_unit
        ? { in_unit: +v.new_in_unit, in_sub_unit: +v.new_in_sub_unit }
        : { in_unit: +v.new_in_unit, in_sub_unit: 0 };
      setItemsForOrder([...itemsForOrder, item_to_add]);
      // console.log(item_to_add);
      refSelectItem.current.select.clearValue();
      refForForm.current.reset();
      // } else {
      //   showNotification(props.t(response.message), props.t("Error"));
      // }
    } else {
      showNotification(props.t("Select an item first."), props.t("Error"));
    }
  };
  const handleValidSubmit = async (e, v) => {
    try {
      const object = {
        item_details: itemsForOrder,
        //is_urgent: isUrgent,
        remarks: v.remarks,
      };
      let url = process.env.REACT_APP_BASEURL + "itemorders/insert";
      let response = await postSubmitForm(url, object);
      if (response.status === 1) {
        setRemarks();
        setItemsForOrder([]);
        setIsUrgent(false);
        setSelectedItem();
        loadAllItems();
        refSelectItem.current.select.clearValue();
        refForForm.current.reset();

        showNotification(response.message, "Success");

        refForForm.current.reset();
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };
  function deleteFormatter(cell, row, rowIndex, extraData) {
    return (
      <>
        <span className="text-danger">
          <i
            className="bx bxs-trash font-size-15"
            title="Click to Delete"
            style={{ cursor: "pointer" }}
            onClick={() => {
              let arr = extraData.filter(
                (item) => item.item_id !== row.item_id
              );

              setItemsForOrder(arr);
            }}
          ></i>
        </span>
      </>
    );
  }
  const columns = [
    {
      dataField: "category",
      text: props.t("Category"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "sub_category",
      text: props.t("Sub-Category"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "name",
      text: props.t("Name"),
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      sort: true,
    },
    {
      dataField: "unit",
      text: props.t("Available"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.new_available.in_unit +
                " " +
                row.unit +
                " " +
                row.new_available.in_sub_unit +
                " " +
                row.sub_unit
              : row.new_available.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Order Quantity"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      formatter: deleteFormatter,
      formatExtraData: itemsForOrder,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];
  const [newAvailableInUnit, setNewAvailableInUnit] = useState();
  useEffect(() => {
    if (selectedItem) setNewAvailableInUnit(selectedItem.available.in_unit);
  }, [selectedItem]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Items")}
            title={props.t("Orders")}
            breadcrumbItem1={props.t("Items")}
            breadcrumbItem2={props.t("Add Order")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    onValidSubmit={handleAddItemForOrder}
                    ref={refForForm}
                  >
                    <CardTitle>{props.t("Add New Order")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Enter the following details to place order for items"
                      )}
                    </CardSubtitle>
                    <Row>
                      <Col lg={3}>
                        <Label>{props.t("Item")}</Label>
                        <Select
                          ref={refSelectItem}
                          value={selectedItem}
                          onChange={(selected) => {
                            setSelectedItem(selected);
                          }}
                          options={allItems}
                          classNamePrefix="select2-selection"
                          components={animatedComponents}
                          required
                        />
                      </Col>
                      {selectedItem && (
                        <>
                          {selectedItem.sub_unit ? (
                            <Col lg={3}>
                              <Label>
                                {props.t("Available ")}{" "}
                                {" (1 " +
                                  selectedItem.unit +
                                  " = " +
                                  selectedItem.formula +
                                  " " +
                                  selectedItem.sub_unit +
                                  ")"}
                              </Label>
                              <Row>
                                <Col lg={6}>
                                  <AvField
                                    disabled
                                    name="new_in_unit"
                                    placeholder={selectedItem.unit}
                                    type="number"
                                    min="0"
                                    step="1"
                                    value={selectedItem.available.in_unit.toString()}
                                    onChange={(e) => {
                                      //setNewAvailableInUnit(e.target.value);
                                    }}
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage:
                                          "Quantity cannot be empty.",
                                      },
                                      pattern: {
                                        value: "^[0-9]+$",
                                        errorMessage: props.t(
                                          "Only numbers are allowed."
                                        ),
                                      },
                                      max: {
                                        value:
                                          selectedItem &&
                                          selectedItem.available.in_unit,
                                        errorMessage:
                                          selectedItem &&
                                          props.t("Cannot enter more than ") +
                                            selectedItem.available.in_unit +
                                            " " +
                                            selectedItem.unit +
                                            ".",
                                      },
                                    }}
                                  />
                                </Col>
                                <Col lg={6}>
                                  <AvField
                                    disabled
                                    name="new_in_sub_unit"
                                    placeholder={selectedItem.sub_unit}
                                    value={selectedItem.available.in_sub_unit.toString()}
                                    type="number"
                                    min="0"
                                    step="1"
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage:
                                          "Quantity cannot be empty.",
                                      },
                                      pattern: {
                                        value: "^[0-9]+$",
                                        errorMessage: props.t(
                                          "Only numbers are allowed."
                                        ),
                                      },
                                      max: {
                                        value:
                                          selectedItem &&
                                          (+selectedItem.available.in_unit ===
                                          +newAvailableInUnit
                                            ? +selectedItem.available
                                                .in_sub_unit
                                            : +selectedItem.formula - 1),
                                        errorMessage:
                                          selectedItem &&
                                          (+selectedItem.available.in_unit ===
                                          +newAvailableInUnit
                                            ? props.t(
                                                "Cannot enter more than "
                                              ) +
                                              +selectedItem.available
                                                .in_sub_unit +
                                              " " +
                                              selectedItem.sub_unit +
                                              "."
                                            : props.t(
                                                "Cannot enter more than "
                                              ) +
                                              (+selectedItem.formula - 1) +
                                              "."),
                                      },
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          ) : (
                            <Col lg={3}>
                              <Label>
                                {props.t("Quantity")}{" "}
                                {selectedItem
                                  ? " (in " + selectedItem.unit + ")"
                                  : ""}
                              </Label>

                              <AvField
                                name="new_in_unit"
                                value={selectedItem.available.in_unit.toString()}
                                placeholder={props.t("Enter Quantity")}
                                type="number"
                                min="0"
                                step="1"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Quantity cannot be empty.",
                                  },
                                  pattern: {
                                    value: "^[0-9]+$",
                                    errorMessage: props.t(
                                      "Only numbers are allowed."
                                    ),
                                  },
                                  max: {
                                    value:
                                      selectedItem &&
                                      selectedItem.available.in_unit,
                                    errorMessage:
                                      selectedItem &&
                                      props.t("Cannot enter more than ") +
                                        selectedItem.available.in_unit +
                                        " " +
                                        selectedItem.unit +
                                        ".",
                                  },
                                }}
                              />
                            </Col>
                          )}
                          <Col lg={2}>
                            <Label>Threshold Quantity</Label>
                            <br />
                            <Label>
                              {selectedItem.threshold + " " + selectedItem.unit}
                            </Label>
                          </Col>
                        </>
                      )}
                      {selectedItem && selectedItem.sub_unit ? (
                        <Col lg={3}>
                          <Label>
                            {props.t("Quantity")}{" "}
                            {" (1 " +
                              selectedItem.unit +
                              " = " +
                              selectedItem.formula +
                              " " +
                              selectedItem.sub_unit +
                              ")"}
                          </Label>
                          <Row>
                            <Col lg={6}>
                              <AvField
                                name="in_unit"
                                placeholder={selectedItem.unit}
                                type="number"
                                min="0"
                                step="1"
                                onChange={(e) => {
                                  setQuantityInUnit(e.target.value);
                                }}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Quantity cannot be empty.",
                                  },
                                  pattern: {
                                    value: "^[0-9]+$",
                                    errorMessage: props.t(
                                      "Only numbers are allowed."
                                    ),
                                  },
                                  max: {
                                    value:
                                      selectedItem &&
                                      selectedItem.max_order_limit,
                                    errorMessage:
                                      selectedItem &&
                                      props.t("Cannot order more than ") +
                                        selectedItem.max_order_limit +
                                        ".",
                                  },
                                }}
                              />
                            </Col>
                            <Col lg={6}>
                              <AvField
                                name="in_sub_unit"
                                placeholder={selectedItem.sub_unit}
                                type="number"
                                min="0"
                                step="1"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Quantity cannot be empty.",
                                  },
                                  pattern: {
                                    value: "^[0-9]+$",
                                    errorMessage: props.t(
                                      "Only numbers are allowed."
                                    ),
                                  },
                                  max: {
                                    value:
                                      selectedItem &&
                                      (+selectedItem.max_order_limit ===
                                      +quantityInUnit
                                        ? 0
                                        : +selectedItem.formula - 1),
                                    errorMessage:
                                      selectedItem &&
                                      (+selectedItem.max_order_limit ===
                                      +quantityInUnit
                                        ? props.t("Cannot order more than ") +
                                          +selectedItem.max_order_limit +
                                          " " +
                                          selectedItem.unit +
                                          "."
                                        : props.t("Cannot enter more than ") +
                                          (+selectedItem.formula - 1) +
                                          "."),
                                  },
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      ) : (
                        <Col lg={3}>
                          <Label>
                            {props.t("Quantity")}{" "}
                            {selectedItem
                              ? " (in " + selectedItem.unit + ")"
                              : ""}
                          </Label>

                          <AvField
                            name="quantity"
                            placeholder={props.t("Enter Quantity")}
                            errorMessage="Quantity cannot be empty."
                            type="text"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage: props.t(
                                  "Only numbers are allowed."
                                ),
                              },
                              max: {
                                value:
                                  selectedItem && selectedItem.max_order_limit,
                                errorMessage:
                                  selectedItem &&
                                  props.t("Cannot order more than ") +
                                    selectedItem.max_order_limit +
                                    ".",
                              },
                            }}
                          />
                        </Col>
                      )}
                      {/* <Col lg={4}>
                        <AvField
                          name="quantity"
                          label={props.t("Quantity")}
                          placeholder={props.t("Enter Quantity")}
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: props.t(
                                "Quantity cannot be empty."
                              ),
                            },
                            pattern: {
                              value: "^[0-9]+$",
                              errorMessage: props.t(
                                "Only numbers are allowed."
                              ),
                            },
                            max: {
                              value:
                                selectedItem && selectedItem.max_order_limit,
                              errorMessage:
                                selectedItem &&
                                props.t("Cannot order more than ") +
                                  selectedItem.max_order_limit +
                                  ".",
                            },
                          }}
                        />
                      </Col> */}
                      <Col lg={1} className="mb-4">
                        <Label>&nbsp;</Label>
                        <br />
                        <Button
                          type="submit"
                          color="primary"
                          className="btn btn-info waves-effect waves-light btn-sm"
                        >
                          <i class="bx bx-add-to-queue"></i> {props.t("Add")}
                        </Button>
                      </Col>
                    </Row>
                    {/* <Button type="submit">Submit</Button> */}
                  </AvForm>
                  <hr />
                  {itemsForOrder && itemsForOrder.length > 0 && (
                    <>
                      <BootstrapTable
                        bootstrap4
                        keyField="_id"
                        data={itemsForOrder && itemsForOrder}
                        columns={columns}
                        noDataIndication="No data to display."
                        striped
                        hover
                        condensed
                      />
                      <AvForm onValidSubmit={handleValidSubmit}>
                        <Row>
                          <Col lg={6}>
                            <AvField
                              name="remarks"
                              value={remarks}
                              onChange={(e) => {
                                setRemarks(e.target.value);
                              }}
                              label={props.t("Remarks")}
                              placeholder={props.t("Enter Remarks")}
                              type="textarea"
                            />
                          </Col>
                          {/* <Col lg={12}>
                            <div class="form-check">
                              <input
                                type="checkbox"
                                name="is_urgent"
                                className="is-untouched is-pristine av-valid form-check-input"
                                checked={isUrgent}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setIsUrgent(true);
                                  } else {
                                    setIsUrgent(false);
                                  }
                                }}
                              />
                              <label for="select_all">
                                {" "}
                                {props.t("This is an urgent order.")}
                              </label>
                            </div>
                          </Col> */}
                          <Col lg={12}>
                            <hr />
                            <FormGroup className="mb-0 text-left">
                              <div>
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="mr-1"
                                >
                                  {props.t("Place Order")}
                                </Button>{" "}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </AvForm>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withNamespaces()(ItemAddOrder)));
