import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
  Table,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const ItemArrangementReportForCkm = (props) => {
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    { dataField: "item", hidden: true },
    { dataField: "item", text: "Product Name" },
    { dataField: "category", text: "Category" },
    { dataField: "sub_category", text: "Sub Category" },
    {
      text: "Quantity",
      formatter: (cell, row) => {
        let quantity = `${row.quantity.in_unit} ${row.unit}`;
        if (
          (row.quantity.in_sub_unit || row.quantity.in_sub_unit === 0) &&
          row.sub_unit
        ) {
          quantity = quantity + ` ${row.quantity.in_sub_unit} ${row.sub_unit}`;
        }
        return quantity;
      },
    },
  ];

  useEffect(() => {
    loadAllTimeStamps();
  }, []);
  const [allTimeStamps, setAllTimeStamps] = useState([]);
  const [selectedTimestamp, setSelectedTimestamp] = useState();
  const loadAllTimeStamps = async () => {
    let url =
      process.env.REACT_APP_BASEURL + "itemorders/get_arrangement_marks";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllTimeStamps(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  useEffect(() => {
    loadProducts();
  }, [selectedTimestamp]);
  const [data, setData] = useState([]);

  const loadProducts = async () => {
    let data_to_send = {
      mark_time:
        selectedTimestamp && selectedTimestamp !== ""
          ? selectedTimestamp
          : null,
    };

    let url =
      process.env.REACT_APP_BASEURL +
      "itemorders/arrangement_report_branchwise";
    const response = await postSubmitForm(url, data_to_send);
    if (response && response.status === 1) {
      // console.log(response.data)
      if (response.data) setData(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const getColumns = (values) => {
    const columns = [
      { dataField: "_id", hidden: true },
      //{ dataField: "prod", text: "Product Name" },
    ];

    for (let key in values[0]) {
      if (key == "Item Name") {
        columns.push({
          text: key,
          dataField: key,
        });
      } else {
        columns.push({
          text: key,
          dataField: key,
          formatter: (cell, row, rowIndex) => {
            return (
              <span>
                {cell.in_unit ? cell.in_unit : ""}{" "}
                {cell.in_sub_unit != "0" ? cell.in_sub_unit : ""}
              </span>
            );
          },
        });
      }
    }

    return columns;
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName).innerHTML;

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            width: 100%;
            color: #495057;
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }

  const printReport = async () => {
    if (!selectedTimestamp) {
      let url =
        process.env.REACT_APP_BASEURL + "itemorders/mark_arrangement_report";
      let response = await postSubmitForm(url, "");
      if (response.status === 1) {
        loadAllTimeStamps();
        window.print();
      } else {
        showNotification(response.message, "Error");
      }
    } else {
      window.print();
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Arrangement Report")}
            title={props.t("Reports")}
            breadcrumbItem1={props.t("Orders")}
            breadcrumbItem2={props.t("Arrangement Report")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={12}>
                        <AvField
                          name="timestamp"
                          label={props.t("Timestamp")}
                          value={selectedTimestamp}
                          type="select"
                          onChange={(e) => setSelectedTimestamp(e.target.value)}
                        >
                          <option value="">{props.t("Latest")}</option>
                          {allTimeStamps &&
                            allTimeStamps.map((e) => (
                              <option key={e._id} value={e.mark_time}>
                                {moment(e.mark_time).format("DD/MM/YYYY HH:mm")}
                              </option>
                            ))}
                        </AvField>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="d-print-none">
                    <div className="float-right">
                      <Link
                        to="#"
                        onClick={() => {
                          printReport();
                        }}
                        className="btn btn-success waves-effect waves-light mr-2"
                      >
                        <i className="fa fa-print"></i> Print Report
                      </Link>
                    </div>
                  </div>
                  <div id="printableArea">
                    <h3 className="text-center">Product Arrangement Report</h3>
                    <hr />
                    {data &&
                      data.map((section) => {
                        return (
                          <>
                            <div className="d-print-none">
                              <Link
                                to="#"
                                onClick={() => {
                                  printDiv(section.branch);
                                }}
                                className="btn btn-sm btn-success mr-2"
                              >
                                <i className="fa fa-print"></i> Print Branch
                              </Link>
                            </div>
                            <div id={section.branch}>
                              <CardTitle className="for-print-heading">
                                {section.branch}
                              </CardTitle>

                              <ToolkitProvider
                                bootstrap4
                                keyField="item"
                                data={section.items}
                                columns={columns}
                                noDataIndication={props.t(
                                  "No data to display."
                                )}
                                search
                                exportCSV
                              >
                                {(props) => (
                                  <div>
                                    <BootstrapTable
                                      striped
                                      hover
                                      condensed
                                      {...props.baseProps}
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                              <br />
                              <hr />
                            </div>
                          </>
                        );
                      })}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(ItemArrangementReportForCkm))
);
