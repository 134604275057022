import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Button,
  CardTitle,
  Label,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";

import showNotification from "../../helpers/show_notification";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { postSubmitForm } from "../../helpers/forms_helper";

const BranchProducts = (props) => {
  const [openingModal, setOpeningModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();

  const handleValidOpening = async (e, v) => {
    const data_to_send = {
      branch_id: selectedBranch.branch_id,
      product_id: selectedRow.product_id,
      quantity: v.quantity,
    };
    console.log(data_to_send);
    let url =
      process.env.REACT_APP_BASEURL + "branchproductinventories/opening";
    const response = await postSubmitForm(url, data_to_send);
    if (response && response.status === 1) {
      loadBranchProducts(selectedBranch.branch_id);
      setOpeningModal(!openingModal);
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };
  const refForForm = useRef(null);
  useEffect(() => {
    loadAllBranches();
  }, []);
  const refContainer = useRef(null);

  const [allBranches, setAllBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState();
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [branchProducts, setBranchProducts] = useState([]);
  const loadBranchProducts = async (branch_id) => {
    let url = process.env.REACT_APP_BASEURL + "branchproducts/get_mapping";
    let response = await postSubmitForm(url, { branch_id: branch_id });
    if (response.status === 1) {
      setBranchProducts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const handleProductAction = async (product, type, extraData) => {
    // console.log(product);
    // console.log(type);

    let arr = extraData.filter(
      (item) => item.product_id === product.product_id
    );
    const data_to_send = {
      branch_id: selectedBranch.branch_id,
      branch_name: selectedBranch.branch_name,
      product_id: arr[0].product_id,
      threshold: arr[0].threshold,
      max_order_limit: arr[0].max_order_limit,
      action: type,
    };
    // if (arr[0].threshold !== 0.0) {
    //   showNotification("Can not be decimal", "Error");
    // }

    console.log(data_to_send);
    let url = process.env.REACT_APP_BASEURL + "branchproducts/set_mapping";
    const response = await postSubmitForm(url, data_to_send);
    if (response && response.status === 1) {
      loadBranchProducts(selectedBranch.branch_id);
      if (type === "add") showToast(props.t("Branch product added."));
      if (type === "edit") showToast(props.t("Branch product updated."));
      if (type === "delete") showToast(props.t("Branch product deleted."));
    } else {
      showNotification(response.message, "Error");
    }
  };
  const columns = [
    {
      dataField: "product_id",
      hidden: true,
    },
    {
      dataField: "product_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
    },
    {
      dataField: "section",
      text: props.t("Section"),
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "category",
      text: props.t("Category"),
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Unit"),
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "threshold",
      text: props.t("Threshold"),
      sort: true,
      editable: true,
      attrs: { title: "Click to Edit" },
      style: { cursor: "pointer" },
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "max_order_limit",
      text: props.t("Max Order Limit"),
      sort: true,
      editable: true,
      attrs: { title: "Click to Edit" },
      style: { cursor: "pointer" },
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Action"),
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: false,
      formatter: (cell, row, rowIndex, extraData) => {
        if (row.is_selected) {
          return (
            <>
              <button
                class="btn btn-secondary btn-sm btn btn-primary mr-2"
                onClick={() => {
                  handleProductAction(row, "edit", extraData);
                }}
              >
                {props.t("Update")}
              </button>
              <button
                class="btn btn-danger btn-sm btn btn-primary"
                onClick={() => {
                  handleProductAction(row, "delete", extraData);
                }}
              >
                {props.t("Delete")}
              </button>
              {(selectedBranch.branch_id == "6114f39424652400083ce9e2" ||
                selectedBranch.branch_id == "6114f43e24652400083ce9e7" ||
                selectedBranch.branch_id == "61f51c5aa200bb0009ea07b4" ||
                selectedBranch.branch_id == "6232163c4999efa246dd4624" ||
                selectedBranch.branch_id == "621b37a4e5ed7b0009938370" ||
                selectedBranch.branch_id == "624d44b82db71e29a0a29d1a" ||
                selectedBranch.branch_id == "6114f52b24652400083ce9ef" ||
                selectedBranch.branch_id == "6114f23a24652400083ce9d9" ||
                selectedBranch.branch_id == "6114f5c024652400083ce9f5" ||
                selectedBranch.branch_id == "62514d654b284d30993be23d" ||
                selectedBranch.branch_id == "6114f40324652400083ce9e5" ||
                selectedBranch.branch_id == "6262f9b0f426340da59a40f0" ||
                selectedBranch.branch_id == "62a326f3bcbbfc786bb812c8") && (
                <button
                  class="btn btn-success btn-sm btn btn-primary"
                  onClick={() => {
                    setSelectedRow(row);
                    setOpeningModal(!openingModal);
                  }}
                >
                  {props.t("Opening")}
                </button>
              )}
            </>
          );
        } else {
          return (
            <button
              class="btn btn-primary btn-sm btn btn-primary"
              onClick={() => {
                handleProductAction(row, "add", extraData);
              }}
            >
              {props.t("Add")}
            </button>
          );
        }
      },
      formatExtraData: branchProducts,
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} - {to} of {size} records.
    </span>
  );
  const pagination_options = {
    paginationSize: 50,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: branchProducts && branchProducts.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };
  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.is_unsaved) {
      style.backgroundColor = "#ffcc00";
    } else if (row.is_selected) {
      style.backgroundColor = "#c7dced";
    } else {
      style.backgroundColor = "#dadada";
    }

    return style;
  };

  const showToast = (message_to_show) => {
    var ele = document.getElementsByName("toastType");
    var position = document.getElementsByName("positions");
    var toastType = "info";
    var title = "";
    var message = message_to_show;

    //Close Button
    var closeButton = true;

    //Progressbar
    var progressBar = true;

    //Duplicates
    var preventDuplicates = false;

    //Newest on Top
    var newestOnTop = true;

    //position class
    var positionClass = "toast-bottom-right";

    //show duration
    var showDuration = 200;

    //Hide duration
    var hideDuration = 1000;

    //timeout
    var timeOut = 2000;

    //extended timeout
    var extendedTimeOut = 1000;

    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showDuration: showDuration,
      hideDuration: hideDuration,
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Branch")}
            breadcrumbItem={props.t("Branch Products")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm ref={refForForm}>
                    <CardTitle>{props.t("Branch Products")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Select a branch to add/update/delete a branch product"
                      )}
                    </CardSubtitle>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="branch"
                          label={props.t("Select Branch")}
                          type="select"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Branch cannot be empty")}
                          onChange={(e) => {
                            const selectedIndex =
                              e.target.options.selectedIndex;

                            setSelectedBranch({
                              branch_id: e.target.value,
                              branch_name:
                                e.target.options[selectedIndex].getAttribute(
                                  "name"
                                ),
                            });
                            loadBranchProducts(e.target.value);
                          }}
                        >
                          <option value="">
                            {props.t("-- Select Branch --")}
                          </option>
                          {allBranches &&
                            allBranches.map((e) => (
                              <option key={e._id} value={e._id} name={e.name}>
                                {e.name}
                              </option>
                            ))}
                        </AvField>
                      </Col>
                    </Row>
                    {/* <Button type="submit">Submit</Button> */}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Branch Products")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t(
                      "View all branch products for selected branch here"
                    )}
                  </CardSubtitle>
                  <ToolkitProvider
                    keyField="product_id"
                    data={branchProducts && branchProducts}
                    columns={columns}
                  >
                    {(propst) => (
                      <div>
                        <BootstrapTable
                          {...propst.baseProps}
                          //pagination={paginationFactory(pagination_options)}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                          rowStyle={rowStyle}
                          cellEdit={cellEditFactory({
                            mode: "click",
                            blurToSave: true,
                            afterSaveCell: (
                              oldValue,
                              newValue,
                              row,
                              column
                            ) => {
                              // row.is_unsaved = true;
                              // let arr = branchProducts.filter((product)=>product.product_id === row.product_id);
                              // arr[0].is_unsaved = true;
                              // setBranchProducts()
                              // let arr = extraData.filter(
                              //   (item) => item.batch !== row.batch
                              // );
                              // console.log(row);
                            },
                          })}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="sm"
            isOpen={openingModal}
            toggle={() => setOpeningModal(!openingModal)}
          >
            <ModalHeader toggle={() => setOpeningModal(!openingModal)}>
              {props.t("Update Opening Balance")}
            </ModalHeader>
            <ModalBody>
              {selectedRow && (
                <>
                  <Row>
                    <Col lg={12}>
                      <Card>
                        <CardBody>
                          <AvForm onValidSubmit={handleValidOpening}>
                            <Row>
                              <Col lg={12}>
                                <AvField
                                  name="quantity"
                                  label={
                                    props.t("Quantity (") +
                                    selectedRow.unit +
                                    ")"
                                  }
                                  value="0"
                                  min="0"
                                  step="1"
                                  type="number"
                                />
                              </Col>

                              <Col lg={12}>
                                <FormGroup className="mb-0 text-center">
                                  <Button
                                    type="submit"
                                    color="primary"
                                    className="mr-1"
                                  >
                                    {props.t("Update")}
                                  </Button>
                                </FormGroup>
                              </Col>
                            </Row>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withNamespaces()(BranchProducts)));
