import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";

import AddInventoryComp from "./AddInventoryComp";
import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";

const ViewInventory = (props) => {
  const { SearchBar } = Search;
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  useEffect(() => {
    loadProducts();
  }, []);

  const [allProducts, setAllProducts] = useState([]);
  const loadProducts = async () => {
    let url = process.env.REACT_APP_BASEURL + "products/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      response.data.map((prod) => (prod.quantity_to_add = ""));
      setAllProducts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const [transactionsModal, setTransactionsModal] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedProduct_Transactions, setSelectedProduct_Transactions] =
    useState([]);
  const handleViewDetailsClick = async (row) => {
    let url =
      process.env.REACT_APP_BASEURL + "productinventories/get_by_product_id";
    const response = await postSubmitForm(url, { product_id: row._id });
    if (response && response.status === 1) {
      setSelectedProduct_Transactions(response.data);
      setSelectedProduct(row);

      setTransactionsModal(!transactionsModal);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [selectedProductToAdd, setSelectedProductToAdd] = useState();
  function editFormatter(cell, row) {
    return (
      <>
        <span className="text-info mr-2">
          <i
            className="mdi mdi-text-box-search font-size-15"
            title="View Details"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleViewDetailsClick(row);
            }}
          ></i>
        </span>
        <span className="text-info">
          <i
            className="mdi mdi-cart-plus font-size-15"
            title="Add Inventory"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedProductToAdd(row);
              setAddInventoryModal(!addInventoryModal);
            }}
          ></i>
        </span>
      </>
    );
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
      editable: false,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
      editable: false,
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      editable: false,
    },
    {
      dataField: "section",
      text: props.t("Section"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "category",
      text: props.t("Category"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "available",
      text: props.t("Available"),
      sort: true,
      formatter: (cell, row) => {
        return row.available < row.threshold ? (
          <span className="font-size-12 badge-soft-danger badge badge-danger badge-pill mr-1 mb-1">
            {row.available}
          </span>
        ) : (
          row.available
        );
      },

      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "quantity_to_add",
      text: props.t("Add Quantity"),
      sort: true,
      editable: true,
      attrs: { title: "Click to Edit" },
      style: { cursor: "pointer", background: "#ffffaa" },
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          };
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          };
        }
        if (newValue == 0) {
          return {
            valid: false,
            message: "Zero not allowed.",
          };
        }
        return true;
      },
    },
    {
      dataField: "threshold",
      text: props.t("Threshold"),

      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "expiry_days",
      text: props.t("Expires in"),
      formatter: (cell, row) => {
        return row.expiry_days > 1
          ? row.expiry_days + " Days"
          : row.expiry_days + " Day";
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      editable: false,
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} - {to} of {size} records.
    </span>
  );

  const columns_transaction = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "quantity",
      text: props.t("Quantity"),
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },

    {
      dataField: "cost_price",
      text: props.t("Cost Price"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "selling_price",
      text: props.t("Selling Price"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Expiry Date"),
      sort: false,
      formatter: (cell, row) => {
        return moment(row.expiry_date).format("DD/MM/YYYY HH:mm");
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "",
      text: props.t("Entry Date"),
      sort: false,
      formatter: (cell, row) => {
        return moment(row.createdAt).format("DD/MM/YYYY HH:mm");
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
  ];
  const pagination_options = {
    paginationSize: 5,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value:
          selectedProduct_Transactions && selectedProduct_Transactions.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };

  const [addInventoryModal, setAddInventoryModal] = useState(false);

  const handleClosePopup = async () => {
    setAddInventoryModal(!addInventoryModal);
  };

  const handleDownload = async () => {
    const fileName = "view_inventory";
    const exportType = "xls";

    let data_to_export = allProducts;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          name: v.name,
          section: v.section,
          category: v.category,
          available: v.available,
          threshold: v.threshold,

          expiry_days: v.expiry_days,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            title={props.t("Inventory")}
            breadcrumbItem1={props.t("Finished Products")}
            breadcrumbItem2={props.t("View Inventory")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("View Inventory")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all products in your inventory here")}
                  </CardSubtitle>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"  "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <div id="printDiv">
                    <ToolkitProvider
                      bootstrap4
                      keyField="_id"
                      data={allProducts && allProducts}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}>
                              <CardTitle>View Inventory</CardTitle>
                            </Col>
                            <Col lg={4} className="text-right">
                              {/* <button
                              className="btn-sm btn btn-info"
                              onClick={() => {
                                setAddInventoryModal(!addInventoryModal);
                              }}
                            >
                              Add Finished Product
                            </button> */}
                            </Col>
                          </Row>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            {...props.baseProps}
                            cellEdit={cellEditFactory({
                              mode: "click",
                              blurToSave: true,
                              afterSaveCell: async (
                                oldValue,
                                newValue,
                                row,
                                column
                              ) => {
                                if (
                                  +newValue < 0 ||
                                  (isNaN(newValue) && !isFinite(newValue))
                                ) {
                                  let arr = [...allProducts];
                                  for (let i = 0; i < arr.length; i++) {
                                    if (arr[i]._id === row._id) {
                                      arr[i].quantity_to_add = oldValue;
                                      break;
                                    }
                                  }
                                  setAllProducts(arr);
                                  showNotification(
                                    "Only positive numbers allowed.",
                                    "Error"
                                  );
                                } else if (+newValue === 0) {
                                  let arr = [...allProducts];
                                  for (let i = 0; i < arr.length; i++) {
                                    if (arr[i]._id === row._id) {
                                      arr[i].quantity_to_add = oldValue;
                                      break;
                                    }
                                  }
                                  setAllProducts(arr);
                                } else if (newValue !== "") {
                                  let url =
                                    process.env.REACT_APP_BASEURL +
                                    "productinventories/insert";
                                  let data_to_send = {};

                                  data_to_send.product_id = row._id;
                                  data_to_send.quantity = newValue;
                                  data_to_send.cost_price = row.cost_price;
                                  data_to_send.selling_price =
                                    row.selling_price;
                                  data_to_send.remarks = "";
                                  const response = await postSubmitForm(
                                    url,
                                    data_to_send
                                  );
                                  if (response && response.status === 1) {
                                    showNotification(
                                      response.message,
                                      "Success"
                                    );
                                    loadProducts();
                                  } else {
                                    showNotification(response.message, "Error");
                                  }
                                }
                              },
                            })}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>

                  <Modal
                    size="lg"
                    style={{
                      width: "940px",
                      maxWidth: "950px",
                    }}
                    isOpen={transactionsModal}
                    toggle={() => setTransactionsModal(!transactionsModal)}
                  >
                    <ModalHeader
                      toggle={() => setTransactionsModal(!transactionsModal)}
                    >
                      Product Name: {selectedProduct && selectedProduct.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedProduct && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col lg={12}>
                                      <ToolkitProvider
                                        keyField="_id"
                                        data={
                                          selectedProduct_Transactions &&
                                          selectedProduct_Transactions
                                        }
                                        columns={columns_transaction}
                                      >
                                        {(props) => (
                                          <div>
                                            <BootstrapTable
                                              {...props.baseProps}
                                              pagination={paginationFactory(
                                                pagination_options
                                              )}
                                              noDataIndication="No data to display."
                                              striped
                                              hover
                                              condensed
                                            />
                                          </div>
                                        )}
                                      </ToolkitProvider>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="lg"
                    style={{
                      width: "940px",
                      maxWidth: "950px",
                    }}
                    isOpen={addInventoryModal}
                    toggle={() => setAddInventoryModal(!addInventoryModal)}
                  >
                    <ModalHeader
                      toggle={() => setAddInventoryModal(!addInventoryModal)}
                    >
                      Add Finished Product
                    </ModalHeader>
                    <ModalBody>
                      <AddInventoryComp
                        isRefreshData={true}
                        refreshData={loadProducts}
                        selectedProduct={selectedProductToAdd}
                        handleClosePopup={handleClosePopup}
                      />
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(ViewInventory))
);
