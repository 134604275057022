import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const FinishedProductAllOrders_New = (props) => {
  const { SearchBar } = Search;
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("status")) {
      setSelectedStatus(urlParams.get("status"));
    }
    if (urlParams.get("section")) {
      setSelectedSection(urlParams.get("section"));
    }
    loadAllBranches();
    loadAllSections();
  }, []);
  const [confirmClose, setConfirmClose] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [selectedSection, setSelectedSection] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [orderStatus, setOrderStatus] = useState([
    "Placed",
    "Approved",
    "Dispatched",
    "Partially Dispatched",
    "Delivered",
    "Partially Delivered",
    "Closed",
  ]);

  useEffect(() => {
    loadAllOrders();
  });

  useEffect(() => {
    if (selectedStatus === "New") {
      setOrderStatus(["Placed"]);
    } else if (selectedStatus === "In Process") {
      setOrderStatus([
        "Approved",
        "Dispatched",
        "Partially Dispatched",
        "Delivered",
        "Partially Delivered",
      ]);
    } else if (selectedStatus === "Closed") {
      setOrderStatus(["Closed"]);
    } else if (selectedStatus === "All") {
      setOrderStatus([
        "Placed",
        "Approved",
        "Dispatched",
        "Partially Dispatched",
        "Delivered",
        "Partially Delivered",
        "Closed",
      ]);
    }
  }, [selectedStatus]);

  const [allOrders, setAllOrders] = useState([]);
  const loadAllOrders = async () => {
    let url = process.env.REACT_APP_BASEURL + "productorders/getall_unwind";
    const object = {
      status: orderStatus,
      branch: selectedBranch,
      section: selectedSection,
    };
    console.log(object);
    const response = await postSubmitForm(url, object);
    if (response && response.status === 1) {
      setAllOrders(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [allSections, setAllSections] = useState([]);
  const loadAllSections = async () => {
    let url = process.env.REACT_APP_BASEURL + "productsections/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllSections(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [allBranches, setAllBranches] = useState([]);
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("Branch Name"),
      dataField: "branch_details.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Section"),
      dataField: "product_details.section",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "product_details.category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Product"),
      dataField: "product_details.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
    },
    {
      dataField: "product_details.quantity",
      text: props.t("Order Quantity"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "product_details.available",
      text: props.t("Branch Avl. Qty."),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      text: props.t("Date"),
      dataField: "date_to_process",
      sort: true,
      formatter: (cell, row) => {
        return moment(row.date_to_process).format("DD/MM/YYYY");
      },
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
  ];
  const [selectedOrder, setSelectedOrder] = useState();
  const handleCloseOrder = async () => {
    let url = process.env.REACT_APP_BASEURL + "productorders/set_closed";
    const response = await postSubmitForm(url, {
      productorder_id: selectedOrder._id,
    });
    //console.log(data_to_send);
    if (response && response.status === 1) {
      loadAllOrders();
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };
  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      dataField: "order_id",
      text: props.t("Order ID"),

      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "branch_details.name",
      text: props.t("Branch Name"),

      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),

      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },

      style: { cursor: "pointer" },
    },
    {
      text: props.t("Order Date"),
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      formatter: (cell, row) => {
        return moment(row.createdAt).format("DD-MM-YYYY HH:mm");
      },
      style: { cursor: "pointer" },
    },
    {
      text: props.t("Is Urgent?"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      formatter: (cell, row) => {
        if (row.is_urgent) {
          return props.t("Yes");
        } else {
          return props.t("No");
        }
      },
      style: { cursor: "pointer" },
    },

    {
      dataField: "status",
      text: props.t("Status"),
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "",
      text: props.t("Action"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <button
              class="btn btn-secondary btn-sm mr-2"
              onClick={() => {
                setSelectedOrder(row);
                setProductsForDelivery([]);
                setSelectedProduct();
                setSelectedBatch();
                setQuantity();
                loadProductsInOrder(row._id);
                setOrderDispatchModal(!orderDispatchModal);
              }}
            >
              {props.t("Dispatch")}
            </button>
            <button
              class="btn btn-danger btn-sm mr-2"
              onClick={() => {
                setConfirmClose(true);
                setSelectedOrder(row);
              }}
            >
              {props.t("Close")}
            </button>
          </>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
  ];

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.product_details}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
  };

  const handleDownload = async () => {
    const fileName = "used_inventory";
    const exportType = "xls";

    let data_to_export = allOrders;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          branch_details_name: v.branch_details.name,
          product_details_section: v.product_details.section,
          category: v.product_details.category,
          product_details_name: v.product_details.name,
          product_details_quantity: v.product_details.quantity,
          product_details_available: v.product_details.available,
          date: v.date_to_process,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  //#region Dispatch order related code
  const [productsForDelivery, setProductsForDelivery] = useState([]);
  const [quantity, setQuantity] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [orderDispatchModal, setOrderDispatchModal] = useState();
  const [productBatches, setProductBatches] = useState();
  const [selectedBatch, setSelectedBatch] = useState();
  const [allProducts, setAllProducts] = useState([]);
  const loadProductsInOrder = async (productorder_id) => {
    let url =
      process.env.REACT_APP_BASEURL + "productorders/get_products_in_order";

    const response = await postSubmitForm(url, { productorder_id });

    if (response && response.status === 1) {
      setAllProducts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const loadBatches = async (product_details) => {
    let url =
      process.env.REACT_APP_BASEURL + "productinventories/get_for_delivery";
    console.log(product_details);
    const response = await postSubmitForm(url, {
      product_id: product_details.value,
    });
    if (response && response.status === 1) {
      setProductBatches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const handleAddProductForDelivery = async () => {
    if (!selectedBatch) {
      showNotification(
        props.t("Please select product batch."),
        props.t("Error")
      );
    } else if (!quantity || quantity <= 0) {
      showNotification(props.t("Please add quantity."), props.t("Error"));
    } else {
      var new_array = JSON.parse(JSON.stringify(productsForDelivery));
      const existing_product = new_array.filter(function (product) {
        return product.batch === selectedBatch.batch;
      });
      if (existing_product.length > 0) {
        showNotification(
          props.t("This product batch is already added."),
          "Error"
        );
      } else {
        if (selectedBatch.quantity < quantity) {
          showNotification(props.t("Quantity not in stock."), "Error");
        } else {
          setProductsForDelivery([
            ...productsForDelivery,
            {
              batch: selectedBatch.batch,
              name: selectedProduct.name,
              section: selectedProduct.section,
              category: selectedProduct.category,
              product_id: selectedProduct.value,
              delivered_quantity: quantity,
            },
          ]);

          setQuantity(0);
          setSelectedProduct();
        }
      }
    }
  };
  function deleteFormatter(cell, row, rowIndex, extraData) {
    return (
      <span className="text-danger">
        <i
          className="bx bxs-trash font-size-15"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            let arr = extraData.filter((item) => item.batch !== row.batch);

            setProductsForDelivery(arr);
          }}
        ></i>
      </span>
    );
  }
  const columns_products = [
    {
      text: props.t("Product"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Quantity"),
      dataField: "delivered_quantity",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      formatter: deleteFormatter,
      formatExtraData: productsForDelivery,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
  ];

  const columns_delivered_products = [
    {
      text: props.t("Section, Category, Product"),
      dataField: "section",
      formatter: (cell, row) => {
        return row.section + ", " + row.category + ", " + row.name;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },

    {
      text: props.t("Dispatch Time"),
      dataField: "date_of_transaction",
      formatter: (cell, row) => {
        return moment(row.date_of_transaction).format("DD/MM/YYYY HH:mm");
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      text: props.t("Del. Qty."),
      dataField: "delivered_quantity",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      text: props.t("Rec. Qty."),
      dataField: "received_quantity",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      text: props.t("Received by Branch?"),
      dataField: "is_received",
      formatter: (cell, row) => {
        return row.is_received ? "Yes" : "No";
      },
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const handleDispatchOrder = async () => {
    if (productsForDelivery.length > 0) {
      let data_to_send = {};
      data_to_send.productorder_id = selectedOrder._id;
      data_to_send.delivery_details = productsForDelivery;
      let url = process.env.REACT_APP_BASEURL + "productorders/dispatch";

      const response = await postSubmitForm(url, data_to_send);
      if (response && response.status === 1) {
        loadAllOrders();
        showNotification(response.message, "Success");
        setOrderDispatchModal(!orderDispatchModal);
      } else {
        showNotification(response.message, "Error");
      }
    } else {
      showNotification("Add at least one product.", "Error");
    }
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }
  //#endregion
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Finished Products")}
            title={props.t("Central Kitchen")}
            breadcrumbItem1={props.t("FP Orders")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>
                    {props.t("All Finished Product Orders")}{" "}
                  </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all orders for finished products here")}
                  </CardSubtitle>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"  "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <div id="printDiv">
                    <ToolkitProvider
                      keyField="product_details._id"
                      data={allOrders && allOrders}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      bootstrap4
                      search
                    >
                      {(props_table) => (
                        <div>
                          <AvForm>
                            <Row>
                              <Col lg={3}>
                                <Label>{props.t("Search")}</Label>
                                <SearchBar {...props_table.searchProps} />
                              </Col>
                              <Col lg={4}>
                                <AvField
                                  name="order_status"
                                  label={props.t("Status")}
                                  type="select"
                                  value={selectedStatus}
                                  onChange={(e) => {
                                    setSelectedStatus(e.target.value);
                                  }}
                                >
                                  <option value="All">{props.t("All")}</option>
                                  <option value="New">{props.t("New")}</option>
                                  <option value="In Process">
                                    {props.t("In Process")}
                                  </option>
                                  <option value="Closed">
                                    {props.t("Closed")}
                                  </option>
                                </AvField>
                              </Col>
                              <Col lg={4}>
                                <AvField
                                  name="branch"
                                  label={props.t("Branch")}
                                  type="select"
                                  value={selectedBranch}
                                  onChange={(e) => {
                                    setSelectedBranch(e.target.value);
                                  }}
                                >
                                  <option value="All">{props.t("All")}</option>
                                  {allBranches &&
                                    allBranches.map((branch) => {
                                      return (
                                        <option value={branch._id}>
                                          {branch.name}
                                        </option>
                                      );
                                    })}
                                </AvField>
                              </Col>
                              <Col lg={4}>
                                <AvField
                                  name="section"
                                  label={props.t("Section")}
                                  type="select"
                                  value={selectedSection}
                                  onChange={(e) => {
                                    setSelectedSection(e.target.value);
                                  }}
                                >
                                  <option value="All">{props.t("All")}</option>
                                  {allSections &&
                                    allSections.map((section) => {
                                      return (
                                        <option value={section.section}>
                                          {section.section}
                                        </option>
                                      );
                                    })}
                                </AvField>
                              </Col>
                              <Col lg={4}>
                                {" "}
                                <CardTitle className="mt-4">
                                  (All Finished Product Orders)
                                </CardTitle>
                              </Col>
                            </Row>
                          </AvForm>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            //expandRow={expandRow}
                            {...props_table.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                  <Modal
                    size="lg"
                    isOpen={orderDispatchModal}
                    toggle={() => setOrderDispatchModal(!orderDispatchModal)}
                  >
                    <ModalHeader
                      toggle={() => setOrderDispatchModal(!orderDispatchModal)}
                    >
                      {props.t("Dispatch Order")}
                    </ModalHeader>
                    <ModalBody>
                      <>
                        <Row>
                          <Col lg={12}>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col lg={12} md={12}>
                                    <Label>Ordered Products</Label>
                                  </Col>

                                  <Col lg={12}>
                                    <BootstrapTable
                                      bootstrap4
                                      keyField="name"
                                      data={
                                        selectedOrder &&
                                        selectedOrder.product_details
                                      }
                                      columns={columns}
                                      noDataIndication="No data to display."
                                      striped
                                      hover
                                      condensed
                                    />
                                    <hr />
                                  </Col>

                                  {selectedOrder &&
                                  selectedOrder.delivery_details &&
                                  selectedOrder.delivery_details.length > 0 ? (
                                    <>
                                      <Col lg={12} md={12}>
                                        <br />
                                        <Label>
                                          <b>
                                            {props.t("Dispatched Products")}{" "}
                                          </b>
                                        </Label>
                                      </Col>
                                      <Col lg={12} className="mb-4">
                                        <BootstrapTable
                                          bootstrap4
                                          keyField="_id"
                                          data={selectedOrder.delivery_details}
                                          columns={columns_delivered_products}
                                          noDataIndication="No data to display."
                                          striped
                                          hover
                                          condensed
                                        />
                                        <hr />
                                      </Col>
                                    </>
                                  ) : null}
                                  <Col lg={3}>
                                    <Label>{props.t("Add Product")}</Label>
                                    <Select
                                      value={selectedProduct && selectedProduct}
                                      key={selectedProduct}
                                      placeholder={props.t("Select...")}
                                      onChange={(e) => {
                                        setSelectedProduct(e);
                                        loadBatches(e);
                                        setSelectedBatch();
                                      }}
                                      options={allProducts}
                                      classNamePrefix="select2-selection"
                                      required
                                    />
                                  </Col>
                                  {selectedProduct ? (
                                    selectedProduct.available > 0 ? (
                                      <>
                                        <Col lg={3}>
                                          <Label>{props.t("Batch")}</Label>
                                          <Select
                                            value={
                                              selectedBatch && selectedBatch
                                            }
                                            key={selectedBatch}
                                            onChange={(e) => {
                                              setSelectedBatch(e);
                                            }}
                                            options={
                                              productBatches && productBatches
                                            }
                                            classNamePrefix="select2-selection"
                                            required
                                          />
                                        </Col>

                                        <Col lg={3}>
                                          <Label>{props.t("Quantity")}</Label>
                                          <AvForm>
                                            <AvField
                                              name="quantity"
                                              placeholder={props.t(
                                                "Enter Quantity"
                                              )}
                                              type="text"
                                              onChange={(e) => {
                                                setQuantity(e.target.value);
                                              }}
                                              validate={{
                                                required: {
                                                  value: true,
                                                  errorMessage: props.t(
                                                    "Quantity cannot be empty."
                                                  ),
                                                },
                                                pattern: {
                                                  value: "^[0-9]+$",
                                                  errorMessage: props.t(
                                                    "Only numbers are allowed."
                                                  ),
                                                },
                                              }}
                                            />
                                          </AvForm>
                                        </Col>
                                        <Col lg={2}>
                                          {" "}
                                          <Label>&nbsp;</Label>
                                          <br />
                                          <Link
                                            class="btn btn-info waves-effect waves-light btn-sm"
                                            onClick={
                                              handleAddProductForDelivery
                                            }
                                          >
                                            <i class="bx bx-add-to-queue"></i>{" "}
                                            Add
                                          </Link>
                                        </Col>
                                      </>
                                    ) : (
                                      <Col lg={6}>
                                        <p class="text-muted mb-2">&nbsp;</p>
                                        <div
                                          class="alert alert-danger fade show text-center"
                                          role="alert"
                                        >
                                          {props.t(
                                            "This product is out of stock."
                                          )}
                                        </div>
                                      </Col>
                                    )
                                  ) : null}

                                  {productsForDelivery &&
                                  productsForDelivery.length > 0 ? (
                                    <>
                                      <Col lg={12} md={12}>
                                        <br />
                                        <Label>
                                          <b>{props.t("Products")} </b>
                                        </Label>
                                      </Col>
                                      <Col lg={12}>
                                        <BootstrapTable
                                          bootstrap4
                                          keyField="name"
                                          key="name"
                                          data={
                                            productsForDelivery &&
                                            productsForDelivery
                                          }
                                          columns={columns_products}
                                          noDataIndication="No data to display."
                                          striped
                                          hover
                                          condensed
                                        />
                                      </Col>
                                    </>
                                  ) : null}
                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          onClick={handleDispatchOrder}
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Submit")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    </ModalBody>
                  </Modal>

                  {confirmClose && (
                    <SweetAlert
                      title={props.t("Are you sure?")}
                      warning
                      showCancel
                      cancelBtnText={props.t("No")}
                      confirmBtnText={props.t("Yes")}
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        setConfirmClose(false);
                        handleCloseOrder();
                      }}
                      onCancel={() => {
                        setConfirmClose(false);
                      }}
                    >
                      {props.t(
                        "Once you close this order, it cannot be revoked."
                      )}
                    </SweetAlert>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(
  connect(null, {})(withNamespaces()(FinishedProductAllOrders_New))
);
