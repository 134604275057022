import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
  Table,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const CostOfGoodsSoldProductsReport = (props) => {
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const { SearchBar } = Search;
  const [monthYear, setMonthYear] = useState();
  const [currentMonthYear, setCurrentMonthYear] = useState();
  const [selectedSupplier, setSelectedSupplier] = useState("All");
  const [allSuppliers, setAllSuppliers] = useState([]);

  const [selectedBranch, setSelectedBranch] = useState("All");
  const [selectedRow, setSelectedRow] = useState([]);
  const [allBranches, setAllBranches] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    // setDateFrom(formatDate(firstDay));
    // setDateTo(formatDate(currentDay));

    setMonthYear(formatDate(currentDay).slice(0, 7));
    setCurrentMonthYear(formatDate(currentDay).slice(0, 7));
    //loadAllSuppliers();
    //loadOrders();
  }, []);

  const loadAllSuppliers = async () => {
    let url = process.env.REACT_APP_BASEURL + "suppliers/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllSuppliers(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [allOrders, setAllOrders] = useState([]);
  const loadOrders = async () => {
    console.log("helloooooo");
    preloader(true);
    let url =
      process.env.REACT_APP_BASEURL +
      "reports/cost_of_goods_sold_products_report";
    const response = await postSubmitForm(url, {
      monthYear: monthYear,
    });
    if (response && response.status === 1) {
      preloader(false);
      //console.log(response.data.length);
      setAllOrders(response.data.branches);
    } else {
      preloader(false);

      showNotification(response.message, "Error");
    }
  };
  const [orderStatus, setOrderStatus] = useState("All");
  const [filteredOrders, setFilteredOrders] = useState([]);
  // useEffect(() => {
  //   if (orderStatus === "All") {
  //     setFilteredOrders([...allOrders]);
  //   } else {
  //     let arr = allOrders.filter((order) => order.status === orderStatus);
  //     setFilteredOrders(arr);
  //   }
  // }, [allOrders, orderStatus]);

  useEffect(() => {
    if (monthYear) {
      loadOrders();
    }
  }, [monthYear]);

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const [purchaseModal, setPurchaseModal] = useState(false);
  const [directPurchaseModal, setDirectPurchaseModal] = useState(false);
  const [productModal, setProductModal] = useState(false);
  const [itemModal, setItemModal] = useState(false);

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      dataField: "name",
      text: props.t("Branch"),
      sort: true,

      style: { cursor: "pointer" },
    },
    {
      text: props.t("Opening Cost"),
      dataField: "product_cost_opening",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      formatter: (cell, row, rowIndex) => {
        return row.product_cost_opening ? row.product_cost_opening : "0.00";
      },
    },
    {
      dataField: "product_purchase_cost",
      text: props.t("Purchase Cost"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      formatter: (cell, row, rowIndex) => {
        return row.product_purchase_cost;
      },
    },
    {
      dataField: "product_outbound_transfer_cost",
      text: props.t("Outbound Transfer"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      formatter: (cell, row, rowIndex) => {
        return row.product_outbound_transfer_cost;
      },
    },
    {
      dataField: "product_inbound_transfer_cost",
      text: props.t("Inbound Transfer"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      formatter: (cell, row, rowIndex) => {
        return row.product_inbound_transfer_cost;
      },
    },
    {
      text: props.t("Closing Cost"),
      dataField: "product_cost",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("COGS"),
      dataField: "product_cost",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      formatter: (cell, row, rowIndex) => {
        let cogs =
          Number(row.product_cost_opening ? row.product_cost_opening : 0) +
          Number(row.product_purchase_cost) +
          Number(row.product_inbound_transfer_cost) -
          Number(row.product_outbound_transfer_cost) -
          Number(row.product_cost);
        if (isNaN(cogs)) {
          cogs = "0.00";
        } else {
          cogs = cogs.toFixed(2);
        }
        return cogs;
      },
    },
  ];

  const [orderDetailsModal, setOrderDetailsModal] = useState();

  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      setExpanded([...expanded, row._id]);
    } else {
      setExpanded(expanded.filter((x) => x !== row._id));
    }
  };
  const [expanded, setExpanded] = useState([]);

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.item_details}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
    expanded: expanded,
    onExpand: handleOnExpand,
  };

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.status === "Completed") {
      style.backgroundColor = "#b0ff9e";
    } else if (row.status === "Booked") {
      style.backgroundColor = "#9ec3ff";
    } else if (row.status === "In Process") {
      style.backgroundColor = "#fffd9e";
    } else if (row.status === "Cancelled") {
      style.backgroundColor = "#ff9e9e";
    }

    return style;
  };

  const handleDownload = async () => {
    const fileName = "cogs_report_products";
    const exportType = "xls";

    let data_to_export = allOrders;
    //console.log(data_to_export);

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        let cogs =
          Number(v.product_cost_opening ? v.product_cost_opening : 0) +
          Number(v.product_purchase_cost) +
          Number(v.product_inbound_transfer_cost) -
          Number(v.product_outbound_transfer_cost) -
          Number(v.product_cost);
        if (isNaN(cogs)) {
          cogs = "0.00";
        } else {
          cogs = cogs.toFixed(2);
        }
        data.push({
          Branch: v.name,
          "Opening Cost": v.product_cost_opening,
          "Purchase Cost": v.product_purchase_cost,
          "Outbound Transfer": v.product_outbound_transfer_cost,
          "Inbound Transfer": v.product_inbound_transfer_cost,
          "Closing Cost": v.product_cost,
          COGS: cogs,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };

  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }

  const columns_ordered_items = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Sub-Category"),
      dataField: "sub_category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Available"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.available.in_unit +
                " " +
                row.unit +
                " " +
                row.available.in_sub_unit +
                " " +
                row.sub_unit
              : row.available.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Order Quantity"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];

  const columns_delivered_items = [
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Sub-Category"),
      dataField: "sub_category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Delivered Qty."),
      formatter: (cell, row) => {
        return (
          <>
            {row.delivered_quantity.in_sub_unit
              ? row.delivered_quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.delivered_quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.delivered_quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Rec. Qty."),
      formatter: (cell, row) => {
        return (
          <>
            {row.received_quantity.in_sub_unit
              ? row.received_quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.received_quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.received_quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb
            alternateTitle={props.t("Cost Of Goods Sold Report Products")}
            title={props.t("Reports")}
            breadcrumbItem={props.t("Cost Of Goods Sold Report Products")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={4}>
                      <input
                        type="month"
                        name="monthYear"
                        label={props.t("Month-Year")}
                        value={monthYear}
                        onChange={(e, v) => {
                          setMonthYear(e.target.value);
                        }}
                        max={currentMonthYear}
                        className="is-touched is-pristine av-valid form-control"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"   "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <div id="printDiv">
                    <CardTitle className="text-center">
                      {props.t("Cost Of Goods Sold Report (Products)")}
                      <br />
                      <h6>
                        (cogs = opening cost + purchase cost + inbound transfer
                        cost - outbound transfer cost - closing cost)
                      </h6>
                    </CardTitle>
                    <ToolkitProvider
                      keyField="id"
                      data={allOrders && allOrders}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      bootstrap4
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}></Col>
                            <Col lg={4}></Col>
                          </Row>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            rowStyle={rowStyle}
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>

                  <Modal
                    size="lg"
                    isOpen={orderDetailsModal}
                    toggle={() => setOrderDetailsModal(!orderDetailsModal)}
                  >
                    <ModalHeader
                      toggle={() => setOrderDetailsModal(!orderDetailsModal)}
                    >
                      {props.t("Order Details")}
                    </ModalHeader>
                    <ModalBody>
                      <>
                        <Row>
                          <Col lg={12}>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col lg={12} md={12}>
                                    <Label>Ordered Items</Label>
                                  </Col>

                                  <Col lg={12}>
                                    <BootstrapTable
                                      bootstrap4
                                      keyField="name"
                                      data={
                                        selectedRow && selectedRow.item_details
                                      }
                                      columns={columns_ordered_items}
                                      noDataIndication="No data to display."
                                      striped
                                      hover
                                      condensed
                                    />
                                    <hr />
                                  </Col>

                                  {selectedRow &&
                                  selectedRow.delivery_details &&
                                  selectedRow.delivery_details.length > 0 ? (
                                    <>
                                      <Col lg={12} md={12}>
                                        <br />
                                        <Label>
                                          <b>{props.t("Delivered Items")} </b>
                                        </Label>
                                      </Col>
                                      <Col lg={12} className="mb-4">
                                        <BootstrapTable
                                          bootstrap4
                                          keyField="name"
                                          key="name"
                                          data={selectedRow.delivery_details}
                                          columns={columns_delivered_items}
                                          noDataIndication="No data to display."
                                          striped
                                          hover
                                          condensed
                                        />
                                        <hr />
                                      </Col>
                                    </>
                                  ) : null}
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(CostOfGoodsSoldProductsReport))
);
