import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";

import { postSubmitForm } from "../../../helpers/forms_helper";
import moment from "moment";

const BranchPOGeneration = (props) => {
  const { SearchBar } = Search;
  const [branchID, setBranchID] = useState();
  const [requestID, setRequestID] = useState();
  const [branchName, setBranchName] = useState();
  const [hideActions, setHideActions] = useState(true);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("id")) {
      loadPOData({ id: urlParams.get("id") });
      setRequestID(urlParams.get("id"));
    }
    if (urlParams.get("branch_id")) {
      setBranchID(urlParams.get("branch_id"));
    }
    if (urlParams.get("branch_name")) {
      setBranchName(urlParams.get("branch_name"));
    }
  }, []);
  const [selectedItems, setSelectedItems] = useState([]);

  const [allItems, setAllItems] = useState([]);
  const loadPOData = async (data) => {
    let url =
      process.env.REACT_APP_BASEURL +
      "branchporequests/get_by_id_with_suppliers";
    const response = await postSubmitForm(url, data);

    if (response && response.status === 1) {
      if (response.data.status == "Pending") {
        setHideActions(false);
      }
      setAllItems(response.data.requested_items);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("Category Name"),
      dataField: "_id",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Supplier"),
      dataField: "unit",
      formatter: (cell, row) => {
        return (
          <AvForm>
            <AvField name="supplier" type="select">
              <option value={null}>{props.t("--Select Supplier--")}</option>
              {row.suppliers &&
                row.suppliers.map((e) => (
                  <option key={e._id} value={e._id} name={e.name}>
                    {e.name}
                  </option>
                ))}
            </AvField>
          </AvForm>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
  ];
  const columns_items = [
    {
      dataField: "item_id",
      hidden: true,
    },
    {
      dataField: "item_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Sub-Category"),
      dataField: "sub_category",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Brand"),
      dataField: "brand",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Quantity"),
      dataField: "quantity",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      text: props.t("Price"),
      dataField: "price",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: true,
      attrs: (cell, row, rowIndex, colIndex) => {
        const title_text = { title: "Click to Edit" };
        return title_text;
      },
      style: (cell, row, rowIndex, colIndex) => {
        const cur_style = { cursor: "pointer" };
        return cur_style;
      },
    },
    {
      text: props.t("Total"),
      dataField: "total_price",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: false,
    },
    // {
    //   text: props.t("Available"),
    //   dataField: "unit",
    //   formatter: (cell, row) => {
    //     return (
    //       row.available.in_unit +
    //       " " +
    //       row.unit +
    //       (row.sub_unit
    //         ? " " + row.available.in_sub_unit + " " + row.sub_unit
    //         : "")
    //     );
    //   },
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "12%" };
    //   },
    // },
  ];

  const handleSupplierSelection = (event) => {
    let arrSelected = JSON.parse(JSON.stringify(selectedItems));
    let selectedCategory = event.target.getAttribute("category");
    let selectedSupplier = event.target.value;
    let existingIndex = arrSelected.findIndex(
      (item) => item.category === selectedCategory
    );
    if (existingIndex >= 0) {
      if (selectedSupplier === "") {
        arrSelected.splice(existingIndex, 1);
      } else {
        arrSelected[existingIndex].supplier = selectedSupplier;
      }
    } else {
      arrSelected.push({
        category: selectedCategory,
        supplier: selectedSupplier,
      });
    }
    setSelectedItems(arrSelected);
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} - {to} of {size} records.
    </span>
  );

  const pagination_options = {
    paginationSize: 50,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: allItems && allItems.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };
  const handleSubmitRequisition = async () => {
    let validFlag = true;
    let arrSelected = JSON.parse(JSON.stringify(allItems));
    let data = arrSelected.map((selected_cat) => {
      if (selected_cat.supplier) {
        const itemIndex = allItems.findIndex(
          (item) => item.category === selected_cat.category
        );
        let selectedCategory_items = allItems[itemIndex].items.filter(
          (item) => {
            if (
              item.is_selected &&
              (+item.quantity === 0 || +item.price === 0)
            ) {
              validFlag = false;
              showNotification(
                "Please enter price and quantity for all selected items.",
                "Error"
              );
            }
            return item.is_selected && item.quantity > 0 && +item.price > 0;
          }
        );
        if (selectedCategory_items.length > 0 && validFlag) {
          selected_cat.items = [...selectedCategory_items];
          return selected_cat;
        } else if (validFlag) {
          validFlag = false;
          showNotification(
            "Please select at least one item for every selected supplier.",
            "Error"
          );
        }
      } else {
        validFlag = false;
        showNotification(
          "Please select supplier for all selected items.",
          "Error"
        );
      }
    });
    if (validFlag) {
      try {
        if (data.length > 0) {
          let url = process.env.REACT_APP_BASEURL + "purchaseorders/insert";

          let response = await postSubmitForm(url, {
            request_id: requestID,
            branch_id: branchID,
            deliver_at_branch: true,
            items: data,
          });

          if (response.status === 1) {
            setHideActions(true);
            showNotification(response.message, "Success");
            for (let i = 0; i < response.data.length; i++) {
              window.open(
                `/purchase_order?po_id=${response.data[i]}`,
                "_blank"
              );
            }
          } else {
            showNotification(response.message, "Error");
          }
        } else {
          showNotification(
            "Please select at least one item and supplier.",
            "Error"
          );
        }
      } catch (error) {
        showNotification(error.message, "Error");
      }
    }
  };
  const handleRejectRequest = async () => {
    let url = process.env.REACT_APP_BASEURL + "branchporequests/reject";

    let response = await postSubmitForm(url, {
      request_id: requestID,
    });
    if (response.status === 1) {
      setHideActions(true);
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Branch PO Generation")}
            title={props.t("Procurement")}
            breadcrumbItem1={props.t("Branch PO Generation")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={12}>Branch Name: {branchName && branchName}</Col>
                    </Row>
                    {/* <Button type="submit">Submit</Button> */}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {allItems.map((item) => {
                    return (
                      <>
                        <Row>
                          <Col lg={6}>
                            <Label>Category: {item.category}</Label>
                          </Col>
                          <Col lg={6}>
                            <Label>Supplier: {item.suppliers[0].name}</Label>
                          </Col>
                        </Row>
                        <BootstrapTable
                          bootstrap4
                          keyField="item_id"
                          data={item.items}
                          columns={columns_items}
                          cellEdit={cellEditFactory({
                            mode: "click",
                            blurToSave: true,
                            afterSaveCell: (
                              oldValue,
                              newValue,
                              row,
                              column
                            ) => {
                              row.total_price = row.price * row.quantity;
                            },
                          })}
                          noDataIndication="No data to display."
                          striped
                          hover
                          condensed
                        />
                        <hr />
                      </>
                    );
                  })}
                  <Row>
                    <Col lg={12}>
                      <FormGroup className="mb-0 text-center">
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="mr-1"
                            onClick={handleSubmitRequisition}
                            hidden={hideActions}
                          >
                            {props.t("Approve")}
                          </Button>
                          <Button
                            type="submit"
                            color="danger"
                            className="mr-1"
                            onClick={handleRejectRequest}
                            hidden={hideActions}
                          >
                            {props.t("Reject")}
                          </Button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <ToolkitProvider
                    bootstrap4
                    keyField="_id"
                    data={allItems && allItems}
                    columns={columns}
                    noDataIndication={props.t("No data to display.")}
                    search
                  >
                    {(props) => (
                      <div>
                        <Row>
                          <Col lg={4}>
                            <SearchBar
                              {...props.searchProps}
                              style={{ width: "300px" }}
                            />
                          </Col>
                          <Col lg={4}></Col>
                        </Row>
                        <BootstrapTable
                          striped
                          hover
                          condensed
                          expandRow={expandRow}
                          {...props.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(BranchPOGeneration))
);
