import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  Input,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import csc from "country-state-city";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import ReactAvatarEditor from "react-avatar-editor";
import {
  postSubmitForm,
  postSubmitForm_withformdata,
} from "../../helpers/forms_helper";

const AllUsers = (props) => {
  const { SearchBar } = Search;
  useEffect(() => {
    setAllCountries(csc.getAllCountries);
    loadAllUsers();
    loadAllBranches();
  }, []);
  const [allUsers, setAllUsers] = useState([]);
  const loadAllUsers = async () => {
    let url = process.env.REACT_APP_BASEURL + "adminusers/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllUsers(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [allBranches, setAllBranches] = useState([]);
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [qatarID, setQatarID] = useState();
  const [passport, setPassport] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [allCountries, setAllCountries] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState();
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [scale, setScale] = useState(1);
  const [allowZoomOut] = useState(false);
  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  const editorRef = useRef();
  // setEditorRef = (editor) => (this.editor = editor);
  const [rotate] = useState(0);
  const [borderRadius] = useState(0);
  const onPhotoChange = (event) => {
    setSelectedPhoto(event.target.files[0]);
  };

  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.image_url + "?" + new Date() + "=" + new Date()}
          alt={row.name}
          class="avatar-md rounded-circle img-thumbnail"
        ></img>

        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            // let file = new File(row.image_url)
            setEditModal(false);
            setScale(1);
            setImageModal(!imageModal);
            setSelectedUser(row);
            // setSelectedPhoto(
            //   row.image_url + "?" + new Date() + "=" + new Date()
            // );
            setSelectedPhoto();
          }}
          class="font-size-12 badge-soft-info badge badge-success badge-pill"
        >
          <i className="mdi mdi-image-edit mr-1" title="Click to Edit"></i>{" "}
          {props.t("Change")}
        </span>
      </>
    );
  }
  const handleUserStatusUpdate = async (user) => {
    let url = process.env.REACT_APP_BASEURL + "adminusers/activate_deactivate";

    const response = await postSubmitForm(url, {
      username: user.username,
      is_active: !user.is_active,
    });
    if (response && response.status === 1) {
      loadAllUsers();
    } else {
      showNotification(response.message, props.t("Error"));
    }
  };
  function showStatusFormatter(cell, row) {
    // if (row.is_active) {
    return (
      <>
        <div className="custom-control custom-switch mb-2" dir="ltr">
          <input
            type="checkbox"
            title="Click to change status."
            className="custom-control-input"
            id={"customSwitch1" + row._id}
            checked={row.is_active}
          />
          <label
            title="Click to change status."
            className="custom-control-label"
            htmlFor={"customSwitch1" + row._id}
            style={{ "font-weight": "100", cursor: "pointer" }}
            onClick={() => {
              handleUserStatusUpdate(row);
            }}
          ></label>
        </div>

        {row.is_active ? (
          <span class="font-size-12 badge-soft-success badge badge-success badge-pill">
            {props.t("Active")}
          </span>
        ) : (
          <span class="font-size-12 badge-soft-danger badge badge-danger badge-pill">
            {props.t("Inactive")}
          </span>
        )}
      </>
    );
    // } else {
    //   return (
    //     <span class="font-size-12 badge-soft-danger badge badge-danger badge-pill">
    //       Inactive
    //     </span>
    //   );
    // }
  }
  const [editModal, setEditModal] = useState();
  const [imageModal, setImageModal] = useState();
  const [selectedUser, setSelectedUser] = useState();
  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="bx bxs-edit font-size-15"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditModal(!editModal);
            setSelectedUser(row);
            setSelectedRole(row.role);
            setQatarID(row.qatarid);
            setPassport(row.passport);
          }}
        ></i>
      </span>
    );
  }
  function showNotification(message, type) {
    var title = type;
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const handleValidUpdate = async (e, v) => {
    const data_to_send = {};
    data_to_send.username = selectedUser.username;
    data_to_send.name = v.name;
    data_to_send.pwd = v.pwd;
    data_to_send.role = v.role;

    if (v.branch_id) {
      data_to_send.branch_id = v.branch_id;
    }
    if (v.qatarid !== "") {
      data_to_send.qatarid = v.qatarid;
    }
    if (v.passport !== "") {
      data_to_send.passport = v.passport;
    }
    data_to_send.mobile = v.mobile;
    data_to_send.email = v.email;
    data_to_send.nationality = v.nationality;

    let url = process.env.REACT_APP_BASEURL + "adminusers/update";
    const response = await postSubmitForm(url, data_to_send);
    if (response && response.status === 1) {
      showNotification(response.message, "Success");
      loadAllUsers();
      setEditModal(!editModal);
    } else {
      showNotification(response.message, "Error");
    }
  };
  function srcToFile(src, fileName, mimeType) {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      });
  }
  const handleUpdatePhoto = async (e, v) => {
    // console.log(
    //   editorRef.current.getImageScaledToCanvas().toDataURL("image/png")
    // );
    srcToFile(
      editorRef.current.getImageScaledToCanvas().toDataURL("image/png"),
      "new_image.png",
      "image/png"
    ).then(function (file) {
      let url = process.env.REACT_APP_BASEURL + "adminusers/update_image";
      let formData = new FormData();
      formData.set("id", selectedUser._id);
      formData.append("image_file", file);
      postSubmitForm_withformdata(url, formData).then((api_response) => {
        if (api_response.status === 1 && api_response.data) {
          loadAllUsers();
          setEditModal(false);
          setImageModal(!imageModal);
          setSelectedPhoto();
          showNotification(api_response.message, props.t("Success"));
        } else {
          showNotification(api_response.message, props.t("Error"));
        }
      });
    });
  };

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1;
        return rowNumber;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      text: props.t("Username"),
      //formatter: employeeFormatter,
      dataField: "username",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "role",
      text: props.t("Role"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Name"),
      //formatter: employeeFormatter,
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "mobile",
      text: props.t("Mobile"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Qatar ID"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      formatter: (cell, row) => {
        return row.qatarid !== null ? row.qatarid : "";
      },
    },
    {
      text: props.t("Branch"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      formatter: (cell, row) => {
        return row.branch_details != null && row.branch_details != "null"
          ? row.branch_details.name
          : "";
      },
    },
    {
      text: props.t("Photo"),
      formatter: showPhotoFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "",
      text: props.t("Password"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <i
              className="mdi mdi-lock-open-alert font-size-20"
              title="Reset Password"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setPasswordModal(!passwordModal);
                setSelectedUser(row);
              }}
            ></i>
          </>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      text: props.t("Status"),
      formatter: showStatusFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
  ];
  const [passwordModal, setPasswordModal] = useState(false);
  const handleResetPassword = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "adminusers/resetpassword";
    const data_to_send = {
      username: selectedUser.username,
      newpwd: v.password,
    };
    const response = await postSubmitForm(url, data_to_send);
    if (response && response.status === 1) {
      showNotification(response.message, "Success");
      setPasswordModal(false);
      loadAllUsers();
    } else {
      showNotification(response.message, "Error");
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Users")}
            breadcrumbItem={props.t("All Users")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("All Users")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing users here")}
                  </CardSubtitle>

                  <ToolkitProvider
                    keyField="_id"
                    data={allUsers && allUsers}
                    columns={columns}
                    search
                  >
                    {(propst) => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>

                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Username")}:{" "}
                      {selectedUser && selectedUser.username}
                    </ModalHeader>
                    <ModalBody>
                      {selectedUser && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm
                                    onValidSubmit={(e, v) => {
                                      handleValidUpdate(e, v);
                                    }}
                                  >
                                    <Row>
                                      <Col lg={6}>
                                        <AvField
                                          name="name"
                                          label={props.t("Name") + " *"}
                                          placeholder={props.t("Enter Name")}
                                          type="text"
                                          errorMessage="Name cannot be empty."
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={selectedUser.name}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="role"
                                          label={props.t("Role") + " *"}
                                          placeholder={props.t("Enter Role")}
                                          type="select"
                                          value={selectedRole}
                                          onChange={(e) => {
                                            setSelectedRole(e.target.value);
                                          }}
                                          validate={{
                                            required: { value: true },
                                          }}
                                          errorMessage={props.t("Select Role")}
                                        >
                                          <option value="">
                                            -- {props.t("Select Role")} --
                                          </option>
                                          <option value="Accounts Manager">
                                            {props.t("Accounts Manager")}
                                          </option>
                                          <option value="Branch Supervisor">
                                            {props.t("Branch Supervisor")}
                                          </option>
                                          <option value="Central Store Manager">
                                            {props.t("Central Store Manager")}
                                          </option>
                                          <option value="Central Kitchen Manager">
                                            {props.t("Central Kitchen Manager")}
                                          </option>
                                          <option value="Operations Manager">
                                            {props.t("Operations Manager")}
                                          </option>
                                          <option value="Owner">
                                            {props.t("Owner")}
                                          </option>
                                        </AvField>
                                      </Col>
                                      {selectedRole === "Branch Supervisor" && (
                                        <Col lg={6}>
                                          <AvField
                                            name="branch_id"
                                            label={props.t("Branch") + " *"}
                                            type="select"
                                            //onChange={handleChange}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            errorMessage={props.t(
                                              "Select Branch"
                                            )}
                                            value={
                                              selectedUser.branch_details &&
                                              selectedUser.branch_details._id
                                            }
                                          >
                                            <option value="">
                                              -- {props.t("Select Branch")} --
                                            </option>

                                            {allBranches &&
                                              allBranches.map((branch) => (
                                                <option
                                                  key={branch._id}
                                                  value={branch._id}
                                                >
                                                  {branch.name}
                                                </option>
                                              ))}
                                          </AvField>
                                        </Col>
                                      )}
                                      <Col lg={6}>
                                        <AvField
                                          name="mobile"
                                          label={props.t("Mobile") + " *"}
                                          placeholder={props.t("Enter Mobile")}
                                          type="text"
                                          value={selectedUser.mobile}
                                          validate={{
                                            required: {
                                              value: true,
                                              errorMessage: props.t(
                                                "Mobile cannot be empty."
                                              ),
                                            },
                                            pattern: {
                                              value: "^[0-9]+$",
                                              errorMessage: props.t(
                                                "Only numbers are allowed."
                                              ),
                                            },
                                            maxLength: {
                                              value: 8,
                                              errorMessage: props.t(
                                                "Enter valid mobile."
                                              ),
                                            },
                                            minLength: {
                                              value: 8,
                                              errorMessage: props.t(
                                                "Enter valid mobile."
                                              ),
                                            },
                                          }}
                                        />
                                      </Col>

                                      <Col lg={6}>
                                        <AvField
                                          name="nationality"
                                          label={props.t("Nationality") + " *"}
                                          type="select"
                                          value={selectedUser.nationality}
                                          validate={{
                                            required: { value: true },
                                          }}
                                          errorMessage={props.t(
                                            "Select Nationality"
                                          )}
                                        >
                                          <option value="">
                                            -- {props.t("Select Nationality")}{" "}
                                            --
                                          </option>
                                          {allCountries &&
                                            allCountries.map((item) => {
                                              return (
                                                <option value={item.name}>
                                                  {item.name}
                                                </option>
                                              );
                                            })}
                                        </AvField>
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="email"
                                          value={selectedUser.email}
                                          label={props.t("Email")}
                                          placeholder={props.t("Enter Email")}
                                          errorMessage={props.t(
                                            "Please enter valid email."
                                          )}
                                          type="email"
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="qatarid"
                                          value={
                                            selectedUser.qatarid !== null &&
                                            selectedUser.qatarid !== "null"
                                              ? selectedUser.qatarid
                                              : ""
                                          }
                                          label={props.t("Qatar ID") + " *"}
                                          placeholder={props.t(
                                            "Enter Qatar ID"
                                          )}
                                          errorMessage={props.t(
                                            "Both Qatar ID and Passport Number cannot be empty."
                                          )}
                                          type="text"
                                          onChange={(e) => {
                                            setQatarID(e.target.value);
                                          }}
                                          validate={{
                                            required: {
                                              value: passport ? false : true,
                                            },
                                            pattern: {
                                              value: "^[0-9]+$",
                                              errorMessage: props.t(
                                                "Only numbers are allowed."
                                              ),
                                            },

                                            maxLength: {
                                              value: 11,
                                              errorMessage: props.t(
                                                "Enter valid qatar id."
                                              ),
                                            },
                                            minLength: {
                                              value: 11,
                                              errorMessage: props.t(
                                                "Enter valid qatar id."
                                              ),
                                            },
                                          }}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="passport"
                                          value={
                                            selectedUser.passport !== null &&
                                            selectedUser.passport !== "null"
                                              ? selectedUser.passport
                                              : ""
                                          }
                                          label={
                                            props.t("Passport Number") + " *"
                                          }
                                          placeholder={props.t(
                                            "Enter Passport Number"
                                          )}
                                          errorMessage={props.t(
                                            "Both Qatar ID and Passport Number cannot be empty."
                                          )}
                                          type="text"
                                          onChange={(e) => {
                                            setPassport(e.target.value);
                                          }}
                                          validate={{
                                            required: {
                                              value: qatarID ? false : true,
                                            },
                                            maxLength: {
                                              value: 25,
                                              errorMessage: props.t(
                                                "Enter valid passport number."
                                              ),
                                            },
                                            minLength: {
                                              value: 6,
                                              errorMessage: props.t(
                                                "Enter valid passport number."
                                              ),
                                            },
                                          }}
                                        />
                                      </Col>

                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                            >
                                              {props.t("Update")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="md"
                    isOpen={imageModal}
                    toggle={() => setImageModal(!imageModal)}
                  >
                    <ModalHeader toggle={() => setImageModal(!imageModal)}>
                      {props.t("Username")}:{" "}
                      {selectedUser && selectedUser.username}
                    </ModalHeader>
                    <ModalBody>
                      {selectedUser && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm
                                    onValidSubmit={(e, v) => {
                                      handleUpdatePhoto(e, v);
                                    }}
                                  >
                                    <Row>
                                      <Col lg={12}>
                                        <FormGroup className="mb-0 text-center d-flex justify-content-center">
                                          <div>
                                            <ReactAvatarEditor
                                              ref={editorRef}
                                              scale={parseFloat(scale)}
                                              width={200}
                                              height={200}
                                              position={position}
                                              onPositionChange={
                                                (position) => {
                                                  setPosition(position);
                                                }
                                                //this.handlePositionChange
                                              }
                                              rotate={rotate}
                                              borderRadius={
                                                100 / (100 / borderRadius)
                                              }
                                              image={
                                                selectedPhoto && selectedPhoto
                                              }
                                              className="editor-canvas"
                                            />
                                            <br />
                                            <br />
                                            <input
                                              name="scale"
                                              type="range"
                                              onChange={handleScale}
                                              min={allowZoomOut ? "0.1" : "1"}
                                              max="2"
                                              step="0.01"
                                              defaultValue="1"
                                            />
                                            <br />
                                            <br />
                                            <Input
                                              type="file"
                                              id="resume"
                                              onChange={onPhotoChange}
                                            />
                                          </div>
                                        </FormGroup>
                                        <hr />
                                      </Col>

                                      <Col lg={12}>
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                              disabled={
                                                selectedPhoto && selectedPhoto
                                                  ? false
                                                  : true
                                              }
                                            >
                                              {props.t("Save")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="md"
                    isOpen={passwordModal}
                    toggle={() => setPasswordModal(!passwordModal)}
                  >
                    <ModalHeader
                      toggle={() => setPasswordModal(!passwordModal)}
                    >
                      Username: {selectedUser && selectedUser.username}
                    </ModalHeader>
                    <ModalBody>
                      {selectedUser && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm
                                    onValidSubmit={(e, v) => {
                                      handleResetPassword(e, v);
                                    }}
                                  >
                                    <Row>
                                      <Col lg={12}>
                                        <AvField
                                          name="password"
                                          placeholder={props.t(
                                            "Enter Password"
                                          )}
                                          type="text"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          errorMessage={props.t(
                                            "Password cannot be empty."
                                          )}
                                        />
                                        <hr />
                                      </Col>

                                      <Col lg={12}>
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                            >
                                              {props.t("Reset")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withNamespaces()(AllUsers)));
