import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import cellEditFactory from "react-bootstrap-table2-editor";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import "../../../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const FinishedProductAllOrders = (props) => {
  const [receiveBtnDisabled, setReceiveBtnDisabled] = useState(false);

  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const [dateFrom, setDateFrom] = useState(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    return firstDay;
  });
  const [dateTo, setDateTo] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    setDateFrom(formatDate(firstDay));
    setDateTo(formatDate(currentDay));

    loadAllProducts();
  }, []);

  const [isUrgent, setIsUrgent] = useState(false);
  const [remarks, setRemarks] = useState();

  const [allOrders, setAllOrders] = useState([]);
  const [allOrdersFiltered, setAllOrdersFiltered] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [received, setRecieved] = useState([]);
  const [yetToReceive, setYetToReceive] = useState([]);
  const loadAllOrders = async () => {
    const days_diff = moment(dateTo).diff(dateFrom, "days");
    if (days_diff > 30) {
      setAllOrders([]);
      showNotification("Total number of days cannot be more than 31.", "Error");
      return false;
    } else if (days_diff < 0) {
      setAllOrders([]);
      showNotification("Invalid date range.", "Error");
      return false;
    }

    preloader(true);
    let date_from = moment(dateFrom).format("YYYY-MM-DD"),
      date_to = moment(dateTo).format("YYYY-MM-DD");

    let url = process.env.REACT_APP_BASEURL + "productorders/getforbranch";
    const response = await postSubmitForm(url, {
      date_from: date_from,
      date_to: date_to,
    });
    if (response && response.status === 1) {
      preloader(false);
      // let expandedRows = [];
      // response.data.map((item) => {
      //   if (item.status !== "Closed" && item.status !== "Rejected") {
      //     expandedRows.push(item._id);
      //   }
      // });
      setAllOrders(response.data);
      // setExpanded(expandedRows);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };
  useEffect(() => {
    if (selectedStatus) {
      if (selectedStatus === "All") {
        setAllOrdersFiltered(allOrders);
      } else {
        var data = JSON.parse(JSON.stringify(allOrders));
        setAllOrdersFiltered(
          data.filter((order) => order.status === selectedStatus)
        );
      }
    }
  }, [allOrders, selectedStatus]);

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Section"),
      dataField: "section",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Product"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
    },
    {
      dataField: "quantity",
      text: props.t("Order Quantity"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "available",
      text: props.t("Branch Avl. Qty."),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const [selectedOrder, setSelectedOrder] = useState();
  const [editModal, setEditModal] = useState();
  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
      csvExport: false,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      csvExport: false,
    },
    {
      dataField: "order_id",
      text: props.t("Order ID"),

      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),

      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },

      style: { cursor: "pointer" },
    },
    {
      text: props.t("Order Date"),
      dataField: "date_to_process",
      headerStyle: (colum, colIndex) => {
        return { width: "14%" };
      },
      // formatter: (cell, row) => {
      //   return moment(row.date_to_process).format("DD-MM-YYYY HH:mm");
      // },
      style: { cursor: "pointer" },
    },
    {
      text: props.t("Is Urgent?"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      formatter: (cell, row) => {
        if (row.is_urgent) {
          return props.t("Yes");
        } else {
          return props.t("No");
        }
      },
      csvFormatter: (cell, row) => {
        if (row.is_urgent) {
          return props.t("Yes");
        } else {
          return props.t("No");
        }
      },
      style: { cursor: "pointer" },
    },

    {
      dataField: "status",
      text: props.t("Status"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "admin_remarks",
      text: props.t("Centre Remarks"),

      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      csvExport: false,
      formatter: (cell, row) => {
        if (row.status === "Placed") {
          return (
            <span className="text-primary">
              <i
                className="bx bxs-edit font-size-15"
                title="Click to Edit"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setReceiveBtnDisabled(false);
                  setEditModal(!editModal);
                  setSelectedOrder(row);

                  setIsUrgent(row.is_urgent);
                  setProductsForOrder(row.product_details);
                  setRemarks(row.remarks);
                }}
              ></i>
            </span>
          );
        } else {
          return (
            <span className="text-primary">
              <i
                className="mdi mdi-text-box-search font-size-15"
                title="Click to View Details"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDetailsModal(!detailsModal);
                  setSelectedOrder(row);
                  let received_products = [];
                  let to_receive_products = [];
                  row.delivery_details.map((product) => {
                    if (product.is_received) {
                      received_products.push(product);
                    } else {
                      product.received_quantity = product.delivered_quantity;
                      to_receive_products.push(product);
                    }
                  });
                  setRecieved(received_products);
                  setYetToReceive(to_receive_products);
                }}
              ></i>
            </span>
          );
        }
      },
    },
  ];

  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      setExpanded([...expanded, row._id]);
    } else {
      setExpanded(expanded.filter((x) => x !== row._id));
    }
  };
  const [expanded, setExpanded] = useState([]);
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.product_details}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
    expanded: expanded,
    onExpand: handleOnExpand,
  };
  const rowStyle = (row, rowIndex) => {
    const style = { backgroundColor: "#fffbb8", fontWeight: "500" };
    if (row.status === "Closed") {
      style.backgroundColor = "#b8ffb9";
    } else if (row.status === "Placed") {
      style.backgroundColor = "#ffb8b8";
    } else if (row.status === "Rejected") {
      style.backgroundColor = "#f1b8ff";
    } else {
      style.backgroundColor = "#b8cdff";
    }
    return style;
  };

  const [detailsModal, setDetailsModal] = useState(false);
  const [receiveModal, setReceiveModal] = useState();
  const [selectedDeliveryDetail, setSelectedDeliveryDetail] = useState();
  const handleReceiveProductOrder = async (e, v) => {
    setReceiveBtnDisabled(true);
    let productorder_id = selectedOrder._id;
    let delivery_details = [];
    for (let i = 0; i < yetToReceive.length; i++) {
      delivery_details.push({
        _id: yetToReceive[i]._id,
        received_quantity: +yetToReceive[i].received_quantity,
        batch: yetToReceive[i].batch,
        remarks: yetToReceive[i].remarks,
      });
    }
    const data_to_send = {
      productorder_id,
      delivery_details,
    };
    let isNoRemarkUpdated = yetToReceive.some(
      (item) =>
        +item.received_quantity !== +item.delivered_quantity &&
        item.remarks === ""
    );

    if (!isNoRemarkUpdated) {
      let url =
        process.env.REACT_APP_BASEURL + "productorders/receive_by_branch";
      const response = await postSubmitForm(url, data_to_send);

      if (response && response.status === 1) {
        loadAllOrders();

        setDetailsModal(!detailsModal);
        showNotification(response.message, "Success");
      } else {
        showNotification(response.message, "Error");
      }
    } else {
      showNotification(
        "Enter remarks for all variances in received products.",
        "Error"
      );
    }
  };
  const columns_received = [
    {
      text: props.t("Section"),
      dataField: "section",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Product"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    // {
    //   text: props.t("Batch"),
    //   dataField: "batch",
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "10%" };
    //   },
    // },

    {
      text: props.t("Dispatch Time"),
      dataField: "date_of_transaction",
      formatter: (cell, row) => {
        return moment(row.date_of_transaction).format("DD/MM/YYYY HH:mm");
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      text: props.t("Del. Qty."),
      dataField: "delivered_quantity",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Rec. Qty."),
      dataField: "received_quantity",

      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Remarks"),
      dataField: "remarks",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
  ];
  const columns_yet_to_receive = [
    {
      text: props.t("Section"),
      dataField: "section",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Product"),
      dataField: "name",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    // {
    //   text: props.t("Batch"),
    //   dataField: "batch",
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "10%" };
    //   },
    // },

    {
      text: props.t("Dispatch Time"),
      dataField: "date_of_transaction",
      editable: false,
      formatter: (cell, row) => {
        return moment(row.date_of_transaction).format("DD/MM/YYYY HH:mm");
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      text: props.t("Del. Qty."),
      dataField: "delivered_quantity",
      editable: false,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Rec. Qty."),
      dataField: "received_quantity",
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          };
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          };
        }
        return true;
      },
      attrs: (cell, row, rowIndex, colIndex) => {
        return { title: "Click to Edit" };
      },
      style: (cell, row, rowIndex, colIndex) => {
        return { cursor: "pointer", background: "#ffffaa" };
      },
      editable: true,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      // formatter: (cell, row) => {
      //   return <>{row.sub_unit ? +row.received_quantity.in_sub_unit : 0}</>;
      // },
      editable: true,
      attrs: { title: "Click to Edit" },
      style: { cursor: "pointer", background: "#ffffaa" },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
  ];
  //#region  Update related functions

  const refForForm = useRef(null);
  const refSelectProduct = useRef(null);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const loadAllProducts = async () => {
    let url = process.env.REACT_APP_BASEURL + "branchproducts/getforbranch";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllProducts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [productsForOrder, setProductsForOrder] = useState([]);
  const handleAddProductForOrder = async (e, v) => {
    var new_array_products = JSON.parse(JSON.stringify(productsForOrder));
    const existing_product = new_array_products.filter(function (product) {
      return product.product_id === selectedProduct.product_id;
    });

    if (existing_product.length > 0) {
      showNotification(
        props.t("This product is already added."),
        props.t("Error")
      );
    } else if (selectedProduct) {
      let product_to_add = {};
      product_to_add.product_id = selectedProduct.product_id;
      product_to_add.name = selectedProduct.name;
      product_to_add.section = selectedProduct.section;
      product_to_add.category = selectedProduct.category;
      product_to_add.available = selectedProduct.available;
      product_to_add.quantity = v.quantity;
      setProductsForOrder([...productsForOrder, product_to_add]);
      refSelectProduct.current.select.clearValue();
      refForForm.current.reset();
    } else {
      showNotification(props.t("Select a product first."), props.t("Error"));
    }
  };
  const handleValidUpdate = async (e, v) => {
    try {
      const object = {
        id: selectedOrder._id,
        product_details: productsForOrder,
        is_urgent: isUrgent,
        remarks: v.remarks,
      };
      let url = process.env.REACT_APP_BASEURL + "productorders/update";
      let response = await postSubmitForm(url, object);
      if (response.status === 1) {
        loadAllOrders();
        setEditModal(!editModal);
        setRemarks();
        setProductsForOrder([]);
        setIsUrgent(false);
        setSelectedProduct();
        refSelectProduct.current.select.clearValue();
        refForForm.current.reset();

        showNotification(response.message, "Success");

        refForForm.current.reset();
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };
  function deleteFormatter(cell, row, rowIndex, extraData) {
    return (
      <>
        <span className="text-danger">
          <i
            className="bx bxs-trash font-size-15"
            title="Click to Delete"
            style={{ cursor: "pointer" }}
            onClick={() => {
              let arr = extraData.filter(
                (item) => item.product_id !== row.product_id
              );

              setProductsForOrder(arr);
            }}
          ></i>
        </span>
      </>
    );
  }
  const columns_products = [
    {
      dataField: "section",
      text: props.t("Section"),
    },
    {
      dataField: "category",
      text: props.t("Category"),
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "available",
      text: props.t("Branch Avl. Qty."),
    },
    {
      dataField: "quantity",
      text: props.t("Order Quantity"),
    },
    {
      formatter: deleteFormatter,
      formatExtraData: productsForOrder,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];
  //#endregion Update related functions

  const handleDownload = async () => {
    const fileName = "all_finished_product_orders";
    const exportType = "xls";

    let data_to_export = allOrders;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          OrderID: v.order_id,
          Remarks: v.remarks,
          Date: moment(v.date_to_process).format("DD/MM/YYYY HH:mm"),
          IsUrgent: v.is_urgent ? "Yes" : "No",
          Status: v.status,
          CenterRemarks: v.admin_remarks,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Finished Products")}
            title={props.t("Orders")}
            breadcrumbItem1={props.t("Finished Products")}
            breadcrumbItem2={props.t("All Orders")}
          />

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={4}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={4}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={4}>
                        <Button
                          type="submit"
                          className="btn btn-md btn-success mt-4"
                          onClick={() => {
                            if (dateFrom && dateTo) {
                              loadAllOrders();
                            }
                          }}
                        >
                          <i className="mdi mdi-checkbox-marked-circle"></i>{" "}
                          {props.t("Check")}
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"   "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <div id="printDiv">
                    <CardTitle className="text-center">
                      {props.t("All Finished Product Orders")}
                    </CardTitle>
                    <ToolkitProvider
                      keyField="_id"
                      data={allOrdersFiltered && allOrdersFiltered}
                      columns={columns_external}
                      noDataIndication={props.t("No data to display.")}
                      bootstrap4
                      search
                      exportCSV
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={5}></Col>
                            <Col lg={3} className="text-right">
                              <select
                                name="status"
                                className="form-control"
                                value={selectedStatus}
                                onChange={(e) => {
                                  setSelectedStatus(e.target.value);
                                }}
                              >
                                <option value="All">All</option>
                                <option value="Placed">Placed</option>
                                <option value="Approved">Approved</option>
                                <option value="Dispatched">Dispatched</option>
                                <option value="Closed">Closed</option>
                                <option value="Rejected">Rejected</option>
                              </select>
                            </Col>
                          </Row>

                          <BootstrapTable
                            striped
                            hover
                            condensed
                            expandRow={expandRow}
                            rowStyle={rowStyle}
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update Order")}
                    </ModalHeader>
                    <ModalBody>
                      {selectedOrder && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm
                                    onValidSubmit={handleAddProductForOrder}
                                    ref={refForForm}
                                  >
                                    <Row>
                                      <Col lg={6}>
                                        <Label>{props.t("Product")}</Label>
                                        <Select
                                          ref={refSelectProduct}
                                          value={selectedProduct}
                                          onChange={(selected) => {
                                            setSelectedProduct(selected);
                                          }}
                                          options={allProducts}
                                          classNamePrefix="select2-selection"
                                          components={animatedComponents}
                                          required
                                        />
                                      </Col>
                                      <Col lg={4}>
                                        <AvField
                                          name="quantity"
                                          label={props.t("Quantity")}
                                          placeholder={props.t(
                                            "Enter Quantity"
                                          )}
                                          type="text"
                                          validate={{
                                            required: {
                                              value: true,
                                              errorMessage: props.t(
                                                "Quantity cannot be empty."
                                              ),
                                            },
                                            pattern: {
                                              value: "^[0-9]+$",
                                              errorMessage: props.t(
                                                "Only numbers are allowed."
                                              ),
                                            },
                                            max: {
                                              value:
                                                selectedProduct &&
                                                selectedProduct.max_order_limit,
                                              errorMessage:
                                                selectedProduct &&
                                                props.t(
                                                  "Cannot order more than "
                                                ) +
                                                  selectedProduct.max_order_limit +
                                                  ".",
                                            },
                                          }}
                                        />
                                      </Col>
                                      <Col lg={2} className="mb-4">
                                        <Label>&nbsp;</Label>
                                        <br />
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="btn btn-info waves-effect waves-light btn-sm"
                                        >
                                          <i class="bx bx-add-to-queue"></i>{" "}
                                          {props.t("Add")}
                                        </Button>
                                      </Col>
                                    </Row>
                                    {/* <Button type="submit">Submit</Button> */}
                                  </AvForm>
                                  <hr />
                                  {productsForOrder &&
                                    productsForOrder.length > 0 && (
                                      <>
                                        <BootstrapTable
                                          bootstrap4
                                          keyField="_id"
                                          data={
                                            productsForOrder && productsForOrder
                                          }
                                          columns={columns_products}
                                          noDataIndication="No data to display."
                                          striped
                                          hover
                                          condensed
                                        />
                                        <AvForm
                                          onValidSubmit={handleValidUpdate}
                                        >
                                          <Row>
                                            <Col lg={6}>
                                              <AvField
                                                name="remarks"
                                                value={remarks}
                                                onChange={(e) => {
                                                  setRemarks(e.target.value);
                                                }}
                                                label={props.t("Remarks")}
                                                placeholder={props.t(
                                                  "Enter Remarks"
                                                )}
                                                type="textarea"
                                              />
                                            </Col>
                                            <Col lg={12}>
                                              <div class="form-check">
                                                <input
                                                  type="checkbox"
                                                  name="is_urgent"
                                                  className="is-untouched is-pristine av-valid form-check-input"
                                                  checked={isUrgent}
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setIsUrgent(true);
                                                    } else {
                                                      setIsUrgent(false);
                                                    }
                                                  }}
                                                />
                                                <label for="select_all">
                                                  {" "}
                                                  {props.t(
                                                    "This is an urgent order."
                                                  )}
                                                </label>
                                              </div>
                                            </Col>
                                            <Col lg={12}>
                                              <hr />
                                              <FormGroup className="mb-0 text-left">
                                                <div>
                                                  <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="mr-1"
                                                  >
                                                    {props.t("Update Order")}
                                                  </Button>{" "}
                                                </div>
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                        </AvForm>
                                      </>
                                    )}
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="lg"
                    isOpen={detailsModal}
                    toggle={() => setDetailsModal(!detailsModal)}
                  >
                    <ModalHeader toggle={() => setDetailsModal(!detailsModal)}>
                      {props.t("Order Details")}
                    </ModalHeader>
                    <ModalBody>
                      <>
                        <Row>
                          <Col lg={12}>
                            <Card>
                              <CardBody>
                                <Row>
                                  {selectedOrder &&
                                  yetToReceive &&
                                  yetToReceive.length > 0 ? (
                                    <>
                                      <AvForm
                                        onValidSubmit={
                                          handleReceiveProductOrder
                                        }
                                      >
                                        <Col lg={12} md={12}>
                                          <br />
                                          <Label>
                                            <b>
                                              {props.t("Dispatched Products")}{" "}
                                            </b>
                                          </Label>
                                        </Col>
                                        <Col lg={12} className="mb-4">
                                          <BootstrapTable
                                            bootstrap4
                                            keyField="name"
                                            key="name"
                                            data={yetToReceive}
                                            columns={columns_yet_to_receive}
                                            cellEdit={cellEditFactory({
                                              mode: "click",
                                              blurToSave: true,
                                            })}
                                            noDataIndication="No data to display."
                                            striped
                                            hover
                                            condensed
                                          />
                                        </Col>
                                        <Col lg={12} className="text-center">
                                          <Label>&nbsp;</Label>
                                          <br />
                                          <Button
                                            type="submit"
                                            disabled={receiveBtnDisabled}
                                            color="primary"
                                            className="btn btn-default waves-effect waves-light"
                                          >
                                            {props.t("Receive Products")}
                                          </Button>
                                          <hr />
                                        </Col>
                                      </AvForm>
                                    </>
                                  ) : null}
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      printDiv("printOrdered");
                                    }}
                                    className="btn btn-sm btn-success mr-2"
                                  >
                                    <i className="fa fa-print"></i> Print
                                  </Link>
                                  <div id="printOrdered">
                                    <Col lg={12} md={12}>
                                      <Label>Ordered Products</Label>
                                    </Col>

                                    <Col lg={12}>
                                      <BootstrapTable
                                        bootstrap4
                                        keyField="name"
                                        data={
                                          selectedOrder &&
                                          selectedOrder.product_details
                                        }
                                        columns={columns}
                                        noDataIndication="No data to display."
                                        striped
                                        hover
                                        condensed
                                      />
                                      <hr />
                                    </Col>
                                  </div>
                                  {selectedOrder &&
                                  received &&
                                  received.length > 0 ? (
                                    <>
                                      <Col lg={12} md={12}>
                                        <br />
                                        <Label>
                                          <b>{props.t("Received Products")} </b>
                                        </Label>
                                      </Col>
                                      <Col lg={12} className="mb-4">
                                        <BootstrapTable
                                          bootstrap4
                                          keyField="name"
                                          key="name"
                                          data={received}
                                          columns={columns_received}
                                          noDataIndication="No data to display."
                                          striped
                                          hover
                                          condensed
                                        />
                                        <hr />
                                      </Col>
                                    </>
                                  ) : null}
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="md"
                    isOpen={receiveModal}
                    toggle={() => setReceiveModal(!receiveModal)}
                  >
                    <ModalHeader toggle={() => setReceiveModal(!receiveModal)}>
                      {props.t("Recieve Product Order")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleReceiveProductOrder(e, v);
                                }}
                              >
                                <Row>
                                  <Col lg={6}>
                                    <Label>{props.t("Section") + ": "}</Label>

                                    <span>
                                      {" "}
                                      {selectedDeliveryDetail &&
                                        selectedDeliveryDetail.section}
                                    </span>
                                  </Col>

                                  <Col lg={6}>
                                    <Label>{props.t("Category") + ": "}</Label>
                                    <span>
                                      {" "}
                                      {selectedDeliveryDetail &&
                                        selectedDeliveryDetail.category}
                                    </span>
                                  </Col>

                                  <Col lg={6}>
                                    <Label>{props.t("Name") + ": "}</Label>
                                    <span>
                                      {" "}
                                      {selectedDeliveryDetail &&
                                        selectedDeliveryDetail.name}
                                    </span>
                                  </Col>
                                  <Col lg={6}>
                                    <Label>
                                      {props.t("Delivered Quantity") + ": "}
                                    </Label>
                                    <span>
                                      {" "}
                                      {selectedDeliveryDetail &&
                                        selectedDeliveryDetail.delivered_quantity}
                                    </span>
                                  </Col>
                                  <Col lg={12}>
                                    <hr />
                                    <AvField
                                      name="received_quantity"
                                      label={props.t("Received Quantity")}
                                      placeholder={props.t(
                                        "Enter Received Quantity"
                                      )}
                                      type="text"
                                      errorMessage="Received quantity cannot be empty."
                                      validate={{
                                        required: { value: true },
                                        pattern: {
                                          value: "^[0-9]+$",
                                          errorMessage: props.t(
                                            "Only numbers are allowed."
                                          ),
                                        },
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Submit")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(
  connect(null, {})(withNamespaces()(FinishedProductAllOrders))
);
