import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";
import "../../../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { postSubmitForm } from "../../../../helpers/forms_helper";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const FinishedProductOrdersInProcessForDashboard = (props) => {
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }

  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }
  const urlParams = new URLSearchParams(window.location.search);
  const { SearchBar } = Search;
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [selectedSection, setSelectedSection] = useState(
    urlParams.get("section") ? urlParams.get("section") : "All"
  );
  useEffect(() => {
    if (urlParams.get("section")) {
      setSelectedSection(urlParams.get("section"));
    }
    loadAllBranches();
    loadAllSections();
  }, []);
  const [confirmClose, setConfirmClose] = useState(false);

  const [centralAvailableStatus, setCentralAvailableStatus] = useState(true);

  useEffect(() => {
    loadAllOrders();
  }, [selectedSection, selectedBranch]);

  const [allOrders, setAllOrders] = useState([]);
  const loadAllOrders = async () => {
    preloader(true);
    let url = process.env.REACT_APP_BASEURL + "productorders/getall";
    const response = await postSubmitForm(url, {
      status: ["Partially Delivered"],
      branch: selectedBranch,
      section: selectedSection,
    });
    if (response && response.status === 1) {
      // let expandedRows = [];
      // response.data.map((item) => {
      //   expandedRows.push(item._id);
      // });
      preloader(false);

      setAllOrders(response.data);
      //setExpanded(expandedRows);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };
  const [allSections, setAllSections] = useState([]);
  const loadAllSections = async () => {
    let url = process.env.REACT_APP_BASEURL + "productsections/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllSections(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [allBranches, setAllBranches] = useState([]);
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Section"),
      dataField: "section",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Product"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
    },
    {
      dataField: "quantity",
      text: props.t("Order Quantity"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "available",
      text: props.t("Branch Avl. Qty."),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "central_available",
      text: props.t("Central Avl. Qty."),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const [selectedOrder, setSelectedOrder] = useState();
  const handleCloseOrder = async () => {
    let url = process.env.REACT_APP_BASEURL + "productorders/set_closed";
    const response = await postSubmitForm(url, {
      productorder_id: selectedOrder._id,
      admin_remarks: adminRemarks,
    });
    //console.log(data_to_send);
    if (response && response.status === 1) {
      loadAllOrders();
      setRemarksModal(!remarksModal);
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [remarksModal, setRemarksModal] = useState();
  const [adminRemarks, setAdminRemarks] = useState();
  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      dataField: "order_id",
      text: props.t("Order ID"),

      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "branch_details.name",
      text: props.t("Branch Name"),

      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),

      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },

      style: { cursor: "pointer" },
    },
    {
      text: props.t("Order Date"),
      dataField: "date_to_process",
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      // formatter: (cell, row) => {
      //   return moment(row.date_to_process).format("DD-MM-YYYY HH:mm");
      // },
      style: { cursor: "pointer" },
    },
    {
      text: props.t("Is Urgent?"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      formatter: (cell, row) => {
        if (row.is_urgent) {
          return props.t("Yes");
        } else {
          return props.t("No");
        }
      },
      style: { cursor: "pointer" },
    },

    {
      dataField: "status",
      text: props.t("Status"),
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "",
      text: props.t("Action"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <button
              class="btn btn-secondary btn-sm mr-2"
              onClick={() => {
                setSelectedOrder(row);
                //setProductsForDelivery([]);
                // setSelectedProduct();
                // setSelectedBatch();
                // setQuantity();
                // loadProductsInOrder(row._id);
                loadProductsForDelivery(row._id);
                setOrderDispatchModal(!orderDispatchModal);
                //setCentralAvailableStatus(row.central_available_status);
                // if (row.status === "Approved") {
                //   setIsPartialDelivery(false);
                //   handleSetProductsForFullDelivery(row.product_details);
                // } else setIsPartialDelivery(true);
              }}
            >
              {props.t("Dispatch")}
            </button>
            <button
              class="btn btn-danger btn-sm mr-2"
              onClick={() => {
                setRemarksModal(!remarksModal);
                setSelectedOrder(row);
              }}
            >
              {props.t("Close")}
            </button>
          </>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
  ];
  const handleSetProductsForFullDelivery = async (products) => {
    if (products.length > 0) {
      let new_arr = products.map((prod) => {
        let obj = {
          name: prod.name,
          section: prod.section,
          category: prod.category,
          product_id: prod.product_id,
          delivered_quantity: prod.quantity,
        };
        return obj;
      });
      setProductsForDelivery(new_arr);
    } else {
      setProductsForDelivery([]);
    }
  };
  const columns_fulldelivery = [
    {
      dataField: "product_id",
      hidden: true,
    },
    {
      text: props.t("Product"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Section"),
      dataField: "section",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "delivered_quantity",
      text: props.t("Delivered Quantity"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      setExpanded([...expanded, row._id]);
    } else {
      setExpanded(expanded.filter((x) => x !== row._id));
    }
  };
  const [expanded, setExpanded] = useState([]);
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.product_details}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
    expanded: expanded,
    onExpand: handleOnExpand,
  };

  //#region Dispatch order related code
  const [productsForDelivery, setProductsForDelivery] = useState([]);
  const [isShowDispatch, setIsShowDispatch] = useState();
  const loadProductsForDelivery = async (productorder_id) => {
    let url =
      process.env.REACT_APP_BASEURL + "productorders/get_products_to_dispatch";

    const response = await postSubmitForm(url, { productorder_id });

    if (response && response.status === 1) {
      setIsShowDispatch(response.data.show_dispatch);
      setProductsForDelivery(response.data.to_dispatch);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const columns_products_to_dispatch = [
    {
      dataField: "product_id",
      hidden: true,
    },
    {
      text: props.t("Product"),
      dataField: "name",
      editable: false,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Section"),
      dataField: "section",
      editable: false,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      editable: false,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "quantity",
      editable: true,
      text: props.t("Delivered Quantity"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const [quantity, setQuantity] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [orderDispatchModal, setOrderDispatchModal] = useState();
  const [productBatches, setProductBatches] = useState();
  const [selectedBatch, setSelectedBatch] = useState();
  const [allProducts, setAllProducts] = useState([]);
  const loadProductsInOrder = async (productorder_id) => {
    let url =
      process.env.REACT_APP_BASEURL + "productorders/get_products_in_order";

    const response = await postSubmitForm(url, { productorder_id });

    if (response && response.status === 1) {
      setAllProducts(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const loadBatches = async (product_details) => {
    let url =
      process.env.REACT_APP_BASEURL + "productinventories/get_for_delivery";
    console.log(product_details);
    const response = await postSubmitForm(url, {
      product_id: product_details.value,
    });
    if (response && response.status === 1) {
      setProductBatches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const handleAddProductForDelivery = async () => {
    //console.log("hello");
    if (!selectedBatch) {
      showNotification(
        props.t("Please select product batch."),
        props.t("Error")
      );
    } else if (!quantity || quantity <= 0) {
      showNotification(props.t("Please add quantity."), props.t("Error"));
    } else {
      var new_array = JSON.parse(JSON.stringify(productsForDelivery));
      const existing_product = new_array.filter(function (product) {
        return product.batch === selectedBatch.batch;
      });
      if (existing_product.length > 0) {
        showNotification(
          props.t("This product batch is already added."),
          "Error"
        );
      } else {
        if (selectedBatch.quantity < quantity) {
          showNotification(props.t("Quantity not in stock."), "Error");
        } else {
          setProductsForDelivery([
            ...productsForDelivery,
            {
              batch: selectedBatch.batch,
              name: selectedProduct.name,
              section: selectedProduct.section,
              category: selectedProduct.category,
              product_id: selectedProduct.value,
              delivered_quantity: quantity,
            },
          ]);

          setQuantity(0);
          setSelectedProduct();
        }
      }
    }
  };
  function deleteFormatter(cell, row, rowIndex, extraData) {
    return (
      <span className="text-danger">
        <i
          className="bx bxs-trash font-size-15"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            let arr = extraData.filter((item) => item.batch !== row.batch);

            setProductsForDelivery(arr);
          }}
        ></i>
      </span>
    );
  }
  const columns_products = [
    {
      text: props.t("Product"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Quantity"),
      dataField: "delivered_quantity",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      formatter: deleteFormatter,
      formatExtraData: productsForDelivery,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
  ];

  const columns_delivered_products = [
    {
      text: props.t("Section, Category, Product"),
      dataField: "section",
      formatter: (cell, row) => {
        return row.section + ", " + row.category + ", " + row.name;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },

    {
      text: props.t("Dispatch Time"),
      dataField: "date_of_transaction",
      formatter: (cell, row) => {
        return moment(row.date_of_transaction).format("DD/MM/YYYY HH:mm");
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      text: props.t("Del. Qty."),
      dataField: "delivered_quantity",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      text: props.t("Rec. Qty."),
      dataField: "received_quantity",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      text: props.t("Received by Branch?"),
      dataField: "is_received",
      formatter: (cell, row) => {
        return row.is_received ? "Yes" : "No";
      },
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const handleDispatchOrder = async () => {
    if (productsForDelivery.length > 0) {
      let productsForDelivery_arr = JSON.parse(
        JSON.stringify(productsForDelivery)
      );
      let productsForDelivery_arr_final = productsForDelivery_arr.map(
        (prod) => {
          let obj = {
            name: prod.name,
            section: prod.section,
            category: prod.category,
            product_id: prod.product_id,
            delivered_quantity: prod.quantity,
          };
          return obj;
        }
      );
      // setProductsForDelivery(new_arr);
      let data_to_send = {};
      data_to_send.productorder_id = selectedOrder._id;
      data_to_send.branch_id = selectedOrder.branch_details._id;
      data_to_send.delivery_details = productsForDelivery_arr_final;
      data_to_send.isPartialDelivery = isPartialDelivery;
      let url = process.env.REACT_APP_BASEURL + "productorders/dispatch";

      const response = await postSubmitForm(url, data_to_send);
      if (response && response.status === 1) {
        loadAllOrders();
        showNotification(response.message, "Success");
        setOrderDispatchModal(!orderDispatchModal);
      } else {
        showNotification(response.message, "Error");
      }
    } else {
      showNotification("Add at least one product.", "Error");
    }
  };
  //#endregion
  const [selectedProductOrderQty, setSelectedProductOrderQty] = useState();
  const [isPartialDelivery, setIsPartialDelivery] = useState(false);
  return (
    <Container>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle>{props.t("Partial Delivery")} </CardTitle>

              {/* <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row> */}
              <ToolkitProvider
                keyField="_id"
                data={allOrders && allOrders}
                columns={columns_external}
                noDataIndication={props.t("No data to display.")}
                bootstrap4
                search
              >
                {(props_table) => (
                  <div>
                    <AvForm>
                      <Row>
                        <Col lg={4}>
                          <Label>{props.t("Search")}</Label>
                          <SearchBar
                            {...props_table.searchProps}
                            style={{ width: "280px" }}
                          />
                        </Col>
                        <Col lg={4}>
                          <AvField
                            name="branch"
                            label={props.t("Branch")}
                            type="select"
                            value={selectedBranch}
                            onChange={(e) => {
                              setSelectedBranch(e.target.value);
                            }}
                          >
                            <option value="All">{props.t("All")}</option>
                            {allBranches &&
                              allBranches.map((branch) => {
                                return (
                                  <option value={branch._id}>
                                    {branch.name}
                                  </option>
                                );
                              })}
                          </AvField>
                        </Col>
                        <Col lg={4}>
                          <AvField
                            name="section"
                            label={props.t("Section")}
                            type="select"
                            value={selectedSection}
                            onChange={(e) => {
                              setSelectedSection(e.target.value);
                            }}
                          >
                            <option value="All">{props.t("All")}</option>
                            {allSections &&
                              allSections.map((section) => {
                                return (
                                  <option value={section.section}>
                                    {section.section}
                                  </option>
                                );
                              })}
                          </AvField>
                        </Col>
                      </Row>
                    </AvForm>
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      expandRow={expandRow}
                      {...props_table.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
              <Modal
                size="lg"
                isOpen={orderDispatchModal}
                toggle={() => setOrderDispatchModal(!orderDispatchModal)}
              >
                <ModalHeader
                  toggle={() => setOrderDispatchModal(!orderDispatchModal)}
                >
                  {props.t("Dispatch Order")}
                </ModalHeader>
                <ModalBody>
                  <>
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody>
                            <Row>
                              <Link
                                to="#"
                                onClick={() => {
                                  printDiv("printOrdered");
                                }}
                                className="btn btn-sm btn-success mr-2"
                              >
                                <i className="fa fa-print"></i> Print
                              </Link>
                              <div id="printOrdered">
                                <Col lg={12} md={12}>
                                  <Label>Ordered Products</Label>
                                </Col>

                                <Col lg={12}>
                                  <BootstrapTable
                                    bootstrap4
                                    keyField="name"
                                    data={
                                      selectedOrder &&
                                      selectedOrder.product_details
                                    }
                                    columns={columns}
                                    noDataIndication="No data to display."
                                    striped
                                    hover
                                    condensed
                                  />
                                  <hr />
                                </Col>
                              </div>
                              {/* {selectedOrder &&
                                  selectedOrder.status === "Approved" ? (
                                    <Col lg={12}>
                                      <div class="form-check">
                                        <input
                                          type="checkbox"
                                          name="isPartialDelivery"
                                          id="isPartialDelivery"
                                          className="is-untouched is-pristine av-valid form-check-input"
                                          checked={isPartialDelivery}
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setIsPartialDelivery(true);
                                              setProductsForDelivery([]);
                                            } else {
                                              handleSetProductsForFullDelivery(
                                                selectedOrder.product_details
                                              );
                                              setIsPartialDelivery(false);
                                            }
                                          }}
                                        />
                                        <label for="isPartialDelivery">
                                          {" "}
                                          {props.t("Partial Delivery")}
                                        </label>
                                      </div>

                                      <br />
                                    </Col>
                                  ) : null} */}
                              {selectedOrder &&
                              selectedOrder.delivery_details &&
                              selectedOrder.delivery_details.length > 0 ? (
                                <>
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      printDiv("printDispatched");
                                    }}
                                    className="btn btn-sm btn-success mr-2"
                                  >
                                    <i className="fa fa-print"></i> Print
                                  </Link>
                                  <div id="printDispatched">
                                    <Col lg={12} md={12}>
                                      <Label>
                                        <b>{props.t("Dispatched Products")} </b>
                                      </Label>
                                    </Col>
                                    <Col lg={12} className="mb-4">
                                      <BootstrapTable
                                        bootstrap4
                                        keyField="_id"
                                        data={selectedOrder.delivery_details}
                                        columns={columns_delivered_products}
                                        noDataIndication="No data to display."
                                        striped
                                        hover
                                        condensed
                                      />
                                      <hr />
                                    </Col>
                                  </div>
                                </>
                              ) : null}

                              {/* {isPartialDelivery && (
                                    <Col lg={12}>
                                      <AvForm
                                        onValidSubmit={
                                          handleAddProductForDelivery
                                        }
                                      >
                                        <Row>
                                          <Col lg={3}>
                                            <Label>
                                              {props.t("Add Product")}
                                            </Label>
                                            <Select
                                              value={
                                                selectedProduct &&
                                                selectedProduct
                                              }
                                              key={selectedProduct}
                                              placeholder={props.t("Select...")}
                                              onChange={(e) => {
                                                setSelectedProduct(e);
                                                loadBatches(e);
                                                setSelectedBatch();
                                                setSelectedProductOrderQty(
                                                  selectedOrder.product_details.filter(
                                                    (prod) =>
                                                      prod.product_id === e._id
                                                  )[0].quantity
                                                );
                                              }}
                                              options={allProducts}
                                              classNamePrefix="select2-selection"
                                              required
                                            />
                                          </Col>
                                          {selectedProduct ? (
                                            selectedProduct.available > 0 ? (
                                              <>
                                                <Col lg={3}>
                                                  <Label>
                                                    {props.t("Batch")}
                                                  </Label>
                                                  <Select
                                                    value={
                                                      selectedBatch &&
                                                      selectedBatch
                                                    }
                                                    key={selectedBatch}
                                                    onChange={(e) => {
                                                      setSelectedBatch(e);
                                                    }}
                                                    options={
                                                      productBatches &&
                                                      productBatches
                                                    }
                                                    classNamePrefix="select2-selection"
                                                    required
                                                  />
                                                </Col>

                                                <Col lg={3}>
                                                  <Label>
                                                    {props.t("Quantity")}
                                                  </Label>

                                                  <AvField
                                                    name="quantity"
                                                    placeholder={props.t(
                                                      "Enter Quantity"
                                                    )}
                                                    type="text"
                                                    onChange={(e) => {
                                                      setQuantity(
                                                        e.target.value
                                                      );
                                                    }}
                                                    validate={{
                                                      required: {
                                                        value: true,
                                                        errorMessage: props.t(
                                                          "Quantity cannot be empty."
                                                        ),
                                                      },
                                                      pattern: {
                                                        value: "^[0-9]+$",
                                                        errorMessage: props.t(
                                                          "Only numbers are allowed."
                                                        ),
                                                      },
                                                      max: {
                                                        value:
                                                          selectedProductOrderQty,
                                                        errorMessage: props.t(
                                                          `Cannot enter more than ${selectedProductOrderQty}.`
                                                        ),
                                                      },
                                                    }}
                                                  />
                                                </Col>
                                                <Col lg={2}>
                                                  {" "}
                                                  <Label>&nbsp;</Label>
                                                  <br />
                                                  <button
                                                    type="submit"
                                                    className="btn btn-info waves-effect waves-light btn-sm"
                                                  >
                                                    <i class="bx bx-add-to-queue"></i>{" "}
                                                    Add
                                                  </button>
                                                </Col>
                                              </>
                                            ) : (
                                              <Col lg={6}>
                                                <p class="text-muted mb-2">
                                                  &nbsp;
                                                </p>
                                                <div
                                                  class="alert alert-danger fade show text-center"
                                                  role="alert"
                                                >
                                                  {props.t(
                                                    "This product is out of stock."
                                                  )}
                                                </div>
                                              </Col>
                                            )
                                          ) : null}
                                        </Row>
                                      </AvForm>
                                    </Col>
                                  )} */}

                              {isShowDispatch &&
                              productsForDelivery &&
                              productsForDelivery.length > 0 ? (
                                <>
                                  <Col lg={12} md={12}>
                                    <br />
                                    <Label>
                                      <b>{props.t("Products to Deliver")}</b>
                                    </Label>
                                  </Col>
                                  <Col lg={12}>
                                    <BootstrapTable
                                      bootstrap4
                                      keyField="name"
                                      key="name"
                                      data={
                                        productsForDelivery &&
                                        productsForDelivery
                                      }
                                      cellEdit={cellEditFactory({
                                        mode: "click",
                                        blurToSave: true,
                                        afterSaveCell: async (
                                          oldValue,
                                          newValue,
                                          row,
                                          column
                                        ) => {
                                          if (column.dataField === "quantity") {
                                            if (
                                              +newValue < 0 ||
                                              (isNaN(parseFloat(newValue)) &&
                                                !isFinite(newValue))
                                            ) {
                                              let arr = [
                                                ...productsForDelivery,
                                              ];
                                              for (
                                                let i = 0;
                                                i < arr.length;
                                                i++
                                              ) {
                                                if (arr[i]._id === row._id) {
                                                  arr[i].quantity = oldValue;
                                                  break;
                                                }
                                              }
                                              setProductsForDelivery(arr);
                                              showNotification(
                                                "Only positive numbers allowed.",
                                                "Error"
                                              );
                                            } else {
                                              if (
                                                +newValue >
                                                +row.quantity_to_compare
                                              ) {
                                                let arr = [
                                                  ...productsForDelivery,
                                                ];
                                                for (
                                                  let i = 0;
                                                  i < arr.length;
                                                  i++
                                                ) {
                                                  if (arr[i]._id === row._id) {
                                                    arr[i].quantity = oldValue;
                                                    break;
                                                  }
                                                }
                                                setProductsForDelivery(arr);
                                                showNotification(
                                                  "Cannot enter more than " +
                                                    row.quantity_to_compare +
                                                    ".",
                                                  "Error"
                                                );
                                              } else if (
                                                +newValue <
                                                +row.quantity_to_compare
                                              ) {
                                                setIsPartialDelivery(true);
                                              }
                                            }
                                          }
                                        },
                                      })}
                                      columns={columns_products_to_dispatch}
                                      noDataIndication="No data to display."
                                      striped
                                      hover
                                      condensed
                                    />
                                  </Col>
                                </>
                              ) : null}
                              {isShowDispatch && (
                                <Col lg={12}>
                                  <hr />
                                  <FormGroup className="mb-0 text-center">
                                    <div>
                                      <Button
                                        onClick={handleDispatchOrder}
                                        color="primary"
                                        className="mr-1"
                                        disabled={!centralAvailableStatus}
                                      >
                                        {centralAvailableStatus &&
                                        centralAvailableStatus
                                          ? "Submit"
                                          : "Not enough stock at center"}
                                      </Button>{" "}
                                    </div>
                                  </FormGroup>
                                </Col>
                              )}
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </>
                </ModalBody>
              </Modal>
              <Modal
                size="md"
                isOpen={remarksModal}
                toggle={() => setRemarksModal(!remarksModal)}
              >
                <ModalHeader toggle={() => setRemarksModal(!remarksModal)}>
                  Order ID: {selectedOrder && selectedOrder.order_id}
                </ModalHeader>
                <ModalBody>
                  <>
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody>
                            <AvForm
                              onValidSubmit={(e, v) => setConfirmClose(true)}
                            >
                              <Row>
                                <Col lg={12}>
                                  <AvField
                                    name="admin_remarks"
                                    placeholder="Enter remarks"
                                    type="textarea"
                                    onChange={(e) =>
                                      setAdminRemarks(e.target.value)
                                    }
                                    value={adminRemarks}
                                    label="Remarks"
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage:
                                          "Remarks cannot be empty.",
                                      },
                                    }}
                                  />
                                </Col>

                                <Col lg={12}>
                                  <hr />
                                  <FormGroup className="mb-0 text-center">
                                    <div>
                                      <Button
                                        type="submit"
                                        color="primary"
                                        className="mr-1"
                                      >
                                        {props.t("Submit")}
                                      </Button>{" "}
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </AvForm>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </>
                </ModalBody>
              </Modal>

              {confirmClose && (
                <SweetAlert
                  title={props.t("Are you sure?")}
                  warning
                  showCancel
                  cancelBtnText={props.t("No")}
                  confirmBtnText={props.t("Yes")}
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    setConfirmClose(false);
                    handleCloseOrder();
                  }}
                  onCancel={() => {
                    setConfirmClose(false);
                  }}
                >
                  {props.t("Once you close this order, it cannot be revoked.")}
                </SweetAlert>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(
  connect(
    null,
    {}
  )(withNamespaces()(FinishedProductOrdersInProcessForDashboard))
);
