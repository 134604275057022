import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import { AvForm, AvField } from "availity-reactstrap-validation";
import logo from "../../../assets/images/karak_logo_new.png";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
  Table,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

import { postSubmitForm } from "../../../helpers/forms_helper";
import moment from "moment";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const UserBasedTransactionReport = (props) => {
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const { SearchBar } = Search;
  const [monthYear, setMonthYear] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();

  const [selectedRow, setSelectedRow] = useState([]);
  const [allBranches, setAllBranches] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    // setDateFrom(formatDate(firstDay));
    // setDateTo(formatDate(currentDay));
    setDateFrom(formatDate(firstDay));
    setDateTo(formatDate(currentDay));
    loadOrders();
  }, []);

  const [allOrders, setAllOrders] = useState([]);
  const loadOrders = async () => {
    preloader(true);

    let url = process.env.REACT_APP_BASEURL + "reports/users_for_transactions";
    const response = await postSubmitForm(url, {});
    if (response && response.status === 1) {
      preloader(false);
      setGrandTotal(response.data.grand_total_cost);
      setAllOrders(response.data);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };

  const [purchaseDetails, setPurchaseDetails] = useState([]);
  const [directPurchaseDetails, setDirectPurchaseDetails] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [itemDetails, setItemDetails] = useState([]);

  const loadPurchaseDetails = async (po_id) => {
    preloader(true);

    // let url =
    //   process.env.REACT_APP_BASEURL + "reports/branchwise_costing_summary_po";
    // const response = await postSubmitForm(url, {
    //   branch_id: selectedRow.branch_details._id,
    //   monthYear: monthYear,
    //   item_id: item_id,
    // });
    // if (response && response.status === 1) {
    //   preloader(false);
    //   console.log(response.data);
    //   setPurchaseDetails(response.data);
    //   setPurchaseDetailsModal(!purchaseDetailsModal);
    // } else {
    //   preloader(false);
    //   showNotification(response.message, "Error");
    // }
  };

  const loadDirectPurchaseDetails = async (item_id) => {
    preloader(true);

    let url =
      process.env.REACT_APP_BASEURL +
      "reports/branchwise_costing_summary_direct_po";
    const response = await postSubmitForm(url, {
      branch_id: selectedRow._id,
      monthYear: monthYear,
      item_id: item_id,
    });
    if (response && response.status === 1) {
      preloader(false);
      console.log(response.data);
      setDirectPurchaseDetails(response.data);
      setDirectPurchaseDetailsModal(!directPurchaseDetailsModal);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };

  const loadItemDetails = async (item_id) => {
    preloader(true);

    let url =
      process.env.REACT_APP_BASEURL +
      "reports/branchwise_costing_summary_item_orders";
    const response = await postSubmitForm(url, {
      branch_id: selectedRow._id,
      monthYear: monthYear,
      item_id: item_id,
    });
    if (response && response.status === 1) {
      preloader(false);
      console.log(response.data);
      setItemDetails(response.data);
      setItemDetailsModal(!itemDetailsModal);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };

  const loadProductOrders = async (adminuser_id) => {
    const days_diff = moment(dateTo).diff(dateFrom, "days");
    if (days_diff > 30) {
      setProductOrders([]);
      showNotification("Total number of days cannot be more than 31.", "Error");
      return false;
    } else if (days_diff < 0) {
      setProductOrders([]);
      showNotification("Invalid date range.", "Error");
      return false;
    }
    console.log(days_diff);
    preloader(true);
    let date_from = moment(dateFrom).format("YYYY-MM-DD"),
      date_to = moment(dateTo).format("YYYY-MM-DD");
    console.log(date_from, date_to, adminuser_id);
    let url =
      process.env.REACT_APP_BASEURL + "reports/user_product_transactions";
    const response = await postSubmitForm(url, {
      date_from: date_from,
      date_to: date_to,
      adminuser_id: adminuser_id,
    });
    if (response && response.status === 1) {
      preloader(false);
      console.log(response);
      setProductOrders(response.data);
      setProductModal(!productModal);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };

  const loadItemOrders = async (adminuser_id) => {
    const days_diff = moment(dateTo).diff(dateFrom, "days");
    if (days_diff > 30) {
      setItemOrders([]);
      showNotification("Total number of days cannot be more than 31.", "Error");
      return false;
    } else if (days_diff < 0) {
      setItemOrders([]);
      showNotification("Invalid date range.", "Error");
      return false;
    }
    console.log(days_diff);
    preloader(true);
    let date_from = moment(dateFrom).format("YYYY-MM-DD"),
      date_to = moment(dateTo).format("YYYY-MM-DD");
    console.log(date_from, date_to);

    let url = process.env.REACT_APP_BASEURL + "reports/user_item_transactions";
    const response = await postSubmitForm(url, {
      date_from: date_from,
      date_to: date_to,
      adminuser_id: adminuser_id,
    });
    if (response && response.status === 1) {
      preloader(false);
      console.log(response);
      setItemOrders(response.data);
      setItemModal(!itemModal);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };

  const loadPurchaseOrders = async (adminuser_id) => {
    const days_diff = moment(dateTo).diff(dateFrom, "days");
    if (days_diff > 30) {
      setPurchaseOrders([]);
      showNotification("Total number of days cannot be more than 31.", "Error");
      return false;
    } else if (days_diff < 0) {
      setPurchaseOrders([]);
      showNotification("Invalid date range.", "Error");
      return false;
    }
    console.log(days_diff);
    preloader(true);
    let date_from = moment(dateFrom).format("YYYY-MM-DD"),
      date_to = moment(dateTo).format("YYYY-MM-DD");
    console.log(date_from, date_to);

    let url =
      process.env.REACT_APP_BASEURL + "reports/user_purchaseorder_transactions";
    const response = await postSubmitForm(url, {
      monthYear: monthYear,
      adminuser_id: adminuser_id,
    });
    if (response && response.status === 1) {
      preloader(false);
      console.log(response);
      setPurchaseOrders(response.data);
      setPurchaseModal(!itemModal);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };

  const [orderStatus, setOrderStatus] = useState("All");
  const [filteredOrders, setFilteredOrders] = useState([]);
  useEffect(() => {
    if (allOrders.length > 0) {
      if (orderStatus === "All") {
        setFilteredOrders([...allOrders]);
      } else {
        let arr = allOrders.filter((order) => order.status === orderStatus);
        setFilteredOrders(arr);
      }
    }
  }, [allOrders, orderStatus]);

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const [productOrders, setProductOrders] = useState([]);
  const [itemOrders, setItemOrders] = useState([]);
  const [purchaseOrders, setPurchaseOrders] = useState([]);

  const [purchaseModal, setPurchaseModal] = useState(false);
  const [productModal, setProductModal] = useState(false);
  const [itemModal, setItemModal] = useState(false);

  const [purchaseDetailsModal, setPurchaseDetailsModal] = useState(false);
  const [directPurchaseDetailsModal, setDirectPurchaseDetailsModal] =
    useState(false);
  const [productDetailsModal, setProductDetailsModal] = useState(false);
  const [itemDetailsModal, setItemDetailsModal] = useState(false);

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Userame"),
      dataField: "username",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Role"),
      dataField: "role",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Finished Products"),
      dataField: "role",

      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <span
            style={{ cursor: "pointer" }}
            title="Click for details"
            onClick={() => {
              setSelectedRow(row);
              loadProductOrders(row._id);
            }}
          >
            <i class="bx bxs-folder-plus"></i> Show
          </span>
        );
      },
    },
    {
      text: props.t("Items"),
      dataField: "role",

      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <span
            style={{ cursor: "pointer" }}
            title="Click for details"
            onClick={() => {
              setSelectedRow(row);
              loadItemOrders(row._id);
            }}
          >
            <i class="bx bxs-folder-plus"></i> Show
          </span>
        );
      },
    },
    {
      text: props.t("Purchase Orders"),
      dataField: "role",

      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <span
            style={{ cursor: "pointer" }}
            title="Click for details"
            onClick={() => {
              setSelectedRow(row);
              loadPurchaseOrders(row._id);
            }}
          >
            <i class="bx bxs-folder-plus"></i> Show
          </span>
        );
      },
    },
  ];

  const columnsPurchase = [
    {
      dataField: "po_id",
      hidden: true,
    },
    {
      dataField: "po_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },

    {
      text: props.t("PO"),
      dataField: "po_id",
      formatter: (cell, row, rowIndex) => {
        return (
          <span
            className="text-info mr-2"
            title="View Details"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setPurchaseDetails(row);
              setPurchaseDetailsModal(!purchaseDetailsModal);
            }}
          >
            {row.po_id}
          </span>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Branch Name"),
      sort: true,
      formatter: (cell, row) =>
        row.branch_details ? row.branch_details.name : "",
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Deliver at Branch"),
      sort: true,
      formatter: (cell, row) => (row.deliver_at_branch ? "Yes" : ""),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Supplier Name"),
      dataField: "supplier.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Supplier Mobile"),
      dataField: "supplier.mobile",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      text: props.t("Date"),
      dataField: "createdAt",
      formatter: (cell, row, rowIndex) => {
        return moment(row.createdAt).format("DD/MM/YYYY");
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Status"),
      dataField: "status",
      formatter: (cell, row, rowIndex) => {
        return row.status;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
  ];

  const columnsPurchaseDetails = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Sub-Category"),
      dataField: "sub_category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },

    {
      dataField: "unit",
      text: props.t("Received Quantity"),
      formatter: (cell, row) => {
        return <>{row.quantity.in_unit + " " + row.quantity.unit}</>;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "price",
      text: props.t("Price (QR)"),

      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "total_price",
      text: props.t("Cost (QR)"),

      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];

  const columnsProduct = [
    {
      dataField: "order_id",
      hidden: true,
    },
    {
      dataField: "order_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },

    {
      text: props.t("Order"),
      dataField: "order_id",
      formatter: (cell, row, rowIndex) => {
        return (
          <span
            className="text-info mr-2"
            title="View Details"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setProductDetails(row);
              setProductDetailsModal(!productDetailsModal);
            }}
          >
            {row.order_id}
          </span>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Branch"),
      dataField: "branch_details.name",

      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Branch Remarks"),
      dataField: "remarks",

      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Date"),
      dataField: "date_to_process",
      formatter: (cell, row, rowIndex) => {
        return moment(row.date_to_process).format("DD/MM/YYYY");
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Status"),
      dataField: "status",
      formatter: (cell, row, rowIndex) => {
        return row.status;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
  ];

  const columnsProductDetails = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("Product"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Section"),
      dataField: "section",
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },

    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },

    {
      dataField: "unit",
      text: props.t("Quantity"),
      formatter: (cell, row) => {
        return row.quantity;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];

  const columnsItem = [
    {
      dataField: "order_id",
      hidden: true,
    },
    {
      dataField: "order_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },

    {
      text: props.t("Order"),
      dataField: "order_id",
      formatter: (cell, row, rowIndex) => {
        return (
          <span
            className="text-info mr-2"
            title="View Details"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setItemDetails(row);
              setItemDetailsModal(!itemDetailsModal);
            }}
          >
            {row.order_id}
          </span>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Branch"),
      dataField: "branch_details.name",

      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Branch Remarks"),
      dataField: "remarks",

      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Date"),
      dataField: "date_to_process",
      formatter: (cell, row, rowIndex) => {
        return moment(row.date_to_process).format("DD/MM/YYYY");
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Status"),
      dataField: "status",
      formatter: (cell, row, rowIndex) => {
        return row.status;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
  ];

  const columnsItemDetails = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Sub-Category"),
      dataField: "sub_category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },

    {
      dataField: "unit",
      text: props.t("Quantity"),
      formatter: (cell, row) => {
        return row.quantity.in_unit + " " + row.unit;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];

  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      setExpanded([...expanded, row._id]);
    } else {
      setExpanded(expanded.filter((x) => x !== row._id));
    }
  };
  const [expanded, setExpanded] = useState([]);

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.item_details}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
    expanded: expanded,
    onExpand: handleOnExpand,
  };

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.status === "Completed") {
      style.backgroundColor = "#b0ff9e";
    } else if (row.status === "Booked") {
      style.backgroundColor = "#9ec3ff";
    } else if (row.status === "In Process") {
      style.backgroundColor = "#fffd9e";
    } else if (row.status === "Cancelled") {
      style.backgroundColor = "#ff9e9e";
    }

    return style;
  };

  const handleDownload = async () => {
    const fileName = "user_based_transaction_report_" + monthYear;
    const exportType = "xls";

    let data_to_export = filteredOrders;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Name: v.name,
          PurchaseCost: v.purchase_cost,
          ProductCost: v.product_cost,
          DirectCost: v.direct_purchase_cost,
          ItemCost: v.item_cost,
          TotalCost: v.total_cost,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };

  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb
            alternateTitle={props.t("User Based Transaction Report")}
            title={props.t("Reports")}
            breadcrumbItem={props.t("User Based Transaction Report")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={4}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={4}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={4}></Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"   "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv0");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <div id="printDiv0">
                    <CardTitle className="text-center">
                      {props.t("User Based Transaction Report")}
                    </CardTitle>
                    <ToolkitProvider
                      keyField="_id"
                      data={filteredOrders && filteredOrders}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      bootstrap4
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                                placeholder="  "
                              />
                            </Col>
                            <Col lg={4}></Col>
                            <Col lg={4}></Col>
                          </Row>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            rowStyle={rowStyle}
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* Purchase breakup modal       */}
          <Modal
            size="lg"
            isOpen={purchaseModal}
            toggle={() => setPurchaseModal(!purchaseModal)}
          >
            <ModalHeader toggle={() => setPurchaseModal(!purchaseModal)}>
              {" "}
              <Link
                to="#"
                onClick={() => {
                  printDiv("printDiv");
                }}
                className="btn btn-sm btn-success mr-2"
              >
                <i className="fa fa-print"></i> Print
              </Link>
            </ModalHeader>
            <ModalBody>
              <Row id="printDiv">
                <Col lg={12}>
                  <Row>
                    <Col lg={4}>
                      <img
                        src={logo}
                        alt=""
                        height="50"
                        style={{ borderRadius: "5px" }}
                      />
                    </Col>
                    <Col lg={4}>
                      <CardTitle className="text-center">
                        {props.t("User Based Transaction Report")}
                      </CardTitle>
                    </Col>
                    <Col lg={4}></Col>
                  </Row>
                  <CardTitle className="text-center">
                    {" "}
                    {selectedRow.name}
                  </CardTitle>
                  <CardTitle className="text-center">
                    {props.t("(Purchase Orders)")}
                  </CardTitle>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          <BootstrapTable
                            keyField="_id"
                            data={purchaseOrders && purchaseOrders}
                            columns={columnsPurchase}
                            striped
                            hover
                            condensed
                            rowStyle={rowStyle}
                            {...props.baseProps}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          {/* Purchase breakup details modal       */}
          <Modal
            size="lg"
            isOpen={purchaseDetailsModal}
            toggle={() => setPurchaseDetailsModal(!purchaseDetailsModal)}
          >
            <ModalHeader
              toggle={() => setPurchaseDetailsModal(!purchaseDetailsModal)}
            >
              {props.t("Purchase Order Details")}
            </ModalHeader>
            <ModalBody>
              <>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col lg={12} md={12}>
                            <Label>Ordered Items</Label>
                          </Col>

                          <Col lg={12}>
                            <BootstrapTable
                              bootstrap4
                              keyField="_id"
                              data={purchaseDetails && purchaseDetails.items}
                              columns={columnsPurchaseDetails}
                              noDataIndication="No data to display."
                              striped
                              hover
                              condensed
                            />
                            <hr />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            </ModalBody>
          </Modal>

          {/* Product breakup modal       */}
          <Modal
            size="lg"
            isOpen={productModal}
            toggle={() => setProductModal(!productModal)}
          >
            <ModalHeader toggle={() => setProductModal(!productModal)}>
              <Link
                to="#"
                onClick={() => {
                  printDiv("printDiv3");
                }}
                className="btn btn-sm btn-success mr-2"
              >
                <i className="fa fa-print"></i> Print
              </Link>
            </ModalHeader>
            <ModalBody>
              <Row id="printDiv3">
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          <Row>
                            <Col lg={4}>
                              <img
                                src={logo}
                                alt=""
                                height="50"
                                style={{ borderRadius: "5px" }}
                              />
                            </Col>
                            <Col lg={4}>
                              <CardTitle className="text-center">
                                {props.t("User Based Transaction Report")}
                              </CardTitle>
                            </Col>
                            <Col lg={4}></Col>
                          </Row>
                          <CardTitle className="text-center">
                            {" "}
                            {selectedRow.name}
                          </CardTitle>

                          <CardTitle className="text-center">
                            {" "}
                            {props.t("(Product Orders)")}
                          </CardTitle>

                          <BootstrapTable
                            keyField="_id"
                            data={productOrders && productOrders}
                            columns={columnsProduct}
                            striped
                            hover
                            condensed
                            rowStyle={rowStyle}
                            {...props.baseProps}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          {/*Product breakup details modal       */}
          <Modal
            size="lg"
            isOpen={productDetailsModal}
            toggle={() => setProductDetailsModal(!productDetailsModal)}
          >
            <ModalHeader
              toggle={() => setProductDetailsModal(!productDetailsModal)}
            >
              {" "}
              <Link
                to="#"
                onClick={() => {
                  printDiv("printDivProduct");
                }}
                className="btn btn-sm btn-success mr-2"
              >
                <i className="fa fa-print"></i> Print
              </Link>
            </ModalHeader>
            <ModalBody>
              <Row id="printDivProduct">
                <Col lg={12}>
                  <Row>
                    <Col lg={4}>
                      {" "}
                      <img
                        src={logo}
                        alt=""
                        height="50"
                        style={{ borderRadius: "5px" }}
                      />{" "}
                    </Col>
                    <Col lg={4}>
                      <CardTitle className="text-center">
                        {props.t("User Based Transaction Report")}
                      </CardTitle>{" "}
                    </Col>
                  </Row>{" "}
                  <CardTitle className="text-center">
                    {" "}
                    {props.t("Product Details")}
                  </CardTitle>
                  <CardTitle className="text-center">
                    {props.t("(Product Order Details)")}
                  </CardTitle>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          <BootstrapTable
                            keyField="_id"
                            data={
                              productDetails && productDetails.product_details
                            }
                            columns={columnsProductDetails}
                            striped
                            hover
                            condensed
                            rowStyle={rowStyle}
                            {...props.baseProps}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          {/* Item breakup modal       */}
          <Modal
            size="lg"
            isOpen={itemModal}
            toggle={() => setItemModal(!itemModal)}
          >
            <ModalHeader toggle={() => setItemModal(!itemModal)}>
              <Link
                to="#"
                onClick={() => {
                  printDiv("printDiv4");
                }}
                className="btn btn-sm btn-success mr-2"
              >
                <i className="fa fa-print"></i> Print
              </Link>
            </ModalHeader>
            <ModalBody>
              <Row id="printDiv4">
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          <Row>
                            <Col lg={4}>
                              <img
                                src={logo}
                                alt=""
                                height="50"
                                style={{ borderRadius: "5px" }}
                              />
                            </Col>
                            <Col lg={4}>
                              <CardTitle className="text-center">
                                {props.t("User Based Transaction Report")}
                              </CardTitle>
                            </Col>
                            <Col lg={4}></Col>
                          </Row>
                          <CardTitle className="text-center">
                            {" "}
                            {selectedRow.name}
                          </CardTitle>
                          <CardTitle className="text-center">
                            {" "}
                            {props.t("(Item Orders)")}
                          </CardTitle>

                          <BootstrapTable
                            keyField="_id"
                            data={itemOrders && itemOrders}
                            columns={columnsItem}
                            striped
                            hover
                            condensed
                            rowStyle={rowStyle}
                            {...props.baseProps}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          {/*Item breakup details modal       */}
          <Modal
            size="lg"
            isOpen={itemDetailsModal}
            toggle={() => setItemDetailsModal(!itemDetailsModal)}
          >
            <ModalHeader toggle={() => setItemDetailsModal(!itemDetailsModal)}>
              {" "}
              <Link
                to="#"
                onClick={() => {
                  printDiv("printDivitem");
                }}
                className="btn btn-sm btn-success mr-2"
              >
                <i className="fa fa-print"></i> Print
              </Link>
            </ModalHeader>
            <ModalBody>
              <Row id="printDivitem">
                <Col lg={12}>
                  <Row>
                    <Col lg={4}>
                      {" "}
                      <img
                        src={logo}
                        alt=""
                        height="50"
                        style={{ borderRadius: "5px" }}
                      />{" "}
                    </Col>
                    <Col lg={4}>
                      <CardTitle className="text-center">
                        {props.t("User Based Transaction Report")}
                      </CardTitle>{" "}
                    </Col>
                  </Row>{" "}
                  <CardTitle className="text-center">
                    {" "}
                    {props.t("Item Details")}
                  </CardTitle>
                  <CardTitle className="text-center">
                    {props.t("(Item Order Details)")}
                  </CardTitle>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          <BootstrapTable
                            keyField="_id"
                            data={itemDetails && itemDetails.item_details}
                            columns={columnsItemDetails}
                            striped
                            hover
                            condensed
                            rowStyle={rowStyle}
                            {...props.baseProps}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(UserBasedTransactionReport))
);
