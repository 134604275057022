import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import Select from "react-select";

import AvRadioGroup from "availity-reactstrap-validation/lib/AvRadioGroup";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Table,
} from "reactstrap";

import { postSubmitForm } from "../../helpers/forms_helper";
import { AvForm, AvField, AvRadio } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

// actions
import { loadBuyers, loadAddUser } from "../../store/actions";
import swal from "sweetalert2";
const AddOrder = (props) => {
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  useEffect(() => {}, []);

  // ---------------- Load Functions Begin -------------------------
  const [availableCarsForSale, setAvailableCarsForSale] = useState();
  const loadAvailableCarsForSale = async (criteria) => {
    try {
      let url = process.env.REACT_APP_BASEURL + "products/getbystatus";
      const response = await postSubmitForm(url, criteria);

      if (response && response.status === 1) {
        setAvailableCarsForSale(response.data);
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error, "Error");
    }
  };
  const [salesExecutives, setSalesExecutives] = useState();
  const loadSalesExecutives = async (criteria) => {
    try {
      let url = process.env.REACT_APP_BASEURL + "adminusers/getbyrole";
      const response = await postSubmitForm(url, criteria);

      if (response && response.status === 1) {
        setSalesExecutives(response.data);
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error, "Error");
    }
  };
  // ---------------- Load Functions End -------------------------
  function showNotification(message, type) {
    var title = type;

    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }
  const [selectedItem, setselectedItem] = useState();
  const [isModal, setModal] = useState();
  const [supplier, setSupplier] = useState("");

  const onRowSelect = async (row, isSelected, e) => {
    setselectedItem(row);
    setModal(!isModal);
  };
  const selectRowProp = {
    mode: "radio",
    clickToSelect: true,
    onSelect: onRowSelect,
    columnWidth: "20px",
  };
  const pagination_options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: props.enquiries && props.enquiries.length,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 10, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 2, // the pagination bar size.
    prePage: "Prev", // Previous page button text
    nextPage: "Next", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    paginationShowsTotal: true, // Accept bool or function
    paginationPosition: "bottom", // default is bottom, top and both is all available
    hideSizePerPage: true, //> You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    // withFirstAndLast: false > Hide the going to First and Last page button
  };

  // -------------------------- Add Order Function Begin ---------------------------
  const [finalPrice, setFinalPrice] = useState();
  const [sellingPrice, setSellingPrice] = useState();
  const [discount, setDiscount] = useState("0");
  const [registrationAmount, setRegistrationAmount] = useState(0);
  const [insuranceAmount, setInsuranceAmount] = useState(0);
  const handleSellingPriceChange = async (e, v) => {
    setSellingPrice(v);
    setFinalPrice(+v - +discount + +registrationAmount + +insuranceAmount);
  };
  const handleDiscountChange = async (e, v) => {
    setDiscount(v);
    setFinalPrice(+sellingPrice - +v + +registrationAmount + +insuranceAmount);
  };
  const handleRegistrationChange = async (e, v) => {
    setRegistrationAmount(v);
    setFinalPrice(+sellingPrice - +discount + +v + +insuranceAmount);
  };
  const handleInsuranceChange = async (e, v) => {
    setInsuranceAmount(v);
    setFinalPrice(+sellingPrice - +discount + +registrationAmount + +v);
  };
  const [orderStatus, setOrderStatus] = useState();
  function handleOrderStatusChange(e) {
    setOrderStatus(e.target.value);
  }
  const [registrationStatus, setRegistrationStatus] = useState();
  function handleRegistrationStatusChange(e) {
    if (e.target.value === "true") {
      setRegistrationStatus(true);
    } else {
      setRegistrationStatus(false);
    }
  }
  const [insuranceStatus, setInsuranceStatus] = useState();

  // -------------------------- Buyer Functions Begin --------------------------
  const [selectedBuyer, setSelectedBuyer] = useState("");
  function handleSelectBuyer(e) {
    setSelectedBuyer(e);
  }

  const [unit, setUnit] = useState([]);
  const [items, setItems] = useState("");
  const [select_Cate, setSelectCate] = useState("");
  const [answer, setAnswer] = useState([]);
  const [quantityOfI, setQuantity] = useState(0);

  const optionGroup = [
    {
      label: "Chicken",
      value: "Chicken",
    },
    {
      label: "Beef",
      value: "Beef",
    },
    { label: "Mutton", value: "Mutton" },
    {
      label: "Milk",
      value: "Milk",
    },
    {
      label: "Cheez",
      value: "Cheez",
    },
    { label: "Curd", value: "Curd" },
    {
      label: "Ice-Cream",
      value: "IceCream",
    },
    {
      label: "Chocolate",
      value: "Chocolate",
    },
    { label: "Soft Drink", value: "SoftDrink" },
  ];

  const optionGroup2 = [
    {
      text: "Supplier's name",
      dataField: "supplierN",
    },
    {
      text: "Category",
      dataField: "Category",
    },
    { text: "Items", dataField: "ItemsSecleted" },
    { text: "Unit", dataField: "Units" },
    { text: "Quantity", dataField: "Quantity" },
  ];

  const dataholder = {
    supplierN: supplier,
    Category: select_Cate,
    ItemsSelected: items,
    Units: unit,
    Quantity: quantityOfI,
  };

  const optionGroup_2 = [
    {
      label: "Non-Vegetarian",
      value: "Non-Vegetarian",
    },
    {
      label: "Dairy",
      value: "Dairy",
    },
    { label: "Ready-made", value: "Ready-made" },
  ];

  const role = localStorage.getItem("role");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("New Purchase")}
            breadcrumbItem={props.t("Add New Purchase")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Add New Purchase")} </CardTitle>
                  <br></br>

                  <AvForm onValidSubmit={(e) => setAnswer(e)}>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="supplier"
                          label={props.t("Supplier")}
                          type="select"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Please Select A Supplier")}
                          onChange={(e) => {
                            let selectedIndex = e.target.options.selectedIndex;
                            setSupplier(
                              e.target.options[selectedIndex].getAttribute(
                                "value"
                              )
                            );
                          }}
                        >
                          <option value="">
                            {props.t("--Select Supplier--")}
                          </option>
                          <option
                            value="Supplier_1"
                            label={props.t("Supplier 1")}
                          ></option>
                          <option
                            value="Supplier_2"
                            label={props.t("Supplier 2")}
                          ></option>
                          <option
                            value="Supplier_3"
                            label={props.t("Supplier 3")}
                          ></option>
                        </AvField>
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="category"
                          label={props.t("Select Category")}
                          type="select"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Please Select A Category")}
                          onChange={(e) => {
                            let selectedIndex = e.target.options.selectedIndex;
                            setSelectCate(
                              e.target.options[selectedIndex].getAttribute(
                                "value"
                              )
                            );
                          }}
                        >
                          <option value="">
                            {props.t("--Select Category--")}
                          </option>
                          <option
                            value="Non-Vegetarian"
                            label={props.t("Non-Vegetarian")}
                          ></option>
                          <option
                            value="Dairy"
                            label={props.t("Dairy")}
                          ></option>
                          <option
                            value="ready-made"
                            label={props.t("Ready-Made")}
                          ></option>
                        </AvField>
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="Items"
                          label={props.t("Items")}
                          type="select"
                          required
                          onChange={(e) => {
                            let selectedIndex = e.target.options.selectedIndex;
                            setItems(
                              e.target.options[selectedIndex].getAttribute(
                                "value"
                              )
                            );
                          }}
                          errorMessage={props.t("Please Select Items")}
                        >
                          <option value="">
                            {props.t("-- Select Item --")}
                          </option>
                          <option value="milk" label={props.t("Milk")}></option>
                          <option
                            value="chicken"
                            label={props.t("Chicken")}
                          ></option>
                          <option
                            value="maggi"
                            label={props.t("Maggi")}
                          ></option>
                          <option
                            value="ice-cream"
                            label={props.t("Ice-Cream")}
                          ></option>
                          <option
                            value="drink"
                            label={props.t("Drink")}
                          ></option>
                          <option value="curd" label={props.t("Curd")}></option>
                          <option
                            value="lassi"
                            label={props.t("Lassi")}
                          ></option>
                        </AvField>
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="unit"
                          label={props.t("Unit")}
                          type="select"
                          onChange={(e) => {
                            let selectedIndex = e.target.options.selectedIndex;
                            setUnit(
                              e.target.options[selectedIndex].getAttribute(
                                "value"
                              )
                            );
                          }}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t(
                            "Please Select A measuring unit"
                          )}
                        >
                          {" "}
                          <option value="">
                            {props.t("-- Select Unit --")}
                          </option>
                          <option value="unit">{props.t("Unit")}</option>
                          <option
                            value="kilogram"
                            label={props.t("Kilogram")}
                          ></option>
                          <option value="gram" label={props.t("Gram")}></option>
                        </AvField>
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="quantity"
                          label={props.t("Quantity")}
                          type="number"
                          onChange={(e) => setQuantity(e.target.value)}
                          placehold={props.t("Enter Quantity in Numbers")}
                          required
                          validate={{
                            pattern: {
                              value: "/^[0-9]*$/",
                              errorMessage: props.t("Only Numeric values"),
                            },
                          }}
                          errorMessage={props.t("Please Enter Available Unit")}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={12}>
                        <hr />
                        <Link
                          onClick={(e) => {
                            {
                              setAnswer(dataholder);
                            }
                          }}
                        >
                          <Button type="button">Add</Button>
                        </Link>
                      </Col>
                    </Row>
                    {console.log(answer)}
                    {/* {supplier === "Supplier_1" ? (
                        <Col lg={6}>
                          <FormGroup className="mb-3 select2-container">
                            <label className="control-label">Item</label>
                            <Select
                              value={items}
                              isMulti={true}
                              onChange={(selected) => setItems(selected)}
                              options={optionGroup}
                              classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                        </Col>
                      ) : supplier === "Supplier_2" ? (
                        <Col lg={6}>
                          <FormGroup className="mb-3 select2-container">
                            <label className="control-label">Item</label>
                            <Select
                              value={items}
                              isMulti={true}
                              onChange={(selected) => setItems(selected)}
                              options={optionGroup2}
                              classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                        </Col>
                      ) : supplier === "Supplier_3" ? (
                        <Col lg={6}>
                          <FormGroup className="mb-3 select2-container">
                            <label className="control-label">Select Item</label>
                            <Select
                              value={items}
                              isMulti={true}
                              onChange={(selected) => setItems(selected)}
                              options={optionGroup3}
                              classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                        </Col>
                      ) : null} */}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Shopping Cart")}
            breadcrumbItem={props.t("Items Table")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Col lg={12}>
                    <Label>{props.t("Current Cart")}</Label>
                    <br />
                    {/* {answer &&
                      answer.map((item) => {
                        return <tr>{item}</tr>;
                      })} */}
                    <Table>
                      <thead>
                        <tr>
                          <th>Supplier Name</th>
                          <th>Category</th>
                          <th>Item</th>
                          <th>Unit</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{answer.supplierN}</td>
                          <td>{answer.Category}</td>
                          <td>{answer.ItemsSelected}</td>
                          <td>{answer.Units}</td>
                          <td>{answer.Quantity}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <Row>
                      <Col lg={12}>
                        <hr />
                        <FormGroup className="mb-0 text-center">
                          <div>
                            <Button
                              type="button"
                              color="primary"
                              className="mr-1"
                            >
                              {props.t("Place Order")}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <BootstrapTable data={answer && answer} striped hover>
                      <TableHeaderColumn
                        dataField="supplierN"
                        isKey={true}
                        width="90"
                        tdStyle={{ whiteSpace: "normal" }}
                      >
                        {props.t("Name")}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="Category"
                        width="90"
                        tdStyle={{ whiteSpace: "normal" }}
                      >
                        {props.t("Categories")}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="ItemsSelected"
                        width="90"
                        tdStyle={{ whiteSpace: "normal" }}
                      >
                        {props.t("Items")}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="Units"
                        width="90"
                        tdStyle={{ whiteSpace: "normal" }}
                      >
                        {props.t("Unit")}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="Quantity"
                        width="90"
                        tdStyle={{ whiteSpace: "normal" }}
                      >
                        {props.t("Quantity")}
                      </TableHeaderColumn>
                    </BootstrapTable> */}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { buyers } = state.Buyers;
  const { adduser } = state.AddUser;
  return { buyers, adduser };
};
export default withRouter(
  connect(mapStatetoProps, { loadBuyers, loadAddUser })(
    withNamespaces()(AddOrder)
  )
);
