import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";

import { postSubmitForm } from "../../../helpers/forms_helper";
import moment from "moment";
import { filter } from "lodash";

const SupplierWiseRequisition = (props) => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { SearchBar } = Search;
  useEffect(() => {
    loadAllSuppliers();
  }, []);
  const [selectedItems, setSelectedItems] = useState([]);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState();
  const loadAllSuppliers = async () => {
    let url = process.env.REACT_APP_BASEURL + "suppliers/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllSuppliers(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  useEffect(() => {
    if (selectedSupplier) loadItems();
    else {
      setAllItems([]);
      setFilteredItems([]);
    }
  }, [selectedSupplier]);
  const [allItems, setAllItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const loadItems = async () => {
    let url = process.env.REACT_APP_BASEURL + "items/get_supplierwise_for_po";
    const response = await postSubmitForm(url, {
      supplier_id: selectedSupplier,
    });
    if (response && response.status === 1) {
      let arr = response.data.map((category) => {
        //console.log(items);
        let out_category = category;
        out_category.items = Array.from(category.items, (x) => {
          let y = x;
          y.quantity = 0;
          // y.price = 0;
          y.total_price = 0;
          y.is_selected = false;
          return y;
        });
        return out_category;
      });

      setAllItems(arr);
      setFilteredItems(arr);
      setDisableSubmit(false);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      style: { cursor: "pointer" },
    },
    {
      text: props.t("Category Name"),
      dataField: "_id",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      style: { cursor: "pointer" },
    },
    {
      text: props.t("Supplier"),
      dataField: "unit",
      formatter: (cell, row) => {
        return (
          <AvForm>
            <AvField name="supplier" type="select">
              <option value="">{props.t("--Select Supplier--")}</option>
              {row.suppliers &&
                row.suppliers.map((e) => (
                  <option key={e._id} value={e._id} name={e.name}>
                    {e.name}
                  </option>
                ))}
            </AvField>
          </AvForm>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
  ];
  const columns_items = [
    {
      dataField: "item_id",
      hidden: true,
    },

    {
      dataField: "item_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      editable: false,
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "22%" };
      },
      editable: false,
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      editable: false,
    },
    {
      text: props.t("Sub-Category"),
      dataField: "sub_category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      editable: false,
    },
    {
      text: props.t("Brand"),
      dataField: "brand",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      editable: false,
    },
    {
      text: props.t("Quantity"),
      dataField: "quantity",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: (content, row, rowIndex, columnIndex) => {
        return row.is_selected;
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          };
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          };
        }
        return true;
      },
      attrs: (cell, row, rowIndex, colIndex) => {
        const title_text = row.is_selected
          ? { title: "Click to Edit" }
          : { title: "Select to Edit" };
        return title_text;
      },
      style: (cell, row, rowIndex, colIndex) => {
        const cur_style = row.is_selected
          ? { cursor: "pointer" }
          : { cursor: "not-allowed" };
        return cur_style;
      },
    },
    {
      text: props.t("Price"),
      dataField: "price",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: (content, row, rowIndex, columnIndex) => {
        return row.is_selected;
      },
      attrs: (cell, row, rowIndex, colIndex) => {
        const title_text = row.is_selected
          ? { title: "Click to Edit" }
          : { title: "Select to Edit" };
        return title_text;
      },
      style: (cell, row, rowIndex, colIndex) => {
        const cur_style = row.is_selected
          ? { cursor: "pointer" }
          : { cursor: "not-allowed" };
        return cur_style;
      },
    },
    {
      text: props.t("Total"),
      dataField: "total_price",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: false,
    },
    // {
    //   text: props.t("Available"),
    //   dataField: "unit",
    //   formatter: (cell, row) => {
    //     return (
    //       row.available.in_unit +
    //       " " +
    //       row.unit +
    //       (row.sub_unit
    //         ? " " + row.available.in_sub_unit + " " + row.sub_unit
    //         : "")
    //     );
    //   },
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "12%" };
    //   },
    // },
  ];

  // const handleOnExpand = (row, isExpand, rowIndex, e) => {
  //   console.log(isExpand);
  //   // if (isExpand) {
  //   //   setExpanded([...expanded, row._id]);
  //   // } else {
  //   //   return false;
  //   //   // setExpanded(expanded.filter((x) => x !== row._id));
  //   // }
  // };
  // const [expanded, setExpanded] = useState([]);
  // const expandRow = {
  //   onlyOneExpanding: true,
  //   expanded: expanded,
  //   onExpand: handleOnExpand,
  //   renderer: (row) => (
  //     <div>
  //       <BootstrapTable
  //         bootstrap4
  //         keyField="item_id"
  //         data={row.items}
  //         columns={columns_items}
  //         selectRow={selectRow}
  //         cellEdit={cellEditFactory({
  //           mode: "click",
  //           blurToSave: true,
  //           afterSaveCell: (oldValue, newValue, row, column) => {
  //             // let tota_service_cost = 0;
  //             // selectedAppointmentServices &&
  //             //   selectedAppointmentServices.map(
  //             //     (service) => {
  //             //       tota_service_cost +=
  //             //         +service.cost;
  //             //     }
  //             //   );
  //             // setTotalServiceAmount(
  //             //   tota_service_cost
  //             // );
  //           },
  //         })}
  //         noDataIndication="No data to display."
  //         striped
  //         hover
  //         condensed
  //       />
  //     </div>
  //   ),
  // };
  const handleOnSelectRow = (row, isSelect) => {
    updateItems(row, isSelect);
    //return true;
  };
  const handleOnSelectAllRows = (is_selected, rows) => {
    let arr = JSON.parse(JSON.stringify(allItems));
    let selectedCatIndex = arr.findIndex((cat) => {
      return cat._id === rows[0].category;
    });

    arr[selectedCatIndex].items.map((item) => {
      item.is_selected = is_selected;
    });
    console.log(arr);
    setAllItems(arr);
    setFilteredItems(arr);
  };
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: handleOnSelectRow,
    onSelectAll: handleOnSelectAllRows,
  };
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} - {to} of {size} records.
    </span>
  );

  const pagination_options = {
    paginationSize: 50,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: allItems && allItems.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };
  const handleSubmitRequisition = async () => {
    // console.log(selectedItems);
    // console.log(allItems);
    let arrSelected = []; //JSON.parse(JSON.stringify(selectedItems));
    allItems.map((category) => {
      let new_arr = category.items.filter((item) => item.is_selected);
      let new_arr_concat = arrSelected.concat(new_arr);
      arrSelected = new_arr_concat;
    });

    let notValidFlag = arrSelected.some(
      (item) =>
        item.quantity === "" ||
        item.quantity <= 0 ||
        item.price === "" ||
        item.price <= 0
    );

    if (notValidFlag) {
      showNotification(
        "Please enter quantity and price for all selected items."
      );
    } else {
      try {
        let data = [{ supplier: selectedSupplier, items: arrSelected }];

        let url = process.env.REACT_APP_BASEURL + "purchaseorders/insert";
        let response = await postSubmitForm(url, { items: data });
        if (response.status === 1) {
          setDisableSubmit(true);
          showNotification(response.message, "Success");
          window.open(`/purchase_order?po_id=${response.data[0]}`, "_blank");
        } else {
          showNotification(response.message, "Error");
        }
      } catch (error) {
        showNotification(error.message, "Error");
      }
    }
  };

  const handleSearch = async (e, v) => {
    v = v.toUpperCase();
    let arr = [];
    for (let i = 0; i < allItems.length; i++) {
      const innerarr = allItems[i].items.filter((item) =>
        item.name.toUpperCase().includes(v)
      );
      if (innerarr.length > 0) {
        arr.push({ _id: allItems[i]._id, items: innerarr });
      }
    }
    //console.log(arr);

    setFilteredItems(arr);
  };

  const updateItems = async (row, isSelect) => {
    let arr = JSON.parse(JSON.stringify(allItems));

    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr[i].items.length; j++) {
        if (arr[i].items[j].item_id == row.item_id) {
          arr[i].items[j].is_selected = isSelect;
          arr[i].items[j].price = row.price;
          arr[i].items[j].quantity = row.quantity;
          arr[i].items[j].total_price = row.total_price;
        }
      }
    }
    setAllItems(arr);

    let arrFiltered = JSON.parse(JSON.stringify(filteredItems));

    for (let i = 0; i < arrFiltered.length; i++) {
      for (let j = 0; j < arrFiltered[i].items.length; j++) {
        if (arrFiltered[i].items[j].item_id == row.item_id) {
          arrFiltered[i].items[j].is_selected = isSelect;
          arrFiltered[i].items[j].price = row.price;
          arrFiltered[i].items[j].quantity = row.quantity;
          arrFiltered[i].items[j].total_price = row.total_price;
        }
      }
    }
    setFilteredItems(arrFiltered);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Supplier-wise Purchase Order")}
            title={props.t("Procurement")}
            breadcrumbItem1={props.t("Supplier-wise Purchase Order")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={12}>
                        <AvField
                          name="branch"
                          label={props.t("Select Supplier")}
                          type="select"
                          value={selectedSupplier}
                          errorMessage={props.t("Supplier cannot be empty")}
                          onChange={(e) => {
                            setSelectedSupplier(e.target.value);
                          }}
                        >
                          <option value="">
                            {props.t("--Select Supplier--")}
                          </option>
                          {allSuppliers &&
                            allSuppliers.map((e) => (
                              <option key={e._id} value={e._id} name={e.name}>
                                {e.name}
                              </option>
                            ))}
                        </AvField>
                      </Col>
                    </Row>
                    {/* <Button type="submit">Submit</Button> */}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            {/* <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={12}>
                        <AvField
                          name="search"
                          label="Search"
                          type="text"
                          onChange={handleSearch}
                        ></AvField>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
          {filteredItems && filteredItems.length > 0 && (
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {filteredItems.map((item) => {
                      return (
                        <>
                          <Row>
                            <Col lg={6}>
                              <Label>Category: {item._id}</Label>
                            </Col>
                          </Row>
                          <ToolkitProvider
                            bootstrap4
                            keyField="item_id"
                            data={item.items}
                            columns={columns_items}
                            noDataIndication={props.t("No data to display.")}
                            search
                          >
                            {(props) => (
                              <div>
                                <Row>
                                  <Col lg={4}>
                                    <SearchBar
                                      {...props.searchProps}
                                      style={{ width: "300px" }}
                                    />
                                  </Col>
                                  <Col lg={4}></Col>
                                </Row>
                                <BootstrapTable
                                  striped
                                  hover
                                  condensed
                                  selectRow={selectRow}
                                  cellEdit={cellEditFactory({
                                    mode: "click",
                                    blurToSave: true,
                                    afterSaveCell: (
                                      oldValue,
                                      newValue,
                                      row,
                                      column
                                    ) => {
                                      switch (column.dataField) {
                                        case "quantity":
                                          row.quantity = Number(newValue);
                                          row.total_price =
                                            row.price * Number(newValue);
                                          break;
                                        case "price":
                                          row.price = Number(newValue);
                                          row.total_price =
                                            Number(newValue) * row.quantity;
                                          break;
                                      }

                                      updateItems(row, true);
                                    },
                                  })}
                                  {...props.baseProps}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                          {/* <BootstrapTable
                            bootstrap4
                            keyField="item_id"
                            data={item.items}
                            columns={columns_items}
                            selectRow={selectRow}
                            cellEdit={cellEditFactory({
                              mode: "click",
                              blurToSave: true,
                              afterSaveCell: (
                                oldValue,
                                newValue,
                                row,
                                column
                              ) => {
                                row.total_price = row.price * row.quantity;
                                updateItems(row, true);
                              },
                            })}
                            noDataIndication="No data to display."
                            striped
                            hover
                            condensed
                          /> */}
                          <hr />
                        </>
                      );
                    })}
                    <Row>
                      <Col lg={12}>
                        <FormGroup className="mb-0 text-center">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                              disabled={disableSubmit}
                              onClick={handleSubmitRequisition}
                            >
                              {props.t("Submit")}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                        <hr />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(SupplierWiseRequisition))
);
