import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Label,
  Input,
} from "reactstrap";
import swal from "sweetalert2";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
//Import Breadcrumb
import Breadcrumb_2Items from "../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";

import {
  postSubmitForm,
  postSubmitForm_withformdata,
} from "../../../helpers/forms_helper";
import moment from "moment";

import SweetAlert from "react-bootstrap-sweetalert";

const ViewPO_Owner = (props) => {
  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const [confirmClose, setConfirmClose] = useState(false);
  const [closePO, setClosePO] = useState();
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [allBranches, setAllBranches] = useState([]);

  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  const { SearchBar } = Search;

  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    setDateFrom(formatDate(currentDay));
    setDateTo(formatDate(currentDay));
    loadAllBranches();
 
  }, []);

  const [allPO, setAllPO] = useState([]);
  const loadAllPO = async () => {
    const days_diff = moment(dateTo).diff(dateFrom, "days");
    if (days_diff > 30) {
      setAllPO([]);
      showNotification("Total number of days cannot be more than 31.", "Error");
      return false;
    } else if (days_diff < 0) {
      setAllPO([]);
      showNotification("Invalid date range.", "Error");
      return false;
    }
    console.log(days_diff);
    preloader(true);
    let date_from = dateFrom,
      date_to = moment(dateFrom)
        .add(days_diff / 2, "days")
        .format("YYYY-MM-DD");

    //console.log(date_from, date_to);

    let url =
      process.env.REACT_APP_BASEURL + "purchaseorders/getall_for_approval";
    const response1 = await postSubmitForm(url, {
      date_from: date_from,
      date_to: date_to,
    });
    if (response1 && response1.status === 1) {
      console.log(response1.data);
    } else {
      preloader(false);
      showNotification(response1.message, "Error");
      return false;
    }
    date_from = moment(dateFrom)
      .add(days_diff / 2 + 1, "days")
      .format("YYYY-MM-DD");
    date_to = dateTo;
    console.log(date_from, date_to);
    const response2 = await postSubmitForm(url, {
      date_from: date_from,
      date_to: date_to,
    });
    if (response2 && response2.status === 1) {
      preloader(false);
      console.log(response2.data);
      setAllPO(response2.data.concat(response1.data));
    } else {
      preloader(false);
      showNotification(response2.message, "Error");
      return false;
    }
  };

  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  const sendEmail = async (id) => {
    preloader(true);
    let url = process.env.REACT_APP_BASEURL + "purchaseorders/email_by_id";
    const response = await postSubmitForm(url, { id: id });

    if (response && response.status === 1) {
      preloader(false);
      showNotification("Email sent successfully", "Success");
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const [itemsModal, setItemsModal] = useState();
  const [selectedPO, setSelectedPO] = useState();

  function editFormatter(cell, row) {
    return (
      <>
        <span className=" mr-2">
          <i
            className="mdi mdi-text-box-search font-size-15"
            title="View Details"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedPO(row);
              setItemsModal(!itemsModal);
            }}
          ></i>
        </span>
        <span className="mr-2 text-info">
          <a
            href={"/purchase_order?po_id=" + row._id}
            target="_blank"
            className="font-size-11"
          >
            <i
              className="mdi mdi-printer font-size-15"
              title="Print PO"
              style={{ cursor: "pointer" }}
            ></i>
          </a>
        </span>
        {row.status != "Pending Approval" && (
          <span className=" mr-2">
            <i
              className="bx bx-mail-send font-size-15"
              title="Send Email"
              style={{ cursor: "pointer" }}
              onClick={() => {
                sendEmail(row._id);
              }}
            ></i>
          </span>
        )}
      </>
    );
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("#"),
      dataField: "_id",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      text: props.t("PO ID"),
      dataField: "po_id",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Branch Name"),
      dataField: "branch_details.name",
      sort: true,
      // formatter: (cell, row) =>
      //   row.branch_details ? row.branch_details.name : "CENTRAL STORE",

      filter: selectFilter({
        options: () => {
          const branch_allpo = allPO.filter((item) => item.branch_details);
          const unique = [
            ...new Map(
              [
                ...new Set(
                  branch_allpo.map(({ branch_details: value }) => ({
                    value: value.name,
                    label: value.name,
                  }))
                ),
              ].map((item) => [item["value"], item])
            ).values(),
          ];
          //unique.push({ value: "CENTRAL STORE", label: "CENTRAL STORE" });

          unique.sort(dynamicSort("label"));
          //console.log(unique);
          return unique;
        },
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      text: props.t("Deliver at Branch"),
      sort: true,
      formatter: (cell, row) => (row.deliver_at_branch ? "Yes" : "No"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: props.t("Supplier Name"),
      dataField: "supplier.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      filter: selectFilter({
        options: () => {
          const unique = [
            ...new Map(
              [
                ...new Set(
                  allPO.map(({ supplier: value }) => ({
                    value: value.name,
                    label: value.name,
                  }))
                ),
              ].map((item) => [item["value"], item])
            ).values(),
          ];
          unique.sort(dynamicSort("label"));
          // console.log(unique);
          return unique;
        },
      }),
    },
    {
      text: props.t("Supplier Mobile"),
      dataField: "supplier.mobile",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "item_total_price",
      text: props.t("Amount"),

      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "CreateDate",
      text: props.t("Date"),
      formatter: (cell, row) =>
        moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "status",
      text: props.t("Status"),

      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      filter: selectFilter({
        options: () => {
          const unique = [
            ...new Map(
              [
                ...new Set(
                  allPO.map(({ status: value }) => ({
                    value: value,
                    label: value,
                  }))
                ),
              ].map((item) => [item["value"], item])
            ).values(),
          ];
          unique.sort(dynamicSort("label"));
          // console.log(unique);
          return unique;
        },
      }),
    },
    {
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
  ];

  const columns_items = [
    {
      dataField: "item_id",
      hidden: true,
    },
    {
      dataField: "name",
      text: props.t("Name"),
      headerStyle: (colum, colIndex) => {
        return { width: "30%" };
      },
      editable: false,
      footer: true,
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: false,
      footer: true,
    },
    {
      dataField: "sub_category",
      text: props.t("Sub-Category"),
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: false,
      footer: true,
    },

    {
      dataField: "unit",
      text: props.t("Unit"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
      footer: true,
    },
    {
      dataField: "price",
      text: props.t("Price"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
      footer: true,
    },

    {
      dataField: "quantity.in_unit",
      text: props.t("Quantity"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
      footer: true,
    },
    {
      dataField: "total_price",
      text: props.t("Total"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,

      footer: (columnData) =>
        columnData.reduce((acc, item) => Number((acc + item).toFixed(2)), 0),
    },
  ];

  const columns_delivered_items = [
    {
      dataField: "item_id",
      hidden: true,
    },
    {
      dataField: "name",
      text: props.t("Name"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      editable: false,
    },
    {
      dataField: "sub_category",
      text: props.t("Sub-Category"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "price",
      text: props.t("Price"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "unit",
      text: props.t("Unit"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "receive.in_unit",
      text: props.t("Received"),
      editable: false,

      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "receiving_date",
      text: props.t("Date"),
      editable: false,
      formatter: (cell, row) => {
        return moment(row.receiving_date).format("DD/MM/YYYY HH:mm");
      },

      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "invoice_number",
      text: props.t("Invoice"),
      editable: false,

      formatter: (cell, row) => {
        if (row.invoice_url) {
          return (
            <a href={row.invoice_url}>
              <i
                className="mdi mdi-download font-size-15"
                title="Download Invoice"
              ></i>
            </a>
          );
        } else {
          return row.invoice_number;
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
  ];

  const handleClosePO = async () => {
    let url = process.env.REACT_APP_BASEURL + "purchaseorders/close";
    const response = await postSubmitForm(url, { id: selectedPO._id });
    if (response && response.status === 1) {
      setItemsModal(!itemsModal);
      setClosePO();
      loadAllPO(response.data);
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };

  const [invoiceNumber, setInvoiceNumber] = useState();
  const [invoice, setInvoice] = useState();
  const handleDownload = async () => {
    const fileName = "all_po";
    const exportType = "xls";

    let data_to_export = allPO;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          po_id: v.po_id,
          branch_name: v.branch_details.name,
          category: v.category,
          supplier_name: v.supplier.name,
          supplier_mobile: v.supplier.mobile,
          createdAt: v.createdAt,
          status: v.status,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };

  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }
  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.is_email_sent) {
      style.backgroundColor = "#b0ff9e";
    }
    return style;
  };

  const handleApprovePO = async () => {
    let url = process.env.REACT_APP_BASEURL + "purchaseorders/approve";

    let response = await postSubmitForm(url, {
      purchaseorder_id: selectedPO._id,
    });
    if (response.status === 1) {
      loadAllPO();
      setItemsModal(false);
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };

  const handleRejectPO = async () => {
    let url = process.env.REACT_APP_BASEURL + "purchaseorders/reject";

    let response = await postSubmitForm(url, {
      purchaseorder_id: selectedPO._id,
    });
    if (response.status === 1) {
      loadAllPO();
      setItemsModal(false);
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            title={props.t("Requisition")}
            alternateTitle={props.t("Purchase Orders")}
            breadcrumbItem1={props.t("All Purchase Orders")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg={3}>
                        <AvField
                          name="dateFrom"
                          label={props.t("From Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateFrom}
                          onChange={(e, v) => {
                            setDateFrom(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={3}>
                        <AvField
                          name="dateTo"
                          label={props.t("To Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={dateTo}
                          onChange={(e, v) => {
                            setDateTo(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>

                      <Col lg={3}>
                        <Button
                          type="submit"
                          className="btn btn-md btn-success mt-4"
                          onClick={() => {
                            if (dateFrom && dateTo) {
                              loadAllPO();
                            }
                          }}
                        >
                          <i className="mdi mdi-checkbox-marked-circle"></i>{" "}
                          {props.t("Check")}
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"   "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <div id="printDiv">
                    <ToolkitProvider
                      bootstrap4
                      keyField="_id"
                      data={allPO && allPO}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}>
                              {" "}
                              <CardTitle>All Purchase Orders</CardTitle>
                            </Col>
                            <Col lg={4} className="text-right">
                              {/* <button
                              className="btn-sm btn btn-info"
                              onClick={() => {
                                setAddInventoryModal(!addInventoryModal);
                              }}
                            >
                              Add Item
                            </button> */}
                            </Col>
                          </Row>
                          <BootstrapTable
                            striped
                            filter={filterFactory()}
                            hover
                            condensed
                            rowStyle={rowStyle}
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>

                  <Modal
                    size="lg"
                    isOpen={itemsModal}
                    toggle={() => setItemsModal(!itemsModal)}
                  >
                    <ModalHeader toggle={() => setItemsModal(!itemsModal)}>
                      Purchase Order ID: {selectedPO && selectedPO.po_id}{" "}
                    </ModalHeader>
                    <ModalBody>
                      {selectedPO && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Label>Ordered Items</Label>
                              <ToolkitProvider
                                keyField="_id"
                                data={selectedPO && selectedPO.items}
                                columns={columns_items}
                              >
                                {(props) => (
                                  <div>
                                    <BootstrapTable
                                      {...props.baseProps}
                                      cellEdit={cellEditFactory({
                                        mode: "click",
                                        blurToSave: true,
                                      })}
                                      noDataIndication="No data to display."
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </Col>
                            {selectedPO.deliver_at_branch !== true &&
                              selectedPO.status !== "Delivered" &&
                              selectedPO.status !== "Closed" && (
                                <>
                                  <Col lg={12} className="text-center">
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="btn btn-default waves-effect waves-light"
                                      onClick={handleApprovePO}
                                    >
                                      {props.t("Approve")}
                                    </Button>{" "}
                                    <Button
                                      type="submit"
                                      color="danger"
                                      className="btn btn-default waves-effect waves-light"
                                      onClick={handleRejectPO}
                                    >
                                      {props.t("Reject")}
                                    </Button>{" "}
                                  </Col>
                                </>
                              )}
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(ViewPO_Owner))
);
