import React, { useEffect, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import exportFromJSON from "export-from-json";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import swal from "sweetalert2";

import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb_2Items from "../../../components/Common/Breadcrumb_2Items";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";

import { postSubmitForm } from "../../../helpers/forms_helper";
import moment from "moment";

const ProductClosing = (props) => {
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  useEffect(() => {
    loadProductsForClosing();
  }, []);

  const [closingDate, setClosingDate] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    setClosingDate(formatDate(currentDay));
  }, []);
  useEffect(() => {
    if (closingDate) loadProductsForClosing();
  }, [closingDate]);
  const [allProducts, setAllProducts] = useState([]);
  const [isClosureDone, setIsClosureDone] = useState();
  const loadProductsForClosing = async () => {
    preloader(true);
    if (closingDate) {
      let url =
        process.env.REACT_APP_BASEURL + "branchproducts/get_for_closure";
      let data_to_send = {
        date_of_closure: closingDate,
      };
      const response = await postSubmitForm(url, data_to_send);
      if (response && response.status === 1) {
        preloader(false);
        setAllProducts(response.data);
        setIsClosureDone(response.is_closure_done);
      } else {
        preloader(false);
        showNotification(response.message, "Error");
      }
    }
  };
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }
  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
      csvExport: false,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "4%" };
      },
      csvExport: false,
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      dataField: "section",
      text: props.t("Section"),
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "category",
      text: props.t("Category"),
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
    {
      dataField: "available",
      text: props.t("Balance"),
      sort: true,
      editable: false,
      // attrs: (cell, row, rowIndex, colIndex) => {
      //   return isClosureDone ? {} : { title: "Click to Edit" };
      // },
      // style: (cell, row, rowIndex, colIndex) => {
      //   return isClosureDone
      //     ? {}
      //     : { cursor: "pointer", background: "#ffffaa" };
      // },
      // formatter: (cell, row) => {
      //   return row.available < row.threshold ? (
      //     <span className="font-size-12 badge-soft-danger badge badge-danger badge-pill mr-1 mb-1">
      //       {row.available}
      //     </span>
      //   ) : (
      //     row.available
      //   );
      // },
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
    },

    {
      dataField: "discarded",
      text: props.t("Expired"),
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
    },
    {
      dataField: "used",
      text: props.t("Used"),
      sort: true,
      editable: isClosureDone ? false : true,
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          };
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          };
        }
        return true;
      },
      attrs: (cell, row, rowIndex, colIndex) => {
        return isClosureDone ? {} : { title: "Click to Edit" };
      },
      style: (cell, row, rowIndex, colIndex) => {
        return isClosureDone
          ? {}
          : { cursor: "pointer", background: "#ffffaa" };
      },
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
    },
    {
      dataField: "balance_in_hand",
      text: props.t("Balance (in hand)"),
      sort: true,
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          };
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          };
        }
        return true;
      },
      editable: isClosureDone ? false : true,
      attrs: (cell, row, rowIndex, colIndex) => {
        return isClosureDone ? {} : { title: "Click to Edit" };
      },
      style: (cell, row, rowIndex, colIndex) => {
        return isClosureDone
          ? {}
          : { cursor: "pointer", background: "#ffffaa" };
      },
      // formatter: (cell, row) => {
      //   return row.available < row.threshold ? (
      //     <span className="font-size-12 badge-soft-danger badge badge-danger badge-pill mr-1 mb-1">
      //       {row.available}
      //     </span>
      //   ) : (
      //     row.available
      //   );
      // },
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "waste",
      text: props.t("Waste"),
      sort: true,
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          };
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          };
        }
        return true;
      },
      editable: isClosureDone ? false : true,
      attrs: (cell, row, rowIndex, colIndex) => {
        return isClosureDone ? {} : { title: "Click to Edit" };
      },
      style: (cell, row, rowIndex, colIndex) => {
        return isClosureDone
          ? {}
          : { cursor: "pointer", background: "#ffffaa" };
      },

      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),
      sort: true,
      editable: isClosureDone ? false : true,
      attrs: (cell, row, rowIndex, colIndex) => {
        return isClosureDone ? {} : { title: "Click to Edit" };
      },
      style: (cell, row, rowIndex, colIndex) => {
        return isClosureDone
          ? {}
          : { cursor: "pointer", background: "#ffffaa" };
      },
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} - {to} of {size} records.
    </span>
  );

  const pagination_options = {
    paginationSize: 50,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: allProducts && allProducts.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };
  const handleSubmitClosure = async (e, v) => {
    try {
      let arr_products = JSON.parse(JSON.stringify(allProducts));
      let isBalanceUpdated = arr_products.some(
        (prod) => prod.balance_in_hand !== ""
      );
      let isRemarkNotUpdated = arr_products.some(
        (prod) => +prod.waste > 0 && prod.remarks === ""
      );
      let isNotValidInputs = arr_products.some(
        (prod) =>
          +prod.waste_to_compare +
            +prod.available_to_compare +
            +prod.used_to_compare !==
          +prod.waste + +prod.balance_in_hand + +prod.used
      );

      if (!isBalanceUpdated) {
        showNotification("Enter balance in hand for all products.", "Error");
      } else if (isNotValidInputs) {
        showNotification(
          "Invalid values entered in Used/Balance/Waste.",
          "Error"
        );
      } else if (isRemarkNotUpdated) {
        showNotification("Enter remarks for all wasted products.", "Error");
      } else {
        const object = {
          date_of_closure: closingDate,
          closure_details: allProducts,
        };
        let url =
          process.env.REACT_APP_BASEURL + "branchproductclosures/insert";

        let response = await postSubmitForm(url, object);
        if (response.status === 1) {
          loadProductsForClosing();
          showNotification(response.message, "Success");
        } else {
          showNotification(response.message, "Error");
        }
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };

  const handleDownload = async () => {
    const fileName = "finished_product_closure";
    const exportType = "xls";

    let data_to_export = allProducts;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Name: v.name,
          Section: v.section,
          Category: v.category,
          Balance: v.available,
          Expired: v.discarded,
          Used: v.used,
          Balance_In_Hand: v.balance_in_hand,
          Waste: v.waste,
          Remarks: v.remarks,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb_2Items
            alternateTitle={props.t("Finished Product Closing")}
            title={props.t("Closing")}
            breadcrumbItem1={props.t("Finished Products")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm onValidSubmit={handleSubmitClosure}>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="closing_date"
                          label={props.t("Date")}
                          placeholder={props.t("Select")}
                          type="date"
                          value={closingDate}
                          onChange={(e, v) => {
                            setClosingDate(formatDate(e.target.value));
                          }}
                          errorMessage={props.t("Date cannot be empty.")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        {isClosureDone && isClosureDone ? (
                          <>
                            {" "}
                            <Label>&nbsp;</Label> <br />
                            <p style={{ color: "green" }}>
                              {props.t("Closure done for selected date.")}
                            </p>
                          </>
                        ) : (
                          <>
                            <Label>&nbsp;</Label> <br />
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                            >
                              {props.t("Submit Closure")}
                            </Button>{" "}
                          </>
                        )}
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <Button
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {props.t("Export CSV")}
                      </Button>
                      {"   "}
                      <Link
                        to="#"
                        onClick={() => {
                          printDiv("printDiv");
                        }}
                        className="btn btn-sm btn-success mr-2"
                      >
                        <i className="fa fa-print"></i> Print
                      </Link>
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <div id="printDiv">
                    <CardTitle className="text-center">
                      {props.t("Finished Product Closure")}
                    </CardTitle>
                    <ToolkitProvider
                      bootstrap4
                      keyField="_id"
                      data={allProducts && allProducts}
                      columns={columns}
                      noDataIndication={props.t("No data to display.")}
                      search
                      exportCSV
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <SearchBar
                                {...props.searchProps}
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}></Col>
                            <Col lg={4} className="text-right"></Col>
                          </Row>
                          <BootstrapTable
                            striped
                            cellEdit={cellEditFactory({
                              mode: "click",
                              blurToSave: true,
                              afterSaveCell: async (
                                oldValue,
                                newValue,
                                row,
                                column
                              ) => {
                                if (column.dataField === "balance_in_hand") {
                                  if (
                                    +newValue < 0 ||
                                    (isNaN(parseFloat(newValue)) &&
                                      !isFinite(newValue))
                                  ) {
                                    let arr = [...allProducts];
                                    for (let i = 0; i < arr.length; i++) {
                                      if (arr[i]._id === row._id) {
                                        arr[i].balance_in_hand = oldValue;
                                      }
                                    }
                                    setAllProducts(arr);
                                    showNotification(
                                      "Only positive numbers allowed.",
                                      "Error"
                                    );
                                  } else {
                                    let change_in_value = +oldValue - +newValue; //+row.available - +newValue;
                                    if (+newValue > +row.available) {
                                      let arr = [...allProducts];
                                      for (let i = 0; i < arr.length; i++) {
                                        if (arr[i]._id === row._id) {
                                          arr[i].balance_in_hand = oldValue;
                                        }
                                      }
                                      setAllProducts(arr);
                                      showNotification(
                                        "Cannot enter more than " +
                                          row.available +
                                          ".",
                                        "Error"
                                      );
                                    }
                                    // else if (
                                    //   +newValue + +row.used >
                                    //   +row.available_to_compare +
                                    //     +row.used_to_compare
                                    // ) {
                                    //   let arr = [...allProducts];
                                    //   for (let i = 0; i < arr.length; i++) {
                                    //     if (arr[i]._id === row._id) {
                                    //       arr[i].balance_in_hand = oldValue;
                                    //     }
                                    //   }
                                    //   setAllProducts(arr);
                                    //   showNotification(
                                    //     "Cannot enter more than " +
                                    //       (+row.available_to_compare +
                                    //         +row.used_to_compare -
                                    //         +row.used) +
                                    //       ".",
                                    //     "Error"
                                    //   );
                                    // }
                                    // else {
                                    //   let arr = [...allProducts];
                                    //   for (let i = 0; i < arr.length; i++) {
                                    //     if (arr[i]._id === row._id) {
                                    //       arr[i].waste =
                                    //         +arr[i].waste + +change_in_value;
                                    //     }
                                    //   }
                                    //   setAllProducts(arr);
                                    // }
                                  }
                                } else if (column.dataField === "used") {
                                  if (!(+newValue === +oldValue)) {
                                    //preloader(true);
                                    if (
                                      +newValue < 0 ||
                                      (isNaN(parseFloat(newValue)) &&
                                        !isFinite(newValue))
                                    ) {
                                      let arr = [...allProducts];
                                      for (let i = 0; i < arr.length; i++) {
                                        if (arr[i]._id === row._id) {
                                          arr[i].used = oldValue;
                                        }
                                      }
                                      setAllProducts(arr);

                                      preloader(false);
                                      showNotification(
                                        "Only positive numbers allowed.",
                                        "Error"
                                      );
                                    } else if (
                                      +newValue < +row.used_to_compare
                                    ) {
                                      let arr = [...allProducts];
                                      for (let i = 0; i < arr.length; i++) {
                                        if (arr[i]._id === row._id) {
                                          arr[i].used = oldValue;
                                        }
                                      }
                                      setAllProducts(arr);
                                      preloader(false);
                                      showNotification(
                                        `Cannot enter less than ${+row.used_to_compare}.`,
                                        "Error"
                                      );
                                    }
                                    // else {
                                    //   let change_in_value = +newValue - +oldValue;
                                    //   if (change_in_value > row.balance_in_hand) {
                                    //     let arr = [...allProducts];
                                    //     for (let i = 0; i < arr.length; i++) {
                                    //       if (arr[i]._id === row._id) {
                                    //         arr[i].used = oldValue;
                                    //       }
                                    //     }
                                    //     setAllProducts(arr);
                                    //     preloader(false);
                                    //     showNotification(
                                    //       `Cannot enter more than ${
                                    //         +oldValue + +row.balance_in_hand
                                    //       }.`,
                                    //       "Error"
                                    //     );
                                    //   }
                                    //   else {
                                    //     let arr = [...allProducts];
                                    //     for (let i = 0; i < arr.length; i++) {
                                    //       if (arr[i]._id === row._id) {
                                    //         arr[i].balance_in_hand =
                                    //           +arr[i].balance_in_hand -
                                    //           +change_in_value;
                                    //       }
                                    //     }
                                    //     setAllProducts(arr);
                                    //     // let url =
                                    //     //   process.env.REACT_APP_BASEURL +
                                    //     //   "branchproducts/update_used";

                                    //     // let response = await postSubmitForm(url, {
                                    //     //   product_id: row.product_id,
                                    //     //   difference: change_in_value,
                                    //     // });
                                    //     // if (response.status === 1) {
                                    //     //   preloader(false);

                                    //     //   loadProductsForClosing();
                                    //     // } else {
                                    //     //   preloader(false);
                                    //     //   showNotification(
                                    //     //     response.message,
                                    //     //     "Error"
                                    //     //   );
                                    //     // }
                                    //   }
                                    // }
                                  }
                                } else if (column.dataField === "waste") {
                                  if (
                                    +newValue < 0 ||
                                    (isNaN(parseFloat(newValue)) &&
                                      !isFinite(newValue))
                                  ) {
                                    let arr = [...allProducts];
                                    for (let i = 0; i < arr.length; i++) {
                                      if (arr[i]._id === row._id) {
                                        arr[i].waste = oldValue;
                                      }
                                    }
                                    setAllProducts(arr);
                                    showNotification(
                                      "Only positive numbers allowed.",
                                      "Error"
                                    );
                                  } else {
                                    let change_in_value = +oldValue - +newValue; //+row.available - +newValue;
                                    if (+newValue < +row.waste_to_compare) {
                                      let arr = [...allProducts];
                                      for (let i = 0; i < arr.length; i++) {
                                        if (arr[i]._id === row._id) {
                                          arr[i].waste = oldValue;
                                        }
                                      }
                                      setAllProducts(arr);
                                      showNotification(
                                        "Cannot enter less than " +
                                          oldValue +
                                          ".",
                                        "Error"
                                      );
                                    }
                                  }
                                }
                              },
                            })}
                            hover
                            condensed
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(ProductClosing))
);
