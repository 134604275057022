import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Input,
  CardSubtitle,
  Modal,
  Label,
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  UncontrolledAlert,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
//import Charts
import StackedColumnChart from "./StackedColumnChart";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import NotificationDashboard from "./NotificationDashboard";
import OrderProductSections from "./OrderProductSections";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";
import LatestTranaction from "./LatestTranaction";
import BootstrapTable from "react-bootstrap-table-next";
//import "../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
//Import Breadcrumb
import swal from "sweetalert2";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { postSubmitForm } from "../../helpers/forms_helper";
//i18n
import { withNamespaces } from "react-i18next";
import FinishedProductOrdersInProcessForDashboard from "../../pages/Orders/Centre/FinishedProducts/FinishedProductOrdersInProcessForDashboard";
const Dashboard = (props) => {
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [monthFrom, setMonthFrom] = useState();
  const [monthTo, setMonthTo] = useState();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  useEffect(() => {
    const currentDay = new Date();
    const firstDay = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      1
    );
    // setDateFrom(formatDate(firstDay));
    // setDateTo(formatDate(currentDay));

    setDateFrom(formatDate(firstDay));
    setDateTo(formatDate(currentDay));
  }, []);
  useEffect(() => {
    if (dateFrom && dateTo) {
      loadOwnerData();
    }
  }, [dateFrom, dateTo]);
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);

  const reports = [
    { title: "Total Orders", iconClass: "bx-copy-alt", description: "12,335" },
    {
      title: "Total Sale",
      iconClass: "bx-archive-in",
      description: "QR 353,723",
    },
    {
      title: "Average Order Cost",
      iconClass: "bx-purchase-tag-alt",
      description: "QR 28.67",
    },
  ];
  const email = [
    { title: "Week", linkto: "#", isActive: false },
    { title: "Month", linkto: "#", isActive: false },
    { title: "Year", linkto: "#", isActive: true },
  ];

  useEffect(() => {
    loadNotifications();
    loadAllSections();
    loadProductOrderCounts();
    loadItemOrderCounts();
  }, []);
  const [notifications, setNotifications] = useState([]);
  const loadNotifications = async () => {
    let url = process.env.REACT_APP_BASEURL + "notifications/get_last_ten";
    const response = await postSubmitForm(url, "");
    if (response && response.status === 1) {
      setNotifications(response.data);
    }
  };

  const [itemData, setItemData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [poData, setPoData] = useState([]);
  const loadOwnerData = async () => {
    let url = process.env.REACT_APP_BASEURL + "reports/owner_dashboard";
    let response = await postSubmitForm(
      url,

      {
        date_from: dateFrom,
        date_to: dateTo,
      }
    );
    if (response.status === 1) {
      setItemData(response.data.itemeorder_count);
      setProductData(response.data.productorder_count);
      setPoData(response.data.purchaseorder_count);
    }
  };
  const [allSections, setAllSections] = useState([]);
  const loadAllSections = async () => {
    let url = process.env.REACT_APP_BASEURL + "productsections/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllSections(response.data);
    }
  };

  const [productOrderCounts, setAllProductOrderCounts] = useState();
  const loadProductOrderCounts = async () => {
    let url = process.env.REACT_APP_BASEURL + "productorders/order_counts";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllProductOrderCounts(response.data);
    }
  };

  const [itemOrderCounts, setAllItemOrderCounts] = useState();
  const loadItemOrderCounts = async () => {
    let url = process.env.REACT_APP_BASEURL + "itemorders/order_counts";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllItemOrderCounts(response.data);
    }
  };
  const role = localStorage.getItem("role");
  function preloader(status) {
    if (status) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }

  function printDiv(divName) {
    let printContents = document.getElementById(divName);
    let searchelement = printContents.getElementsByClassName("sr-only");
    // let searchelement = printdoc.getElementById("search-bar-0-label");
    // const savedChild = searchelement;
    // console.log(searchelement);
    if (searchelement && searchelement[0]?.parentNode) {
      searchelement[0].parentNode.removeChild(searchelement[0]);
    }
    // printContents.getElementsByClassName(".col-lg-5 .search-label").remove();

    var winPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    // winPrint.document.body.innerHTML = printContents;
    winPrint.document.write(
      `<title>${divName} Report</title>
      <head>
        <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
        
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 7px 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 7px 0;
            font-weight: normal !important;
          }

          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${printContents.innerHTML}
      </body>
      `
    );
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    //winPrint.close();
  }

  const [productClosurePending, setProductClosurePending] = useState();
  const [itemClosurePending, setItemClosurePending] = useState();

  const checkClosures = async () => {
    let url1 = process.env.REACT_APP_BASEURL + "branchproductclosures/check";
    let response1 = await postSubmitForm(url1, "");
    if (response1.status === 1) {
      setProductClosurePending(response1.data);
    }

    let url2 = process.env.REACT_APP_BASEURL + "branchitemclosures/check";
    let response2 = await postSubmitForm(url2, "");
    if (response2.status === 1) {
      setItemClosurePending(response2.data);
    }
  };

  const { SearchBar } = Search;
  useEffect(() => {
    loadAllBranches();
    loadAllCategories();
    if (role === "Branch Supervisor") {
      checkClosures();
    }
  }, []);
  const [confirmClose, setConfirmClose] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [selectedCategory, setSelectedCategory] = useState("All");

  const [centralAvailableStatus, setCentralAvailableStatus] = useState(true);

  useEffect(() => {
    loadAllOrders();
  }, [selectedCategory, selectedBranch]);

  const [allOrders, setAllOrders] = useState([]);
  const loadAllOrders = async () => {
    preloader(true);
    let url = process.env.REACT_APP_BASEURL + "itemorders/getall";
    const response = await postSubmitForm(url, {
      status: ["Partially Delivered"],
      branch: selectedBranch,
      category: selectedCategory,
    });
    if (response && response.status === 1) {
      // let expandedRows = [];
      // response.data.map((item) => {
      //   expandedRows.push(item._id);
      // });
      preloader(false);
      setAllOrders(response.data);
      // setExpanded(expandedRows);
    } else {
      preloader(false);
      showNotification(response.message, "Error");
    }
  };

  const [allBranches, setAllBranches] = useState([]);
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [allCategories, setAllCategories] = useState([]);
  const loadAllCategories = async () => {
    let url = process.env.REACT_APP_BASEURL + "itemcategories/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllCategories(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  function showNotification(message, type) {
    if (type === "Success") swal.fire(type, message, "success");
    else swal.fire(type, message, "error");
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      text: props.t("Sub-Category"),
      dataField: "sub_category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Available"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.available.in_unit +
                " " +
                row.unit +
                " " +
                row.available.in_sub_unit +
                " " +
                row.sub_unit
              : row.available.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Order Quantity"),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "central_available",
      text: props.t("Central Avl. Qty."),
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? row.central_available.in_unit +
                " " +
                row.unit +
                " " +
                row.central_available.in_sub_unit +
                " " +
                row.sub_unit
              : row.central_available.in_unit + " " + row.unit}
          </>
        );
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const [selectedOrder, setSelectedOrder] = useState();
  const handleCloseOrder = async () => {
    let url = process.env.REACT_APP_BASEURL + "itemorders/set_closed";
    const response = await postSubmitForm(url, {
      itemorder_id: selectedOrder._id,
      admin_remarks: adminRemarks,
    });

    if (response && response.status === 1) {
      setAdminRemarks("");
      loadAllOrders();
      setRemarksModal(!remarksModal);
      showNotification(response.message, "Success");
    } else {
      showNotification(response.message, "Error");
    }
  };
  const [remarksModal, setRemarksModal] = useState();
  const [adminRemarks, setAdminRemarks] = useState();
  const columns_external = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      dataField: "order_id",
      text: props.t("Order ID"),

      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "branch_details.name",
      text: props.t("Branch Name"),

      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },

      style: { cursor: "pointer" },
    },
    {
      dataField: "remarks",
      text: props.t("Remarks"),

      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },

      style: { cursor: "pointer" },
    },
    {
      text: props.t("Order Date"),
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },

      dataField: "createdAt",
      // formatter: (cell, row) => {
      //   return moment(row.createdAt).format("DD-MM-YYYY HH:mm");
      // },
      style: { cursor: "pointer" },
    },
    // {
    //   text: props.t("Is Urgent?"),
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "12%" };
    //   },
    //   formatter: (cell, row) => {
    //     if (row.is_urgent) {
    //       return props.t("Yes");
    //     } else {
    //       return props.t("No");
    //     }
    //   },
    //   style: { cursor: "pointer" },
    // },

    {
      dataField: "status",
      text: props.t("Status"),
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
    },
    {
      dataField: "",
      text: props.t("Action"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <button
              class="btn btn-secondary btn-sm mr-2"
              onClick={() => {
                setSelectedOrder(row);
                setItemsForDelivery([]);
                setSelectedItem();
                setSelectedBatch();
                setQuantity();
                loadItemsInOrder(row._id);
                setOrderDispatchModal(!orderDispatchModal);
                if (row.status === "Approved") {
                  setIsPartialDelivery(false);
                  handleSetItemsForFullDelivery(row.item_details);
                } else setIsPartialDelivery(true);
                setCentralAvailableStatus(row.central_available_status);
              }}
            >
              {props.t("Dispatch")}
            </button>
            <button
              class="btn btn-danger btn-sm mr-2"
              onClick={() => {
                setRemarksModal(!remarksModal);
                setSelectedOrder(row);
              }}
            >
              {props.t("Close")}
            </button>
          </>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
  ];
  const handleSetItemsForFullDelivery = async (items) => {
    if (items.length > 0) {
      let new_arr = items.map((item) => {
        let obj = {
          name: item.name,
          category: item.category,
          sub_category: item.sub_category,
          item_id: item.item_id,
          delivered_quantity: item.quantity,
          brand: item.brand,
          unit: item.unit,
          sub_unit: item.sub_unit,
          formula: item.formula,
        };
        return obj;
      });
      setItemsForDelivery(new_arr);
    } else {
      setItemsForDelivery([]);
    }
  };
  const columns_fulldelivery = [
    {
      dataField: "item_id",
      hidden: true,
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Sub-Category"),
      dataField: "sub_category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Delivered Qty."),
      formatter: (cell, row) => {
        return (
          <>
            {row.delivered_quantity.in_sub_unit
              ? row.delivered_quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.delivered_quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.delivered_quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      setExpanded([...expanded, row._id]);
    } else {
      setExpanded(expanded.filter((x) => x !== row._id));
    }
  };
  const [expanded, setExpanded] = useState([]);
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="_id"
          data={row.item_details}
          columns={columns}
          noDataIndication="No data to display."
          striped
          hover
          condensed
        />
      </div>
    ),
    expanded: expanded,
    onExpand: handleOnExpand,
  };
  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.status === "Dispatched") {
      style.backgroundColor = "#b0ff9e";
    } else if (row.status === "Partially Delivered") {
      style.backgroundColor = "#bb87cc";
    } else {
      style.backgroundColor = "#fffbb8";
    }
    return style;
  };

  //#region Dispatch order related code
  const [itemsForDelivery, setItemsForDelivery] = useState([]);
  const [quantity, setQuantity] = useState();
  const [quantityInUnit, setQuantityInUnit] = useState();
  const [quantityInSubUnit, setQuantityInSubUnit] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [orderDispatchModal, setOrderDispatchModal] = useState();
  const [itemBatches, setItemBatches] = useState();
  const [selectedBatch, setSelectedBatch] = useState();
  const [allItems, setAllItems] = useState([]);
  const loadItemsInOrder = async (itemorder_id) => {
    let url = process.env.REACT_APP_BASEURL + "itemorders/get_items_in_order";

    const response = await postSubmitForm(url, { itemorder_id });

    if (response && response.status === 1) {
      setAllItems(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const loadBatches = async (item_details) => {
    let url =
      process.env.REACT_APP_BASEURL + "iteminventories/get_for_delivery";

    const response = await postSubmitForm(url, {
      item_id: item_details.value,
    });
    if (response && response.status === 1) {
      setItemBatches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };
  const handleAddItemForDelivery = async () => {
    if (!selectedBatch) {
      showNotification(props.t("Please select item batch."), props.t("Error"));
    } else {
      var new_array = JSON.parse(JSON.stringify(itemsForDelivery));
      const existing_item = new_array.filter(function (item) {
        return item.batch === selectedBatch.batch;
      });
      if (existing_item.length > 0) {
        showNotification(props.t("This item batch is already added."), "Error");
      } else {
        if (selectedItem.sub_unit) {
          if (selectedBatch.quantity.in_unit < quantityInUnit) {
            showNotification(props.t("Quantity not in stock."), "Error");
          } else if (
            selectedBatch.quantity.in_unit === quantityInUnit &&
            selectedBatch.quantity.in_sub_unit < quantityInSubUnit
          ) {
            showNotification(props.t("Quantity not in stock."), "Error");
          } else {
            setItemsForDelivery([
              ...itemsForDelivery,
              {
                batch: selectedBatch.batch,
                name: selectedItem.name,
                brand: selectedItem.brand,
                category: selectedItem.category,
                sub_category: selectedItem.sub_category,
                item_id: selectedItem.value,
                delivered_quantity: {
                  in_unit: quantityInUnit,
                  in_sub_unit: quantityInSubUnit,
                },
                unit: selectedItem.unit,
                sub_unit: selectedItem.sub_unit,
                formula: selectedItem.formula,
              },
            ]);

            setQuantityInUnit(0);
            setQuantityInSubUnit(0);
            setSelectedItem();
          }
        } else {
          if (selectedBatch.quantity < quantityInUnit) {
            showNotification(props.t("Quantity not in stock."), "Error");
          } else {
            setItemsForDelivery([
              ...itemsForDelivery,
              {
                batch: selectedBatch.batch,
                name: selectedItem.name,
                brand: selectedItem.brand,
                category: selectedItem.category,
                sub_category: selectedItem.sub_category,
                item_id: selectedItem.value,
                delivered_quantity: {
                  in_unit: quantityInUnit,
                  in_sub_unit: 0,
                },
                unit: selectedItem.unit,
              },
            ]);

            setQuantityInUnit(0);
            setQuantityInSubUnit(0);
            setSelectedItem();
          }
        }
      }
    }
  };
  function deleteFormatter(cell, row, rowIndex, extraData) {
    return (
      <span className="text-danger">
        <i
          className="bx bxs-trash font-size-15"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            let arr = extraData.filter((item) => item.batch !== row.batch);

            setItemsForDelivery(arr);
          }}
        ></i>
      </span>
    );
  }
  const columns_items = [
    {
      text: props.t("Item"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Delivered Qty."),
      formatter: (cell, row) => {
        return (
          <>
            {row.delivered_quantity.in_sub_unit
              ? row.delivered_quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.delivered_quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.delivered_quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      formatter: deleteFormatter,
      formatExtraData: itemsForDelivery,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
  ];

  const columns_delivered_items = [
    {
      text: props.t("Category, Sub-Category, Item"),
      dataField: "section",
      formatter: (cell, row) => {
        return row.category + ", " + row.sub_category + ", " + row.name;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },

    {
      text: props.t("Dispatch Time"),
      dataField: "date_of_transaction",
      formatter: (cell, row) => {
        return moment(row.date_of_transaction).format("DD/MM/YYYY HH:mm");
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Delivered Qty."),
      formatter: (cell, row) => {
        return (
          <>
            {row.delivered_quantity.in_sub_unit
              ? row.delivered_quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.delivered_quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.delivered_quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "unit",
      text: props.t("Rec. Qty."),
      formatter: (cell, row) => {
        return (
          <>
            {row.received_quantity.in_sub_unit
              ? row.received_quantity.in_unit +
                " " +
                row.unit +
                " " +
                row.received_quantity.in_sub_unit +
                " " +
                row.sub_unit
              : row.received_quantity.in_unit + " " + row.unit}
          </>
        );
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    // {
    //   text: props.t("Rec. Qty."),
    //   dataField: "received_quantity",
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "9%" };
    //   },
    // },
    {
      text: props.t("Received by Branch?"),
      dataField: "is_received",
      formatter: (cell, row) => {
        return row.is_received ? "Yes" : "No";
      },
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
  ];
  const handleDispatchOrder = async () => {
    if (itemsForDelivery.length > 0) {
      let data_to_send = {};
      data_to_send.itemorder_id = selectedOrder._id;
      data_to_send.branch_id = selectedOrder.branch_details._id;
      data_to_send.delivery_details = itemsForDelivery;
      data_to_send.isPartialDelivery = isPartialDelivery;
      let url = process.env.REACT_APP_BASEURL + "itemorders/dispatch";

      const response = await postSubmitForm(url, data_to_send);
      if (response && response.status === 1) {
        loadAllOrders();
        showNotification(response.message, "Success");
        setOrderDispatchModal(!orderDispatchModal);
      } else {
        showNotification(response.message, "Error");
      }
    } else {
      showNotification("Add at least one item.", "Error");
    }
  };
  //#endregion

  const [isPartialDelivery, setIsPartialDelivery] = useState(false);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {productClosurePending && productClosurePending.pending && (
            <Row>
              <Col lg={12}>
                <UncontrolledAlert
                  color="warning"
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  <i className="mdi mdi-alert-outline me-2"></i>{" "}
                  {productClosurePending.message}
                </UncontrolledAlert>
              </Col>
            </Row>
          )}
          {itemClosurePending && itemClosurePending.pending && (
            <Row>
              <Col lg={12}>
                <UncontrolledAlert
                  color="warning"
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  <i className="mdi mdi-alert-outline me-2"></i>{" "}
                  {itemClosurePending.message}
                </UncontrolledAlert>
              </Col>
            </Row>
          )}

          <CardTitle>{props.t("DASHBOARD")}</CardTitle>

          <Row>
            <Col xl="8">
              <Row>
                <Col xl="4">
                  <WelcomeComp />
                </Col>

                <Col xl="8">
                  {role === "Central Kitchen Manager" && (
                    <Row>
                      {/* Reports Render */}
                      <Col md="4">
                        <Link to="/finished_products/new_orders">
                          <Card className="mini-stats-wid bg-danger">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p
                                    className="fw-medium"
                                    style={{ color: "white" }}
                                  >
                                    New
                                  </p>
                                  <h4
                                    className="mb-0"
                                    style={{ color: "white" }}
                                  >
                                    {productOrderCounts &&
                                      productOrderCounts.new_orders}
                                  </h4>
                                </Media>
                              </Media>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>

                      <Col md="4">
                        <Link to="/finished_products/orders_in_process">
                          <Card className="mini-stats-wid bg-info">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p
                                    className="fw-medium"
                                    style={{ color: "white" }}
                                  >
                                    In Process
                                  </p>
                                  <h4
                                    className="mb-0"
                                    style={{ color: "white" }}
                                  >
                                    {productOrderCounts &&
                                      productOrderCounts.in_process_orders}
                                  </h4>
                                </Media>
                              </Media>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>
                      <Col md="4">
                        <Link to="/finished_products/closed_orders">
                          <Card className="mini-stats-wid bg-success">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p
                                    className="fw-medium"
                                    style={{ color: "white" }}
                                  >
                                    Closed
                                  </p>
                                  <h4
                                    className="mb-0"
                                    style={{ color: "white" }}
                                  >
                                    {productOrderCounts &&
                                      productOrderCounts.closed_orders}
                                  </h4>
                                </Media>
                              </Media>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>
                    </Row>
                  )}

                  {role === "Central Store Manager" && (
                    <Row>
                      {/* Reports Render */}

                      <Col md="4">
                        <Link to="/items/new_orders">
                          <Card className="mini-stats-wid bg-danger">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p
                                    className="fw-medium"
                                    style={{ color: "white" }}
                                  >
                                    Orders
                                  </p>
                                  <h4
                                    className="mb-0"
                                    style={{ color: "white" }}
                                  >
                                    {itemOrderCounts &&
                                      itemOrderCounts.new_orders}
                                  </h4>
                                </Media>
                              </Media>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>

                      <Col md="4">
                        <Link to="/items/orders_in_process">
                          <Card className="mini-stats-wid bg-info">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p
                                    className="fw-medium"
                                    style={{ color: "white" }}
                                  >
                                    In Process
                                  </p>
                                  <h4
                                    className="mb-0"
                                    style={{ color: "white" }}
                                  >
                                    {itemOrderCounts &&
                                      itemOrderCounts.in_process_orders}
                                  </h4>
                                </Media>
                              </Media>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>
                      <Col md="4">
                        <Link to="/items/closed_orders">
                          <Card className="mini-stats-wid bg-success">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p
                                    className="fw-medium"
                                    style={{ color: "white" }}
                                  >
                                    Closed
                                  </p>
                                  <h4
                                    className="mb-0"
                                    style={{ color: "white" }}
                                  >
                                    {itemOrderCounts &&
                                      itemOrderCounts.closed_orders}
                                  </h4>
                                </Media>
                              </Media>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>
                      {/* <Col md="4">
                        <Link to="/items/#">
                          <Card className="mini-stats-wid bg-info">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p
                                    className="fw-medium"
                                    style={{ color: "white" }}
                                  >
                                    Partially Delivered Order
                                  </p>
                                  <h4
                                    className="mb-0"
                                    style={{ color: "white" }}
                                  >
                                    {itemOrderCounts &&
                                      itemOrderCounts.closed_orders}
                                  </h4>
                                </Media>
                              </Media>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col> */}
                    </Row>
                  )}

                  {role === "Owner" && (
                    <Row>
                      <Col md="12">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <AvForm>
                              <Row>
                                <Col lg={6}>
                                  <AvField
                                    name="dateFrom"
                                    label={props.t("From Date")}
                                    placeholder={props.t("Select")}
                                    type="date"
                                    value={dateFrom}
                                    onChange={(e, v) => {
                                      setDateFrom(formatDate(e.target.value));
                                    }}
                                    errorMessage={props.t(
                                      "Date cannot be empty."
                                    )}
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                </Col>
                                <Col lg={6}>
                                  <AvField
                                    name="dateTo"
                                    label={props.t("To Date")}
                                    placeholder={props.t("Select")}
                                    type="date"
                                    value={dateTo}
                                    onChange={(e, v) => {
                                      setDateTo(formatDate(e.target.value));
                                    }}
                                    errorMessage={props.t(
                                      "Date cannot be empty."
                                    )}
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                </Col>
                              </Row>
                            </AvForm>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Link to="">
                          <Card className="mini-stats-wid bg-danger">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p
                                    className="fw-medium"
                                    style={{ color: "white" }}
                                  >
                                    Total Central Store Order
                                  </p>
                                  <h4
                                    className="mb-0"
                                    style={{ color: "white" }}
                                  >
                                    {itemData}
                                  </h4>
                                </Media>
                              </Media>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>
                      <Col md="4">
                        <Link to="">
                          <Card className="mini-stats-wid bg-info">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p
                                    className="fw-medium"
                                    style={{ color: "white" }}
                                  >
                                    Total Central Kitchen Order
                                  </p>
                                  <h4
                                    className="mb-0"
                                    style={{ color: "white" }}
                                  >
                                    {productData}
                                  </h4>
                                </Media>
                              </Media>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>
                      <Col md="4">
                        <Link to="">
                          <Card className="mini-stats-wid bg-success">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p
                                    className="fw-medium"
                                    style={{ color: "white" }}
                                  >
                                    Total Purchase Order
                                  </p>
                                  <h4
                                    className="mb-0"
                                    style={{ color: "white" }}
                                  >
                                    {poData}
                                  </h4>
                                </Media>
                              </Media>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Row>
                  {role === "Central Store Manager" && (
                    <Row>
                      <Col className="col-12">
                        <Card>
                          <CardBody>
                            <CardTitle>
                              {props.t("Partially Delivered")}{" "}
                            </CardTitle>

                            {/* <Row className="mb-2">
                          <Col sm="4">
                            <Button
                              type="submit"
                              className="btn btn-sm btn-secondary"
                              onClick={handleDownload}
                            >
                              <i className="mdi mdi-file-export"></i>{" "}
                              {props.t("Export CSV")}
                            </Button>
                            <div className="search-box mr-2 mb-2 d-inline-block">
                              <div className="position-relative"></div>
                            </div>
                          </Col>
                        </Row> */}
                            <ToolkitProvider
                              keyField="_id"
                              data={allOrders && allOrders}
                              columns={columns_external}
                              noDataIndication={props.t("No data to display.")}
                              bootstrap4
                              search
                            >
                              {(props_table) => (
                                <div>
                                  <AvForm>
                                    <Row>
                                      <Col lg={4}>
                                        <Label>{props.t("Search")}</Label>
                                        <SearchBar
                                          {...props_table.searchProps}
                                          style={{ width: "280px" }}
                                        />
                                      </Col>
                                      <Col lg={4}>
                                        <AvField
                                          name="branch"
                                          label={props.t("Branch")}
                                          type="select"
                                          value={selectedBranch}
                                          onChange={(e) => {
                                            setSelectedBranch(e.target.value);
                                          }}
                                        >
                                          <option value="All">
                                            {props.t("All")}
                                          </option>
                                          {allBranches &&
                                            allBranches.map((branch) => {
                                              return (
                                                <option value={branch._id}>
                                                  {branch.name}
                                                </option>
                                              );
                                            })}
                                        </AvField>
                                      </Col>
                                      <Col lg={4}>
                                        <AvField
                                          name="category"
                                          label={props.t("Category")}
                                          type="select"
                                          value={selectedCategory}
                                          onChange={(e) => {
                                            setSelectedCategory(e.target.value);
                                          }}
                                        >
                                          <option value="All">
                                            {props.t("All")}
                                          </option>
                                          {allCategories &&
                                            allCategories.map((category) => {
                                              return (
                                                <option
                                                  value={category.category}
                                                >
                                                  {category.category}
                                                </option>
                                              );
                                            })}
                                        </AvField>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                  <BootstrapTable
                                    striped
                                    hover
                                    condensed
                                    expandRow={expandRow}
                                    {...props_table.baseProps}
                                  />
                                </div>
                              )}
                            </ToolkitProvider>
                            <Modal
                              size="lg"
                              style={{ width: "925px", minWidth: "925px" }}
                              isOpen={orderDispatchModal}
                              toggle={() =>
                                setOrderDispatchModal(!orderDispatchModal)
                              }
                            >
                              <ModalHeader
                                toggle={() =>
                                  setOrderDispatchModal(!orderDispatchModal)
                                }
                              >
                                {props.t("Dispatch Order")}
                              </ModalHeader>
                              <ModalBody>
                                <>
                                  <Row>
                                    <Col lg={12}>
                                      <Card>
                                        <CardBody>
                                          <Row>
                                            <Link
                                              to="#"
                                              onClick={() => {
                                                printDiv("printOrdered");
                                              }}
                                              className="btn btn-sm btn-success mr-2"
                                            >
                                              <i className="fa fa-print"></i>{" "}
                                              Print
                                            </Link>

                                            <div id="printOrdered">
                                              <Col lg={12} md={12}>
                                                <Label>Ordered Items</Label>
                                              </Col>

                                              <Col lg={12}>
                                                <BootstrapTable
                                                  bootstrap4
                                                  keyField="name"
                                                  data={
                                                    selectedOrder &&
                                                    selectedOrder.item_details
                                                  }
                                                  columns={columns}
                                                  noDataIndication="No data to display."
                                                  striped
                                                  hover
                                                  condensed
                                                />

                                                <hr />
                                              </Col>
                                            </div>
                                            {selectedOrder &&
                                            selectedOrder.status ===
                                              "Approved" ? (
                                              <Col lg={12}>
                                                <div class="form-check">
                                                  <input
                                                    type="checkbox"
                                                    name="isPartialDelivery"
                                                    id="isPartialDelivery"
                                                    className="is-untouched is-pristine av-valid form-check-input"
                                                    checked={isPartialDelivery}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        setIsPartialDelivery(
                                                          true
                                                        );
                                                        setItemsForDelivery([]);
                                                      } else {
                                                        handleSetItemsForFullDelivery(
                                                          selectedOrder.item_details
                                                        );
                                                        setIsPartialDelivery(
                                                          false
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  <label for="isPartialDelivery">
                                                    {" "}
                                                    {props.t(
                                                      "Partial Delivery"
                                                    )}
                                                  </label>
                                                </div>

                                                <br />
                                              </Col>
                                            ) : null}
                                            {selectedOrder &&
                                            selectedOrder.delivery_details &&
                                            selectedOrder.delivery_details
                                              .length > 0 ? (
                                              <>
                                                <Link
                                                  to="#"
                                                  onClick={() => {
                                                    printDiv("printDispatched");
                                                  }}
                                                  className="btn btn-sm btn-success mr-2"
                                                >
                                                  <i className="fa fa-print"></i>{" "}
                                                  Print
                                                </Link>
                                                <div id="printDispatched">
                                                  <Col lg={12} md={12}>
                                                    <Label>
                                                      <b>
                                                        {props.t(
                                                          "Dispatched Items"
                                                        )}{" "}
                                                      </b>
                                                    </Label>
                                                  </Col>
                                                  <Col lg={12} className="mb-4">
                                                    <BootstrapTable
                                                      bootstrap4
                                                      keyField="_id"
                                                      data={
                                                        selectedOrder.delivery_details
                                                      }
                                                      columns={
                                                        columns_delivered_items
                                                      }
                                                      noDataIndication="No data to display."
                                                      striped
                                                      hover
                                                      condensed
                                                    />
                                                    <hr />
                                                  </Col>
                                                </div>
                                              </>
                                            ) : null}
                                          </Row>
                                          {isPartialDelivery && (
                                            <Col lg={12}>
                                              <AvForm
                                                onValidSubmit={
                                                  handleAddItemForDelivery
                                                }
                                              >
                                                <Row>
                                                  <Col lg={3}>
                                                    <Label>
                                                      {props.t("Add Item")}
                                                    </Label>
                                                    <Select
                                                      value={
                                                        selectedItem &&
                                                        selectedItem
                                                      }
                                                      key={selectedItem}
                                                      placeholder={props.t(
                                                        "Select..."
                                                      )}
                                                      onChange={(e) => {
                                                        setSelectedItem(e);
                                                        loadBatches(e);
                                                        setSelectedBatch();
                                                      }}
                                                      options={allItems}
                                                      classNamePrefix="select2-selection"
                                                      required
                                                    />
                                                  </Col>

                                                  {selectedItem &&
                                                  selectedItem.sub_unit ? (
                                                    selectedItem.available
                                                      .in_unit > 0 ||
                                                    selectedItem.available
                                                      .in_sub_unit > 0 ? (
                                                      <>
                                                        <Col lg={3}>
                                                          <Label>
                                                            {props.t("Batch")}
                                                          </Label>
                                                          <Select
                                                            value={
                                                              selectedBatch &&
                                                              selectedBatch
                                                            }
                                                            key={selectedBatch}
                                                            onChange={(e) => {
                                                              setSelectedBatch(
                                                                e
                                                              );
                                                            }}
                                                            options={
                                                              itemBatches &&
                                                              itemBatches
                                                            }
                                                            classNamePrefix="select2-selection"
                                                            required
                                                          />
                                                        </Col>

                                                        <Col lg={4}>
                                                          <Label>
                                                            {props.t(
                                                              "Quantity"
                                                            )}{" "}
                                                            {" (1 " +
                                                              selectedItem.unit +
                                                              " = " +
                                                              selectedItem.formula +
                                                              " " +
                                                              selectedItem.sub_unit +
                                                              ")"}
                                                          </Label>
                                                          <Row>
                                                            <Col lg={6}>
                                                              <AvField
                                                                name="in_unit"
                                                                placeholder={
                                                                  selectedItem.unit
                                                                }
                                                                type="text"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  setQuantityInUnit(
                                                                    e.target
                                                                      .value
                                                                  );
                                                                }}
                                                                validate={{
                                                                  required: {
                                                                    value: true,
                                                                    errorMessage:
                                                                      "Quantity cannot be empty.",
                                                                  },
                                                                  pattern: {
                                                                    value:
                                                                      "^[0-9]+$",
                                                                    errorMessage:
                                                                      props.t(
                                                                        "Only numbers are allowed."
                                                                      ),
                                                                  },
                                                                  max: {
                                                                    value:
                                                                      selectedBatch &&
                                                                      selectedBatch
                                                                        .quantity
                                                                        .in_unit,
                                                                    errorMessage:
                                                                      selectedBatch &&
                                                                      props.t(
                                                                        "Only "
                                                                      ) +
                                                                        selectedBatch
                                                                          .quantity
                                                                          .in_unit +
                                                                        " available in inventory.",
                                                                  },
                                                                }}
                                                              />
                                                            </Col>
                                                            <Col lg={6}>
                                                              <AvField
                                                                name="in_sub_unit"
                                                                placeholder={
                                                                  selectedItem.sub_unit
                                                                }
                                                                type="text"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  setQuantityInSubUnit(
                                                                    e.target
                                                                      .value
                                                                  );
                                                                }}
                                                                validate={{
                                                                  required: {
                                                                    value: true,
                                                                    errorMessage:
                                                                      "Quantity cannot be empty.",
                                                                  },
                                                                  pattern: {
                                                                    value:
                                                                      "^[0-9]+$",
                                                                    errorMessage:
                                                                      props.t(
                                                                        "Only numbers are allowed."
                                                                      ),
                                                                  },
                                                                  max: {
                                                                    value:
                                                                      selectedBatch &&
                                                                      (+selectedBatch
                                                                        .quantity
                                                                        .in_unit ===
                                                                      +quantityInUnit
                                                                        ? selectedBatch
                                                                            .quantity
                                                                            .in_sub_unit
                                                                        : +selectedItem.formula -
                                                                          1),
                                                                    errorMessage:
                                                                      selectedBatch &&
                                                                      (+selectedBatch
                                                                        .quantity
                                                                        .in_unit ===
                                                                      +quantityInUnit
                                                                        ? props.t(
                                                                            "Cannot enter more than "
                                                                          ) +
                                                                          +selectedBatch
                                                                            .quantity
                                                                            .in_sub_unit +
                                                                          " " +
                                                                          selectedItem.sub_unit +
                                                                          "."
                                                                        : props.t(
                                                                            "Cannot enter more than "
                                                                          ) +
                                                                          (+selectedItem.formula -
                                                                            1) +
                                                                          "."),
                                                                  },
                                                                }}
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </Col>
                                                        <Col lg={2}>
                                                          <Label>&nbsp;</Label>
                                                          <br />
                                                          <Button
                                                            type="submit"
                                                            color="primary"
                                                            className="btn btn-info waves-effect waves-light btn-sm"
                                                          >
                                                            <i class="bx bx-add-to-queue"></i>{" "}
                                                            {props.t("Add")}
                                                          </Button>
                                                        </Col>
                                                      </>
                                                    ) : (
                                                      <Col lg={6}>
                                                        <p class="text-muted mb-2">
                                                          &nbsp;
                                                        </p>
                                                        <div
                                                          class="alert alert-danger fade show text-center"
                                                          role="alert"
                                                        >
                                                          {props.t(
                                                            "This item is out of stock."
                                                          )}
                                                        </div>
                                                      </Col>
                                                    )
                                                  ) : selectedItem ? (
                                                    <>
                                                      <Col lg={3}>
                                                        <Label>
                                                          {props.t("Batch")}
                                                        </Label>
                                                        <Select
                                                          value={
                                                            selectedBatch &&
                                                            selectedBatch
                                                          }
                                                          key={selectedBatch}
                                                          onChange={(e) => {
                                                            setSelectedBatch(e);
                                                          }}
                                                          options={
                                                            itemBatches &&
                                                            itemBatches
                                                          }
                                                          classNamePrefix="select2-selection"
                                                          required
                                                        />
                                                      </Col>

                                                      <Col lg={4}>
                                                        <Label>
                                                          {props.t("Quantity")}{" "}
                                                          {selectedItem
                                                            ? " (in " +
                                                              selectedItem.unit +
                                                              ")"
                                                            : ""}
                                                        </Label>

                                                        <AvField
                                                          name="quantity"
                                                          placeholder={props.t(
                                                            "Enter Quantity"
                                                          )}
                                                          onChange={(e) => {
                                                            setQuantityInUnit(
                                                              e.target.value
                                                            );
                                                          }}
                                                          errorMessage="Quantity cannot be empty."
                                                          type="text"
                                                          validate={{
                                                            required: {
                                                              value: true,
                                                            },
                                                            pattern: {
                                                              value: "^[0-9]+$",
                                                              errorMessage:
                                                                props.t(
                                                                  "Only numbers are allowed."
                                                                ),
                                                            },
                                                            max: {
                                                              value:
                                                                selectedBatch &&
                                                                selectedBatch
                                                                  .quantity
                                                                  .in_unit,
                                                              errorMessage:
                                                                selectedBatch &&
                                                                props.t(
                                                                  "Only "
                                                                ) +
                                                                  selectedBatch
                                                                    .quantity
                                                                    .in_unit +
                                                                  " available in inventory.",
                                                            },
                                                          }}
                                                        />
                                                      </Col>
                                                      <Col lg={2}>
                                                        <Label>&nbsp;</Label>
                                                        <br />
                                                        <Button
                                                          type="submit"
                                                          color="primary"
                                                          className="btn btn-info waves-effect waves-light btn-sm"
                                                        >
                                                          <i class="bx bx-add-to-queue"></i>{" "}
                                                          {props.t("Add")}
                                                        </Button>
                                                      </Col>
                                                    </>
                                                  ) : null}
                                                </Row>
                                              </AvForm>
                                            </Col>
                                          )}
                                          <Row>
                                            {itemsForDelivery &&
                                            itemsForDelivery.length > 0 ? (
                                              <>
                                                <Col lg={12} md={12}>
                                                  <br />
                                                  <Label>
                                                    <b>
                                                      {props.t(
                                                        "Items to Deliver"
                                                      )}{" "}
                                                    </b>
                                                  </Label>
                                                </Col>
                                                <Col lg={12}>
                                                  <BootstrapTable
                                                    bootstrap4
                                                    keyField="name"
                                                    key="name"
                                                    data={
                                                      itemsForDelivery &&
                                                      itemsForDelivery
                                                    }
                                                    columns={
                                                      isPartialDelivery
                                                        ? columns_items
                                                        : columns_fulldelivery
                                                    }
                                                    noDataIndication="No data to display."
                                                    striped
                                                    hover
                                                    condensed
                                                  />
                                                </Col>
                                              </>
                                            ) : null}
                                            <Col lg={12}>
                                              <hr />
                                              <FormGroup className="mb-0 text-center">
                                                <div>
                                                  <Button
                                                    onClick={
                                                      handleDispatchOrder
                                                    }
                                                    color="primary"
                                                    className="mr-1"
                                                    disabled={
                                                      !centralAvailableStatus
                                                    }
                                                  >
                                                    {centralAvailableStatus &&
                                                    centralAvailableStatus
                                                      ? "Submit"
                                                      : "Not enough stock at center"}
                                                  </Button>{" "}
                                                </div>
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </>
                              </ModalBody>
                            </Modal>

                            <Modal
                              size="md"
                              isOpen={remarksModal}
                              toggle={() => setRemarksModal(!remarksModal)}
                            >
                              <ModalHeader
                                toggle={() => setRemarksModal(!remarksModal)}
                              >
                                Order ID:{" "}
                                {selectedOrder && selectedOrder.order_id}
                              </ModalHeader>
                              <ModalBody>
                                <>
                                  <Row>
                                    <Col lg={12}>
                                      <Card>
                                        <CardBody>
                                          <AvForm
                                            onValidSubmit={(e, v) =>
                                              setConfirmClose(true)
                                            }
                                          >
                                            <Row>
                                              <Col lg={12}>
                                                <AvField
                                                  name="admin_remarks"
                                                  placeholder="Enter remarks"
                                                  type="textarea"
                                                  onChange={(e) =>
                                                    setAdminRemarks(
                                                      e.target.value
                                                    )
                                                  }
                                                  value={adminRemarks}
                                                  label="Remarks"
                                                  validate={{
                                                    required: {
                                                      value: true,
                                                      errorMessage:
                                                        "Remarks cannot be empty.",
                                                    },
                                                  }}
                                                />
                                              </Col>

                                              <Col lg={12}>
                                                <hr />
                                                <FormGroup className="mb-0 text-center">
                                                  <div>
                                                    <Button
                                                      type="submit"
                                                      color="primary"
                                                      className="mr-1"
                                                    >
                                                      {props.t("Submit")}
                                                    </Button>{" "}
                                                  </div>
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                          </AvForm>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </>
                              </ModalBody>
                            </Modal>

                            {confirmClose && (
                              <SweetAlert
                                title={props.t("Are you sure?")}
                                warning
                                showCancel
                                cancelBtnText={props.t("No")}
                                confirmBtnText={props.t("Yes")}
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                onConfirm={() => {
                                  setConfirmClose(false);
                                  handleCloseOrder();
                                }}
                                onCancel={() => {
                                  setConfirmClose(false);
                                }}
                              >
                                {props.t(
                                  "Once you close this order, it cannot be revoked."
                                )}
                              </SweetAlert>
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </Row>
              </Row>
              {role === "Branch Supervisor" && (
                <Row>
                  <>
                    <Row>
                      <Col lg={12}>
                        <CardTitle>Finished Products</CardTitle>
                        <div
                          style={{
                            height: "90px",
                            overflowX: "hidden",
                            overflowY: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Link
                            to="/finished_products/add_order"
                            style={{ display: "inline-block" }}
                          >
                            <Card
                              className={
                                props.type === "new_orders"
                                  ? "mini-stats-wid bg-danger mr-4"
                                  : props.type === "orders_in_process"
                                  ? "mini-stats-wid bg-info mr-4"
                                  : "mini-stats-wid bg-success mr-4"
                              }
                              style={{ width: "180px" }}
                            >
                              <CardBody>
                                <Media>
                                  <Media body>
                                    {/* <p
                                className="fw-medium"
                                style={{ color: "white" }}
                              >
                                {section.section}
                              </p> */}
                                    <h5
                                      className="mb-0"
                                      style={{ color: "white" }}
                                    >
                                      Add New Order
                                    </h5>
                                  </Media>
                                </Media>
                              </CardBody>
                            </Card>
                          </Link>
                          <Link
                            to="/finished_products/all_orders"
                            style={{ display: "inline-block" }}
                          >
                            <Card
                              className={
                                props.type === "new_orders"
                                  ? "mini-stats-wid bg-danger mr-4"
                                  : props.type === "orders_in_process"
                                  ? "mini-stats-wid bg-info mr-4"
                                  : "mini-stats-wid bg-success mr-4"
                              }
                              style={{ width: "180px" }}
                            >
                              <CardBody>
                                <Media>
                                  <Media body>
                                    {/* <p
                                className="fw-medium"
                                style={{ color: "white" }}
                              >
                                {section.section}
                              </p> */}
                                    <h5
                                      className="mb-0"
                                      style={{ color: "white" }}
                                    >
                                      View All Order
                                    </h5>
                                  </Media>
                                </Media>
                              </CardBody>
                            </Card>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <CardTitle>Items</CardTitle>
                        <div
                          style={{
                            height: "90px",
                            overflowX: "hidden",
                            overflowY: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Link
                            to="/items/add_order"
                            style={{ display: "inline-block" }}
                          >
                            <Card
                              className={
                                props.type === "new_orders"
                                  ? "mini-stats-wid bg-danger mr-4"
                                  : props.type === "orders_in_process"
                                  ? "mini-stats-wid bg-info mr-4"
                                  : "mini-stats-wid bg-success mr-4"
                              }
                              style={{ width: "180px" }}
                            >
                              <CardBody>
                                <Media>
                                  <Media body>
                                    {/* <p
                                className="fw-medium"
                                style={{ color: "white" }}
                              >
                                {section.section}
                              </p> */}
                                    <h5
                                      className="mb-0"
                                      style={{ color: "white" }}
                                    >
                                      Add New Order
                                    </h5>
                                  </Media>
                                </Media>
                              </CardBody>
                            </Card>
                          </Link>
                          <Link
                            to="/items/all_orders"
                            style={{ display: "inline-block" }}
                          >
                            <Card
                              className={
                                props.type === "new_orders"
                                  ? "mini-stats-wid bg-danger mr-4"
                                  : props.type === "orders_in_process"
                                  ? "mini-stats-wid bg-info mr-4"
                                  : "mini-stats-wid bg-success mr-4"
                              }
                              style={{ width: "180px" }}
                            >
                              <CardBody>
                                <Media>
                                  <Media body>
                                    {/* <p
                                className="fw-medium"
                                style={{ color: "white" }}
                              >
                                {section.section}
                              </p> */}
                                    <h5
                                      className="mb-0"
                                      style={{ color: "white" }}
                                    >
                                      View All Order
                                    </h5>
                                  </Media>
                                </Media>
                              </CardBody>
                            </Card>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </>
                </Row>
              )}
              <Row>
                {role === "Central Kitchen Manager" && (
                  <>
                    <Col xl="12">
                      <OrderProductSections
                        sections={allSections && allSections}
                        heading="New Orders"
                        type="new_orders"
                      />
                    </Col>
                    <Col xl="12">
                      <OrderProductSections
                        sections={allSections && allSections}
                        heading="Orders in Process"
                        type="orders_in_process"
                      />
                    </Col>

                    <Col xl="12">
                      <OrderProductSections
                        sections={allSections && allSections}
                        heading="Closed Orders"
                        type="closed_orders"
                      />
                    </Col>
                  </>
                )}
              </Row>
              {role === "Central Kitchen Manager" && (
                <Row>
                  <FinishedProductOrdersInProcessForDashboard />
                </Row>
              )}
            </Col>

            <Col xl="4">
              <NotificationDashboard
                data={notifications}
                setNotificationsRead={() => {}}
                viewAll={true}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
};

export default withNamespaces()(Dashboard);
