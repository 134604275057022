import React, { useEffect, useState, useRef } from "react";
//i18n
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Button,
  CardTitle,
  Label,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import showNotification from "../../helpers/show_notification";

import { postSubmitForm } from "../../helpers/forms_helper";

const AddBranch = (props) => {
  const refForForm = useRef(null);
  useEffect(() => {
    loadAllBranches();
    //loadAllCities();
  }, []);
  const refContainer = useRef(null);

  // const [allCities, setAllCities] = useState([]);
  // const [selectedCity, setSelectedCity] = useState();
  // const loadAllCities = async () => {
  //   let url = process.env.REACT_APP_BASEURL + "cities/getall";
  //   let response = await postSubmitForm(url, "");
  //   if (response.status === 1) {
  //     setAllCities(response.data);
  //   } else {
  //     showNotification(response.message, "Error");
  //   }
  // };

  const [allBranches, setAllBranches] = useState([]);
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall";
    let response = await postSubmitForm(url, "");
    if (response.status === 1) {
      setAllBranches(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  // const [newCityModal, setNewCityModal] = useState();
  // const handleAddNewCity = async (e, v) => {
  //   let url = process.env.REACT_APP_BASEURL + "cities/insert";
  //   const response = await postSubmitForm(url, v);
  //   if (response && response.status === 1) {
  //     loadAllCities();
  //     setSelectedCity(v.name);
  //     setNewCityModal(!newCityModal);
  //   } else {
  //     showNotification(response.message, "Error");
  //   }
  // };

  const [isStore, setIsStore] = useState(false);
  const [isPrivileged, setIsPrivileged] = useState(false);
  const handleValidSubmit = async (e, v) => {
    try {
      const object = {
        name: v.name,
        address: v.address,
        mobile: v.mobile,
        email: v.email,
        opening_time: v.opening_time,
        closing_time: v.closing_time,
        is_store: isStore,
        transfer_privilege: isPrivileged,
      };

      let url = process.env.REACT_APP_BASEURL + "branches/insert";
      let response = await postSubmitForm(url, object);
      if (response.status === 1) {
        showNotification(response.message, "Success");
        loadAllBranches();

        refForForm.current.reset();
      } else {
        showNotification(response.message, "Error");
      }
    } catch (error) {
      showNotification(error.message, "Error");
    }
  };

  const columns = [
    {
      dataField: "name",
      text: props.t("Name"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      sort: true,
    },
    {
      dataField: "address",
      text: props.t("Address"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "mobile",
      text: props.t("Contact"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "email",
      text: props.t("Email"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "11%" };
      },
    },
    {
      dataField: "is_store",
      text: props.t("Store Exist"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      formatter: (cell, row) => {
        return row.is_store ? "Yes" : "No";
      },
    },
    {
      dataField: "transfer_privilege",
      text: props.t("Transfer Privilege"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
      formatter: (cell, row) => {
        return row.transfer_privilege ? "Yes" : "No";
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Branch")}
            breadcrumbItem={props.t("Add Branch")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm onValidSubmit={handleValidSubmit} ref={refForForm}>
                    <CardTitle>{props.t("Add Branch")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Enter the following details to add a new branch"
                      )}
                    </CardSubtitle>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="name"
                          label={props.t("Name")}
                          placeholder={props.t("Enter Name")}
                          type="text"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Name cannot be empty")}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="address"
                          label={props.t("Address")}
                          placeholder={props.t("Enter Address")}
                          type="text"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Address cannot be empty")}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="mobile"
                          label={props.t("Contact Number")}
                          placeholder={props.t("Enter Contact Number")}
                          type="text"
                          // validate={{
                          //   required: {
                          //     value: true,
                          //     errorMessage: props.t("Contact cannot be empty."),
                          //   },
                          //   pattern: {
                          //     value: "^[0-9]+$",
                          //     errorMessage: props.t(
                          //       "Only numbers are allowed."
                          //     ),
                          //   },
                          //   maxLength: {
                          //     value: 8,
                          //     errorMessage: props.t("Enter valid contact."),
                          //   },
                          //   minLength: {
                          //     value: 8,
                          //     errorMessage: props.t("Enter valid contact."),
                          //   },
                          // }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="email"
                          label={props.t("Email")}
                          placeholder={props.t("Enter Email")}
                          type="email"
                          errorMessage={props.t("Enter valid email.")}
                        />
                      </Col>
                      {/* <Col lg={6}>
                        <Row>
                          <Col lg={9}>
                            <AvField
                              name="city"
                              label={props.t("Select City")}
                              value={selectedCity}
                              type="select"
                              validate={{ required: { value: true } }}
                              errorMessage={props.t("City cannot be empty")}
                            >
                              <option value="">
                                {props.t("-- Select City --")}
                              </option>
                              {allCities &&
                                allCities.map((e) => (
                                  <option key={e._id} value={e.name}>
                                    {e.name}
                                  </option>
                                ))}
                            </AvField>
                          </Col>
                          <Col lg={3}>
                            {" "}
                            <Label>&nbsp;</Label>
                            <br />
                            <Link
                              class="btn btn-success waves-effect waves-light btn-sm"
                              onClick={() => {
                                setNewCityModal(!newCityModal);
                              }}
                            >
                              <i class="mdi mdi-file-plus"></i>{" "}
                              {props.t("Add New")}
                            </Link>
                          </Col>
                        </Row>
                      </Col> */}
                      <Col lg={6}>
                        <AvField
                          name="opening_time"
                          label={props.t("Opening Time")}
                          placeholder={props.t("Enter Opening Time")}
                          type="time"
                          errorMessage={props.t("Opening time cannot be empty")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="closing_time"
                          label={props.t("Closing Time")}
                          placeholder={props.t("Enter Closing Time")}
                          type="time"
                          errorMessage={props.t("Closing time cannot be empty")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvRadioGroup
                          inline
                          name="is_store"
                          required
                          errorMessage={props.t("Select an option")}
                        >
                          <Label>{props.t("Store Exists?")}</Label>
                          <br />
                          <AvRadio
                            onChange={(e, v) => {
                              setIsStore(true);
                            }}
                            label="Yes"
                            value="Yes"
                          />
                          <AvRadio
                            onChange={(e, v) => {
                              setIsStore(false);
                            }}
                            label="No"
                            value="No"
                          />
                        </AvRadioGroup>
                      </Col>
                      <Col lg={6}>
                        <AvRadioGroup
                          inline
                          name="transfer_privilege"
                          required
                          errorMessage={props.t("Select an option")}
                        >
                          <Label>{props.t("Transfer Privileged?")}</Label>
                          <br />
                          <AvRadio
                            onChange={(e, v) => {
                              setIsPrivileged(true);
                            }}
                            label="Yes"
                            value="Yes"
                          />
                          <AvRadio
                            onChange={(e, v) => {
                              setIsPrivileged(false);
                            }}
                            label="No"
                            value="No"
                          />
                        </AvRadioGroup>
                      </Col>
                      <Col lg={12}>
                        <hr />
                        <FormGroup className="mb-0 text-center">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                            >
                              {props.t("Submit")}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Button type="submit">Submit</Button> */}
                  </AvForm>
                  {/* <Modal
                    size="md"
                    isOpen={newCityModal}
                    toggle={() => setNewCityModal(!newCityModal)}
                  >
                    <ModalHeader toggle={() => setNewCityModal(!newCityModal)}>
                      {props.t("Add new city")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAddNewCity(e, v);
                                }}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <AvField
                                      name="name"
                                      label={props.t("Name of City") + " *"}
                                      placeholder={props.t("Enter City Name")}
                                      type="text"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: props.t(
                                            "Name cannot be empty."
                                          ),
                                        },
                                      }}
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          {props.t("Add")}
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Existing Branches")} </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing branches here")}
                  </CardSubtitle>
                  <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={allBranches && allBranches}
                    columns={columns}
                    noDataIndication="No data to display."
                    striped
                    hover
                    condensed
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withNamespaces()(AddBranch)));
